import { OnboardingService } from '@/core/services/onboarding.service';
import { StorageService } from '@/core/services/storage.service';
import { ConfirmDialogComponent } from '@/shared/ui/confirm-dialog.component';
import { Injectable, inject } from '@angular/core';
import { DialogService } from 'primeng/dynamicdialog';
import { filter, first } from 'rxjs';

const SKIP_THREAD_GUIDE = 'SKIP_THREAD_GUIDE';

@Injectable()
export class ThreadGuideService extends OnboardingService {
  private readonly dialogService = inject(DialogService);
  private readonly storageService = inject(StorageService);

  override start(): void {
    const skip = !!this.storageService.get<boolean>(SKIP_THREAD_GUIDE);

    if (!this.isClient || skip) {
      return;
    }

    this.shepherdService.modal = true;
    this.shepherdService.defaultStepOptions = {
      scrollTo: true,
      cancelIcon: {
        enabled: true,
      },
    };

    this.shepherdService.addSteps([
      this.createStep({
        id: 'app-guide-comment-positive',
        element: 'comment-positive',
        position: this.getPosition('bottom'),
        text: [
          'Это комментарий с доводом “за” проект. Он отображается зеленым цветом и отражает один из плюсов проекта',
        ],
        buttons: [this.createNextButton('Понятно')],
      }),
      this.createStep({
        id: 'app-guide-comment-rating-positive',
        element: 'comment-rating-positive',
        position: this.getPosition('left'),
        text: [
          'Это оценка значимости комментария “за” по пятибалльной шкале. Она формируется из оценок пользователей и ИИ. Чем выше оценка значимости комментария, тем выше влияние на итоговый рейтинг проекта',
        ],
      }),
      this.createStep({
        id: 'app-guide-comment-negative',
        element: 'comment-negative',
        position: this.getPosition('bottom'),
        text: [
          'Это комментарий с доводом “против” проекта. Он отображается красным цветом и отражает один из минусов проекта',
        ],
      }),
      this.createStep({
        id: 'app-guide-comment-rating-negative',
        element: 'comment-rating-negative',
        position: this.getPosition('left'),
        text: [
          'Комментарии “против” также имеют значимость по пятибалльной шкале. Чем выше оценка значимости комментария “против”, тем выше влияние на итоговый отрицательный рейтинг проекта',
        ],
      }),
      this.createStep({
        id: 'app-guide-comment-neutral',
        element: 'comment-neutral',
        position: this.getPosition('bottom'),
        text: [
          'Можно писать и нейтральные комментарии. Они не имеют оценки значимости, так как могут содержать вопросы, предложения и др',
        ],
      }),
      this.createStep({
        id: 'app-guide-project-topics',
        element: 'project-topics',
        position: this.getPosition('right'),
        text: [
          'На странице проекта также есть темы. Они объединяют комментарии для удобство чтения. Темы также имеют оценку значимости исходя из комментариев в теме',
        ],
        buttons: [this.createBackButton(), this.createNextButton('Завершить')],
      }),
    ]);

    this.dialogService
      .open(ConfirmDialogComponent, {
        width: 'min(440px, 100%)',
        data: {
          msg: 'Это страница проекта. Посмотрите как она устроена',
          yes: 'Начать!',
          no: 'Пропустить',
        },
      })
      .onClose.pipe(first(), filter(Boolean))
      .subscribe({
        next: () => {
          this.shepherdService.start();
        },
        complete: () => this.storageService.set(SKIP_THREAD_GUIDE, true),
      });
  }
}
