import { UserMenu } from '@/core';
import {
  ChangeDetectionStrategy,
  Component,
  inject,
  Input,
  ViewEncapsulation,
} from '@angular/core';
import { MenuItem } from 'primeng/api';
import { MenuModule } from 'primeng/menu';

import { UserComponent } from './user.component';

@Component({
  selector: 'sw-user-dropdown',
  standalone: true,
  imports: [UserComponent, MenuModule],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
  template: `
    <button
      #button
      class="flex w-full flex-row items-center justify-between"
      (click)="menu.toggle($event)"
      id="menu-btn"
    >
      <sw-user
        [name]="name"
        [description]="description"
        [avatarUrl]="avatarUrl"
        [showStar]="showStar"
      ></sw-user>
      <div>
        <svg
          width="3"
          height="16"
          viewBox="0 0 3 16"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <g opacity="0.3">
            <circle cx="1.5" cy="2" r="1.5" fill="#1A1A1A" />
            <circle cx="1.5" cy="8" r="1.5" fill="#1A1A1A" />
            <circle cx="1.5" cy="14" r="1.5" fill="#1A1A1A" />
          </g>
        </svg>
      </div>
    </button>

    <p-menu
      #menu
      styleClass="user-dropdown-menu"
      [model]="items"
      [popup]="true"
      showTransitionOptions=".1s linear"
    ></p-menu>
  `,
  styles: [
    `
      .user-dropdown-menu {
        &.p-menu {
          left: 0px !important;
          top: 0px !important;
          @apply mt-[60px] w-full
         max-w-[282px] overflow-hidden rounded-lg p-0 drop-shadow-menu;
          .p-menuitem-link {
            @apply p-5;

            .p-menuitem-text {
              @apply text-sm font-medium leading-[18px] text-black;
            }
            .p-menuitem-icon {
              @apply mr-[11px];
            }
          }
        }

        .icon {
          @apply h-[19px] w-[19px];
        }
      }
    `,
  ],
})
export class UserDropdownComponent {
  @Input({ required: true }) name!: string;
  @Input() description?: string | null;
  @Input() avatarUrl?: string;
  @Input() showStar?: boolean;

  readonly items: MenuItem[] = inject(UserMenu);
}
