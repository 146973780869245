import { CommentsService } from '@/core/services/comments.service';
import { ProjectService } from '@/core/services/project.service';
import { Injectable, inject } from '@angular/core';
import { tap } from 'rxjs';

@Injectable()
export class VerifyProjectService {
  private readonly projectService = inject(ProjectService);
  private readonly commentsService = inject(CommentsService);

  updateVerify(projectId: string, isVerified: boolean) {
    return this.projectService.updateVerified(projectId, isVerified).pipe(
      tap(({ isVerified }) => {
        this.commentsService.updateProjectInThread({
          isVerified,
        });
      })
    );
  }
}
