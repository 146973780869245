import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

@Component({
  selector: 'sw-svg',
  standalone: true,
  imports: [],
  changeDetection: ChangeDetectionStrategy.OnPush,
  template: `
    <svg
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xmlns:xlink="http://www.w3.org/1999/xlink"
      focusable="false"
      [style.width]="width || '100%'"
      [style.height]="height || '100%'"
    >
      <use [attr.xlink:href]="src" class="overflow-visible" />
    </svg>
  `,

  styles: [
    `
      :host {
        display: inline-flex;
        vertical-align: middle;
        flex-shrink: 0;
        align-items: center;
        justify-content: center;
        line-height: 0;
        fill: transparent;
        stroke: transparent;
      }
    `,
  ],
})
export class SVGComponent {
  @Input()
  width?: string;

  @Input()
  height?: string;

  @Input({ required: true })
  icon!: string;

  get src() {
    return `assets/img/icons/svg/${this.icon}.svg#${this.icon}`;
  }
}
