import { Category } from '@/core/types/category.type';
import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { CategoryTagComponent } from '@shared/ui/category-tag.component';

@Component({
  selector: 'sw-project-card-categories',
  standalone: true,
  imports: [CategoryTagComponent],
  changeDetection: ChangeDetectionStrategy.OnPush,
  template: `<div class="absolute left-5 top-5 flex flex-row flex-wrap gap-2">
    @for (c of list; track $index) {
    <div>
      <sw-category-tag [color]="c.color">
        {{ c.name }}
      </sw-category-tag>
    </div>
    }
  </div>`,
})
export class ProjectCardCategoriesComponent {
  @Input({ required: true }) list: Category[] = [];
}
