import { InjectionToken } from '@angular/core';

export const RATING_LABELS = [
  'Малозначимо',
  'Небольшой вклад',
  'Серьезный довод',
  'Убежден',
  'Железно!',
] as const;

export const RATING_LABELS_TOKEN = new InjectionToken<typeof RATING_LABELS>(
  'Rating labels array',
  {
    factory: () => RATING_LABELS,
  }
);
