import { CommentExtended, CommentMedia } from '@/core/models';

import {
  AnimationEvent,
  animate,
  style,
  transition,
  trigger,
} from '@angular/animations';
import { CommonModule, NgIf } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewEncapsulation,
  inject,
} from '@angular/core';
import {
  FormControl,
  FormGroup,
  FormsModule,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';

import { SettingsService, UserService } from '@/core';
import { isMissed, isPresent, replaceNewLines } from '@/core/helpers';
import { deepEqual } from '@/core/helpers/deep-equal';
import { EDITOR_COMMENT_CONTEXT } from '@/pages/project/comment-context';
import { PermissionActionDirective } from '@/shared/directives/permission/permission-action.directive';
import { InitialsPipe } from '@/shared/pipes';
import { ConfirmDialogComponent } from '@/shared/ui/confirm-dialog.component';
import { OpinionWrapperComponent } from '@/shared/ui/opinion';
import { ClickOutsideDirective, LetDirective } from '@shared/directives';
import { ButtonComponent } from '@shared/ui/button.component';
import { CommentEditorImageInputComponent } from '@shared/ui/comment-editor-image-input.component';
import { EmptyCommentAgreementDialogComponent } from '@shared/ui/empty-comment-agreement-dialog.component';
import { DynamicValidationMessage } from '@shared/ui/error';
import { RangeSliderComponent } from '@shared/ui/range-slider.component';
import { SVGComponent } from '@shared/ui/svg.component';
import { TextAreaComponent } from '@shared/ui/text-area.component';
import { AvatarModule } from 'primeng/avatar';
import { DialogService } from 'primeng/dynamicdialog';
import { EMPTY, filter, finalize, of, switchMap, take } from 'rxjs';
import { NotificationComponent } from '../notification.component';
import { CommentEditorImgPreviewComponent } from './comment-editor-img-preview.component';

@Component({
  selector: 'sw-comment-editor',
  standalone: true,
  imports: [
    AvatarModule,
    ButtonComponent,
    ClickOutsideDirective,
    CommentEditorImageInputComponent,
    CommentEditorImgPreviewComponent,
    CommonModule,
    DynamicValidationMessage,
    FormsModule,
    InitialsPipe,
    LetDirective,
    NgIf,
    NotificationComponent,
    OpinionWrapperComponent,
    PermissionActionDirective,
    RangeSliderComponent,
    ReactiveFormsModule,
    SVGComponent,
    TextAreaComponent,
  ],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
  animations: [
    trigger('showForm', [
      transition(':enter', [
        style({ opacity: 0, height: 42 }),
        animate('200ms', style({ height: '*', opacity: 1 })),
      ]),
      transition(':leave', [
        style({ opacity: 0.4, height: '*' }),
        animate('200ms', style({ opacity: 0, height: 0 })),
      ]),
    ]),
  ],

  template: `
    <div
      class="relative z-0"
      (swClickOutside)="handleOutside()"
      *swLet="loading$ | async as loading"
    >
      <form
        *ngIf="open; else closedView"
        [@showForm]="open"
        (@showForm.done)="animationDone($event)"
        [formGroup]="form"
        (ngSubmit)="handleSubmit()"
      >
        <fieldset
          [disabled]="loading"
          class="flex flex-col space-y-5 overflow-y-hidden"
        >
          <div>
            <sw-opinion-wrapper ngDefaultControl formControlName="agreement" />
          </div>

          <div *ngIf="showTopicWarning">
            <sw-notification status="info" icon="pi-info-circle">
              Пожалуйста, обратите внимание, что выбор "согласен" или "не
              согласен", относится к конкретному посту, а не к выбранной теме
            </sw-notification>
          </div>

          <div *ngIf="previewImages.length">
            <sw-comment-editor-img-preview
              [images]="previewImages"
              (clickRemove)="removePreview($event)"
            />
          </div>

          <div
            class="comment-form flex justify-center gap-5 "
            [class.flex-col]="commentControl.value.length > 0"
          >
            <div class="flex flex-1 flex-row items-start space-x-[10px] ">
              <div class="">
                <p-avatar
                  *ngIf="user$ | async as user"
                  [label]="!user.photo ? (user.fullName | swInitials : 2) : ''"
                  [image]="user.photo || undefined"
                  shape="circle"
                  class="block h-10 w-10 shrink-0"
                  [style]="{ width: '100%', height: '100%' }"
                />
              </div>

              <div class="flex-1">
                <sw-textarea
                  ngDefaultControl
                  formControlName="comment"
                  [placeholder]="placeholder"
                  styleClass="px-3 py-[9px] focus:pl-3 focus:pb-4 transition-all duration-300"
                />
              </div>
            </div>

            <div class="ml-auto flex flex-row">
              <div class="flex flex-row items-center space-x-[15px]">
                <sw-comment-editor-image-input
                  (fileChanged)="handleMediaFilesChanged($event)"
                />

                <button
                  type="submit"
                  swButton
                  [loading]="loading"
                  [disabled]="form.disabled"
                  aria-label="Отправить комментарий"
                  class="button-rounded aspect-square h-[37px] w-[37px] bg-accent"
                >
                  <sw-svg icon="arrow-right" width="20px" height="16px" />
                </button>
              </div>
            </div>
          </div>
        </fieldset>
      </form>
    </div>

    <ng-template #closedView>
      <div class="flex w-full flex-row items-center space-x-[10px]">
        <p-avatar
          *ngIf="user$ | async as user"
          [label]="!user.photo ? (user.fullName | swInitials : 2) : ''"
          [image]="user.photo || undefined"
          shape="circle"
          class="block h-10 w-10 shrink-0"
          [style]="{ width: '100%', height: '100%' }"
        />

        <button
          class="button-base w-full flex-1 rounded-b-md rounded-tr-md bg-black/5 px-5 py-[10px] text-left text-sm font-medium leading-5"
          swPermissionAction
          (click)="handleOpenForm()"
        >
          {{ placeholder }}
        </button>
      </div>
    </ng-template>
  `,
  styles: [
    `
      .comment-form:has(.ng-invalid.ng-dirty, textarea:focus) {
        @apply flex-col justify-center;
      }

      .comment-form sw-textarea .wrapper {
        &::after {
          padding: 5px 12px;
        }

        &:focus-within::after {
          padding-bottom: 12px;
        }

        textarea {
          @apply rounded-tl-none;
        }
      }
    `,
  ],
  providers: [DialogService],
})
export class CommentEditorComponent implements OnInit {
  @Input()
  open = false;

  @Input()
  showTopicWarning = false;

  @Input()
  comment?: Partial<
    Pick<CommentExtended, 'myAgreement' | 'comment' | 'media' | 'id'>
  > | null = null;

  @Output()
  closeForm = new EventEmitter<boolean>();

  get previewImages() {
    return [...this.savedFiles, ...this.mediaPreviews];
  }

  protected mediaFiles: File[] = [];
  protected mediaPreviews: string[] = [];
  protected savedFiles: CommentMedia[] = [];

  private disableClose = false;

  private readonly commentCtx = inject(EDITOR_COMMENT_CONTEXT, {
    self: true,
    optional: true,
  });

  protected readonly user$ = inject(UserService).user$;

  private readonly dialogService = inject(DialogService);

  private readonly hideEmptyCommentAgreementDialog$ =
    inject(SettingsService).hideEmptyCommentAgreementDialog$;

  protected loading$ = this.commentCtx?.loading$;

  protected readonly placeholder = 'Написать комментарий...';

  protected readonly commentControl = new FormControl(
    this.comment?.comment || '',
    {
      nonNullable: true,
      validators: [Validators.required, Validators.minLength(2)],
    }
  );

  protected readonly agreementControl = new FormControl<number | null>(
    this.comment?.myAgreement || null
  );

  protected readonly form = new FormGroup({
    comment: this.commentControl,
    agreement: this.agreementControl,
  });

  ngOnInit(): void {
    if (this.comment) {
      this.commentControl.patchValue(this.comment.comment || '');
      this.agreementControl.patchValue(this.comment.myAgreement || null);

      if (this.comment.media) {
        this.savedFiles = this.comment.media;
      }
    }
  }

  protected animationDone(event: AnimationEvent) {
    if (event.toState !== 'void') {
      const componentElement = event.element as HTMLElement;

      const textarea = componentElement.querySelector('textarea');

      textarea?.focus();

      // textarea?.scrollIntoView({
      //   behavior: 'smooth',
      //   block: 'center',
      // });
    }
  }

  protected handleMediaFilesChanged(files: File[] | null) {
    if (files === null) {
      return;
    }

    this.mediaFiles = [...this.mediaFiles, ...files];
    this.mediaPreviews = [
      ...this.mediaPreviews,
      ...files.map((f) => URL.createObjectURL(f)),
    ];
  }

  protected removePreview(index: number) {
    if (index >= this.savedFiles.length) {
      this.removeImage(index - this.savedFiles.length);
    } else {
      this.removeSavedFile(index);
    }
  }

  protected removeImage(index: number) {
    this.mediaFiles = this.mediaFiles.filter((_, i) => i !== index);
    this.mediaPreviews = this.mediaPreviews.filter((_, i) => i !== index);
  }

  protected removeSavedFile(index: number) {
    this.savedFiles = this.savedFiles.filter((_, i) => i !== index);
  }

  protected handleSubmit() {
    if (this.form.invalid) {
      return;
    }

    this.disableClose = true;

    const { comment, agreement } = this.form.getRawValue();

    const mediaFiles = this.mediaFiles;

    this.hideEmptyCommentAgreementDialog$
      .pipe(
        take(1),
        switchMap((v) => {
          if (!v && isMissed(agreement)) {
            return this.dialogService
              .open(EmptyCommentAgreementDialogComponent, {
                header: 'Сохранить комментарий',
                width: 'min(100%, 652px)',
                dismissableMask: false,
              })
              .onClose.pipe(
                take(1),
                switchMap((v) => (v ? of(v) : EMPTY))
              );
          }
          return of('');
        }),
        switchMap(() => {
          if (!this.commentCtx) {
            throw Error('the comment context is required to send a comment');
          }

          let savedFiles: string[] | null = null;
          if (this.comment?.media) {
            savedFiles = [];
            for (const elem of this.savedFiles) {
              savedFiles.push(...Object.values(elem));
            }
          }

          return this.commentCtx.send({
            files: mediaFiles,
            data: {
              comment: replaceNewLines(comment),
              ...(agreement !== null && { agreement }),
              ...(savedFiles && { savedFiles }),
            },
          });
        }),
        finalize(() => (this.disableClose = false))
      )
      .subscribe({
        next: () => {
          this.reset(true);
        },
      });
  }

  protected handleOutside() {
    const { comment, agreement } = this.form.getRawValue();

    const formHasData =
      isMissed(this.comment) &&
      (comment || isPresent(agreement) || this.mediaFiles.length > 0);

    const isChangedComment =
      this.comment &&
      (this.comment.comment !== comment ||
        this.comment.myAgreement !== agreement ||
        !deepEqual(this.comment.media, this.savedFiles));

    if (formHasData || this.disableClose) {
      return;
    }

    if (isChangedComment) {
      this.disableClose = true;

      this.dialogService
        .open(ConfirmDialogComponent, {
          header: 'Отменить изменения?',
          width: 'min(100%, 652px)',
          dismissableMask: false,
          data: {
            msg: 'Изменения не будут сохранены. Вы действительно желаете выйти?',
            yes: 'Выйти, без изменений',
          },
        })
        .onClose.pipe(take(1), filter(Boolean))
        .subscribe({
          next: () => this.reset(false),
          complete: () => (this.disableClose = false),
        });
      return;
    }

    this.reset(false);
  }

  protected handleOpenForm() {
    this.open = true;
  }

  protected reset(send: boolean) {
    this.form.reset({
      agreement: null,
      comment: '',
    });

    this.mediaFiles = [];
    this.mediaPreviews = [];
    this.savedFiles = [];

    // this.sentiment = null;
    this.open = false;
    this.closeForm.emit(send);
  }
}
