import {
  Component,
  inject,
  isDevMode,
  PLATFORM_ID,
  ViewEncapsulation,
} from '@angular/core';
import {
  ActivatedRoute,
  NavigationEnd,
  Router,
  RouterOutlet,
} from '@angular/router';
import { filter, map, Observable, of, pairwise, switchMap } from 'rxjs';

import { PortalModule } from '@angular/cdk/portal';
import { AsyncPipe, isPlatformServer, ViewportScroller } from '@angular/common';
import { toSignal } from '@angular/core/rxjs-interop';
import { Metrika } from 'ng-yandex-metrika';
import { ToastModule } from 'primeng/toast';
import { UserService } from './core';
import { PortalBridgeService } from './core/services/portal-bridge.service';
import { GemInfoEntryComponent, LikeInfoEntryComponent } from './features/info';

@Component({
  selector: 'sw-root',
  standalone: true,
  imports: [
    PortalModule,
    GemInfoEntryComponent,
    LikeInfoEntryComponent,
    RouterOutlet,
    ToastModule,
    AsyncPipe,
  ],
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class AppComponent {
  private readonly activatedRoute = inject(ActivatedRoute);
  private readonly userService = inject(UserService);

  private readonly router = inject(Router);
  private readonly viewportScroller = inject(ViewportScroller);
  private readonly metrika = inject(Metrika);

  private readonly platformId = inject(PLATFORM_ID);

  private readonly portalBridge = inject(PortalBridgeService);
  protected readonly portal$ = this.portalBridge.portal$;

  protected readonly dialogInfoType = toSignal<'gem' | 'karma' | ''>(
    this.userService.isLoggedIn$.pipe(
      switchMap((isLoggedIn) =>
        isLoggedIn
          ? this.activatedRoute.queryParams.pipe(
              map(({ info }) =>
                info === 'gem' ? 'gem' : info === 'karma' ? 'karma' : ''
              )
            )
          : of('')
      )
    ) as Observable<'gem' | 'karma' | ''>
  );

  constructor() {
    if (isPlatformServer(this.platformId)) {
      return;
    }

    this.router.events
      .pipe(
        filter(
          (event: unknown): event is NavigationEnd =>
            event instanceof NavigationEnd
        ),
        pairwise()
      )
      .subscribe((nav) => {
        const [prev, curr] = [
          nav[0].urlAfterRedirects,
          nav[1].urlAfterRedirects,
        ];

        this.sendNavigationMetrics(prev, curr);

        const skipScrollUrls = ['profile'];
        const alwaysSkipUrls = ['thread'];

        const isUrlsSkip =
          nav.every((n) =>
            skipScrollUrls.some((v) => n.urlAfterRedirects.includes(v))
          ) || alwaysSkipUrls.some((v) => nav[1].urlAfterRedirects.includes(v));

        if (isUrlsSkip) {
          return;
        }
        this.viewportScroller.scrollToPosition([0, 0]);
      });
  }

  private sendNavigationMetrics = (prev: string, curr: string) => {
    if (isDevMode()) {
      return;
    }

    if (prev !== curr) {
      this.metrika.hit(curr, { referer: prev });
    }
  };

  // private readonly httpClient = inject(HttpClient);
  // destroySession() {
  //   this.httpClient.post('/test', {}, {}).subscribe();
  // }
}
