import { ChangeDetectionStrategy, Component } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';

import { DynamicValidationMessage } from '@/shared/ui/error';
import { NotificationComponent } from '@/shared/ui/notification.component';
import { TextAreaComponent } from '@/shared/ui/text-area.component';
import { NgIf } from '@angular/common';
import { EditorSentimentBtnsComponent } from './editor-sentiment-btns.component';
import { EditorTopicTagComponent } from './editor-topic-tag.component';
import { TopicEditor } from './topic-editor';

@Component({
  selector: 'sw-project-topic-editor',
  standalone: true,
  imports: [
    NgIf,
    DynamicValidationMessage,
    TextAreaComponent,
    EditorSentimentBtnsComponent,
    EditorTopicTagComponent,
    ReactiveFormsModule,
    NotificationComponent,
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
  template: `
    <div
      class="w-full space-y-5 rounded-lg bg-black/5 p-5"
      [formGroup]="formGroup"
    >
      <div class="flex flex-row pt-5">
        <span
          class=" text-[21px] font-bold leading-[18px] tracking-tighter text-black"
        >
          Напишите за и против вашего проекта
        </span>

        <button
          *ngIf="canDelete"
          class="ml-auto text-accent"
          type="button"
          (click)="delete.emit()"
        >
          Удалить
        </button>
      </div>

      <div class="space-y-5 pt-5">
        <div class="">
          <sw-editor-sentiment-btns
            ngDefaultControl
            formControlName="agreement"
          />
        </div>
      </div>
      <div class="space-y-5 pt-5">
        <span
          class="text-[21px] font-bold leading-[18px] tracking-tighter text-black"
        >
          Напишите ваш довод
        </span>
        <div class="space-y-5">
          <p class="text-[14px]">
            Пожалуйста, представьте свой довод. Если у вас есть несколько
            аргументов, пожалуйста, разделите их на отдельные доводы
          </p>

          <div class="">
            <sw-textarea
              ngDefaultControl
              formControlName="comment"
              placeholder="О чем ваш довод?"
            />
          </div>
        </div>
      </div>

      <ng-container *ngIf="commentControl?.valid">
        <div class="">
          <span
            class="text-[21px] font-bold leading-[18px] tracking-tighter text-black"
          >
            Тема проекта
          </span>
        </div>

        <div>
          <sw-notification status="info" icon="pi-info-circle">
            Темы проекта позволяют удобнее читать доводы. Вы можете не выбирать
            тему довода или выбрать из шаблона или написать свою тему
          </sw-notification>
        </div>

        <div class="">
          <div class="">
            <sw-editor-topic-tag formControlName="name" ngDefaultControl />
          </div>
        </div>
        <div class="">
          <button
            class="button flex h-11 w-full flex-row items-center justify-center space-x-[10px] rounded-[8px] border border-black/20 bg-black/5"
            type="button"
            (click)="handleAddMore()"
          >
            <span *ngIf="creating">
              <svg
                width="25px"
                height="24px"
                viewBox="0 0 25 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M12.5 4C13.0523 4 13.5 4.44772 13.5 5V11L19.5 11C20.0523 11 20.5 11.4477 20.5 12C20.5 12.5523 20.0523 13 19.5 13L13.5 13L13.5 19C13.5 19.5523 13.0523 20 12.5 20C11.9477 20 11.5 19.5523 11.5 19L11.499 13L5.5 13C4.94772 13 4.5 12.5523 4.5 12C4.5 11.4477 4.94772 11 5.5 11L11.499 11L11.5 5C11.5 4.44772 11.9477 4 12.5 4Z"
                  fill="#1A1A1A"
                />
              </svg>
            </span>
            <span class="text-base font-bold text-black">{{
              buttonLabel
            }}</span>
          </button>
        </div>
      </ng-container>
    </div>
  `,
})
export class ProjectTopicEditorComponent extends TopicEditor {
  get commentControl() {
    return this.formGroup.get('comment');
  }

  override get buttonLabel() {
    return this.creating ? 'Еще довод' : 'Применить';
  }
}
