import { ProjectService, UserService } from '@/core';
import { CommentsService } from '@/core/services/comments.service';
import { Directive, inject } from '@angular/core';
import { Observable, tap } from 'rxjs';

import { GemContext } from './gem-context';
import { GEM_CONTEXT } from './gem-context.token';

@Directive({
  selector: '[swGemsToProject]',
  standalone: true,
  providers: [
    {
      provide: GEM_CONTEXT,
      useExisting: SendGemsToProjectDirective,
    },
  ],
})
export class SendGemsToProjectDirective extends GemContext {
  private readonly userService = inject(UserService);
  private readonly projectService = inject(ProjectService, { optional: true });
  private readonly commentsService = inject(CommentsService, {
    optional: true,
  });

  override sendGems(count: number): Observable<unknown> {
    return this.gemService.addGemsToProject(this.recipient, count).pipe(
      tap({
        next: ({ projectId, count: c }) => {
          if (this.projectService) {
            this.projectService.updateProjectGames(projectId, c);
          }

          if (this.commentsService) {
            this.commentsService.updateProjectGems(projectId, c);
          }
          this.userService.writeOffGems(count);
        },
      })
    );
  }
}
