import {
  Directive,
  EventEmitter,
  HostBinding,
  HostListener,
  Input,
  Output,
} from '@angular/core';

@Directive({
  selector: '[swDndFiles]',
  standalone: true,
})
export class DndFilesDirective {
  private acceptTypesRegExp: RegExp[] | null = null;

  @Input() set acceptExtension(accept: string | string[] | undefined) {
    if (!accept || accept?.length === 0) {
      this.acceptTypesRegExp = null;
    } else {
      const acceptArray = Array.isArray(accept)
        ? accept
        : accept.split(',').map((v) => v.trim());

      this.acceptTypesRegExp = acceptArray.map(
        (type: string) => new RegExp(type)
      );
    }
  }
  @Input() swDndFilesDisabled = false;

  @Output() fileDropped = new EventEmitter<File[]>();

  @HostBinding('class.file-over') fileOver = false;

  @HostListener('dragover', ['$event']) onDragOver(e: Event) {
    e.preventDefault();
    e.stopPropagation();

    if (this.swDndFilesDisabled) {
      return;
    }

    this.fileOver = true;
  }

  @HostListener('dragleave', ['$event']) onDragLeave(e: Event) {
    e.preventDefault();
    e.stopPropagation();

    if (this.swDndFilesDisabled) {
      return;
    }
    this.fileOver = false;
  }

  @HostListener('drop', ['$event']) ondrop(e: DragEvent) {
    e.preventDefault();
    e.stopPropagation();

    if (this.swDndFilesDisabled) {
      return;
    }

    this.fileOver = false;
    const files = <File[]>Array.from(e.dataTransfer?.files || []);

    if (!files.length) {
      return;
    }

    if (!this.acceptTypesRegExp) {
      this.fileDropped.emit(files);
      return;
    }

    const filteredFiles = files.filter((f) => {
      return this.acceptTypesRegExp?.some((pattern) => {
        return pattern.test(f.type);
      });
    });

    if (!filteredFiles.length) {
      return;
    }

    this.fileDropped.emit(filteredFiles);
  }
}
