import { isPlatformServer } from '@angular/common';
import {
  inject,
  Injectable,
  makeStateKey,
  PLATFORM_ID,
  TransferState,
} from '@angular/core';
import { Router } from '@angular/router';
import { TUser, User, UserBasic, UserResponse } from '@core/models';
import { LoginReq, SignupReq } from '@core/types';
import {
  BehaviorSubject,
  catchError,
  distinctUntilChanged,
  EMPTY,
  map,
  of,
  switchMap,
  tap,
  throwError,
} from 'rxjs';

import { HttpContext } from '@angular/common/http';
import { MAP_REQ_HTTP_TOKEN } from '@core/interceptors';
import { JWTPair } from '@core/types/jwt-pair';
import { VerifyPayload } from '@core/types/verify';
import { HttpCacheManager } from '@ngneat/cashew';
import { isPresent } from '../helpers';
import { BaseAPIService } from './base-api.service';
import { TokenService } from './token.service';

type UserContentType = 'photo' | 'cover';

@Injectable({ providedIn: 'root' })
export class UserAPIService extends BaseAPIService {
  private readonly platformId = inject(PLATFORM_ID);
  private readonly transferState = inject(TransferState);
  private readonly tokenService = inject(TokenService);
  private readonly cacheManager = inject(HttpCacheManager);

  fetchMe() {
    const getMeKey = makeStateKey<TUser | null>('getMe');
    const token =
      this.tokenService.getAccessToken() || this.tokenService.getRefreshToken();

    if (!token) {
      return throwError(() => new Error('notoken'));
    }

    if (this.transferState.hasKey(getMeKey)) {
      const user = this.transferState.get(getMeKey, null);
      this.transferState.remove(getMeKey);

      return user ? of(user) : throwError(() => new Error('empty user'));
    }

    return this.httpClient
      .get<UserResponse>('/users/me', {
        withCredentials: true,
      })
      .pipe(
        catchError(() => {
          return of(null);
        }),
        map((u) => {
          if (u === null) {
            return null;
          }

          const categoriesExperience = (u.categoriesExperience || []).reduce<
            Record<string, number>
          >((acc, curr) => ((acc[curr.slug] = curr.experience), acc), {});

          const categoriesLikes = (u.categoriesLikes || []).reduce<
            Record<string, number>
          >((acc, curr) => ((acc[curr.slug] = curr.likes), acc), {});

          return {
            ...u,
            categoriesLikes,
            categoriesExperience,
          } as TUser;
        }),
        tap({
          next: (u) => {
            if (isPlatformServer(this.platformId)) {
              this.transferState.set(getMeKey, u);
            }
          },
        })
      );
  }

  updateUser(payload: Partial<NonNullable<UserBasic>>) {
    return this.httpClient.patch<TUser>('/users/me', payload).pipe(
      tap({
        next: () => {
          this.cacheManager.clear();
        },
      })
    );
  }

  updatePassword(payload: { oldPassword: string; newPassword: string }) {
    return this.httpClient.patch('/users/me/password', payload);
  }

  uploadContent(file: File, type: UserContentType) {
    const context = new HttpContext();
    context.set(MAP_REQ_HTTP_TOKEN, false);

    const fd = new FormData();
    fd.append('file', file);

    return this.httpClient
      .post<TUser>(`/users/me/content/${type}`, fd, {
        context,
      })
      .pipe(
        tap(() => {
          this.cacheManager.clear();
        })
      );
  }

  deleteContent(type: UserContentType) {
    return this.httpClient.delete<unknown>(`/users/me/content/${type}`).pipe(
      tap(() => {
        this.cacheManager.clear();
      })
    );
  }

  getNotificationSettings() {
    return this.httpClient.get<Record<string, boolean>>(
      '/users/me/notification-settings',
      {
        params: {
          t: Date.now(),
        },
      }
    );
  }

  updateNotificationSettings(payload: Record<string, boolean>) {
    return this.httpClient.put<Record<string, boolean>>(
      '/users/me/notification-settings',
      payload
    );
  }

  updateSettings(payload: Record<string, unknown>) {
    return this.httpClient.put<User>('/users/me/settings', payload);
  }

  login(creds: LoginReq) {
    return this.httpClient.post<JWTPair>('/login', creds, {
      withCredentials: true,
    });
    // .pipe(tap((pair) => this.tokenService.setTokenPair(pair)));
  }

  refreshToken(payload: { refreshToken: string }) {
    return this.httpClient.post<JWTPair>('/refresh', payload, {
      withCredentials: true,
    });
  }

  registration(payload: SignupReq) {
    return this.httpClient.post<unknown>('/users/registration', payload);
  }

  verifyEmail(payload: VerifyPayload) {
    return this.httpClient.post<JWTPair>(
      '/users/registration-confirm',
      payload
    );
  }

  recoveryFindAccount(email: string) {
    return this.httpClient.post('/users/send-password-recovery-code', {
      email,
    });
  }

  recoveryVerifyConfirmationCode(payload: {
    email: string;
    confirmCode: string;
  }) {
    return this.httpClient.post(`/users/password-recovery-confirm`, payload);
  }

  recoveryUpdatePassword(payload: {
    email: string;
    confirmCode: string;
    newPassword: string;
  }) {
    return this.httpClient.post(`/users/password-recovery`, payload);
  }

  sendUpdateEmailCode(payload: { newEmail: string; password: string }) {
    return this.httpClient.post('/users/send-change-email-code', payload);
  }

  updateEmail(payload: {
    newEmail: string;
    password: string;
    confirmCode: string;
  }) {
    return this.httpClient.post('/users/change-email-confirm', payload);
  }

  logout() {
    return this.httpClient.post('/logout', {}, { withCredentials: true }).pipe(
      tap({
        finalize: () => {
          this.cacheManager.clear();
        },
      })
    );
  }
}

@Injectable({ providedIn: 'root' })
export class UserService {
  private readonly router = inject(Router);
  private readonly api = inject(UserAPIService);
  protected readonly pId = inject(PLATFORM_ID);

  private readonly userSubject$ = new BehaviorSubject<User | null>(null);

  readonly tokenService = inject(TokenService);

  private readonly isLoggedInSubject$ = new BehaviorSubject<boolean>(
    !!(
      this.tokenService.getAccessToken() || this.tokenService.getRefreshToken()
    )
  );

  get isLoggedIn$() {
    return this.isLoggedInSubject$.asObservable();
  }

  readonly user$ = this.userSubject$
    .asObservable()
    .pipe(distinctUntilChanged());

  get userGems$() {
    return this.user$.pipe(map((user) => user?.gems || 0));
  }

  get userLikes$() {
    return this.user$.pipe(map((user) => user?.categoriesLikes || {}));
  }

  get isSuperuser$() {
    return this.user$.pipe(map((user) => user?.isSuperuser || false));
  }

  getMe() {
    return this.api.fetchMe().pipe(
      tap({
        next: (u) => {
          if (u === null) {
            return this.reset();
          }

          this.userSubject$.next(new User(u));
          this.isLoggedInSubject$.next(true);
        },
        error: (e: Error) => {
          if (e.message === 'notoken' || e.message === 'empty user') {
            this.reset();
          }
        },
      }),
      catchError(() => EMPTY)
    );
  }

  updateUser(payload: Partial<NonNullable<UserBasic>>) {
    return this.api
      .updateUser(payload)
      .pipe(tap((u) => this.userSubject$.next(new User(u))));
  }

  uploadContent(file: File, type: UserContentType) {
    return this.api.uploadContent(file, type).pipe(
      tap((u) => {
        this.userSubject$.next(new User(u));
      })
    );
  }

  deleteContent(type: UserContentType) {
    return this.api.deleteContent(type).pipe(
      tap(() => {
        const user = this.userSubject$.value;
        this.userSubject$.next(
          user ? new User({ ...user, [type]: null }) : null
        );
      })
    );
  }

  updateSettings(payload: Record<string, unknown>) {
    return this.api
      .updateSettings({
        ...(this.userSubject$.value?.settings || {}),
        ...payload,
      })
      .pipe(
        tap({
          next: (u) => {
            this.userSubject$.next(u ? new User(u) : null);
          },
        })
      );
  }

  //TODO move to auth service
  login(creds: LoginReq) {
    return this.api.login(creds).pipe(
      // tap((pair) => this.tokenService.setTokenPair(pair)),
      switchMap(() => this.getMe())
    );
  }

  registration(payload: SignupReq) {
    return this.api.registration(payload);
  }

  refreshToken() {
    const refreshToken = this.tokenService.getRefreshToken();
    if (!refreshToken) {
      return throwError(() => 'refresh token is empty');
    }

    return this.api.refreshToken({ refreshToken }).pipe(
      tap((pair) => {
        this.tokenService.setTokenPair(pair);
      })
    );
  }

  verifyEmail(payload: VerifyPayload) {
    return this.api.verifyEmail(payload).pipe(
      tap((pair) => {
        this.tokenService.setTokenPair(pair);
      }),
      switchMap(() => this.getMe())
    );
  }

  recoveryFindAccount(email: string) {
    return this.api.recoveryFindAccount(email);
  }

  recoveryVerifyConfirmationCode(payload: VerifyPayload) {
    return this.api.recoveryVerifyConfirmationCode(payload);
  }

  recoveryUpdatePassword(
    payload: VerifyPayload & {
      newPassword: string;
    }
  ) {
    return this.api.recoveryUpdatePassword(payload);
  }

  sendUpdateEmailCode(payload: { newEmail: string; password: string }) {
    return this.api.sendUpdateEmailCode(payload);
  }

  updateEmail(payload: {
    newEmail: string;
    password: string;
    confirmCode: string;
  }) {
    return this.api.updateEmail(payload).pipe(
      tap(() => {
        this.updateUser({ email: payload.newEmail });
        this.logout();
      })
    );
  }

  addGems(count: number) {
    const u = this.userSubject$.value;

    if (!u) {
      return;
    }

    this.userSubject$.next(
      new User({
        ...u,
        gems: isPresent(u.gems) ? Math.max(u.gems + count, 0) : u.gems,
      })
    );
  }

  writeOffGems(count: number) {
    this.addGems(-count);
  }

  writeOffLikes(category: string, count: number) {
    const u = this.userSubject$.value;

    if (!u) {
      return;
    }

    const categoriesLikes = u.categoriesLikes || {};

    categoriesLikes[category] = Math.max(
      (categoriesLikes[category] || 0) - count,
      0
    );

    this.userSubject$.next(
      new User({
        ...u,
        categoriesLikes,
      })
    );
  }

  logout() {
    this.api
      .logout()
      .pipe(catchError(() => of(null)))
      .subscribe({
        next: () => {
          this.reset();
          this.router.navigateByUrl('/login');
        },
      });
  }

  updatePassword(payload: { oldPassword: string; newPassword: string }) {
    return this.api.updatePassword(payload);
  }

  private reset() {
    this.tokenService.removeSessionToken();
    this.isLoggedInSubject$.next(false);
    this.userSubject$.next(null);
  }
}
