import { isPlatformServer } from '@angular/common';
import {
  inject,
  Injectable,
  makeStateKey,
  PLATFORM_ID,
  TransferState,
} from '@angular/core';
import {
  Category,
  CategoryExtended,
  CategoryMenus,
} from '@core/types/category.type';
import { BehaviorSubject, map, of, tap } from 'rxjs';

import { CacheBucket, HttpCacheManager, withCache } from '@ngneat/cashew';
import { isMissed, isPresent } from '../helpers';
import { BaseAPIService } from './base-api.service';

const DEFAULT_FIELDS = [
  {
    name: 'Горячие проекты',
    color: '#ffffff',
    slug: 'trending',
    gems: null,
    likes: 0,
    iconFile: 'fire',
    projects: 0,
    comments: 0,
  },
  {
    name: 'Новое',
    color: '#ffffff',
    slug: 'latest',
    gems: null,
    likes: 0,
    iconFile: 'diamond',
    projects: 0,
    comments: 0,
  },
] as (CategoryExtended & { gems: null })[];
@Injectable({ providedIn: 'root' })
export class CategoriesAPIService extends BaseAPIService {
  private readonly platformId = inject(PLATFORM_ID);
  private readonly transferState = inject(TransferState);

  protected readonly categoriesBucket = new CacheBucket();
  private readonly cacheManager = inject(HttpCacheManager);

  fetchList() {
    // includeGems?: boolean
    this._loading$.next(true);

    const getCategoriesKey = makeStateKey<Category[]>('getCategory');

    if (this.transferState.hasKey(getCategoriesKey)) {
      const categories = this.transferState.get(getCategoriesKey, []);
      if (categories.length > 0) {
        return of(categories);
      }
    }

    return this.httpClient
      .get<Category[]>('/categories/', {
        context: withCache(),
        // params: {
        //   includeGems,
        // },
      })
      .pipe(
        tap({
          next: (c) => {
            if (isPlatformServer(this.platformId)) {
              this.transferState.set(getCategoriesKey, c);
            }
          },
          finalize: () => this._loading$.next(false),
        })
      );
  }

  fetchCategoryMenus() {
    this._loading$.next(true);

    const getCatMenu = makeStateKey<CategoryExtended[]>('getCategoryMenu');

    if (this.transferState.hasKey(getCatMenu)) {
      const categories = this.transferState.get(getCatMenu, []);

      this.transferState.remove(getCatMenu);

      if (categories.length > 0) {
        this.cacheManager.set('/categories/feed', categories, {
          bucket: this.categoriesBucket,
        });
        return of(categories);
      }
    }

    return this.httpClient
      .get<CategoryExtended[]>('/categories/feed', {
        context: withCache({
          bucket: this.categoriesBucket,
        }),
      })
      .pipe(
        tap({
          next: (c) => {
            if (isPlatformServer(this.platformId)) {
              this.transferState.set(getCatMenu, c);
            }
          },
          finalize: () => this._loading$.next(false),
        })
      );
  }

  invalidateCategories() {
    this.cacheManager.delete(this.categoriesBucket);
  }
}

@Injectable({ providedIn: 'root' })
export class CategoriesService {
  private readonly api = inject(CategoriesAPIService);

  private readonly categoriesSubject$ =
    new BehaviorSubject<CategoryMenus | null>(null);

  get categoriesWithCounts$() {
    return this.categoriesSubject$.asObservable();
  }

  getCategoryWithCount(slug: string) {
    if (isMissed(this.categoriesSubject$.value)) {
      this.fetchCategoriesWithCounts().subscribe();
    }

    return this.categoriesWithCounts$.pipe(
      map((v) =>
        isPresent(v)
          ? [...v.top, ...v.bottom].find((c) => c.slug === slug) || null
          : null
      )
    );
  }

  fetchList() {
    return this.api.fetchList();
  }

  fetchCategoriesWithCounts() {
    return this.api.fetchCategoryMenus().pipe(
      map((c) => {
        return {
          top: c,
          bottom: DEFAULT_FIELDS,
        };
      }),
      tap((c) => this.categoriesSubject$.next(c))
    );
  }

  addGemsToCategory({ slug, count }: { slug: string; count: number }) {
    const v = this.categoriesSubject$.value;
    if (v === null) {
      return;
    }

    this.categoriesSubject$.next({
      top: v.top.map((c) =>
        c.slug === slug ? { ...c, gems: c.gems + count } : c
      ),
      bottom: structuredClone(v.bottom),
    });
    this.api.invalidateCategories();
  }
}
