import { TextComponent } from '@/shared/ui/text.component';
import { NgIf } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output,
} from '@angular/core';
import { FormGroup, FormControl } from '@angular/forms';

@Component({
  selector: 'sw-project-editor-topic-preview',
  standalone: true,
  imports: [TextComponent, NgIf],
  changeDetection: ChangeDetectionStrategy.OnPush,
  template: `
    <div class="w-full rounded-lg bg-black/5 p-5">
      <div class="flex flex-row border-b border-black/10 pb-[15px]">
        <span class="text-sm font-medium leading-normal text-black/50"
          >Довод</span
        >
        <button class="ml-auto text-accent" type="button" (click)="edit.emit()">
          Редактировать
        </button>
      </div>
      <div class="pt-[15px] text-black">
        <div
          class="flex flex-row items-start justify-between space-x-1 text-sm leading-[22px]"
        >
          <ng-container *ngIf="title">
            <span class="block font-bold">{{ title }}</span> &nbsp;
          </ng-container>

          <span
            class="ml-auto block"
            *ngIf="agreement"
            [class.text-green]="agreementValue > 0"
            [class.text-red]="agreementValue < 0"
            >{{ agreement }}</span
          >
        </div>

        <sw-text [text]="message" />
      </div>
    </div>
  `,
  styles: [
    `
      :host {
        @apply scroll-my-5;
      }
    `,
  ],
})
export class ProjectEditorTopicPreviewComponent {
  @Input({ required: true })
  formGroup!: FormGroup<{
    comment: FormControl<string>;
    name: FormControl<string>;
    agreement: FormControl<number | null>;
  }>;

  @Output()
  edit = new EventEmitter<void>();

  get title() {
    return this.formGroup.get('name')?.value || '';
  }

  get message() {
    return this.formGroup.get('comment')?.value || '';
  }

  get agreementValue() {
    return this.formGroup.get('agreement')?.value || 0;
  }

  get agreement() {
    const val = this.agreementValue;

    if (val === 0) {
      return null;
    } else if (val > 0) {
      return 'За';
    }
    return 'Против';
  }
}
