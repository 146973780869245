import { KeyValue } from '@angular/common';
import { inject, Pipe, PipeTransform } from '@angular/core';

import { VALIDATION_ERROR_MESSAGES } from './error.token';

@Pipe({
  name: 'swErrorMessages',
  standalone: true,
})
export class ErrorMessagesPipe implements PipeTransform {
  protected readonly errorsMap = inject(VALIDATION_ERROR_MESSAGES);

  transform({ value, key }: KeyValue<string, unknown>): string | null {
    if (!this.errorsMap[key]) {
      console.warn(`Missing message for ${key} validator!`);
      return null;
    }

    return this.errorsMap[key](value);
  }
}
