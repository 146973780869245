export interface UserBasic {
  email: string | null;
  surname: string;
  name: string;
  phone: string | null;
  about: string | null;
}

export interface TUser
  extends Pick<UserBasic, 'surname' | 'name'>,
    Partial<Omit<UserBasic, 'surname' | 'name'>> {
  id: string;
  photo?: string | null;
  cover?: string | null;
  rank?: string | null;
  gems?: number;
  likes?: number;
  settings?: Record<string, unknown>;
  isSuperuser?: boolean;
  categoriesExperience?: Record<string, number>;
  categoriesLikes?: Record<string, number>;
}

export interface UserResponse
  extends Omit<TUser, 'categoriesExperience' | 'categoriesLikes'> {
  categoriesExperience?: { slug: string; experience: number }[];
  categoriesLikes?: { slug: string; likes: number }[];
}

export class User {
  public id!: string;
  public surname!: string;
  public name!: string;
  public email?: string | null;
  public about?: string | null;
  public phone?: string | null;
  public photo?: string | null;
  public cover?: string | null;
  public rank?: string | null;
  public gems?: number;
  public likes?: number;
  public settings?: Record<string, unknown>;
  public isSuperuser?: boolean;
  public categoriesExperience?: Record<string, number> = {};
  public categoriesLikes?: Record<string, number> = {};

  constructor(user: TUser) {
    Object.assign(this, user);
  }

  get showStar() {
    return this.rank?.toLocaleLowerCase() === 'эксперт';
  }

  get fullName() {
    return `${this.name} ${this.surname}`;
  }

  get shortUser() {
    return {
      name: this.fullName,
      avatarUrl: this.photo,
      rank: this.rank,
      showStar: this.showStar,
    };
  }
}
