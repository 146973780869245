import { OnboardingService } from '@/core/services/onboarding.service';
import { StorageService } from '@/core/services/storage.service';
import { GUIDE_PROJECT_ID } from '@/core/tokens/guide-project-id.token';
import { ConfirmDialogComponent } from '@/shared/ui/confirm-dialog.component';
import { Injectable, inject } from '@angular/core';
import { Router } from '@angular/router';
import { DialogService } from 'primeng/dynamicdialog';
import { filter, first } from 'rxjs';

const SKIP_FEED_GUIDE = 'SKIP_FEED_GUIDE';

@Injectable()
export class FeedGuideService extends OnboardingService {
  protected readonly dialogService = inject(DialogService);
  protected readonly router = inject(Router);
  private readonly storageService = inject(StorageService);

  protected readonly guideProjectId = inject(GUIDE_PROJECT_ID);

  override start(): void {
    const skip = !!this.storageService.get<boolean>(SKIP_FEED_GUIDE);
    if (!this.isClient || skip) {
      return;
    }

    this.shepherdService.modal = true;
    this.shepherdService.defaultStepOptions = {
      scrollTo: true,
      cancelIcon: {
        enabled: true,
      },
    };

    this.shepherdService.addSteps([
      this.createStep({
        id: 'app-guide-rating',
        element: 'project-rating',
        position: this.getPosition('bottom'),
        text: [
          'Рейтинг проекта формируется исходя из комментариев “за” и “против” к проекту',
        ],
        buttons: [this.createNextButton()],
      }),
      this.createStep({
        id: 'app-guide-like',
        element: 'like-reactions',
        position: this.getPosition('right'),
        text: [
          'Чем больше лайков у проекта, тем выше вознаграждение за значимые комментарии получают пользователи',
        ],
      }),
      this.createStep({
        id: 'app-guide-gem',
        element: 'gem-reactions',
        position: this.getPosition('right'),
        text: [
          'К проекту можно также добавить кристаллов, которые получат пользователи, написавшие наиболее значимые комментарии. Гемы можно менять на датасеты, вычислительные мощности и другое',
        ],
      }),
      this.createStep({
        id: 'app-guide-project',
        element: 'project-link',
        position: this.getPosition('bottom'),
        text: ['Теперь перейдем в проект и посмотрим что там'],
        canClickTarget: true,
        buttons: [
          this.createBackButton(),
          this.createButton({
            text: 'Понятно',
            type: 'next',
            action: () => {
              this.router.navigate(['thread', this.guideProjectId]).then(() => {
                this.shepherdService?.complete();
              });
            },
          }),
        ],
      }),
    ]);

    this.dialogService
      .open(ConfirmDialogComponent, {
        width: 'min(480px, 100%)',
        header: 'Добро пожаловать в Единое окно в сфере ИИ!',
        data: {
          msg: ' Здесь граждане и искусственный интеллект вместе обсуждают инициативы в сфере ИИ. Посмотрите как проходит такая коммуникация',
          yes: 'Начать!',
          no: 'Пропустить',
        },
      })
      .onClose.pipe(first(), filter(Boolean))
      .subscribe({
        next: () => {
          this.shepherdService.start();
        },
        complete: () => this.storageService.set(SKIP_FEED_GUIDE, true),
      });
  }
}
