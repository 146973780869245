import { inject, InjectionToken } from '@angular/core';

import { APP_ORIGIN_URL } from './app-origin-url.token';

export type GetPostUrl = (projectId: string, commentId?: string) => string;

export const GET_POST_URL = new InjectionToken<GetPostUrl>(
  'Get post url function',
  {
    factory: () => {
      const origin = inject(APP_ORIGIN_URL);

      return (projectId: string, commentId?: string) => {
        return (
          `${origin}/thread/${projectId}` + (commentId ? `/${commentId}` : '')
        );
      };
    },
  }
);
