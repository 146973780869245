import { PluralPipe, ShortNumberPipe } from '@/shared/pipes';
import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { RouterModule } from '@angular/router';
import { CategoryIconComponent } from '@shared/ui/category-icon.component';
import { SVGComponent } from '@shared/ui/svg.component';

import { TaxonomyItem } from './taxonomy.type';

@Component({
  selector: 'sw-taxonomy-item',
  standalone: true,
  imports: [
    CommonModule,
    CategoryIconComponent,
    RouterModule,
    PluralPipe,
    ShortNumberPipe,
    SVGComponent,
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
  template: `
    <a [routerLink]="item.path" [queryParams]="item.queryParams" class="group">
      <div
        class="flex w-full flex-row items-center space-x-5 px-5 py-[10px] group-hover:bg-black/10 group-focus-visible:bg-black/10"
      >
        <div class="h-[18px] w-[17px] text-black/50">
          <sw-category-icon
            [icon]="item.icon"
            class="flex text-base text-black/50"
          />
        </div>
        <div class="w-full space-y-[5px]">
          <div class="flex w-full flex-row items-start">
            <span class="break-word  mr-1 text-sm leading-[18px]">
              {{ item.title }}
            </span>
            <!-- <div
              *ngIf="item.gems !== null"
              class="ml-auto flex h-[21px] flex-row items-center justify-center rounded-[5px] bg-black/10 px-[6px] py-[5px]"
            >
              <span class="mr-[3px] flex">
                <sw-svg
                  icon="gem-sm"
                  width="12px"
                  height="12px"
                  class="text-xs"
                />
              </span>

              <span class="text-[9px] font-bold">{{
                item.gems | swShortNumber
              }}</span>
            </div> -->
          </div>
          <!--
          <p
            class="text-xs leading-[15px] text-black/50"
            *ngIf="item.attributes.length"
          >
            <span
              *ngFor="let attr of item.attributes"
              class="after:inline-block after:content-['/'] last:after:hidden"
            >
              {{ attr.value | swShortNumber }}
              {{ attr.value | swPlural : attr.plural }}
            </span>
          </p> -->
        </div>
      </div>
    </a>
  `,
})
export class TaxonomyItemComponent {
  @Input({ required: true }) item!: TaxonomyItem;
}
