import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

@Component({
  selector: 'sw-info-header',
  standalone: true,
  imports: [],
  changeDetection: ChangeDetectionStrategy.OnPush,
  template: `
    <div>
      <h1 class="text-center text-[32px] font-bold text-white">{{ title }}</h1>
      <div class="mt-5">
        <ng-content select="[description]" />
      </div>
      <div class="mt-[50px] flex">
        <ng-content select="[content]" />
      </div>
    </div>
  `,
})
export class InfoHeaderComponent {
  @Input()
  title = '';
}
