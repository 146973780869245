import { CommonModule } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output,
} from '@angular/core';

import { extractFileName } from '@/core/helpers/extract-filename';
import { DndFilesDirective } from '@/shared/directives/dnd-files.directive';
import { FileBoxComponent } from './file-box.component';

@Component({
  selector: 'sw-documents-input',
  standalone: true,
  imports: [CommonModule, FileBoxComponent, DndFilesDirective],
  changeDetection: ChangeDetectionStrategy.OnPush,
  template: `
    <div
      class="form-field"
      swDndFiles
      (fileDropped)="updateFileList($event || [])"
      [acceptExtension]="['application/pdf']"
    >
      <div class="relative flex flex-row items-center justify-between">
        <div class="w-full">
          <button
            (click)="fileInput.click()"
            class="-m-5 w-[calc(100%+40px)] rounded-md py-5 pl-5 pr-28 text-left text-sm font-medium leading-[18px] text-black/50 outline-2 focus:outline"
            type="button"
          >
            {{ placeholder }}
          </button>
        </div>
        <span
          class="pointer-events-none absolute right-0 top-1/2 -translate-y-1/2 p-[2px] text-accent "
        >
          Добавить
        </span>

        <input
          #fileInput
          type="file"
          class="absolute -z-10 h-0 w-0 appearance-none overflow-hidden"
          tabindex="-1"
          accept="application/pdf"
          [multiple]="true"
          (change)="handleChange($event)"
        />
      </div>

      <ul
        *ngIf="fileList.length > 0"
        class="mt-5 flex flex-row flex-wrap gap-2 border-t border-black/10 pt-5"
      >
        <li *ngFor="let f of fileList" class="max-w-[168px] ">
          <ng-container
            *ngTemplateOutlet="
              fileBlock;
              context: {
                fileName: f,
                remove: removeFile.bind(this, f)
              }
            "
          ></ng-container>
        </li>
      </ul>
    </div>

    <ng-template #fileBlock let-fileName="fileName" let-remove="remove">
      <sw-file-box
        [fileName]="fileName"
        [showRemove]="true"
        styleClass="border border-black/10"
        (remove)="remove?.()"
      />
    </ng-template>
  `,
})
export class DocumentsInputComponent {
  @Input()
  placeholder?: string;

  files: File[] = [];

  @Output()
  fileChanged = new EventEmitter<File[]>();

  @Output()
  savedFilesChanged = new EventEmitter<string[]>();

  @Input()
  fileList: string[] = [];

  protected removeFile(fId: string) {
    this.files = this.files.filter((f) => f.name !== fId);
    this.fileList = this.fileList.filter((f) => f !== fId);

    const savedFiles = this.fileList.filter(
      (f) => !this.files.some((file) => file.name === f)
    );

    this.savedFilesChanged.emit(savedFiles);
  }

  protected handleChange(event: Event) {
    const element = event.currentTarget as HTMLInputElement;
    const fileList: File[] = element.files ? Array.from(element.files) : [];
    this.updateFileList(fileList);
    (event.target as HTMLInputElement).value = '';
  }

  protected updateFileList(files: File[]) {
    const fl = this.fileList.map(extractFileName);

    const filteredFiles = files.filter(
      (f) => !fl.find((file) => file === f.name)
    );

    this.files = [...this.files, ...filteredFiles];
    this.fileList = [...this.fileList, ...filteredFiles.map((v) => v.name)];

    this.fileChanged.emit(this.files);
  }
}
