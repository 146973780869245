import { Directive, EventEmitter, Input, Output } from '@angular/core';
import { FormGroup, FormControl } from '@angular/forms';

@Directive()
export abstract class BaseTopicEditorWrapper {
  @Input()
  skipScroll = false;

  @Input()
  isPreview = true;

  @Input()
  isLast = false;

  @Input()
  canDelete = false;

  @Input({ required: true })
  formGroup!: FormGroup<{
    comment: FormControl<string>;
    name: FormControl<string>;
    agreement: FormControl<number | null>;
  }>;

  @Output()
  save = new EventEmitter<void>();

  @Output()
  delete = new EventEmitter<void>();

  protected saveTopic() {
    if (this.isLast) {
      this.save.emit();
    } else {
      this.isPreview = true;
    }
  }
}
