import { NgIf, SlicePipe } from '@angular/common';
import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { InitialsPipe, TimeAgoPipe } from '@shared/pipes';
import { AvatarModule } from 'primeng/avatar';

import { SVGComponent } from './svg.component';

@Component({
  selector: 'sw-user',
  standalone: true,
  imports: [
    NgIf,
    AvatarModule,
    SlicePipe,
    InitialsPipe,
    TimeAgoPipe,
    SVGComponent,
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
  template: `
    <div class="flex flex-row space-x-[10px]">
      <p-avatar
        [label]="!avatarUrl ? (name | swInitials : 2) : ''"
        [image]="avatarUrl"
        shape="circle"
        class="block h-10 w-10"
        [style]="{ width: '100%', height: '100%' }"
      />
      <div class="flex flex-col items-start  justify-center">
        <div class="flex items-center text-sm font-bold leading-5 text-black">
          <span class="max-w-[140px] truncate">
            {{ name }}
          </span>

          <span *ngIf="showStar" class="ml-[5px] text-accent">
            <sw-svg icon="star" width="11px" height="11px" />
          </span>

          <span *ngIf="timeAgo" class="time-ago">{{
            timeAgo | swTimeAgo
          }}</span>
        </div>
        <span *ngIf="description" class="text-xs leading-5 text-black/50">{{
          description
        }}</span>
      </div>
    </div>
  `,
  styles: [
    `
      :host {
        display: block;
      }
    `,
  ],
})
export class UserComponent {
  @Input({ required: true }) name!: string;

  @Input() description?: string | null;
  @Input() avatarUrl?: string;

  @Input() showStar?: boolean = false;
  @Input() timeAgo?: Date | number | string;
}
