import { isPresent } from '@/core/helpers';
import {
  MAP_REQ_HTTP_TOKEN,
  MAP_RES_HTTP_TOKEN,
  SKIP_BASE_URL_HTTP_TOKEN,
} from '@/core/interceptors';
import { HttpClient, HttpContext, HttpResponse } from '@angular/common/http';
import { Injectable, inject } from '@angular/core';
import * as saveAs from 'file-saver';
import { tap } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class SaverService {
  private readonly httpClient = inject(HttpClient);

  save(filePath: string) {
    const context = new HttpContext()
      .set(MAP_REQ_HTTP_TOKEN, false)
      .set(MAP_RES_HTTP_TOKEN, false)
      .set(SKIP_BASE_URL_HTTP_TOKEN, true);

    return this.httpClient
      .get(filePath, {
        responseType: 'blob',
        observe: 'response',
        context,
      })
      .pipe(tap((response) => this.saveAsFile(response)));
  }

  saveAsFile = (
    response: HttpResponse<Blob>,
    contentType?: string,
    fileName?: string
  ) => {
    const type = contentType || response.headers.get('Content-Type');

    if (!isPresent(response.body)) {
      throw new Error(`response body is empty`);
    }

    if (!isPresent(type)) {
      throw new Error(`response header content-type is empty`);
    }

    const filename =
      fileName ||
      response.headers.get('X-Amz-Meta-Filename') ||
      this.parseContentDisposition(
        response.headers.get('Content-Disposition') || ''
      );

    const blob = new Blob([response.body], { type });
    saveAs(blob, filename);
  };

  private parseContentDisposition(disposition: string | null) {
    if (disposition && disposition.indexOf('attachment') !== -1) {
      const matches =
        /(?:.*filename\*|filename)=(?:([^'"]*)''|("))([^;]+)\2(?:[;`\n]|$)/i.exec(
          disposition
        );
      if (matches != null && matches[3]) return decodeURIComponent(matches[3]);
    }
    return `file-${new Date().getTime()}`;
  }
}
