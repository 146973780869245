import { inject, Injectable } from '@angular/core';
import { map, tap } from 'rxjs';

import { LikeStatistics, TopLikeStatistics, User } from '@/core/models';
import { BaseAPIService } from './base-api.service';
import { UserService } from './user.service';

@Injectable({ providedIn: 'root' })
export class LikeAPIService extends BaseAPIService {
  toggleLike(projectId: string) {
    return this.httpClient.post<{
      projectId: string;
      count: number;
    }>(`/likes/project/${projectId}`, null);
  }

  exchangeToExperience(slug: string, count: number) {
    return this.httpClient.post<{
      slug: string;
      count: number;
    }>(`/likes/category/${slug}`, { count });
  }

  exchangeToGems(slug: string, count: number) {
    return this.httpClient.post<{
      slug: string;
      count: number;
    }>(`/gems/change/${slug}`, { count });
  }

  fetchStats(slug: string) {
    return this.httpClient.get<LikeStatistics>(`/likes/statistic/${slug}`);
  }

  fetchTop() {
    return this.httpClient.get<TopLikeStatistics>(`/likes/tops`);
  }
}

@Injectable({ providedIn: 'root' })
export class LikeService {
  private readonly api = inject(LikeAPIService);
  private readonly userService = inject(UserService);

  get loading$() {
    return this.api.loading$;
  }

  toggleLike(projectId: string) {
    return this.api.toggleLike(projectId);
  }

  exchangeToExperience(slug: string, count: number) {
    return this.api.exchangeToExperience(slug, count).pipe(
      tap({
        next: () => {
          this.userService.writeOffLikes(slug, count);
        },
      })
    );
  }

  exchangeToGems(slug: string, count: number) {
    return this.api.exchangeToGems(slug, count).pipe(
      tap({
        next: () => {
          this.userService.writeOffLikes(slug, count);
        },
      })
    );
  }

  getStats(slug: string) {
    return this.api.fetchStats(slug);
  }

  getTops() {
    return this.api
      .fetchTop()
      .pipe(
        map((v) =>
          v.map(({ owner, ...elem }) => ({ ...elem, owner: new User(owner) }))
        )
      );
  }
}
