import { InjectionToken } from '@angular/core';

import { RatingType } from '../types';

export const RATING_TYPE_LABELS: Record<RatingType, string> = {
  neutral: 'нейтральный',
  negative: 'негативный',
  positive: 'позитивный',
} as const;

export const RatingTypeLabels = new InjectionToken<Record<RatingType, string>>(
  'Rating type labels map',
  {
    providedIn: 'root',
    factory: () => RATING_TYPE_LABELS,
  }
);
