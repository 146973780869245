import {
  BreakpointDesktopDirective,
  PermissionDirective,
} from '@/shared/directives';
import { HeaderComponent, HeaderMobileComponent } from '@/shared/ui';

import {
  MenuComponent,
  MobileMenuComponent,
  MobileTopMenuComponent,
} from '@/widgets/menu';
import { SidebarLayout } from '@/widgets/sidebar';
import { LayoutModule } from '@angular/cdk/layout';
import { ChangeDetectionStrategy, Component, inject } from '@angular/core';
import { Router, RouterOutlet } from '@angular/router';

@Component({
  selector: 'sw-main-layout',
  standalone: true,
  imports: [
    BreakpointDesktopDirective,
    RouterOutlet,
    LayoutModule,
    HeaderComponent,
    HeaderMobileComponent,
    MenuComponent,
    MobileTopMenuComponent,
    MobileMenuComponent,
    PermissionDirective,
    SidebarLayout,
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
  template: `
    <div
      swPermission
      (calledUnauthorized)="handleOnUnauthorized()"
      class="flex flex-grow flex-row items-stretch justify-center space-x-[30px] md:pl-[68px] lg:pl-[138px]"
    >
      <div *swBreakpointDesktop class="sticky top-0 z-10 h-max bg-bg pb-2">
        <sw-sidebar-left />
      </div>
      <div class="mx-auto w-full shrink-0 md:max-w-[490px]">
        <ng-template
          swBreakpointDesktop
          [swBreakpointDesktopElse]="mobileElements"
        >
          <sw-header class="sticky top-0 z-[10] block" />
        </ng-template>

        <ng-template #mobileElements>
          <sw-header-mobile class="sticky top-0 z-[10] block" />

          <div class="mb-8 mt-5 ">
            <sw-mobile-top-menu />
          </div>

          <div class="fixed bottom-0 left-0 z-50 h-[100px] w-full">
            <sw-mobile-menu />
          </div>
        </ng-template>

        <main class="pb-[100px] md:pb-0">
          <router-outlet />
        </main>
      </div>
      <div *swBreakpointDesktop>
        <sw-sidebar-layout>
          <router-outlet name="right-sidebar" />
        </sw-sidebar-layout>
      </div>
    </div>
  `,
  styles: [
    `
      :host {
        @apply relative block pb-12 md:px-[80px] lg:px-[135px];
      }
    `,
  ],
})
export class MainLayout {
  private readonly router = inject(Router);

  protected handleOnUnauthorized() {
    this.router.navigateByUrl('/login');
  }
}
