import { FormArray, FormGroup, NgForm } from '@angular/forms';

export const validateAllFormFields = (form: FormGroup | FormArray) => {
  for (const i in form.controls) {
    const control = form.get(i);

    control?.markAsDirty();
    control?.markAllAsTouched();
    control?.updateValueAndValidity();

    if (control instanceof FormGroup || control instanceof FormArray) {
      validateAllFormFields(control);
    }
  }
  form.updateValueAndValidity();
  form.markAllAsTouched();
  form.markAsDirty();
};

export const validateAllNgFormFields = (form: NgForm) => {
  for (const control in form.controls) {
    form.controls[control].updateValueAndValidity({ emitEvent: false });
    form.controls[control].markAsTouched();
  }
};
