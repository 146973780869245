import { ButtonComponent, SVGComponent } from '@/shared/ui';
import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { AgreementBtnDirective } from './agreement-btn.directive';

@Component({
  selector: 'sw-super-like',
  standalone: true,
  imports: [SVGComponent, ButtonComponent, AgreementBtnDirective],
  changeDetection: ChangeDetectionStrategy.OnPush,
  template: `
    <button
      swButton
      swAgreementBtn
      [value]="5"
      [commentId]="commentId"
      class="button spinning-button relative z-0 mx-auto rounded-md p-[2px]"
      type="button"
    >
      <div
        class="bg-animation z-10 flex flex-row items-center justify-center space-x-[10px] rounded-[8px] px-[27.75px] py-[10px]"
      >
        <span class="text-[22px] leading-none text-gold">
          <sw-svg icon="like" height="22px" width="22px" />
        </span>

        <span class="text-sm font-bold leading-[22px]">Супер-Довод</span>
      </div>
    </button>
  `,
  styles: [
    `
      .spinning-button {
        @apply overflow-hidden;
        &::after {
          @apply absolute aspect-square animate-spin;
          content: '';
          top: -220%;
          width: 140%;
          background-image: conic-gradient(
            from 270deg at 50% 50%,
            #ffe9c9 0deg,
            #ff871a 64.13deg,
            #ff871a 117.97deg,
            #ffe9c9 179.72deg,
            #ff871a 237.89deg,
            #ff871a 296.25deg,
            #ffe9c9 360deg
          );
          animation-duration: 2500ms;
        }
      }

      @keyframes gradient {
        0% {
          background-position: 0% 0%;
        }
        50% {
          background-position: 100% 100%;
        }
        100% {
          background-position: 0% 0%;
        }
      }

      .bg-animation {
        background: linear-gradient(95deg, #ffffff 0, #fff6e9 100%);
        animation: gradient 2500ms ease infinite;
        background-size: 200% 200%;
        background-attachment: fixed;
      }
    `,
  ],
})
export class SuperLikeComponent {
  @Input({ required: true }) commentId!: string;
}
