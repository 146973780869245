import { UserService } from '@/core';
import { TopicService } from '@/core/services/topics.service';
import { LetDirective } from '@/shared/directives';
import { PermissionActionDirective } from '@/shared/directives/permission/permission-action.directive';
import { ChangeDetectionStrategy, Component, inject } from '@angular/core';
import { toSignal } from '@angular/core/rxjs-interop';
import { ActivatedRoute, RouterLink, RouterLinkActive } from '@angular/router';
import { EMPTY, map, switchMap } from 'rxjs';

import { CreateTopicButtonComponent } from '@/features/create-topic-button/ui/create-topic-button.component';
import { ProjectTopicBlockComponent } from './ui/project-topic-block.component';

// !TODO del topicservice provider when primeng fix dialog injector

@Component({
  selector: 'sw-project-topic-list',
  standalone: true,
  imports: [
    ProjectTopicBlockComponent,
    RouterLink,
    RouterLinkActive,
    LetDirective,
    CreateTopicButtonComponent,
    PermissionActionDirective,
  ],
  // providers: [DialogService],
  changeDetection: ChangeDetectionStrategy.OnPush,
  template: `
    <div guide="project-topics">
      <div class="flex flex-row-reverse items-center px-5 md:flex-col md:px-0">
        @if(isLoggedIn()) {
        <div class="md:mb-[30px] md:w-full">
          <sw-create-topic-button [projectId]="projectId()" />
        </div>

        }
        <span class="title mr-auto block text-lg leading-[22px]"
          >Темы проекта</span
        >
      </div>

      <div
        class="no-scrollbar mt-5 flex snap-x snap-proximity flex-row flex-nowrap overflow-x-auto p-[2px] md:snap-none md:flex-col  md:space-y-3 md:overflow-visible"
        *swLet="selectedTopic() as topicId"
      >
        <a
          routerLink="./"
          [relativeTo]="route"
          [queryParams]="{
            topic: null
          }"
          queryParamsHandling="merge"
          class="ml-5 snap-center rounded-md md:ml-0"
        >
          <sw-project-topic-block
            label="Все темы"
            [rating]="null"
            [hideInfo]="true"
            [comments]="null"
            appearance="neutral"
            [class.active]="topicId === undefined"
          />
        </a>
        @for (topic of topics(); track topic.id) {
        <div class="ml-3 snap-center last:pr-5 md:ml-0 md:last:pr-0">
          <a
            [routerLink]="['/', 'thread', projectId()]"
            [queryParams]="{
              topic: topicId === topic ? null : topic.id
            }"
            queryParamsHandling="merge"
            class="shrink-0   rounded-md "
          >
            <sw-project-topic-block
              [label]="topic.name"
              [rating]="topic.agreement"
              [comments]="topic.comments"
              [appearance]="topic.color"
              [class.active]="topicId === topic.id"
            />
          </a>
        </div>
        }
      </div>
    </div>
  `,
})
export class ProjectTopicListComponent {
  private readonly activatedRoute = inject(ActivatedRoute);

  protected readonly selectedTopic = toSignal(
    this.activatedRoute.queryParams.pipe(map((params) => params?.['topic']))
  );

  protected readonly route = this.activatedRoute.parent?.children?.find(
    (r) => r.outlet === 'primary'
  );

  protected readonly params$ = this.route?.params || EMPTY;

  private readonly topicsService = inject(TopicService);

  protected readonly isLoggedIn = toSignal(inject(UserService).isLoggedIn$);

  protected readonly topics = toSignal(
    this.params$.pipe(
      switchMap(({ id }) => this.topicsService.getTopics(id)),
      switchMap(() => this.topicsService.topics$)
    ),
    {
      initialValue: [],
    }
  );

  protected readonly projectId = toSignal(
    this.params$.pipe(map(({ id }) => id)),
    {
      initialValue: '',
    }
  );
}
