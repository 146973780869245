import { Comment } from '@/core/models';
import { CommonModule } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  Input,
  ViewEncapsulation,
} from '@angular/core';
import { LetDirective } from '@shared/directives';
import { InitialsPipe, TimeAgoPipe } from '@shared/pipes';
import { AvatarModule } from 'primeng/avatar';

@Component({
  selector: 'sw-comment',
  standalone: true,
  imports: [
    CommonModule,
    TimeAgoPipe,
    InitialsPipe,
    AvatarModule,
    LetDirective,
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
  template: `
    <div
      *swLet="comment.ratingType as type"
      class="rounded-r-md border-l-[3px] bg-inherit p-[10px] shadow-[0px_2px_8px_rgba(0,0,0,0.15)]
    "
      [ngClass]="{
      'border-red': type === 'negative',
      'border-green': type === 'positive',

    }"
    >
      <div class="flex flex-row">
        <p-avatar
          [label]="
            !comment.author.photo
              ? (comment.author.fullName | swInitials : 2)
              : ''
          "
          [image]="comment.author.photo || undefined"
          shape="circle"
          class="block h-[25px] w-[25px] shrink-0"
          [style]="{ width: '100%', height: '100%' }"
        >
        </p-avatar>

        <div
          class="ml-[13px] flex w-full flex-row items-center  text-sm leading-5 text-black"
        >
          <div class="">
            <span class="font-bold">
              {{ comment.author.fullName }}
            </span>

            <span class="time-ago">{{ comment.createdAt | swTimeAgo }}</span>
          </div>

          <div class="ml-auto flex flex-row space-x-[10px]">
            <span class="flex flex-row items-center">
              <span class="mr-[5px]">
                <svg
                  width="11"
                  height="12"
                  viewBox="0 0 11 12"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <mask id="path-1-inside-1_2600_7404" fill="white">
                    <path
                      fill-rule="evenodd"
                      clip-rule="evenodd"
                      d="M9.32691 7.5723C10.3587 6.83915 11 5.81221 11 4.67542C11 2.44787 8.53757 0.64209 5.5 0.64209C2.46243 0.64209 0 2.44787 0 4.67542C0 6.90297 2.46243 8.70876 5.5 8.70876C5.69446 8.70876 5.88656 8.70136 6.07581 8.68692L9.0491 11.3579L9.32691 7.5723Z"
                    />
                  </mask>
                  <path
                    d="M9.32691 7.5723L8.74768 6.75714L8.36404 7.02974L8.3296 7.49911L9.32691 7.5723ZM6.07581 8.68692L6.74409 7.943L6.42604 7.65729L5.99974 7.68982L6.07581 8.68692ZM9.0491 11.3579L8.38082 12.1018L9.89722 13.464L10.0464 11.4311L9.0491 11.3579ZM10 4.67542C10 5.40842 9.58822 6.15988 8.74768 6.75714L9.90615 8.38746C11.1292 7.51842 12 6.21599 12 4.67542H10ZM5.5 1.64209C6.82851 1.64209 7.99118 2.03836 8.79772 2.62983C9.60486 3.22173 10 3.95676 10 4.67542H12C12 3.16654 11.1639 1.8849 9.98045 1.01702C8.79639 0.14871 7.20905 -0.35791 5.5 -0.35791V1.64209ZM1 4.67542C1 3.95676 1.39514 3.22173 2.20228 2.62983C3.00882 2.03836 4.17149 1.64209 5.5 1.64209V-0.35791C3.79095 -0.35791 2.20361 0.14871 1.01955 1.01702C-0.163919 1.8849 -1 3.16654 -1 4.67542H1ZM5.5 7.70876C4.17149 7.70876 3.00882 7.31248 2.20228 6.72102C1.39514 6.12911 1 5.39409 1 4.67542H-1C-1 6.1843 -0.163919 7.46595 1.01955 8.33383C2.20361 9.20214 3.79095 9.70876 5.5 9.70876V7.70876ZM5.99974 7.68982C5.83582 7.70232 5.66909 7.70876 5.5 7.70876V9.70876C5.71982 9.70876 5.9373 9.70039 6.15188 9.68402L5.99974 7.68982ZM9.71737 10.614L6.74409 7.943L5.40753 9.43083L8.38082 12.1018L9.71737 10.614ZM8.3296 7.49911L8.05178 11.2847L10.0464 11.4311L10.3242 7.64549L8.3296 7.49911Z"
                    fill="#1A1A1A"
                    mask="url(#path-1-inside-1_2600_7404)"
                  />
                </svg>
              </span>
              <span>{{ comment.answers }}</span>
            </span>
            <span class="font-bold" *ngIf="comment.agreement"
              >{{ comment.absoluteAgreement }}/5</span
            >
          </div>
        </div>
      </div>

      <p
        class="
      mt-[3px] line-clamp-2 pl-[38px] pr-[14px]
      text-xs leading-[15px]
      "
      >
        {{ comment.comment }}
      </p>
    </div>
  `,
  styles: [
    `
      sw-comment .p-avatar-text {
        @apply text-[12px];
      }
    `,
  ],
})
export class CommentComponent {
  @Input({ required: true })
  comment!: Comment;
}
