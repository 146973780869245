import { UserService } from '@/core';
import { daysAgo, isMissed, isPresent } from '@/core/helpers';
import { CommentExtended } from '@/core/models';
import { CopyService } from '@/core/services/copy.service';

import { animate, style, transition, trigger } from '@angular/animations';
import { AsyncPipe, CommonModule } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  inject,
  Input,
  signal,
} from '@angular/core';

import { MenuItem } from 'primeng/api';

import {
  catchError,
  EMPTY,
  filter,
  finalize,
  first,
  map,
  Observable,
  switchMap,
} from 'rxjs';

import { CommentsService } from '@/core/services/comments.service';

import { ShortNumberPipe } from '@/shared/pipes';
import {
  CommentEditorComponent,
  CommentsListComponent,
  GalleryComponent,
  HeaderCardComponent,
  LoaderComponent,
  SVGComponent,
  TextComponent,
} from '@/shared/ui';
import { ConfirmDialogComponent } from '@/shared/ui/confirm-dialog.component';
import { toSignal } from '@angular/core/rxjs-interop';
import { ActivatedRoute, Router } from '@angular/router';
import { DialogService } from 'primeng/dynamicdialog';
import {
  CreateCommentDirective,
  UpdateCommentDirective,
} from '../comment-context';
import { WrapperCommentAgreementBtnsComponent } from './comment-agreement-btns';
import { SuperLikeComponent } from './comment-agreement-btns/super-like.component';
import { CommentRatingComponent } from './comment-rating.component';
import { CommentStatisticsWrapperComponent } from './comment-statistics';
import { CommentTopicSelectorComponent } from './comment-topic-selector.component';

@Component({
  selector: 'sw-project-extended-comment',
  standalone: true,
  imports: [
    WrapperCommentAgreementBtnsComponent,
    CommentEditorComponent,
    CommentTopicSelectorComponent,
    CommentsListComponent,
    CommonModule,
    CreateCommentDirective,
    GalleryComponent,
    HeaderCardComponent,
    ShortNumberPipe,
    CommentStatisticsWrapperComponent,
    SVGComponent,
    CommentRatingComponent,
    TextComponent,
    SuperLikeComponent,
    UpdateCommentDirective,
    LoaderComponent,
    AsyncPipe,
  ],
  providers: [DialogService],
  changeDetection: ChangeDetectionStrategy.OnPush,
  animations: [
    trigger('leaveShortComments', [
      transition(':leave', [
        style({ opacity: 1, height: '*' }),
        animate('200ms', style({ opacity: 0.3, height: 0 })),
      ]),
    ]),
  ],
  template: `
    <div
      class="island-base relative z-0 overflow-hidden rounded-md"
      [id]="comment.id"
      [attr.guide]="'comment-' + comment.ratingType"
    >
      <sw-loader [overlay]="true" [loading]="loading()">
        <div
          class="relative z-0 after:content-['']"
          [ngClass]="{
            'after:absolute after:left-10 after:top-0 after:block after:h-1/2 after:w-[1px] after:bg-black/30':
              isMainComment,
            'after:hidden': !isMainComment
          }"
        >
          <sw-header-card
            [name]="comment.author.fullName"
            [avatarUrl]="comment.author.photo || undefined"
            [description]="comment.author.rank || undefined"
            [showStar]="comment.author.showStar"
            [menuItems]="menuItems$ | async"
            [createdAt]="comment.createdAt"
            class="relative z-10"
          >
            <ng-container actions>
              <sw-comment-rating
                *ngIf="comment.ratingType"
                [type]="comment.ratingType"
                [value]="comment.absoluteAgreement"
              />
              <ng-content select="[headerActions]" />
            </ng-container>
          </sw-header-card>
        </div>

        <div class="border-t">
          <div
            *ngIf="!isEdit; else form"
            class="space-y-5 bg-gradient-to-b from-white p-5"
            [ngClass]="{
          'to-[#E7FFF2]':comment.ratingType ==='positive',
          'to-[#FFEBEC]':comment.ratingType ==='negative',
        }"
          >
            <ng-container *ngIf="isSuperUser()">
              <div>
                <sw-comment-statistics-wrapper
                  [statistics]="comment.statistics"
                />
              </div>

              <div>
                <sw-comment-topic-selector
                  [topicId]="comment.topicId"
                  [commentId]="comment.id"
                />
              </div>
            </ng-container>

            <div class="text-sm font-medium leading-[22px]">
              <sw-text [text]="comment.comment" />
            </div>

            @if (comment.media.length) {
            <div *ngIf="comment.media?.length">
              <sw-gallery [media]="comment.media" />
            </div>
            }

            <div class="flex flex-row space-x-[10px]">
              <button
                (click)="openComments()"
                aria-label="Перейти к под-комментариям"
                class="button-base flex h-[42px] flex-1 items-center justify-center space-x-[10px] rounded-md bg-black/5"
              >
                <span>
                  <svg
                    width="18px"
                    height="18px"
                    viewBox="0 0 18 18"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <mask id="path-1-inside-1_652_29832" fill="white">
                      <path
                        fill-rule="evenodd"
                        clip-rule="evenodd"
                        d="M15.2621 11.5729C16.9505 10.3731 18 8.69266 18 6.83242C18 3.18734 13.9706 0.232422 9 0.232422C4.02944 0.232422 0 3.18734 0 6.83242C0 10.4775 4.02944 13.4324 9 13.4324C9.31817 13.4324 9.63248 13.4203 9.94212 13.3967L14.8075 17.7674L15.2621 11.5729Z"
                      />
                    </mask>
                    <path
                      d="M15.2621 11.5729L14.1037 9.94251L13.3364 10.4877L13.2675 11.4265L15.2621 11.5729ZM9.94212 13.3967L11.2787 11.9089L10.6426 11.3375L9.79 11.4025L9.94212 13.3967ZM14.8075 17.7674L13.4709 19.2552L16.5037 21.9797L16.8021 17.9138L14.8075 17.7674ZM16 6.83242C16 7.88507 15.4096 9.01458 14.1037 9.94251L16.4205 13.2032C18.4915 11.7317 20 9.50026 20 6.83242H16ZM9 2.23242C11.1047 2.23242 12.9301 2.8608 14.1812 3.77833C15.4336 4.69672 16 5.8001 16 6.83242H20C20 4.21967 18.5517 2.02305 16.5467 0.552707C14.5405 -0.9185 11.8658 -1.76758 9 -1.76758V2.23242ZM2 6.83242C2 5.8001 2.56641 4.69672 3.81877 3.77833C5.06993 2.8608 6.89526 2.23242 9 2.23242V-1.76758C6.13418 -1.76758 3.4595 -0.9185 1.45331 0.552707C-0.551695 2.02305 -2 4.21967 -2 6.83242H2ZM9 11.4324C6.89526 11.4324 5.06993 10.804 3.81877 9.88652C2.56641 8.96812 2 7.86475 2 6.83242H-2C-2 9.44518 -0.551695 11.6418 1.45331 13.1121C3.4595 14.5833 6.13418 15.4324 9 15.4324V11.4324ZM9.79 11.4025C9.53101 11.4222 9.26744 11.4324 9 11.4324V15.4324C9.36889 15.4324 9.73395 15.4184 10.0942 15.3909L9.79 11.4025ZM16.144 16.2796L11.2787 11.9089L8.60557 14.8845L13.4709 19.2552L16.144 16.2796ZM13.2675 11.4265L12.8129 17.621L16.8021 17.9138L17.2567 11.7192L13.2675 11.4265Z"
                      fill="#1A1A1A"
                      mask="url(#path-1-inside-1_652_29832)"
                    />
                  </svg>
                </span>

                <span class="text-sm font-bold leading-[22px] text-black">
                  {{ comment.answers | swShortNumber }}
                </span>
              </button>

              <div class="h-[42px] w-full max-w-[66.7%]">
                <sw-wrapper-comment-agreement-btns
                  [commentId]="comment.id"
                  [value]="comment.myAgreement"
                  [disabled]="comment.isDeleted"
                />
              </div>
            </div>

            <div class="" *ngIf="!isSuperUser() && showSuperLike">
              <sw-super-like [commentId]="comment.id" />
            </div>

            <div
              @leaveShortComments
              *ngIf="
                showChildComments && comment.comments && comment.comments.length
              "
            >
              <sw-comments-list
                [comments]="{
                  count: comment.answers,
                  comments: comment.comments
                }"
                parentType="comment"
                [projectId]="projectId"
                [commentId]="comment.id"
                [open]="true"
                [showMoreBtn]="!isMainComment"
              />
            </div>

            <div *ngIf="!comment.isDeleted && canComment">
              <sw-comment-editor
                swCreateComment
                commentType="comment"
                [projectId]="projectId"
                [parentId]="comment.id"
                [topicId]="(selectedTopicId$ | async) || undefined"
              />
            </div>
          </div>

          <ng-template #form>
            <div class="p-5">
              <sw-comment-editor
                swUpdateComment
                [commentId]="comment.id"
                [open]="true"
                [comment]="comment"
                (closeForm)="closeForm()"
              />
            </div>
          </ng-template>
        </div>
      </sw-loader>
    </div>
  `,
})
export class ExtendedCommentComponent {
  private readonly router = inject(Router);
  protected readonly userService = inject(UserService);
  private readonly commentService = inject(CommentsService);
  private readonly dialogService = inject(DialogService);

  protected loading = signal(false);

  protected readonly user$ = this.userService.user$;

  protected readonly selectedTopicId$: Observable<string | undefined> = inject(
    ActivatedRoute
  ).queryParams.pipe(map((params) => params?.['topic']));

  protected readonly isSuperUser = toSignal(this.userService.isSuperuser$);

  @Input({ required: true })
  projectId!: string;

  @Input({ required: true })
  comment!: CommentExtended;

  @Input() isMainComment = false;

  @Input()
  canComment = true;

  @Input()
  showChildComments = true;

  get showSuperLike() {
    return (
      isPresent(this.comment.myAgreement) && this.comment.myAgreement === 1
    );
  }

  protected isEdit = false;

  private readonly copyService = inject(CopyService);

  protected readonly menuItems$: Observable<MenuItem[] | null> =
    this.user$.pipe(
      map((u) => {
        if (this.comment.isDeleted) {
          return null;
        }

        const edit: MenuItem = {
          label: 'Редактировать',
          command: () => (this.isEdit = true),
        };
        const copy: MenuItem = {
          label: 'Скопировать ссылку',
          command: () => {
            this.copyService.copyLink(this.projectId, this.comment.id);
          },
        };
        // const bookmarks: MenuItem = {
        //   label: 'Сохранить в закладки',
        // };
        const remove: MenuItem = {
          label: 'Удалить комментарий',
          command: () => {
            this.deleteComment(this.comment.id);
          },
        };
        // const report: MenuItem = {
        //   label: 'Пожаловаться',
        // };

        if (isMissed(u)) {
          return [copy];
        }

        const canEdit = daysAgo(this.comment.createdAt) === 0;

        return u.id === this.comment.author.id
          ? [copy, ...(canEdit ? [edit, remove] : [])]
          : [
              copy /* bookmarks, */,
              ...(u.isSuperuser ? [remove] : []) /* report */,
            ];
      })
    );

  protected openComments() {
    this.router.navigate(['/', 'thread', this.projectId, this.comment.id], {
      queryParamsHandling: 'merge',
    });
  }

  protected deleteComment(commentId: string) {
    this.dialogService
      .open(ConfirmDialogComponent, {
        header: 'Удаление комментария',
        data: {
          msg: 'Вы действительно хотите удалить комментарий?',
          yes: 'Удалить',
        },
      })
      .onClose.pipe(
        first(),
        filter(Boolean),
        switchMap(() => {
          this.loading.set(true);
          return this.commentService
            .delete(commentId)
            .pipe(catchError(() => EMPTY));
        }),
        finalize(() => this.loading.set(false))
      )
      .subscribe();
  }

  protected closeForm() {
    this.isEdit = false;
  }
}
