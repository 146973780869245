import { Directive, HostListener, inject, Input } from '@angular/core';

import { SaverService } from './save.service';

@Directive({ selector: '[swSave]', standalone: true })
export class SaveDirective {
  @Input({
    alias: 'swSave',
    required: true,
  })
  fileUrl!: string;

  private readonly saveService = inject(SaverService);

  @HostListener('click')
  handleClick() {
    this.saveService.save(this.fileUrl).subscribe();
  }
}
