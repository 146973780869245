import { NgIf } from '@angular/common';
import { ChangeDetectionStrategy, Component } from '@angular/core';
import { ProjectEditorTopicPreviewComponent } from './project-editor-topic-preview.component';
import { ScrollOnInitDirective } from '@/shared/directives/scroll-on-init.directive';
import { ProjectTopicEditorComponent } from './project-topic-editor.component';
import { BaseTopicEditorWrapper } from './topic-editor-wrapper';

@Component({
  selector: 'sw-project-topic-editor-wrapper',
  standalone: true,
  imports: [
    NgIf,
    ScrollOnInitDirective,
    ProjectTopicEditorComponent,
    ProjectEditorTopicPreviewComponent,
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
  template: `
    <sw-project-editor-topic-preview
      [formGroup]="formGroup"
      *ngIf="isPreview && !isLast; else editor"
      swScrollOnInit
      [skipScroll]="skipScroll"
      scrollPosition="start"
      (edit)="isPreview = false"
    />

    <ng-template #editor>
      <sw-project-topic-editor
        [formGroup]="formGroup"
        [creating]="isLast"
        [canDelete]="canDelete"
        (save)="saveTopic()"
        (delete)="delete.emit()"
      />
    </ng-template>
  `,
})
export class ProjectTopicEditorWrapperComponent extends BaseTopicEditorWrapper {}
