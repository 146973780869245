import { ProjectService } from '@/core';
import {
  CreateProjectDTO,
  Project,
  ProjectDTO,
  UpdateProjectDTO,
} from '@/core/models';
import { Injectable, Provider, inject } from '@angular/core';
import { Observable } from 'rxjs';

@Injectable()
export abstract class ProjectEditor {
  protected readonly projectService = inject(ProjectService);

  get loading$() {
    return this.projectService.loading$;
  }

  abstract create(payload: ProjectDTO<CreateProjectDTO>): Observable<Project>;
  abstract update(
    id: string,
    payload: ProjectDTO<UpdateProjectDTO>
  ): Observable<Project>;
  abstract delete(id: string): Observable<unknown>;
}

export function provideProjectEditor(
  useClass: new () => ProjectEditor
): Provider {
  return {
    provide: ProjectEditor,
    useClass,
  };
}
