import { BaseOpinion } from '@/shared/ui/opinion/base-opinion';
import { NgClass, NgTemplateOutlet } from '@angular/common';
import { ChangeDetectionStrategy, Component } from '@angular/core';

@Component({
  selector: 'sw-editor-sentiment-btns',
  standalone: true,
  imports: [NgClass, NgTemplateOutlet],
  changeDetection: ChangeDetectionStrategy.OnPush,
  template: `
    <div class="flex flex-row space-x-5">
      <button
        type="button"
        class="button  h-[60px] flex-1 items-center justify-center rounded-md border-2 border-green text-[17px] font-bold  leading-[22px] "
        [ngClass]="{
          'bg-green text-white': value && value > 0,
          'bg-transparent text-green': !value || value < 0,
        }"
        (click)="handleBtn(true)"
      >
        <div class="relative">
          <span class="relative">За </span>
          <ng-container
            [ngTemplateOutlet]="value && value > 0 ? checkIcon : null"
          />
        </div>
      </button>
      <button
        type="button"
        class="button h-[60px] flex-1 items-center justify-center rounded-md border-2 border-red text-[17px]  font-bold leading-[22px]"
        [ngClass]="{
          'bg-red text-white': value && value  < 0,
          'bg-transparent text-red': !value || value > 0,
        }"
        (click)="handleBtn(false)"
      >
        <div class="relative">
          <span>Против</span>
          <ng-container
            [ngTemplateOutlet]="value && value < 0 ? checkIcon : null"
          />
        </div>
      </button>
    </div>

    <ng-template #checkIcon>
      <span class="absolute -left-8">
        <i class="pi  pi-check " style="font-size: 1.1rem;color: white"></i>
      </span>
    </ng-template>
  `,
})
export class EditorSentimentBtnsComponent extends BaseOpinion {}
