import { UserService } from '@/core';
import { Project } from '@/core/models';
import { VerifyProjectButtonComponent } from '@/features/verify-project-button';
import { RatingComponent } from '@/shared/ui';
import { VerifiedBannerComponent } from '@/shared/ui/verified-banner/verified-banner.component';
import {
  ChangeDetectionStrategy,
  Component,
  Input,
  inject,
} from '@angular/core';
import { toSignal } from '@angular/core/rxjs-interop';

@Component({
  selector: 'sw-project-details',
  standalone: true,
  imports: [
    VerifiedBannerComponent,
    VerifyProjectButtonComponent,
    RatingComponent,
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
  template: `
    <div class="space-y-5">
      @if (project.isVerified) {
      <div>
        <sw-verified-banner />
      </div>
      } @if(isAdmin()) {
      <div>
        <sw-verify-project-button
          [projectId]="project.id"
          [isVerified]="project.isVerified"
        />
      </div>
      }
      <div class="island-base overflow-hidden">
        <sw-rating
          [rating]="project.rating"
          [ratingType]="project.ratingType"
        />
      </div>
    </div>
  `,
})
export class ProjectDetailsComponent {
  @Input({ required: true }) project!: Project;

  protected readonly isAdmin = toSignal(inject(UserService).isSuperuser$, {
    initialValue: false,
  });
}
