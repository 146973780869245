import { v4 as uuidv4 } from 'uuid';
import { NgFor } from '@angular/common';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  DestroyRef,
  OnInit,
  ViewEncapsulation,
  inject,
} from '@angular/core';
import { FormControl, NgControl, ReactiveFormsModule } from '@angular/forms';
import { AccordionModule } from 'primeng/accordion';
import { RadioButtonModule } from 'primeng/radiobutton';
import { LetDirective } from '@/shared/directives/let.directive';
import { memoize } from '@/core/decorators';
import { startWith } from 'rxjs';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { isMissed } from '@/core/helpers';

@Component({
  selector: 'sw-editor-topic-default',
  standalone: true,
  imports: [
    NgFor,
    LetDirective,
    ReactiveFormsModule,
    AccordionModule,
    RadioButtonModule,
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
  template: `
    <p-accordion [multiple]="true" [activeIndex]="activeTabs">
      <p-accordionTab
        #tab
        *ngFor="let category of categories; let i = index"
        [disabled]="activeTab === i"
        [header]="category.label"
      >
        <ul class="space-y-5">
          <li *ngFor="let t of category.topics; let index = index">
            <ng-container
              *swLet="uuid + '-' + category.short + '-' + index as id"
            >
              <p-radioButton
                [inputId]="id"
                [value]="t"
                [formControl]="control"
                [tabindex]="tab.selected ? 1 : -1"
              ></p-radioButton>
              <label [for]="id" class="ml-2 cursor-pointer">{{ t }}</label>
            </ng-container>
          </li>
        </ul>
      </p-accordionTab>
    </p-accordion>
  `,
  styles: [
    `
      sw-editor-topic-default {
        .p-disabled {
          @apply opacity-100;
        }
        .p-radiobutton .p-radiobutton-box {
          @apply bg-transparent;
        }
        .p-accordion {
          .p-accordion-header {
            &:not(.p-disabled) {
              &.p-highlight .p-accordion-header-link {
                @apply bg-transparent;
              }

              &.p-highlight:hover .p-accordion-header-link {
                @apply bg-transparent;
              }

              .p-accordion-header-link:focus {
                box-shadow: none;
                outline: 2px solid #194cff;
              }
            }

            .p-accordion-header-link .p-accordion-toggle-icon {
              @apply mr-[10px] inline-flex h-6 w-6 items-center justify-center;
            }

            .p-accordion-header-link {
              @apply bg-transparent py-[10px] pl-0;
            }
          }

          .p-icon-wrapper,
          .icon {
            color: #194cff;
          }

          .p-accordion-header-text {
            @apply text-[17px] font-bold leading-[18px] tracking-tighter;
          }

          p-accordiontab .p-accordion-tab {
            @apply mb-[10px] bg-transparent shadow-none;
          }

          .p-accordion-content {
            @apply bg-transparent pb-0 pl-9 pr-5 pt-[10px];
          }
        }
      }
    `,
  ],
})
export class EditorTopicDefaultComponent implements OnInit {
  protected activeTabs: number[] = [];

  protected readonly uuid = uuidv4();

  private readonly destroyRef = inject(DestroyRef);

  protected readonly ngControl = inject(NgControl);

  private readonly cdr = inject(ChangeDetectorRef);

  get control() {
    return this.ngControl.control as FormControl<string>;
  }

  protected readonly categories: {
    label: string;
    short: string;
    topics: string[];
  }[] = [
    {
      label: 'Экономические вопросы',
      short: 'eco',
      topics: [
        'Окупаемость проекта',
        'Конкурентные преимущества',
        'Бизнес-модель',
      ],
    },
    {
      label: 'Приватность и безопасность',
      short: 'privacy',
      topics: [
        'Приватность данных',
        'Кибербезопасность',
        'Защита личных данных',
        'Этические вопросы',
      ],
    },
    {
      label: 'Техническая реализация',
      short: 'tech',
      topics: [
        'Техническая сложность',
        'Эффективность алгоритмов',
        'Масштабируемость',
        'Выбор технологий',
      ],
    },
    {
      label: 'Влияние на рынок и общество',
      short: 'soc',
      topics: [
        'Воздействие на рынок',
        'Трансформация отрасли',
        'Роль технологий в обществе',
        'Экологическая сторона',
      ],
    },
    // {
    //   label: 'Право',
    //   short: 'law',
    //   topics: ['Право1', 'Право2', 'Право3', 'Право4'],
    // },
  ];

  ngOnInit(): void {
    if (isMissed(this.ngControl.valueChanges)) {
      this.activeTabs = [0];
      return;
    }
    this.ngControl.valueChanges
      ?.pipe(
        startWith(this.ngControl.value),
        takeUntilDestroyed(this.destroyRef)
      )
      .subscribe((val) => {
        const activeTabIndex = this.findActiveTopicTab(val, this.categories);
        if (!val || activeTabIndex < 0) {
          this.activeTabs = [0];
          return;
        }
        this.activeTabs = [...new Set([...this.activeTabs, activeTabIndex])];
      });

    this.control.statusChanges
      ?.pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe(() => {
        this.cdr.markForCheck();
      });
  }

  @memoize
  private findActiveTopicTab(
    topic: string,
    categories: {
      label: string;
      short: string;
      topics: string[];
    }[]
  ) {
    return categories.findIndex(({ topics }) => {
      return topics.includes(topic);
    });
  }

  get activeTab() {
    const val = this.ngControl.value;
    const activeTabIndex = this.findActiveTopicTab(val, this.categories);

    return activeTabIndex ?? -1;
  }
}
