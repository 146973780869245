import { Observable, finalize } from 'rxjs';
import { LikeExchangeService } from './like-exchange.service';

export class LikeExchangeExperienceService extends LikeExchangeService {
  override exchange(categorySlug: string, count: number): Observable<unknown> {
    this.loadingSubject$.next(true);
    return this.likeService
      .exchangeToExperience(categorySlug, count)
      .pipe(finalize(() => this.loadingSubject$.next(false)));
  }
}
