import { ChangeDetectionStrategy, Component } from '@angular/core';

@Component({
  selector: 'sw-verified-banner',
  standalone: true,
  changeDetection: ChangeDetectionStrategy.OnPush,
  template: ` <div
    class="island flex flex-row items-center justify-between space-x-4 overflow-hidden bg-gradient-to-b from-white to-[#E6EBFF]"
  >
    <div>
      <p class="text-sm font-medium">
        Статус проекта:
        <span class=" text-accent">верифицирован</span>
      </p>
    </div>

    <div>
      <svg
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g clip-path="url(#clip0_39_3217)">
          <path
            d="M16.298 8.288L17.702 9.713L11.909 15.42C11.523 15.807 11.013 16 10.502 16C9.99102 16 9.47702 15.805 9.08602 15.415L6.30402 12.719L7.69602 11.282L10.489 13.989L16.298 8.288ZM23.637 13.451L19.385 21.413C18.863 22.392 17.849 23 16.739 23H7.28102C6.17402 23 5.16002 22.394 4.63702 21.418L0.366018 13.46C-0.122982 12.548 -0.122982 11.46 0.366018 10.549L4.63802 2.582C5.16102 1.606 6.17402 1 7.28202 1H16.739C17.849 1 18.863 1.608 19.385 2.587L23.637 10.549C24.122 11.457 24.122 12.542 23.637 13.45V13.451ZM21.873 11.493L17.621 3.53C17.446 3.203 17.108 3.001 16.738 3.001H7.28202C6.91302 3.001 6.57502 3.203 6.40102 3.528L2.12802 11.495C1.95702 11.814 1.95702 12.195 2.12802 12.515L6.39902 20.473C6.57402 20.799 6.91102 21 7.28002 21H16.737C17.107 21 17.445 20.797 17.62 20.471L21.872 12.508C22.042 12.19 22.043 11.81 21.873 11.493Z"
            fill="#194CFF"
          />
        </g>
        <defs>
          <clipPath id="clip0_39_3217">
            <rect width="24" height="24" fill="white" />
          </clipPath>
        </defs>
      </svg>
    </div>
  </div>`,
})
export class VerifiedBannerComponent {}
