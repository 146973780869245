import { Purchase } from '@/core/models';
import { ButtonComponent, SVGComponent } from '@/shared/ui';
import { NgClass, NgIf } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  Input,
  inject,
} from '@angular/core';
import { toSignal } from '@angular/core/rxjs-interop';
import { MarketPurchaseDirective } from '../purchase.directive';
import { PurchaseService } from '../purchase.service';

@Component({
  selector: 'sw-market-item',
  standalone: true,
  imports: [
    ButtonComponent,
    NgIf,
    SVGComponent,
    NgClass,
    MarketPurchaseDirective,
  ],
  providers: [PurchaseService],
  changeDetection: ChangeDetectionStrategy.OnPush,
  template: `
    <div class="island-base flex flex-row items-center py-2 pl-4 pr-2">
      <p>{{ name }}</p>

      <div class="ml-auto">
        <button
          swButton
          swMarketPurchase
          [goodId]="goodId"
          [loading]="loading() || false"
          [disabled]="disableBtn"
          spinnerColor="currentColor"
          type="button"
          class="button px-5 py-2"
          [ngClass]="{
            'button-primary  text-white': !isPurchased,
            'button-outline border-accent text-accent': isPurchased
          }"
        >
          <div class="space-x-3">
            <ng-container *ngIf="!isPurchased; else successMsg">
              <span>{{ price }}</span>
              <span>
                <sw-svg
                  icon="gem"
                  class="text-[18px]"
                  width="18px"
                  height="18px"
                />
              </span>
            </ng-container>
            <ng-template #successMsg>
              <span>{{ successLabel }}</span>
            </ng-template>
          </div>
        </button>
      </div>
    </div>
  `,
})
export class MarketItemComponent {
  @Input({ required: true })
  goodId!: string;

  @Input({ required: true })
  name!: string;

  @Input({ required: true })
  price = 0;

  @Input()
  canPurchase = true;

  @Input()
  purchases: Purchase[] = [];

  @Input()
  isPurchased = false;

  @Input({ required: true })
  productType!: 'file' | 'form';

  protected readonly loading = toSignal(inject(PurchaseService).loading$);

  get successLabel() {
    return this.productType === 'file' ? 'Скачать' : 'Заполнить форму';
  }

  get disableBtn() {
    if (this.purchases.length > 0) {
      return false;
    }

    return !this.canPurchase;
  }
}
