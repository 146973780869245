import { memoize } from '@/core/decorators';
import { calcContrastColor } from '@/core/helpers';
import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

@Component({
  selector: 'sw-category-tag',
  standalone: true,
  imports: [CommonModule],
  changeDetection: ChangeDetectionStrategy.OnPush,
  template: `<div
    class="flex h-[25px] select-none items-center justify-center rounded-[5px] bg-[var(--tag-color)] px-[10px]"
    [style]="{ '--tag-color': color }"
  >
    <span
      class="text-xs font-medium leading-[15px] text-[var(--tag-text-color)]"
      [style]="{ '--tag-text-color': textColor(color) }"
    >
      <ng-content></ng-content>
    </span>
  </div>`,
})
export class CategoryTagComponent {
  @Input({ required: true }) color!: string;

  @memoize
  protected textColor(bgColor: string) {
    return calcContrastColor(bgColor);
  }
}
