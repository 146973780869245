import { ChangeDetectionStrategy, Component, inject } from '@angular/core';
import { FormGroup, ReactiveFormsModule } from '@angular/forms';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';

import { ButtonComponent } from '@/shared/ui/button.component';

import { isResponseError, validateAllFormFields } from '@/core/helpers';
import { TopicService } from '@/core/services/topics.service';
import { LetDirective } from '@/shared/directives';
import { HttpErrorResponse } from '@angular/common/http';
import {
  TopicEditorFormService,
  provideTopicEditorService,
} from './topic-editor-form-service';
import { TopicEditorWrapperComponent } from './topic-editor-wrapper.component';
import { TopicEditorService } from './topic-editor.service';

@Component({
  selector: 'sw-project-topic-dialog',
  standalone: true,
  providers: [provideTopicEditorService(TopicEditorService)],
  imports: [
    LetDirective,
    ReactiveFormsModule,
    ButtonComponent,
    TopicEditorWrapperComponent,
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
  template: `
    <form (ngSubmit)="handleSubmit()" [formGroup]="form" class="space-y-10">
      <div class="space-y-5" formArrayName="topics">
        @for (control of topicsControlArray.controls; track $index) {
        <div>
          <sw-topic-editor-wrapper
            *swLet="$index === topicsControlArray.length - 1 as isLast"
            [skipScroll]="skipScrollToTopicPreview"
            [formGroup]="control"
            [isLast]="isLast"
            [canDelete]="(isLast && topicsControlArray.length > 1) || !isLast"
            (save)="handleSaveTopic()"
            (delete)="handleDeleteTopic($index)"
          />
        </div>
        }
      </div>

      <button
        swButton
        class="button button-primary w-full py-5 text-base font-bold leading-5"
        type="submit"
      >
        Добавить тему
      </button>
    </form>
  `,
})
export class ProjectTopicDialogComponent {
  private readonly editorTopicService = inject(TopicEditorFormService);

  private readonly topicService = inject(TopicService);

  protected readonly dialogRef = inject(DynamicDialogRef);
  protected readonly config = inject(DynamicDialogConfig);

  protected skipScrollToTopicPreview = true;

  protected readonly topicsControlArray =
    this.editorTopicService.createFormArray([{}]);

  protected readonly form = new FormGroup({
    topics: this.topicsControlArray,
  });

  get projectId() {
    return this.config.data.projectId as string;
  }

  protected handleSubmit() {
    validateAllFormFields(this.form);
    if (this.form.valid) {
      const topics = this.form
        .getRawValue()
        .topics.map((t) => ({ ...t, comment: t.comment || null }));

      this.topicService.createTopics(this.projectId, { topics }).subscribe({
        next: () => {
          this.dialogRef.close();
        },
        error: (e: HttpErrorResponse) => {
          if (e.status === 422 && isResponseError(e.error)) {
            e.error.detail.forEach((e) => {
              const [field] = e.loc;
              this.form.get(field)?.setErrors({
                commonError: {
                  msg: e.msg,
                },
              });
            });
          }
        },
      });
    }
  }

  protected handleSaveTopic() {
    this.topicsControlArray.push(this.editorTopicService.createTopicGroup({}));
    this.skipScrollToTopicPreview = false;
  }

  protected handleDeleteTopic(topicId: number) {
    this.topicsControlArray.removeAt(topicId);
  }
}
