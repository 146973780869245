import { ValidatorFn, Validators } from '@angular/forms';

export function createStrongPasswordValidator(): ValidatorFn {
  return (control) => {
    const error = Validators.pattern(
      /(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])[A-Za-z\d].*/
    )(control);
    return error ? { strongPassword: true } : null;
  };
}
