import { ProjectService, UserService } from '@/core/services';
import { ProjectEditorComponent } from '@/features/project-editor';
import { ChangeDetectionStrategy, Component, inject } from '@angular/core';
import { RouterModule } from '@angular/router';

import {
  ProjectEditorService,
  provideProjectEditor,
} from '@/core/services/project-editor';
import { OpenInfoTabDirective } from '@/features/info/open-info-tab.directive';
import { UserNotificationsComponent } from '@/features/notification';
import { SVGComponent } from '@/shared/ui';
import { MenuIconComponent } from '@/shared/ui/menu-icon.component';
import { toSignal } from '@angular/core/rxjs-interop';
import { DialogService } from 'primeng/dynamicdialog';
import { BaseMenu } from './base-menu.component';

@Component({
  selector: 'sw-mobile-menu',
  standalone: true,
  imports: [
    UserNotificationsComponent,
    OpenInfoTabDirective,
    MenuIconComponent,
    RouterModule,
    SVGComponent,
  ],
  providers: [
    DialogService,
    ProjectService,
    provideProjectEditor(ProjectEditorService),
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
  template: `
    <div class="w-full bg-bg px-[18px] py-[30px]">
      <!-- swVerticalScroll -->
      @if (isLoggedIn()) {

      <ul
        class="no-scrollbar flex w-full snap-x snap-proximity flex-row flex-nowrap items-center justify-between gap-5 overflow-x-auto"
      >
        @for (elem of menu; track $index) {
        <li
          class="inline-flex shrink-0 snap-start  first:pl-[18px] last:pr-[18px]"
        >
          <a
            class="button-base flex aspect-square h-[50px] w-[50px] items-center justify-center rounded-full bg-grey "
            [routerLink]="elem.path"
            [queryParams]="elem.query"
            queryParamsHandling="merge"
            routerLinkActive="text-accent"
          >
            <sw-menu-icon [icon]="elem.icon" class="text-base " />
          </a>
        </li>
        }

        <li
          class="inline-flex shrink-0 snap-start  first:pl-[18px] last:pr-[18px]"
        >
          <button
            swInfoTab="karma"
            class="button-base flex aspect-square h-[50px] w-[50px] items-center justify-center rounded-full bg-grey "
          >
            <sw-menu-icon icon="heart" class="text-base " />
          </button>
        </li>
        <li
          class="inline-flex shrink-0 snap-start  first:pl-[18px] last:pr-[18px]"
        >
          <button
            swInfoTab="gem"
            class="button-base flex aspect-square h-[50px] w-[50px] items-center justify-center rounded-full bg-grey "
          >
            <sw-menu-icon icon="gem" class="text-base " />
          </button>
        </li>
        <li
          class="inline-flex shrink-0 snap-start  first:pl-[18px] last:pr-[18px]"
        >
          <sw-user-notifications
            buttonClassName="button-base flex aspect-square h-[50px] w-[50px] items-center justify-center rounded-full bg-grey"
          />
        </li>
        <li>
          <button
            class="button button-primary button-rounded aspect-square h-[50px] w-[50px]"
            (click)="handleAddProject()"
          >
            <sw-svg
              icon="circle-plus"
              width="50px"
              height="50px"
              class="text-xl"
            />
          </button>
        </li>
      </ul>
      } @else {
      <a
        href="/login"
        class="button button-primary max-h-10 w-full cursor-pointer py-[10px] text-sm font-bold"
        >Войти</a
      >
      }
    </div>
  `,
})
export class MobileMenuComponent extends BaseMenu {
  protected readonly isLoggedIn = toSignal(inject(UserService).isLoggedIn$);

  private readonly dialogService = inject(DialogService);

  protected handleAddProject() {
    this.dialogService.open(ProjectEditorComponent, {
      header: 'Создание проекта',
      width: 'min(100%, 556px)',
      dismissableMask: true,
    });
  }
}
