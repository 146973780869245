import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RatingRageComponent } from '@shared/ui/rating-range-slider.component';
import { BaseOpinion } from './base-opinion';

@Component({
  selector: 'sw-opinion-user',
  standalone: true,
  imports: [
    CommonModule,
    RatingRageComponent,
    FormsModule,
    ReactiveFormsModule,
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
  template: `
    <div class="flex flex-col space-y-[10px] rounded-md bg-black/5 p-[10px]">
      <span class="text-center text-xs leading-[22px] text-black/50">
        Значимость комментария
      </span>
      <div class="flex flex-1 flex-row items-center">
        <button
          swPermissionAction
          type="button"
          class="button flex-1 rounded-r-none border border-r-0 border-black/10 text-sm font-medium leading-[22px] text-green"
          [ngClass]="{
            'bg-green/20': value && value > 0
          }"
          (click)="handleBtn(true)"
        >
          Согласен
        </button>
        <button
          swPermissionAction
          type="button"
          class="button flex-1 rounded-l-none border border-black/10 text-sm font-medium leading-[22px] text-red"
          [ngClass]="{
            'bg-red/20': value && value < 0
          }"
          (click)="handleBtn(false)"
        >
          Не согласен
        </button>
      </div>
    </div>
  `,
})
export class OpinionUserComponent extends BaseOpinion {}
