import { Directive, HostListener, Input, inject } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

@Directive({ selector: '[swInfoTab]', standalone: true })
export class OpenInfoTabDirective {
  @Input({ required: true })
  swInfoTab!: 'gem' | 'karma';

  private readonly router = inject(Router);
  private readonly activated = inject(ActivatedRoute);

  @HostListener('click')
  protected handleClick() {
    this.openGemOrKarma(this.swInfoTab);
  }

  private openGemOrKarma(type: 'gem' | 'karma') {
    this.router.navigate([], {
      queryParams: { info: type },
      queryParamsHandling: 'merge',
      relativeTo: this.activated,
      replaceUrl: true,
    });
  }
}
