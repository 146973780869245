import { memoize } from '@/core/decorators/memoize';
import { sum } from '@/core/helpers';
import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

@Component({
  selector: 'sw-horizontal-bar',
  standalone: true,
  imports: [CommonModule],
  changeDetection: ChangeDetectionStrategy.OnPush,
  template: `
    <div
      class="flex h-[5px] flex-row items-center overflow-hidden rounded-md bg-black/10"
    >
      <div
        *ngFor="let v of value; let i = index; trackBy: trackByFn"
        class="h-full"
        [style.width.%]="getWidth(v, total)"
        [style.backgroundColor]="colors[i]"
      ></div>
    </div>
  `,
})
export class HorizontalBarComponent {
  @Input({ required: true }) value!: number[];
  @Input() colors: string[] = ['#CF3535', '#1AA654'];

  get total() {
    return this.getTotal(this.value);
  }

  trackByFn(_index: number, value: number) {
    return `bar-${value}`;
  }

  @memoize
  private getTotal(arr: number[]): number {
    return sum(...arr);
  }

  protected getWidth(value: number, sum: number): number {
    return (100 * value) / sum;
  }
}
