import { CommentsService } from '@/core/services/comments.service';
import { Directive, HostListener, Input, inject } from '@angular/core';

@Directive({ selector: '[swAgreementBtn]', standalone: true })
export class AgreementBtnDirective {
  @Input({ required: true }) commentId!: string;
  @Input({ required: true }) value: number | null = null;

  private readonly commentsService = inject(CommentsService);

  @HostListener('click')
  setValue() {
    this.commentsService.setAgreement(this.commentId, this.value).subscribe();
  }
}
