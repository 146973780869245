import { Injectable, inject } from '@angular/core';
import { WINDOW } from '@core/tokens';

@Injectable({ providedIn: 'root' })
export class StorageService {
  private readonly window = inject(WINDOW);

  get<T = unknown>(key: string): T | null {
    const raw = this.window.localStorage?.getItem(key);
    return raw ? JSON.parse(raw) : null;
  }

  set(key: string, value: unknown) {
    return this.window.localStorage?.setItem(key, JSON.stringify(value));
  }

  removeItem(key: string) {
    this.window.localStorage?.removeItem(key);
  }

  clear() {
    this.window.localStorage?.clear();
  }
}
