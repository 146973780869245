import { CommentExtended, Project, Thread, User } from '@/core/models';

import { SVGComponent } from '@/shared/ui/svg.component';
import { animate, style, transition, trigger } from '@angular/animations';
import { CommonModule } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  Input,
  OnChanges,
  SimpleChanges,
} from '@angular/core';
import { RouterModule } from '@angular/router';

import { memoize } from '@/core/decorators';
import { RegisterTrackDirective } from '@/shared/directives/track';
import { MapperPipe } from '@/shared/pipes';
import { FireIconComponent } from '@/shared/ui';
import { HeaderCardComponent } from '@/shared/ui/header-card.component';
import {
  ProjectCardExtendedComponent,
  ProjectStackDelimiterComponent,
} from '@/shared/ui/project';
import { ExtendedCommentComponent } from './extended-comment.component';

type Post = { author: User } & (
  | { type: 'project'; post: Project }
  | { type: 'comment'; post: CommentExtended }
);

@Component({
  selector: 'sw-comment-stack',
  standalone: true,
  imports: [
    CommonModule,
    RouterModule,
    HeaderCardComponent,
    ProjectStackDelimiterComponent,
    ExtendedCommentComponent,
    ProjectCardExtendedComponent,
    RegisterTrackDirective,
    FireIconComponent,
    MapperPipe,
    SVGComponent,
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
  animations: [
    trigger('cardAnimate', [
      transition(':enter', [
        style({ opacity: 0.7, height: 0 }),
        animate('500ms', style({ opacity: 1, height: '*' })),
      ]),
      transition(':leave', [
        style({ opacity: 1, height: '*' }),
        animate('300ms', style({ opacity: 0.2, height: 0 })),
      ]),
    ]),
  ],
  template: `
    <div class="z-0" [@.disabled]="!expanded">
      <ng-container
        *ngFor="let item of posts; let i = index; trackBy: trackByParents"
      >
        <sw-project-card-extended
          @cardAnimate
          *ngIf="expanded && item.type === 'project'"
          [project]="item.post"
          class="block"
        />

        <sw-project-extended-comment
          @cardAnimate
          *ngIf="
            item.type === 'comment' && (expanded || posts.length - 1 === i)
          "
          [comment]="item.post"
          [isMainComment]="true"
          [canComment]="i < posts.length - 1"
          [projectId]="thread.project.id"
          [showChildComments]="false"
          swRegisterTrackSeen
          [trackSeenId]="item.post.id"
          class="block"
        >
          <ng-container headerActions>
            <div class="flex items-center space-x-5">
              <!-- <sw-fire-icon /> -->
              <sw-svg icon="arrow-up" width="15px" height="15px" />
            </div>
          </ng-container>
        </sw-project-extended-comment>

        <div
          @cardAnimate
          *ngIf="!expanded && i < posts.length - 1"
          class="island-base relative z-0 -mt-4 block pb-4 transition-transform duration-200 after:absolute after:left-10 after:top-0 after:block after:h-full after:w-[1px] after:bg-black/30 after:content-[''] first:mt-0 first:after:top-1/2 first:after:h-1/2 last:pb-5 focus-within:-translate-y-2 hover:-translate-y-2"
        >
          <a
            [routerLink]="getPostUrl(thread.project.id, item.post.id)"
            [style]="{ zIndex: i }"
          >
            <sw-header-card
              [name]="item.author.fullName"
              [avatarUrl]="item.author.photo || undefined"
              [description]="item.author.rank || undefined"
              [showStar]="item.author.showStar"
              [createdAt]="item.post.createdAt"
              class="relative z-10"
            >
              <!-- [menuItems]="menuItems$ | async" -->
              <ng-container actions>
                <div class="flex items-center">
                  <sw-svg
                    *ngIf="i === 0"
                    icon="lock"
                    width="18px"
                    height="20px"
                  />
                  <sw-svg
                    *ngIf="i > 0"
                    icon="arrow-up"
                    width="15px"
                    height="15px"
                  />
                </div>
              </ng-container>
            </sw-header-card>
          </a>

          <button
            type="button"
            (click.stop.prevent)="expand()"
            *ngIf="i === posts.length - 2"
            class="m-auto flex flex-row items-center justify-center space-x-[10px] text-[14px] leading-[22px] text-accent outline-none transition-colors duration-200 hover:text-accent/70 hover:underline hover:underline-offset-2 focus-visible:text-accent/70 focus-visible:underline focus-visible:underline-offset-2"
          >
            <span>Развернуть ветку комментариев</span>
            <span>
              <sw-svg icon="chevron-down" width="13px" height="8px" />
            </span>
          </button>
        </div>

        <sw-project-stack-delimiter
          @cardAnimate
          *ngIf="(expanded && i < posts.length - 2) || i === posts.length - 2"
        >
          Уровень {{ i + 2 }}
        </sw-project-stack-delimiter>
      </ng-container>
    </div>
  `,
})
export class CommentStackComponent implements OnChanges {
  @Input({ required: true })
  thread!: Thread;

  protected posts: Post[] = [];

  @Input()
  expanded = false;

  ngOnChanges(changes: SimpleChanges): void {
    if ('thread' in changes) {
      const tChanges = changes['thread'];
      const tCurrent = tChanges.currentValue as Thread;
      const tPrev = tChanges.previousValue as Thread | undefined;

      if (
        tPrev &&
        (tPrev.project.id !== tCurrent.project.id ||
          tPrev.parents.length !== tCurrent.parents.length ||
          tPrev.parents.some(
            (prevP) => !tCurrent.parents.find((currP) => currP.id === prevP.id)
          ))
      ) {
        this.expanded = false;
      }

      this.posts = [
        {
          type: 'project',
          post: tCurrent.project,
          author: tCurrent.project.owner,
        },
        ...tCurrent.parents.map<Post>((c) => ({
          type: 'comment',
          post: c,
          author: c.author,
        })),
      ];
    }
  }

  @memoize
  protected getPostUrl(projectId: string, postId: string) {
    if (projectId === postId) {
      return ['/', 'thread', projectId];
    }
    return ['/', 'thread', projectId, postId];
  }

  protected expand() {
    this.expanded = true;
  }

  protected trackByParents(_index: number, item: Post): string {
    return `${item.post.id}-parent`;
  }
}
