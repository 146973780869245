import {
  HttpContextToken,
  HttpEvent,
  HttpInterceptorFn,
  HttpResponse,
} from '@angular/common/http';
import { isPresent } from '@core/helpers';
import camelcaseKeys from 'camelcase-keys';
import { map } from 'rxjs';
import * as snakecaseKeys from 'snakecase-keys';

export const MAP_RES_HTTP_TOKEN = new HttpContextToken<boolean>(() => true);

export const MAP_REQ_HTTP_TOKEN = new HttpContextToken<boolean>(() => true);

export const caseMapperInterceptor: HttpInterceptorFn = (request, next) => {
  if (request.context.get(MAP_REQ_HTTP_TOKEN)) {
    request = request.clone({
      ...(request.body
        ? {
            body: snakecaseKeys(
              request.body as unknown as Record<string, unknown>,
              { deep: true }
            ),
          }
        : null),
    });
  }

  return next(request).pipe(
    map((event: HttpEvent<unknown>) => {
      if (event instanceof HttpResponse) {
        if (
          request.context.get(MAP_RES_HTTP_TOKEN) &&
          typeof event.body === 'object' &&
          isPresent(event.body)
        ) {
          event = event.clone({
            body: camelcaseKeys(event.body, { deep: true }),
          });
        }
      }
      return event;
    })
  );
};
