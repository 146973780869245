import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  DestroyRef,
  OnInit,
  inject,
} from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { FormControl, NgControl, ReactiveFormsModule } from '@angular/forms';

@Component({
  selector: 'sw-editor-topic-custom',
  standalone: true,
  imports: [ReactiveFormsModule],
  changeDetection: ChangeDetectionStrategy.OnPush,
  template: `
    <div class="space-y-5">
      <input
        class="form-field"
        [formControl]="control"
        (blur)="handleBlur()"
        placeholder="Например: экономия затрат"
      />
      <p class="text-sm font-normal text-black/50">Максимум: 50 символов</p>
    </div>
  `,
})
export class EditorTopicCustomComponent implements OnInit {
  protected readonly ngControl = inject(NgControl);

  private readonly cdr = inject(ChangeDetectorRef);
  private readonly destroyRef = inject(DestroyRef);

  get control() {
    return this.ngControl.control as FormControl;
  }

  ngOnInit(): void {
    this.control.statusChanges
      ?.pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe(() => {
        this.cdr.markForCheck();
      });
  }

  protected handleBlur() {
    this.ngControl.control?.markAsTouched();
  }
}
