import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'swInitials',
  standalone: true,
})
export class InitialsPipe implements PipeTransform {
  transform(value: string, length = 1): string {
    const words = (value || '').toUpperCase().split(' ').splice(0, length);
    return words.map((w) => w[0]).join('');
  }
}
