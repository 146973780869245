import { NgClass } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  inject,
  Input,
  ViewEncapsulation,
} from '@angular/core';
import { NgControl } from '@angular/forms';

@Component({
  selector: 'sw-textarea',
  standalone: true,
  imports: [NgClass],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
  template: `
    <div
      class="wrapper m-[1px]"
      [attr.data-replicated-value]="ngControl.value"
      aria-hidden="true"
    >
      <textarea
        #textarea
        class="form-field resize-none overflow-hidden"
        [ngClass]="styleClass"
        [placeholder]="placeholder"
        [value]="ngControl.value"
        [disabled]="ngControl.disabled"
        (blur)="handleBlur()"
        rows="1"
      ></textarea>
    </div>
  `,

  styles: [
    `
      sw-textarea.ng-dirty.ng-invalid:not(form):not(:focus-within) {
        .form-field {
          @apply border-rose-500;
        }
      }

      .wrapper {
        display: grid;
        &::after {
          content: attr(data-replicated-value) ' ';
          white-space: pre-wrap;
          word-break: break-word;
          visibility: hidden;
          padding: 20px;
        }

        & > textarea,
        &::after {
          font: inherit;
          grid-area: 1 / 1 / 2 / 2;
        }
      }
    `,
  ],
})
export class TextAreaComponent {
  protected readonly ngControl = inject(NgControl);

  protected handleBlur() {
    this.ngControl.control?.markAsTouched();
  }

  @Input() styleClass = 'min-h-[100px]';

  @Input() placeholder = 'О чем ваш проект?';
}
