import {
  ConnectionPositionPair,
  Overlay,
  OverlayRef,
} from '@angular/cdk/overlay';
import { ComponentPortal } from '@angular/cdk/portal';
import { DOCUMENT } from '@angular/common';
import {
  DestroyRef,
  Directive,
  ElementRef,
  InjectionToken,
  Injector,
  Input,
  OnDestroy,
  TemplateRef,
  inject,
} from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import {
  Subject,
  delay,
  distinctUntilChanged,
  fromEvent,
  takeUntil,
  throttleTime,
} from 'rxjs';

import { ReactionOnboardingComponent } from './reaction-onboarding.component';

export type ReactionOnboardingData = {
  title: string;
  content: TemplateRef<unknown> | string;
};

export const REACTION_ONBOARDING_DATA =
  new InjectionToken<ReactionOnboardingData>('Reaction onboarding data');

@Directive({
  selector: '[swReactionOnboarding]',
  standalone: true,
  exportAs: 'swReactionOnboarding',
})
export class ReactionOnboardingDirective implements OnDestroy {
  @Input({ required: true }) reactionHeader!: string;
  @Input({ required: true }) reactionContent!: TemplateRef<unknown> | string;

  private readonly isOpen = new Subject<boolean>();

  overlayRef: OverlayRef | null = null;

  private readonly destroy$ = new Subject<void>();

  private readonly document = inject(DOCUMENT);

  constructor(
    private readonly elementRef: ElementRef<HTMLElement>,
    private readonly overlay: Overlay,
    private readonly destroyRef: DestroyRef
  ) {
    // if (this.isTouchScreen) {
    //   return;
    // }

    this.isOpen
      .asObservable()
      .pipe(
        takeUntilDestroyed(this.destroyRef),
        distinctUntilChanged(),
        delay(100)
      )
      .subscribe((isOpen) => {
        if (isOpen) {
          this.showReactionOnboarding();
        } else {
          this.destroyReactionOnboarding();
        }
      });
  }

  open() {
    this.isOpen.next(true);
  }

  close() {
    this.isOpen.next(false);
  }

  ngOnDestroy(): void {
    this.destroyReactionOnboarding();
    this.destroy$.complete();
  }

  private showReactionOnboarding = () => {
    fromEvent(this.document, 'mousemove')
      .pipe(takeUntil(this.destroy$), distinctUntilChanged(), throttleTime(100))
      .subscribe((e) => {
        const target = e.target as HTMLElement;

        if (
          !(
            this.elementRef.nativeElement === target ||
            this.elementRef.nativeElement.contains(target) ||
            target.closest('sw-reaction-onboarding')
          )
        ) {
          this.isOpen.next(false);
        }
      });

    this.overlayRef = this.overlay.create({
      positionStrategy: this.overlay
        .position()
        .flexibleConnectedTo(this.elementRef)
        .withPositions([
          new ConnectionPositionPair(
            { originX: 'start', originY: 'center' },
            { overlayX: 'end', overlayY: 'top' },
            10
          ),
          new ConnectionPositionPair(
            { originX: 'center', originY: 'center' },
            { overlayX: 'end', overlayY: 'top' },
            10
          ),
          new ConnectionPositionPair(
            { originX: 'start', originY: 'top' },
            { overlayX: 'end', overlayY: 'center' }
          ),
          new ConnectionPositionPair(
            { originX: 'end', originY: 'top' },
            { overlayX: 'start', overlayY: 'center' }
          ),
        ]),
    });

    const injector: Injector = Injector.create({
      providers: [
        {
          provide: REACTION_ONBOARDING_DATA,
          useValue: {
            title: this.reactionHeader,
            content: this.reactionContent,
          },
        },
      ],
    });
    const component = new ComponentPortal(
      ReactionOnboardingComponent,
      null,
      injector
    );
    this.overlayRef?.attach(component);
  };

  private destroyReactionOnboarding = () => {
    this.overlayRef?.detach();
    this.overlayRef?.dispose();

    this.destroy$.next();
  };
}
