import { FormSchema, FormSchemaElement } from '@/core/types';

import { LetDirective } from '@/shared/directives';
import { KeyValue, KeyValuePipe, NgFor, NgIf } from '@angular/common';
import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import {
  AbstractControl,
  FormGroup,
  ReactiveFormsModule,
  isFormGroup,
} from '@angular/forms';
import { FormFieldWrapperComponent } from './form-field-wrapper.component';

@Component({
  selector: 'sw-form-group',
  standalone: true,
  imports: [
    NgIf,
    NgFor,
    LetDirective,
    ReactiveFormsModule,
    FormFieldWrapperComponent,
    KeyValuePipe,
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
  template: `
    <div class="space-y-4">
      <div *ngFor="let field of form.controls | keyvalue : order">
        <ng-container *ngIf="isFormGroup(field.value); else control">
          <sw-form-group
            [form]="field.value"
            [schema]="getFormSubschema(field.key)"
          />
        </ng-container>

        <ng-template #control>
          <sw-field-wrapper
            *swLet="getElementSchema(field.key) as elem"
            [label]="elem.label"
            [placeholder]="elem.placeholder"
            [type]="elem.fieldType"
            [control]="$any(field.value)"
          />
        </ng-template>
      </div>
    </div>
  `,
})
export class FormGroupComponent {
  @Input({ required: true })
  form!: FormGroup;

  @Input({ required: true })
  schema!: FormSchema;

  protected isFormGroup(formGroup: unknown): formGroup is FormGroup {
    return isFormGroup(formGroup);
  }

  protected getFormSubschema(field: string) {
    return this.schema[field] as FormSchema;
  }

  protected getElementSchema(field: string) {
    return this.schema[field] as FormSchemaElement;
  }

  protected order = (
    a: KeyValue<string, AbstractControl>,
    b: KeyValue<string, AbstractControl>
  ): number => {
    if (this.isFormGroup(a.value) && this.isFormGroup(b.value)) {
      return 0;
    }

    if (this.isFormGroup(a.value)) {
      return 1;
    }

    if (this.isFormGroup(b.value)) {
      return -1;
    }

    return 0;
  };
}
