import {
  Directive,
  Inject,
  Input,
  TemplateRef,
  ViewContainerRef,
} from '@angular/core';

export interface ContextWithImplicit<T> {
  $implicit: T;
}

/**
 * @internal
 */
export class LetContext<T> implements ContextWithImplicit<T> {
  constructor(private readonly internalDirectiveInstance: LetDirective<T>) {}

  get $implicit(): T {
    return this.internalDirectiveInstance.swLet;
  }

  get swLet(): T {
    return this.internalDirectiveInstance.swLet;
  }
}

@Directive({
  selector: '[swLet]',
  standalone: true,
})
export class LetDirective<T> {
  @Input()
  swLet!: T;

  constructor(
    @Inject(ViewContainerRef) viewContainer: ViewContainerRef,
    @Inject(TemplateRef) templateRef: TemplateRef<LetContext<T>>
  ) {
    viewContainer.createEmbeddedView(templateRef, new LetContext<T>(this));
  }

  static ngTemplateContextGuard<T>(
    _dir: LetDirective<T>,
    _ctx: unknown
  ): _ctx is LetContext<T> {
    return true;
  }
}
