import { DestroyRef, Injectable, inject } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { pairwise } from 'rxjs';
import {
  EditorTopic,
  TopicEditorFormService,
} from './topic-editor-form-service';
import { commentRequiredValidator } from './comment-required.validator';

@Injectable()
export class TopicEditorService extends TopicEditorFormService {
  private readonly destroyRef = inject(DestroyRef);

  createTopicGroup(topic: Partial<EditorTopic> = {}) {
    const agreementControl = new FormControl<number | null>(
      topic.agreement ?? null
    );

    const commentControl = new FormControl<string>(topic.comment || '', {
      nonNullable: true,
      validators: [
        Validators.minLength(2),
        Validators.maxLength(4096),
        commentRequiredValidator,
      ],
    });

    const fg = new FormGroup({
      name: new FormControl<string>(topic.name || '', {
        nonNullable: true,
        validators: [
          Validators.required,
          Validators.minLength(2),
          Validators.maxLength(50),
        ],
      }),
      comment: commentControl,
      agreement: agreementControl,
    });

    agreementControl.valueChanges
      .pipe(takeUntilDestroyed(this.destroyRef), pairwise())
      .subscribe({
        next: ([prev, curr]) => {
          if (prev !== null && curr === null) {
            commentControl.updateValueAndValidity();
          }
        },
      });

    return fg;
  }
}
