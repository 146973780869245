import { CommentsAPIService } from '@/core/services/comments.service';
import { Injectable, inject } from '@angular/core';
import { TrackSeenAPI } from './track-seen.token';
import { Observable, throwError } from 'rxjs';

@Injectable()
export class TrackSeenCommentsFacade extends TrackSeenAPI {
  private readonly comments = inject(CommentsAPIService);

  override setSeen(ids: string[]): Observable<unknown>;
  override setSeen(ids: string[], parentId: string): Observable<unknown>;
  override setSeen(ids: string[], parentId?: string): Observable<unknown> {
    if (typeof parentId !== 'string') {
      console.warn('parentid are required');
      return throwError(() => 'parentid are required');
    }

    return this.comments.setSeen(parentId, ids);
  }
}
