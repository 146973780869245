import { CategoriesService, UserService } from '@/core';
import { CategoryExtended } from '@/core/types/category.type';
import { BackButtonComponent } from '@/shared/ui/back-button.component';
import { AsyncPipe, NgFor, NgIf } from '@angular/common';
import { ChangeDetectionStrategy, Component, inject } from '@angular/core';
import {
  ActivatedRoute,
  NavigationEnd,
  Router,
  RouterModule,
} from '@angular/router';
import { CategoryIconComponent } from '@shared/ui/category-icon.component';
import { distinctUntilChanged, filter, map, startWith, switchMap } from 'rxjs';

@Component({
  selector: 'sw-mobile-top-menu',
  standalone: true,
  imports: [
    AsyncPipe,
    NgFor,
    NgIf,
    CategoryIconComponent,
    // VerticalScrollDirective,
    BackButtonComponent,
    RouterModule,
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
  template: `
    <div class="flex w-full flex-row  items-center ">
      <!-- swVerticalScroll -->
      <sw-back-button
        *ngIf="showBtnBack$ | async"
        styleClass="button button-rounded bg-black/5  aspect-square h-[60px] w-[60px]"
        class="ml-[18px]"
      />
      <ul
        class="no-scrollbar flex w-full snap-x snap-proximity flex-row flex-nowrap items-center gap-5 overflow-x-auto"
      >
        <li
          class="inline-flex shrink-0 snap-start  first:pl-[18px] last:pr-[18px]"
          *ngFor="let elem of taxonomies$ | async"
        >
          <a
            class="button-base flex aspect-square h-[60px] w-[60px] items-center justify-center rounded-full bg-white text-black/50"
            #rla="routerLinkActive"
            [routerLink]="elem.path"
            routerLinkActive="active"
            (click)="undoSelect($event, rla.isActive)"
          >
            <sw-category-icon [icon]="elem.icon" class="text-xl" />
          </a>
        </li>
      </ul>
    </div>
  `,
  styles: [
    `
      .active {
        @apply bg-accent text-white;
      }
    `,
  ],
})
export class MobileTopMenuComponent {
  private readonly router = inject(Router);
  private readonly activatedRoute = inject(ActivatedRoute);

  private readonly categoriesService = inject(CategoriesService);

  private readonly isLoggedIn$ = inject(UserService).isLoggedIn$;

  protected readonly taxonomies$ = this.isLoggedIn$.pipe(
    switchMap((isLoggedIn) =>
      this.categoriesService.fetchCategoriesWithCounts().pipe(
        switchMap(() =>
          this.categoriesService.categoriesWithCounts$.pipe(
            map((m) => {
              if (m === null) {
                return [];
              }

              const mapMenu = (categories: CategoryExtended[]) => {
                return categories.map((c) => ({
                  title: c.name,
                  icon: c.iconFile,
                  gems: c.gems,
                  path: `/${isLoggedIn ? 'feed' : 'explore'}/${c.slug}`,
                }));
              };
              return mapMenu([...m.bottom, ...m.top]);
            })
          )
        )
      )
    )
  );

  protected showBtnBack$ = this.router.events.pipe(
    filter((event) => event instanceof NavigationEnd),
    startWith(0),
    map(() => this.activatedRoute),
    map((route) => {
      while (route?.firstChild) route = route.firstChild;
      return route;
    }),
    distinctUntilChanged(),
    map((route) => !!route.snapshot.data['showBackBtn'])
  );

  protected undoSelect(event: MouseEvent, isActive: boolean) {
    if (!isActive) {
      return;
    }

    event.preventDefault();
    this.router.navigateByUrl('/feed');
  }
}
