import {
  ChangeDetectionStrategy,
  Component,
  Input,
  Provider,
} from '@angular/core';
import { MarkdownModule } from 'ngx-markdown';
import { AccordionModule } from 'primeng/accordion';

@Component({
  selector: 'sw-info-faq',
  standalone: true,
  imports: [AccordionModule, MarkdownModule],
  providers: [MarkdownModule.forRoot().providers as Provider[]],
  changeDetection: ChangeDetectionStrategy.OnPush,
  template: `
    <div class="space-y-[30px]">
      <h2 class="info-title">FAQ</h2>

      <p-accordion
        expandIcon="pi pi-chevron-down icon"
        collapseIcon="pi pi-chevron-up icon"
        class="block"
      >
        @for (item of content; track $index) {
        <p-accordionTab iconPos="end">
          <ng-template pTemplate="header">
            <span class="vertical-align-middle">{{ item.title }}</span>
          </ng-template>
          <div
            markdown
            class="prose max-w-full leading-8"
            [data]="item.content"
          ></div>
        </p-accordionTab>
        }
      </p-accordion>
    </div>
  `,
})
export class InfoFAQComponent {
  @Input({ required: true })
  content: { title: string; content: string }[] = [];
}
