import { CategoriesService, UserService } from '@/core';
import { inject } from '@angular/core';
import { Observable, finalize, tap } from 'rxjs';
import { LikeExchangeService } from './like-exchange.service';

export class LikeExchangeGemService extends LikeExchangeService {
  private readonly categoryService = inject(CategoriesService);
  private readonly userService = inject(UserService);

  override exchange(categorySlug: string, count: number): Observable<unknown> {
    this.loadingSubject$.next(true);
    return this.likeService.exchangeToGems(categorySlug, count).pipe(
      tap({
        next: () => {
          this.categoryService.addGemsToCategory({
            slug: categorySlug,
            count: -count,
          });
          this.userService.addGems(count);
        },
      }),
      finalize(() => this.loadingSubject$.next(false))
    );
  }
}
