import { NgClass } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output,
} from '@angular/core';

@Component({
  selector: 'sw-editor-tab-btns',
  standalone: true,
  imports: [NgClass],
  changeDetection: ChangeDetectionStrategy.OnPush,
  template: `
    <div class="flex h-12 w-full flex-row rounded-md border border-black/20">
      <button
        class=" button flex-1 rounded-r-none py-[15px] text-center text-sm leading-[18px] tracking-tighter text-black"
        [ngClass]="{
          'bg-black/5': option,
          'bg-white': !option
        }"
        (click)="handleBtnClick(true)"
        type="button"
      >
        Написать свою тему
      </button>
      <span class="h-full w-[1px] bg-black/20"></span>
      <button
        class="button flex-1 rounded-l-none py-[15px] text-center text-sm leading-[18px] tracking-tighter text-black"
        [ngClass]="{
          'bg-black/5': !option,
          'bg-white': option
        }"
        (click)="handleBtnClick(false)"
        type="button"
      >
        Шаблоны тем
      </button>
    </div>
  `,
})
export class EditorTabBtnsComponent {
  @Input()
  option = true;

  @Output()
  optionChange = new EventEmitter<boolean>();

  handleBtnClick(btn: boolean) {
    this.option = btn;
    this.optionChange.emit(btn);
  }
}
