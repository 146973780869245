import { inject } from '@angular/core';
import { CanActivateFn, Router, Routes } from '@angular/router';
import { first, map } from 'rxjs';

import { UserService } from '@/core/services';
import { MainLayout } from '@/widgets/main-layout';
import { SidebarTaxonomiesComponent } from '@/widgets/sidebar';
import { AUTH_ROUTES } from './auth';
import { THREAD_ROUTES } from './project/thread-router';

const canActivate: (loggedIn: boolean) => CanActivateFn = (loggedIn) => {
  return () => {
    const router = inject(Router);
    return inject(UserService).isLoggedIn$.pipe(
      first(),
      map((isLoggedIn) => {
        if (loggedIn) {
          return isLoggedIn || router.createUrlTree(['/login']);
        } else {
          return !isLoggedIn || router.createUrlTree(['/']);
        }
      })
    );
  };
};

const canActivateAnonym = canActivate(false);
const canActivateLoggedIn = canActivate(true);

export const APP_ROUTES: Routes = [
  {
    path: '',
    loadComponent: () => MainLayout,
    children: [
      {
        path: '',
        title: 'Explore',
        canMatch: [
          () =>
            inject(UserService).isLoggedIn$.pipe(
              first(),
              map((loggedIn) => !loggedIn)
            ),
        ],
        children: [
          {
            path: '',
            loadComponent: () => import('./feed').then((c) => c.FeedPage),
          },
          {
            path: 'explore/:category',
            loadComponent: () => import('./feed').then((c) => c.FeedPage),
          },
        ],
      },
      {
        path: 'feed',
        canActivate: [canActivateLoggedIn],
        title: 'Feed',
        children: [
          {
            path: '',
            loadComponent: () => import('./feed').then((c) => c.FeedPage),
          },
          {
            path: ':category',
            loadComponent: () => import('./feed').then((c) => c.FeedPage),
          },
        ],
      },
      // {
      //   path: 'bookmarks',
      //   loadComponent: () =>
      //     import('./features/bookmarks').then((c) => c.BookmarksComponent),
      //   title: 'Закладки',
      //   canActivate: [canActivateLoggedIn],
      // },
      {
        path: 'profile',
        loadComponent: () => import('./profile').then((c) => c.ProfilePage),
        title: 'Профиль',
        canActivate: [canActivateLoggedIn],
      },
      {
        path: 'settings',
        canActivate: [canActivateLoggedIn],
        loadComponent: () => import('./settings').then((c) => c.SettingsPage),
      },
      {
        path: 'notifications',
        canActivate: [canActivateLoggedIn],
        loadComponent: () =>
          import('./notifications').then((c) => c.NotificationsPage),
        data: {
          showBackBtn: true,
        },
      },
      {
        path: 'policy',
        loadComponent: () => import('./policy').then((c) => c.PolicyPage),
      },
      {
        path: '',
        loadComponent: () => SidebarTaxonomiesComponent,
        outlet: 'right-sidebar',
      },
      { path: '', pathMatch: 'full', redirectTo: '/feed' },
    ],
  },
  THREAD_ROUTES,
  {
    ...AUTH_ROUTES,
    canActivateChild: [canActivateAnonym],
  },
  {
    path: 'away',
    loadComponent: () => import('./away').then((c) => c.AwayPage),
  },

  {
    path: '**',
    loadComponent: () => import('./not-found').then((c) => c.NotFoundPage),
    title: '404',
  },
];
