import { isMissed } from '@/core/helpers';
import { User } from '@/core/models';
import { CommentsService } from '@/core/services/comments.service';
import { inject } from '@angular/core';
import { ActivatedRouteSnapshot, ResolveFn } from '@angular/router';
import { map, catchError, of } from 'rxjs';

export const ThreadTitleResolver: ResolveFn<string> = (
  route: ActivatedRouteSnapshot
) => {
  const projectId = route.paramMap.get('id');
  const commentId = route.paramMap.get('commentId') || undefined;
  const topicId = route.queryParamMap.get('topic') || undefined;
  if (isMissed(projectId)) {
    return 'Thread';
  }

  return inject(CommentsService)
    .fetchThread({
      projectId: projectId,
      parentId: commentId,
      topicId,
    })
    .pipe(
      map((v) => {
        const comment = v.parents.at(-1);
        const name = new User(comment?.author || v.project.owner).fullName;
        const msg = comment?.comment || v.project.name;
        return `${name} ${comment ? 'пишет' : 'предлагает'}: ${msg}`;
      }),
      catchError(() => of('Thread'))
    );
};
