import { DisableScrollService } from '@/core/services/disable-scroll.service';
import { NotificationService } from '@/core/services/notification.service';
import { Notification } from '@/core/types/notification.type';
import { NotificationItemComponent } from '@/entities/notification';
import { TailwindMergePipe } from '@/shared/pipes/tw-merge.pipe';
import { MediaMatcher } from '@angular/cdk/layout';
import { NgClass } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  Input,
  ViewEncapsulation,
  inject,
  signal,
} from '@angular/core';
import { toSignal } from '@angular/core/rxjs-interop';
import { Router, RouterLink } from '@angular/router';
import { BadgeModule } from 'primeng/badge';
import { OverlayPanel, OverlayPanelModule } from 'primeng/overlaypanel';
import { ScrollPanelModule } from 'primeng/scrollpanel';

@Component({
  selector: 'sw-user-notifications',
  standalone: true,
  imports: [
    NgClass,
    RouterLink,
    BadgeModule,
    OverlayPanelModule,
    NotificationItemComponent,
    TailwindMergePipe,
    ScrollPanelModule,
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
  providers: [DisableScrollService],
  template: `
    <button
      [class]="
        'button flex h-11 w-11 items-center justify-center rounded-full transition-colors duration-300 hover:bg-accent/10 ' +
          buttonClassName | twMerge
      "
      (click)="toggleNotifications($event, list)"
    >
      <i
        class="pi pi-bell text-xl text-black"
        [ngClass]="{
          '[&>.p-badge]:hidden': notificationsCount() === 0
        }"
        pBadge
        [value]="notificationsCount().toString()"
      ></i>
    </button>

    <p-overlayPanel
      #list
      styleClass="user-notifications-list"
      (onHide)="disableScrollService.enableWindowScroll()"
      (onShow)="disableScrollService.disableWindowScroll()"
    >
      <div class="w-[380px] text-black">
        <div class="mb-2 border-b border-black/10 px-4 py-2">
          <span class="font-medium">Уведомления</span>
        </div>

        <p-scrollPanel
          [style]="{ width: '100%', height: 'min(100dvh, 464px)' }"
        >
          <ul class="pr-2">
            @for (item of notificationList(); track $index) {
            <li>
              <sw-notification-item [item]="item" />
            </li>
            }
          </ul>
        </p-scrollPanel>

        <div class="mt-2">
          <a
            class="button w-full rounded-none py-2 text-sm text-accent hover:bg-accent/10"
            routerLink="/notifications"
            (click)="list.hide()"
            >Показать все</a
          >
        </div>
      </div>
    </p-overlayPanel>
  `,
  styles: [
    `
      .user-notifications-list {
        /* margin-left: -98px; */
        &.p-overlaypanel .p-overlaypanel-content {
          padding: 0;
        }
      }
    `,
  ],
})
export class UserNotificationsComponent {
  @Input() buttonClassName = '';

  private readonly router = inject(Router);
  private readonly mediaMatcher = inject(MediaMatcher);
  protected readonly disableScrollService = inject(DisableScrollService);

  private readonly notificationService = inject(NotificationService);

  // protected readonly loading = signal(false);

  protected readonly notificationsCount = toSignal(
    this.notificationService.subscribeUpdates(),
    {
      initialValue: 0,
    }
  );

  protected readonly notificationList = signal<Notification[] | null>(null);

  toggleNotifications(mouseEvent: MouseEvent, panel: OverlayPanel) {
    if (this.mediaMatcher.matchMedia('(max-width: 768px)').matches) {
      this.router.navigateByUrl('/notifications');
      return;
    }

    // this.loading.set(true);

    this.notificationService
      .getList()
      // .pipe(finalize(() => this.loading.set(false)))
      .subscribe((list) => {
        this.notificationList.set(list);
      });

    panel.toggle(mouseEvent);
  }
}
