import { MarketService } from '@/core/services/market.service';
import { FormSchema } from '@/core/types';
import { FormBuilderComponent } from '@/features/form-builder';
import { ButtonComponent } from '@/shared/ui';
import { NgIf } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  inject,
  signal,
} from '@angular/core';
import { FormGroup } from '@angular/forms';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { finalize } from 'rxjs';

@Component({
  selector: 'sw-market-form-dialog',
  standalone: true,
  imports: [NgIf, FormBuilderComponent, ButtonComponent],
  changeDetection: ChangeDetectionStrategy.OnPush,
  template: `
    <div class="space-y-5">
      <div *ngIf="description">
        <p>{{ description }}</p>
      </div>
      <div class="">
        <sw-form-builder
          [schema]="schema"
          [buttons]="buttons"
          (formSubmit)="handleSubmit($event)"
        />
      </div>
    </div>

    <ng-template #buttons>
      <div class="flex w-full flex-col">
        <button
          swButton
          [loading]="loading()"
          class="button button-primary py-3"
          type="submit"
        >
          Отправить
        </button>
      </div>
    </ng-template>
  `,
})
export class MarketFormDialogComponent {
  private readonly dialogRef = inject(DynamicDialogRef);
  private readonly config = inject(DynamicDialogConfig);

  private readonly marketService = inject(MarketService);

  protected readonly schema: FormSchema = this.config.data.schema;
  protected readonly description?: string = this.config.data.description;

  protected readonly goodId: string = this.config.data.goodId;
  protected readonly purchaseId: string = this.config.data.purchaseId;

  protected readonly loading = signal(false);

  protected handleSubmit(form: FormGroup) {
    if (form.invalid) {
      return;
    }

    this.loading.set(true);
    this.marketService
      .saveForm(this.goodId, this.purchaseId, form.getRawValue())
      .pipe(finalize(() => this.loading.set(false)))
      .subscribe({
        next: () => {
          this.dialogRef.close(true);
        },
      });
  }
}
