import { inject, Injectable } from '@angular/core';
import { BehaviorSubject, tap } from 'rxjs';

import { CategoryWithGems } from '../types/category.type';
import { BaseAPIService } from './base-api.service';
import { ProjectAPIService } from './project.service';

@Injectable({ providedIn: 'root' })
export class GemAPIService extends BaseAPIService {
  private readonly projectAPIService = inject(ProjectAPIService);

  addGemsToProject(projectId: string, count: number) {
    this._loading$.next(true);

    return this.httpClient
      .post<{ count: number; projectId: string }>(
        `/gems/project/${projectId}`,
        { count }
      )
      .pipe(
        tap({
          next: () => {
            this.projectAPIService.invalidateProjects();
          },
          finalize: () => this._loading$.next(false),
        })
      );
  }

  addGemsToCategory(categorySlug: string, count: number) {
    this._loading$.next(true);

    return this.httpClient
      .post<{ count: number; slug: string }>(`/gems/category/${categorySlug}`, {
        count,
      })
      .pipe(
        tap({
          finalize: () => this._loading$.next(false),
        })
      );
  }

  fetchUserSpentGems() {
    this._loading$.next(true);

    return this.httpClient
      .get<{ total: number; categories: CategoryWithGems[] }>(`/users/me/gems`)
      .pipe(
        tap({
          finalize: () => this._loading$.next(false),
        })
      );
  }
}

@Injectable({ providedIn: 'root' })
export class GemService {
  private readonly api = inject(GemAPIService);

  private readonly _spentGems$ = new BehaviorSubject<{
    total: number;
    categories: CategoryWithGems[];
  } | null>(null);

  get spentGems$() {
    return this._spentGems$.asObservable();
  }

  get loading$() {
    return this.api.loading$;
  }

  addGemsToProject(projectId: string, count: number) {
    return this.api.addGemsToProject(projectId, count);
  }

  addGemsToCategory(categorySlug: string, count: number) {
    return this.api.addGemsToCategory(categorySlug, count).pipe(
      tap((result) => {
        const v = this._spentGems$.value;
        if (v === null) {
          return;
        }

        this._spentGems$.next({
          total: v.total + count,
          categories: v.categories.map((c) =>
            c.slug === result.slug ? { ...c, gems: c.gems + count } : c
          ),
        });
      })
    );
  }

  getUserSpentGems() {
    return this.api
      .fetchUserSpentGems()
      .pipe(tap((spent) => this._spentGems$.next(spent)));
  }
}
