import { ChangeDetectionStrategy, Component } from '@angular/core';

@Component({
  selector: 'sw-pe-field-wrapper',
  standalone: true,
  imports: [],
  changeDetection: ChangeDetectionStrategy.OnPush,
  template: `
    <div class="space-y-2">
      <div class="font-medium text-black">
        <ng-content select="[title]" />
      </div>
      <div class=""><ng-content /></div>
    </div>
  `,
})
export class FieldWrapperComponent {}
