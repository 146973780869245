import { isPresent } from '@/core/helpers';
import { AbstractControl, ValidatorFn, Validators } from '@angular/forms';

export const commentRequiredValidator: ValidatorFn = (
  formControl: AbstractControl
) => {
  if (!formControl.parent) {
    return null;
  }

  if (isPresent(formControl.parent.get('agreement')?.value)) {
    return Validators.required(formControl);
  }
  return null;
};
