import { BreakpointObserver } from '@angular/cdk/layout';
import { isPlatformBrowser } from '@angular/common';
import { Injectable, PLATFORM_ID, Provider, inject } from '@angular/core';
import { offset } from '@floating-ui/dom';
import { ShepherdService } from 'angular-shepherd';
import Step from 'shepherd.js/src/types/step';

export type OnboardingItemPosition =
  | 'top'
  | 'top-start'
  | 'top-end'
  | 'bottom'
  | 'bottom-start'
  | 'bottom-end'
  | 'right'
  | 'right-start'
  | 'right-end'
  | 'left'
  | 'left-start'
  | 'left-end';

@Injectable()
export abstract class OnboardingService {
  protected readonly shepherdService = inject(ShepherdService);
  private readonly platformId = inject(PLATFORM_ID);
  protected readonly isClient = isPlatformBrowser(this.platformId);
  private readonly breakpointObserver = inject(BreakpointObserver);
  protected readonly isDesktop =
    this.breakpointObserver.isMatched('(min-width: 768px)');

  protected getPosition(
    desktopPosition: OnboardingItemPosition,
    mobilePosition: 'bottom' | 'top' = 'bottom'
  ) {
    if (['bottom', 'top'].includes(desktopPosition)) {
      return desktopPosition;
    }
    return this.isDesktop ? desktopPosition : mobilePosition;
  }

  protected createButton({
    text,
    classes,
    action,
    type,
  }: {
    text: string;
    type?: 'next' | 'prev';
    classes?: string;
    action?: () => void;
  }): Step.StepOptionsButton {
    return {
      classes: ['button', type === 'next' ? 'button-primary' : null, classes]
        .filter(Boolean)
        .join(' '),
      secondary: type !== 'next',
      text,
      action:
        action ||
        (() => {
          if (type === 'next') {
            this.shepherdService.next();
          } else if (type === 'prev') [this.shepherdService.back()];
        }),
    };
  }

  protected createBackButton(text = 'Назад') {
    return this.createButton({ text, type: 'prev' });
  }

  protected createNextButton(text = 'Понятно') {
    return this.createButton({
      text,
      type: 'next',
    });
  }

  protected createButtons(): Step.StepOptionsButton[] {
    const buttons = [this.createBackButton(), this.createNextButton()];
    return buttons;
  }

  protected createStep({
    id,
    element,
    canClickTarget,
    position,
    text,
    buttons,
  }: {
    id: string;
    element: string;
    position?: OnboardingItemPosition;
    canClickTarget?: boolean;
    text: string[];
    buttons?: Step.StepOptionsButton[];
  }): Step.StepOptions {
    return {
      id,
      attachTo: {
        element: `[guide=${element}]`,
        on: position,
      },
      canClickTarget: canClickTarget || false,
      modalOverlayOpeningPadding: 20,
      modalOverlayOpeningRadius: 20,

      scrollToHandler: (element) => {
        setTimeout(() => {
          element?.scrollIntoView({ behavior: 'smooth', block: 'center' });
        });
      },
      buttons: buttons || this.createButtons(),
      cancelIcon: {
        enabled: true,
      },
      floatingUIOptions: {
        middleware: [offset({ mainAxis: 45 })],
      },
      highlightClass: 'highlight',
      scrollTo: true,
      arrow: false,
      text,
    };
  }

  abstract start(): void;

  stop(): void {
    if (this.shepherdService.tourObject) {
      this.shepherdService.complete();
    }
  }
}

export function provideOnboarding(
  useClass: new () => OnboardingService
): Provider {
  return {
    provide: OnboardingService,
    useClass,
  };
}
