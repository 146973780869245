import { inject, Injectable } from '@angular/core';
import { ClipboardService } from 'ngx-clipboard';
import { MessageService } from 'primeng/api';

import { GET_POST_URL } from '@/core/tokens';

@Injectable({ providedIn: 'root' })
export class CopyService {
  private readonly clipboard = inject(ClipboardService);
  private readonly toastService = inject(MessageService);
  private readonly getPostUrl = inject(GET_POST_URL);

  copyLink(projectId: string, commentId?: string) {
    this.clipboard.copy(this.getPostUrl(projectId, commentId));
    this.toastService.add({
      severity: 'success',
      detail: 'Ссылка скопирована',
      styleClass: 'sw-toast',
      closable: false,
    });
  }
}
