import { APP_ORIGIN_URL } from '@/core';
import { replaceNewLines } from '@/core/helpers';
import { AsyncPipe } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  Input,
  inject,
} from '@angular/core';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';

@Component({
  selector: 'sw-text',
  standalone: true,
  imports: [AsyncPipe],
  changeDetection: ChangeDetectionStrategy.OnPush,
  template: `<p [innerHTML]="textHtml"></p>`,
})
export class TextComponent {
  private readonly sanitizer = inject(DomSanitizer);

  private readonly appUrl = inject(APP_ORIGIN_URL);

  @Input({ required: true })
  set text(text: string) {
    this.textHtml = this.parseText(text);
  }
  protected textHtml: SafeHtml | null = null;

  private parseText(text: string) {
    const lines = replaceNewLines(text).split('\n');
    return this.sanitizer.bypassSecurityTrustHtml(
      lines
        .map((line) => {
          const linkMatch = line.match(/\bhttps?:\/\/\S+/);
          if (linkMatch) {
            const linkUrl = linkMatch[0];

            const link = `${this.appUrl}/away?to=${encodeURIComponent(
              linkUrl
            )}`;

            const restOfLine = line.replace(linkUrl, '').trim();

            return `${restOfLine} <a href="${link}" class="link" target="_blank" rel="noopener">${linkUrl}</a>`;
          } else {
            return line;
          }
        })
        .join('<br>')
    );
  }
}
