import { UserService } from '@/core';
import {
  ChangeDetectionStrategy,
  Component,
  Input,
  inject,
} from '@angular/core';
import { toSignal } from '@angular/core/rxjs-interop';
import { CommentAgreementBtnsUserComponent } from './comment-agreement-btns-user.component';
import { CommentAgreementBtnsSuperuserComponent } from './comment-agreement-btns-superuser.component';
import { NgIf } from '@angular/common';

@Component({
  selector: 'sw-wrapper-comment-agreement-btns',
  standalone: true,
  imports: [
    NgIf,
    CommentAgreementBtnsUserComponent,
    CommentAgreementBtnsSuperuserComponent,
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
  template: `
    <fieldset [disabled]="disabled" class="h-full">
      <sw-comment-agreement-btns-superuser
        *ngIf="isSuperUser(); else defaultBtns"
        [commentId]="commentId"
        [value]="value"
      />
      <ng-template #defaultBtns>
        <sw-comment-agreement-btns-user
          [commentId]="commentId"
          [value]="value"
        />
      </ng-template>
    </fieldset>
  `,
})
export class WrapperCommentAgreementBtnsComponent {
  @Input({ required: true }) commentId!: string;
  @Input() value?: number | null;
  @Input() disabled = false;

  protected readonly isSuperUser = toSignal(inject(UserService).isSuperuser$);
}
