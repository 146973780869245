/* eslint-disable @typescript-eslint/no-non-null-assertion */
/* eslint-disable @angular-eslint/directive-class-suffix */
/* eslint-disable @angular-eslint/directive-selector */
import { validateAllFormFields } from '@/core/helpers';
import {
  ComponentRef,
  DestroyRef,
  Directive,
  inject,
  Input,
  OnInit,
  ViewContainerRef,
} from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import {
  ControlContainer,
  FormGroupDirective,
  NgControl,
  NgForm,
  NgModel,
} from '@angular/forms';
import { EMPTY, filter, iif, merge, skip, startWith, tap } from 'rxjs';

import { ErrorComponent } from './error.component';

@Directive({
  selector: '[ngModel],[formControl],[formControlName]',
  standalone: true,
})
export class DynamicValidationMessage implements OnInit {
  @Input()
  showErrorMsg = true;

  private readonly ngControl = inject(NgControl, { self: true });
  private readonly vcr = inject(ViewContainerRef);
  private componentRef: ComponentRef<ErrorComponent> | null = null;

  private readonly destroyRef = inject(DestroyRef);

  private readonly parentContainer = inject(ControlContainer, {
    optional: true,
  });

  get form() {
    return this.parentContainer?.formDirective as
      | NgForm
      | FormGroupDirective
      | null;
  }

  ngOnInit(): void {
    if (!this.ngControl.control) {
      throw Error(`No control model for ${this.ngControl.name} control...`);
    }

    merge(
      this.ngControl.control.statusChanges,
      iif(() => !!this.form, this.form!.ngSubmit, EMPTY)
    )
      .pipe(
        tap((e) => {
          if (e instanceof SubmitEvent && this.form) {
            validateAllFormFields(this.form.form);
          }
        }),
        takeUntilDestroyed(this.destroyRef),
        startWith(this.ngControl.status),
        skip(this.ngControl instanceof NgModel ? 1 : 0),
        filter((status) => status === 'VALID' || status === 'INVALID')
      )
      .subscribe({
        next: () => {
          if (
            this.showErrorMsg &&
            this.ngControl.errors &&
            this.ngControl.dirty
          ) {
            this.componentRef ??= this.vcr.createComponent(ErrorComponent);
            this.componentRef.setInput('errors', this.ngControl.errors);
          } else {
            this.componentRef?.destroy();
            this.componentRef = null;
          }
        },
      });
  }
}
