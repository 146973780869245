import { isMissed } from '../helpers';

export interface TTopic {
  id: string;
  name: string;
  agreement: number | null;
  comments: number;
}

export class Topic implements TTopic {
  id: string;
  name: string;
  agreement: number | null;
  comments: number;

  constructor({ id, name, agreement, comments }: TTopic) {
    this.id = id;
    this.name = name;
    this.agreement = agreement;
    this.comments = comments;
  }

  get color() {
    if (isMissed(this.agreement)) {
      return 'neutral';
    }

    return this.comments === 0 || this.agreement === 0
      ? 'neutral'
      : this.agreement > 0
      ? 'positive'
      : 'negative';
  }
}

export type CreateTopicDTO = {
  topics: {
    name: string;
    comment: string | null;
    agreement: number | null;
  }[];
};
