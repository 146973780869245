import { COMMENT_PLURAL } from '@/core';
import { CommonModule } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  Input,
  ViewEncapsulation,
} from '@angular/core';
import { InitialsPipe, PluralPipe, ShortNumberPipe } from '@shared/pipes';
import { AvatarModule } from 'primeng/avatar';
import { AvatarGroupModule } from 'primeng/avatargroup';

import { SVGComponent } from './svg.component';

@Component({
  selector: 'sw-show-all-comments-btn',
  standalone: true,
  imports: [
    CommonModule,
    AvatarModule,
    InitialsPipe,
    ShortNumberPipe,
    AvatarGroupModule,
    SVGComponent,
    PluralPipe,
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
  template: `
    <div class="flex flex-row items-center">
      <p-avatarGroup>
        <ng-container *ngFor="let avatar of avatars | slice : 0 : 3">
          <p-avatar
            *ngIf="avatar.photo; else withoutImage"
            [image]="avatar.photo"
            shape="circle"
            class="h-5 w-5 shrink-0"
          />

          <ng-template #withoutImage>
            <p-avatar
              [label]="avatar.name | swInitials : 2"
              shape="circle"
              class="h-5 w-5 shrink-0"
            />
          </ng-template>
        </ng-container>
      </p-avatarGroup>

      <span class="ml-[10px] text-sm leading-[22px]"
        >+{{ commentsCount | swShortNumber }}
        {{ commentsCount | swPlural : CommentPlural }}
      </span>

      <div class="ml-auto text-accent">
        <sw-svg icon="chevron-right" width="8" height="14" />
      </div>
    </div>
  `,

  styles: [
    `
      sw-show-all-comments-btn {
        .p-avatar-group p-avatar + p-avatar {
          margin-left: -10px !important;
        }

        .p-avatar-group .p-avatar {
          @apply border;
        }

        p-avatar {
          @apply h-5 w-5;
        }
        .p-avatar {
          @apply flex h-5 w-5 text-black;
        }
        .p-avatar-text {
          @apply text-[12px];
        }
      }
    `,
  ],
})
export class ShowAllCommentsBtnComponent {
  @Input({ required: true })
  avatars: { name: string; photo?: string | null }[] = [];

  @Input({ required: true })
  commentsCount!: number;

  protected readonly CommentPlural = COMMENT_PLURAL;
}
