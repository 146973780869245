import { isPresent } from '@/core/helpers';
import { CommentMedia } from '@/core/models';
import { PortalBridgeService } from '@/core/services/portal-bridge.service';
import { PortalModule, TemplatePortal } from '@angular/cdk/portal';
import {
  ChangeDetectionStrategy,
  Component,
  Input,
  TemplateRef,
  ViewContainerRef,
  inject,
} from '@angular/core';
import { GalleriaModule } from 'primeng/galleria';
import { ImageModule } from 'primeng/image';

@Component({
  selector: 'sw-gallery',
  standalone: true,
  imports: [ImageModule, GalleriaModule, PortalModule],
  changeDetection: ChangeDetectionStrategy.OnPush,
  template: `
    <div class="flex flex-row flex-wrap-reverse gap-2 ">
      @for (p of media; track $index) {
      <div
        class="relative max-h-[200px] flex-1 basis-[31%] overflow-hidden rounded-md"
      >
        <button
          (click)="openGallery($index, gallery)"
          class="group relative h-full w-full"
        >
          <img
            [src]="p.image"
            draggable="false"
            class=" h-full w-full object-cover object-center"
            appendTo="body"
            alt
          />
          <div
            class=" pointer-events-none absolute left-0 top-0 flex h-full w-full items-center justify-center
            bg-black/50 opacity-0 transition-opacity duration-300 group-hover:opacity-100
            group-focus:opacity-100 group-active:opacity-100
            "
          >
            <i class="pi pi-eye" style="font-size: 1.5rem;color: white"></i>
          </div>
        </button>
      </div>
      }
    </div>

    <ng-template #gallery>
      <p-galleria
        *ngIf="activeIndex !== null"
        [(activeIndex)]="activeIndex"
        [value]="media"
        [visible]="isGalleryOpen"
        (visibleChange)="closeGallery()"
        [circular]="false"
        [fullScreen]="true"
        [showItemNavigators]="media.length > 1"
        [showThumbnails]="false"
        maskClass="text-white"
      >
        <ng-template pTemplate="item" let-item>
          <img [src]="item.cover" style="width: 100%; display: block;" alt />
        </ng-template>
      </p-galleria>
    </ng-template>
  `,
})
export class GalleryComponent {
  @Input({ required: true })
  media: CommentMedia[] = [];

  protected activeIndex: number | null = null;

  private readonly vcr = inject(ViewContainerRef);
  private readonly portalBridge = inject(PortalBridgeService);

  get isGalleryOpen() {
    return isPresent(this.activeIndex);
  }

  protected openGallery(index: number, element: TemplateRef<unknown>) {
    const portal = new TemplatePortal(element, this.vcr);
    this.portalBridge.attach(portal);
    this.activeIndex = index;
  }
  protected closeGallery() {
    this.activeIndex = null;
    this.portalBridge.detach();
  }
}
