import { NgFor } from '@angular/common';
import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

import { TaxonomyItemComponent } from './taxonomy-item.component';
import { TaxonomyItem, TaxonomyMenu } from './taxonomy.type';

@Component({
  selector: 'sw-taxonomy',
  standalone: true,
  imports: [NgFor, TaxonomyItemComponent],
  changeDetection: ChangeDetectionStrategy.OnPush,
  template: `
    <ul>
      <li
        *ngFor="let item of list; trackBy: trackByFn"
        class="border-b border-black/10 last:border-transparent"
      >
        <sw-taxonomy-item [item]="item" />
      </li>
    </ul>
  `,
  styles: [
    `
      :host {
        @apply flex flex-col overflow-hidden rounded-md bg-[#eee];
      }
    `,
  ],
})
export class TaxonomyComponent {
  @Input({ required: true }) list!: TaxonomyMenu;

  trackByFn(index: number, item: TaxonomyItem) {
    return `taxonomy-${item.path}-${index}`;
  }
}
