import { DOCUMENT, isPlatformBrowser } from '@angular/common';
import { Injectable, PLATFORM_ID, inject } from '@angular/core';

@Injectable()
export class DisableScrollService {
  protected readonly document = inject(DOCUMENT);
  protected readonly platformId = inject(PLATFORM_ID);

  disableWindowScroll() {
    if (isPlatformBrowser(this.platformId)) {
      this.document.body.classList.add('p-overflow-hidden');
    }
  }

  enableWindowScroll() {
    if (isPlatformBrowser(this.platformId)) {
      this.document.body.classList.remove('p-overflow-hidden');
    }
  }
}
