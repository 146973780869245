import { CopyService, UserService } from '@/core';
import { daysAgo, isMissed } from '@/core/helpers';
import { Project } from '@/core/models';
import { LabelUrl } from '@/core/types';
import { Directive, inject, Input } from '@angular/core';
import { MenuItem } from 'primeng/api';
import { map, Observable } from 'rxjs';

import { ProjectMenuDirective } from './project-menu.directive';

@Directive()
export abstract class BaseProjectCard {
  @Input({ required: true }) project!: Project;

  private readonly userService$ = inject(UserService);
  private readonly user$ = this.userService$.user$;
  protected readonly isLoggedIn$ = this.userService$.isLoggedIn$;

  private readonly projectMenu = inject(ProjectMenuDirective, {
    optional: true,
  });

  private readonly copyService = inject(CopyService);

  protected readonly menuItems$: Observable<MenuItem[]> = this.user$.pipe(
    map((u) => {
      const copy = {
        label: 'Скопировать ссылку',
        command: () => {
          this.copyService.copyLink(this.project.id);
        },
      };
      const edit = {
        label: 'Редактировать',
        command: () => {
          this.handleOnEdit();
        },
      };
      const deleteProject = {
        label: 'Удалить проект',
        command: () => {
          this.handleOnDelete();
        },
      };

      // const report = {
      //   label: 'Пожаловаться',
      //   command: () => this.projectMenu?.reportProject.emit(),
      // };

      if (isMissed(u)) {
        return [copy];
      }

      const canEdit = daysAgo(this.project.createdAt) === 0;

      return u.id === this.project.owner.id
        ? [copy, ...(canEdit ? [edit, deleteProject] : [])]
        : [copy, ...(u.isSuperuser ? [deleteProject] : []) /* report */];
    })
  );

  get tags(): LabelUrl[] {
    return (this.project.tags || []).map((t) => ({
      label: `#${t}`,
      path: '.',
      queryParams: {
        tag: t,
      },
    }));
  }

  protected handleOnEdit() {
    this.projectMenu?.editProject.emit(this.project.id);
  }

  protected handleOnDelete() {
    this.projectMenu?.deleteProject.emit(this.project.id);
  }
}
