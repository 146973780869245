<p-toast />

<!-- <button (click)="destroySession()">destroy()</button> -->

@switch (dialogInfoType()) { @case ('gem') {
<sw-gem-info-entry />
} @case ('karma') {
<sw-like-info-entry />
} }

<router-outlet />

@if (portal$ | async; as portal) {
<div id="sw-portal" [cdkPortalOutlet]="portal"></div>
}
