import { Pipe, PipeTransform } from '@angular/core';

type Mapper<T, G> = (item: T, ...args: unknown[]) => G;
@Pipe({
  name: 'swMapper',
  standalone: true,
})
export class MapperPipe implements PipeTransform {
  transform<T, G>(value: T, mapper: Mapper<T, G>): G {
    return mapper(value);
  }
}
