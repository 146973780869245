import {
  COMMENT_PLURAL,
  CategoriesService,
  PROJECT_PLURAL,
  UserService,
} from '@/core';
import { CategoryExtended } from '@/core/types/category.type';
import { Component, inject } from '@angular/core';
import { map, switchMap } from 'rxjs';

import { toSignal } from '@angular/core/rxjs-interop';
import { ScrollPanelModule } from 'primeng/scrollpanel';
import { TaxonomyComponent } from './taxonomy';

@Component({
  selector: 'sw-sidebar-right-taxonomies',
  standalone: true,
  imports: [TaxonomyComponent, ScrollPanelModule],

  template: `
    @if(taxonomies(); as taxonomies) {

    <div>
      <span class="title mb-5 block">Отрасли</span>
      <div class="space-y-6">
        @if(taxonomies[0] && taxonomies[0].length > 8) {

        <p-scrollPanel
          [style]="{ width: '100%', height: '304px' }"
          styleClass="tx-scrollpanel"
        >
          <div class="min-h-[284px] pr-5">
            <sw-taxonomy [list]="taxonomies[0]" />
          </div>
        </p-scrollPanel>
        } @else {
        <sw-taxonomy [list]="taxonomies[0]" />
        }

        <div class="">
          <sw-taxonomy [list]="taxonomies[1]" />
        </div>
      </div>
    </div>
    }
  `,
})
export class SidebarTaxonomiesComponent {
  private readonly categoriesService = inject(CategoriesService);

  private readonly isLoggedIn$ = inject(UserService).isLoggedIn$;

  protected readonly taxonomies = toSignal(
    this.isLoggedIn$.pipe(
      switchMap((isLoggedIn) =>
        this.categoriesService.fetchCategoriesWithCounts().pipe(
          switchMap(() =>
            this.categoriesService.categoriesWithCounts$.pipe(
              map((m) => {
                if (m === null) {
                  return [];
                }

                const mapMenu = (categories: CategoryExtended[]) => {
                  return categories.map((c) => ({
                    title: c.name,
                    icon: c.iconFile,
                    gems: c.gems,
                    attributes: [
                      {
                        plural: PROJECT_PLURAL,
                        value: c.projects,
                      },
                      {
                        plural: COMMENT_PLURAL,
                        value: c.comments,
                      },
                    ],
                    path: `/${isLoggedIn ? 'feed' : 'explore'}/${c.slug}`,
                  }));
                };

                return [
                  mapMenu(m.top),
                  mapMenu(m.bottom).map((v) => ({ ...v, attributes: [] })),
                ].filter((m) => m.length);
              })
            )
          )
        )
      )
    ),
    {
      initialValue: [],
    }
  );
}
