import { animate, style, transition, trigger } from '@angular/animations';
import { CommonModule } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  inject,
  TemplateRef,
} from '@angular/core';

import { OVERLAY_DATA } from './overlay.directive';

@Component({
  selector: 'sw-overlay',
  standalone: true,
  imports: [CommonModule],
  changeDetection: ChangeDetectionStrategy.OnPush,
  animations: [
    trigger('show', [
      transition(':enter', [
        style({ opacity: 0 }),
        animate('100ms', style({ opacity: 1 })),
      ]),
      transition(':leave', [
        style({ opacity: 0.8 }),
        animate('100ms', style({ opacity: 0 })),
      ]),
    ]),
  ],

  template: `
    <div @show class="island-base flex flex-col" [ngClass]="data.styleClass">
      <ng-container [ngTemplateOutlet]="template" />

      <p *ngIf="text" class="text-sx">{{ text }}</p>
    </div>
  `,
  styles: [
    `
      :host {
        @apply border-x-[20px] border-y-[10px] border-solid border-transparent;
      }
    `,
  ],
})
export class OverlayComponent {
  protected readonly data = inject(OVERLAY_DATA);

  get template(): TemplateRef<unknown> | null {
    return typeof this.data.content === 'string' ? null : this.data.content;
  }

  get text(): string | null {
    return typeof this.data.content === 'string' ? this.data.content : null;
  }
}
