import {
  OverlayDirective,
  ReactionOnboardingDirective,
} from '@/shared/ui/portal';
import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { SVGComponent } from '@shared/ui/svg.component';

import { RouterModule } from '@angular/router';
import { ReactionButtonComponent } from '../reaction-button.component';
import { GemDonateFormComponent } from './gem-donate-form.component';

@Component({
  selector: 'sw-gem-reaction',
  standalone: true,
  imports: [
    OverlayDirective,
    SVGComponent,
    RouterModule,
    ReactionButtonComponent,
    ReactionOnboardingDirective,
    GemDonateFormComponent,
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
  template: `
    <sw-reaction-button
      [value]="projectGems"
      [swOverlay]="form"
      [timeout]="0"
      #overlay="swOverlay"
      (clickBtn)="overlay.open()"
      (clickOutside)="overlay.close()"
      swReactionOnboarding
      #reactionOnBoarding="swReactionOnboarding"
      (hoverIcon)="reactionOnBoarding.open()"
      reactionHeader="Гемы"
      [reactionContent]="aboutMsg"
    >
      <sw-svg
        icon="gem"
        width="18px"
        height="18px"
        class="text-[18px] text-black/50"
      />
    </sw-reaction-button>

    <ng-template #form>
      <div class="w-[200px] px-4 pb-4 pt-[15px]">
        <sw-gem-donate-form (sended)="overlay.close()" [defaultMax]="1000" />
      </div>
    </ng-template>

    <ng-template #aboutMsg>
      <div class="space-y-2 pr-5">
        <p class="text-xs">
          Гемы - это внутренняя валюта, которую можно обменивать на датасеты,
          вычислительные мощности, предобученные модели и многое другое.
          Добавьте гемов, чтобы поддержать пост. Чем больше гемов у поста, тем
          быстрее появится топовый контент под постом, так как гемы поста
          распределяются между первыми пользователям, которые напишут значимый
          контент.
        </p>
        <div>
          <a
            class="link"
            routerLink="."
            [queryParams]="{ info: 'karma' }"
            queryParamsHandling="merge"
            >Узнать подробнее о лайках и гемах.</a
          >
        </div>
      </div>
    </ng-template>
  `,
})
export class GemReactionComponent {
  @Input({ required: true })
  projectGems!: number;
}
