import { Directive, HostListener, Input, inject } from '@angular/core';
import { PurchaseService } from './purchase.service';

@Directive({ selector: '[swMarketPurchase]', standalone: true })
export class MarketPurchaseDirective {
  @Input({ required: true })
  goodId!: string;

  private readonly purchaseService = inject(PurchaseService);

  @HostListener('click')
  handleClick() {
    this.purchaseService.purchase(this.goodId);
    // this.purchaseService.purchase(this.goodId, this.price).subscribe({
    //   error: () => {

    //   },
    // });
  }
}
