import { isMissed } from '@/core/helpers';
import { LetDirective } from '@/shared/directives';
import { PermissionActionDirective } from '@/shared/directives/permission/permission-action.directive';
import { ButtonComponent, SVGComponent } from '@/shared/ui';
import { AsyncPipe } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  ViewEncapsulation,
} from '@angular/core';
import { AgreementBtnDirective } from './agreement-btn.directive';
import { BaseCommentAgreementBtns } from './base-comment-agreement-btns';

@Component({
  selector: 'sw-comment-agreement-btns-user',
  standalone: true,
  imports: [
    SVGComponent,
    ButtonComponent,
    LetDirective,
    PermissionActionDirective,
    AgreementBtnDirective,
    AsyncPipe,
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
  template: `
    <fieldset class="flex h-full w-full flex-row items-center overflow-hidden">
      <button
        swPermissionAction
        swAgreementBtn
        [commentId]="commentId"
        [value]="getLikeButtonValue(value)"
        class="button flex h-full flex-1 items-center rounded-l-md rounded-r-none bg-black/5 text-[22px]"
        [class.text-accent]="value === 1"
        [class.text-gold]="value === 5"
        type="button"
      >
        <sw-svg icon="like" height="22px" width="22px" />
      </button>

      <button
        swPermissionAction
        swAgreementBtn
        [commentId]="commentId"
        [value]="value === 0 ? null : 0"
        class="button flex h-full flex-1 items-center rounded-l-none rounded-r-md bg-black/5 text-[22px]"
        [class.text-red]="value === 0"
        type="button"
      >
        <sw-svg icon="dislike" height="22px" width="22px" />
        <!-- <sw-svg icon="like" height="22px" width="22px" /> -->
      </button>
    </fieldset>
  `,
})
export class CommentAgreementBtnsUserComponent extends BaseCommentAgreementBtns {
  getLikeButtonValue(value?: number | null) {
    if (isMissed(value) || value === 0) {
      return 1;
    } else {
      return null;
    }
  }
}
