import { NgTemplateOutlet } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  Input,
  TemplateRef,
} from '@angular/core';
import { SVGComponent } from '@shared/ui/svg.component';

export type MenuIcon = 'house' | 'person' | 'bookmark' | 'gem' | 'heart';

@Component({
  selector: 'sw-menu-icon',
  standalone: true,
  imports: [NgTemplateOutlet, SVGComponent],
  changeDetection: ChangeDetectionStrategy.OnPush,
  template: `
    <ng-container
      [ngTemplateOutlet]="
        resolveIcon(icon, [
          houseIcon,
          personIcon,
          bookmarkIcon,
          heartIcon,
          gemIcon
        ])
      "
    />

    <ng-template #houseIcon>
      <sw-svg icon="house" width="1.25em" height="1.1875em" />
    </ng-template>

    <ng-template #personIcon>
      <sw-svg icon="person" width="1.125em" height="1.0625em" />
    </ng-template>

    <ng-template #bookmarkIcon>
      <sw-svg icon="bookmark" width="1em" height="1.125em" />
    </ng-template>

    <ng-template #heartIcon>
      <sw-svg icon="heart" width="1.125em" height="1em" />
    </ng-template>

    <ng-template #gemIcon>
      <span class="text-[22px]">
        <sw-svg icon="gem" width="22px" height="22px" />
      </span>
    </ng-template>
  `,
})
export class MenuIconComponent {
  @Input()
  icon?: MenuIcon;

  // @Input()
  // size: 'md' | 'lg' = 'md';

  resolveIcon(
    icon: MenuIcon | undefined,
    [
      houseIcon,
      personIcon,
      bookmarkIcon,
      heartIcon,
      gemIcon,
    ]: TemplateRef<unknown>[]
  ) {
    switch (icon) {
      case 'house':
        return houseIcon;
      case 'person':
        return personIcon;
      case 'bookmark':
        return bookmarkIcon;
      case 'heart':
        return heartIcon;
      case 'gem':
        return gemIcon;
      default:
        return null;
    }
  }
}
