import { Directive, EventEmitter, Input, Output } from '@angular/core';

@Directive()
export abstract class ImageInput<T = File> {
  protected file: T | null = null;

  protected abstract handleChange(event: Event): void;

  protected setFile(file: T | null) {
    this.file = file;
    this.fileChanged.emit(file);
  }

  @Output()
  fileChanged = new EventEmitter<T | null>();
}

@Directive()
export abstract class ImagePreviewInput extends ImageInput<File> {
  @Input()
  imagePreview?: string | null = '';

  protected override handleChange(event: Event) {
    const element = event.currentTarget as HTMLInputElement;
    const fileList: FileList | null = element.files;
    if (fileList && fileList.length > 0) {
      this.setFile(fileList[0]);
    }
    element.value = '';
  }

  protected override setFile(file: File) {
    super.setFile(file);
    this.imagePreview = file ? URL.createObjectURL(file) : '';
  }
}
