import { Notification } from '@/core/types/notification.type';
import { TimeAgoPipe } from '@/shared/pipes';
import { NgClass } from '@angular/common';
import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

@Component({
  selector: 'sw-notification-item',
  standalone: true,
  imports: [NgClass, TimeAgoPipe],
  changeDetection: ChangeDetectionStrategy.OnPush,
  template: `<div class="relative min-h-12  p-4">
    @if(!item.isRead) {
    <div
      class="absolute left-[6px] top-8 aspect-square h-1 w-1 rounded-full bg-accent"
    ></div>
    }

    <div class="flex flex-row space-x-3">
      <div
        class="flex h-9 w-9 shrink-0 items-center justify-center rounded-full bg-accent/5"
      >
        <i class="pi pi-comments"></i>
      </div>

      <div class="flex-1">
        <p
          [ngClass]="{
      'text-sm': size === 'sm',
    }"
        >
          {{ item.message }}
        </p>

        <p
          class="text-black/70"
          [ngClass]="{
            'text-[10px]': size === 'sm',
            'text-sm': size === 'lg',
            }"
        >
          {{ item.createdAt | swTimeAgo : 'назад' }}
        </p>
      </div>
    </div>
  </div> `,
})
export class NotificationItemComponent {
  @Input() type = 'warning';

  @Input() size: 'sm' | 'lg' = 'sm';

  @Input({ required: true }) item!: Notification;
}
