import { ChangeDetectionStrategy, Component } from '@angular/core';

@Component({
  selector: 'sw-bg-lines',
  standalone: true,
  changeDetection: ChangeDetectionStrategy.OnPush,
  template: `
    <div
      class="fixed -top-[100px] bottom-0 left-[100px]  z-0 flex h-full w-full scale-150 items-center overflow-y-hidden md:left-0  md:top-0 md:scale-100 lg:w-[65%]"
    >
      <svg
        width="824"
        height="832"
        viewBox="0 0 824 832"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        class="absolute bottom-0 left-0 top-0 h-full w-full"
      >
        <path
          opacity="0.4"
          d="M-55.2799 1156.13C-46.5217 1141.29 -33.2166 1052.43 -50.0614 815.719C-71.1174 519.83 -191.814 438.829 -120.677 378.951C-49.5399 319.074 160.193 520.396 277.42 507.143C394.647 493.89 513.733 375.243 452.57 225.784C391.408 76.3253 210.513 67.0769 136.916 -89.1262C63.3179 -245.329 -271.115 -181.982 -288.156 -336.614"
          stroke="#194CFF"
          stroke-width="3"
        />
        <path
          d="M5.61978 1237.58C14.3779 1222.73 27.683 1133.87 10.8382 897.161C-10.2178 601.272 -130.914 520.271 -59.7772 460.394C11.3597 400.516 221.093 601.838 338.32 588.585C455.547 575.333 574.632 456.685 513.47 307.226C452.307 157.768 271.413 148.519 197.815 -7.68381C124.218 -163.887 -210.216 -100.54 -227.256 -255.172"
          stroke="#194CFF"
          stroke-width="3"
        />
        <path
          opacity="0.6"
          d="M71.9966 1208.63C80.377 1192.78 90.9384 1096.63 66.1418 838.772C35.1459 516.455 -89.6858 424.458 -19.6325 361.7C50.4207 298.941 269.321 524.637 387.557 514.067C505.793 503.497 622.462 378.425 555.657 213.964C488.852 49.5026 305.429 33.5313 225.815 -138.668C146.201 -310.867 -190.274 -252.951 -212.584 -421.592"
          stroke="#194CFF"
          stroke-width="3"
        />
      </svg>
    </div>
  `,
})
export class BgLinesComponent {}
