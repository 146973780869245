import { NgClass, NgIf } from '@angular/common';
import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { TailwindMergePipe } from '../pipes/tw-merge.pipe';

@Component({
  selector: 'sw-loader',
  standalone: true,
  imports: [NgIf, NgClass, TailwindMergePipe],
  changeDetection: ChangeDetectionStrategy.OnPush,
  template: `
    <div
      [class]="
        'absolute left-0 top-0 z-10 h-full w-full bg-black/40 ' + overlayClasses
          | twMerge
      "
      *ngIf="hasOverlay"
    ></div>
    <fieldset class="relative h-full w-full" [disabled]="loading">
      <ng-content></ng-content>
    </fieldset>
    <span
      class="absolute left-1/2 top-1/2 z-10 aspect-square h-3/4 max-h-14 -translate-x-1/2 -translate-y-1/2"
      *ngIf="loading"
    >
      <svg
        class="h-full w-full"
        viewBox="0 0 38 38"
        xmlns="http://www.w3.org/2000/svg"
        [attr.stroke]="spinnerColor"
      >
        <g fill="none" fill-rule="evenodd">
          <g transform="translate(1 1)" stroke-width="2">
            <circle stroke-opacity=".5" cx="18" cy="18" r="18" />
            <path d="M36 18c0-9.94-8.06-18-18-18">
              <animateTransform
                attributeName="transform"
                type="rotate"
                from="0 18 18"
                to="360 18 18"
                dur="1s"
                repeatCount="indefinite"
              />
            </path>
          </g>
        </g>
      </svg>
    </span>
  `,
})
export class LoaderComponent {
  // @Input()
  // size: 'xl' = this.options.size;

  @Input()
  overlay = false;

  @Input() overlayClasses = '';

  @Input()
  loading = false;

  @Input()
  spinnerColor = '#fff';

  get hasOverlay(): boolean {
    return this.overlay && this.loading;
  }
}
