import { UserService } from '@/core';
import { LikeService } from '@/core/services/likes.service';

import { AsyncPipe, NgIf } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  Input,
  OnDestroy,
  ViewEncapsulation,
  inject,
} from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { DropdownModule } from 'primeng/dropdown';
import { SkeletonModule } from 'primeng/skeleton';
import {
  BehaviorSubject,
  Subject,
  distinctUntilChanged,
  filter,
  map,
  startWith,
  switchMap,
  tap,
} from 'rxjs';

import {
  ButtonComponent,
  DynamicValidationMessage,
  InputNumberComponent,
} from '@/shared/ui';
import { toSignal } from '@angular/core/rxjs-interop';
import { LikeInfoExperienceExchangeComponent } from './like-info-experience-exchange.component';
import { LikeInfoProgressComponent } from './like-info-progress.component';

@Component({
  selector: 'sw-like-info-experience-tracker',
  standalone: true,
  imports: [
    NgIf,
    AsyncPipe,
    InputNumberComponent,
    DropdownModule,
    ReactiveFormsModule,
    ButtonComponent,
    DynamicValidationMessage,
    LikeInfoProgressComponent,
    LikeInfoExperienceExchangeComponent,
    SkeletonModule,
  ],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
  template: `<div class="island" *ngIf="user$ | async as user">
    <div
      class="pb-10"
      *ngIf="likeStats$ | async as likeStats; else skeletonStats"
    >
      <sw-like-info-progress
        [stats]="likeStats"
        [avatarUrl]="user.photo || undefined"
        [name]="user.fullName"
      />
    </div>

    <ng-template #skeletonStats>
      <p-skeleton
        width="100%"
        height="108px"
        borderRadius="16px"
        styleClass="mb-10"
      />
    </ng-template>
    <sw-like-info-experience-exchange
      *ngIf="category$ | async as category"
      [category]="category"
      [maxValue]="maxValue() || 0"
    />
  </div> `,

  styles: [
    `
      sw-like-info-progress-tracker .p-dropdown {
        @apply rounded-md bg-grey;
      }
    `,
  ],
})
export class LikeInfoExperienceTrackerComponent implements OnDestroy {
  private readonly likeService = inject(LikeService);

  protected readonly loading$ = this.likeService.loading$;

  private readonly userService = inject(UserService);

  protected readonly user$ = this.userService.user$;

  private readonly refreshStats$ = new Subject<void>();

  private readonly categorySubject$ = new BehaviorSubject<string>('');

  protected readonly category$ = this.categorySubject$.asObservable();

  @Input({ required: true })
  set category(category: string) {
    this.categorySubject$.next(category);
  }

  protected readonly userLikes$ = this.category$.pipe(
    switchMap((category) => {
      return this.userService.userLikes$.pipe(
        map((likes) => likes[category] ?? 0)
      );
    }),
    tap(() => {
      this.refreshStats$.next();
    }),
    distinctUntilChanged()
  );

  protected readonly maxValue = toSignal(this.userLikes$);

  protected readonly likeStats$ = this.refreshStats$.asObservable().pipe(
    startWith(0),
    switchMap(() => this.categorySubject$.asObservable().pipe(filter(Boolean))),
    switchMap((v) => this.likeService.getStats(v))
  );

  ngOnDestroy(): void {
    this.refreshStats$.complete();
  }
}
