import { SettingsService } from '@/core/services/settings.service';
import { AsyncPipe } from '@angular/common';
import { ChangeDetectionStrategy, Component, inject } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { CheckboxModule } from 'primeng/checkbox';
import { LetDirective } from '@shared/directives';
import { DynamicDialogRef } from 'primeng/dynamicdialog';
import { EMPTY, finalize, iif, switchMap, take } from 'rxjs';
import { ConfirmDialogContainerComponent } from './confirm-dialog-container.component';

@Component({
  selector: 'sw-empty-comment-agreement-dialog',
  standalone: true,
  imports: [
    CheckboxModule,
    AsyncPipe,
    FormsModule,
    LetDirective,
    ConfirmDialogContainerComponent,
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
  template: `
    <sw-confirm-dialog-container (btnClick)="handleBtnClick($event)">
      <div class="space-y-5" content>
        <p class="text-lg font-medium">
          Вы уверены, что хотите отправить комментарий не указав значимость?
        </p>
        <div>
          <p-checkbox
            label="Больше не показывать это окно"
            [(ngModel)]="hideDialog"
            [binary]="true"
            inputId="hideDialog"
          />
        </div>
      </div>
    </sw-confirm-dialog-container>
  `,
})
export class EmptyCommentAgreementDialogComponent {
  private readonly dialogRef = inject(DynamicDialogRef);
  protected readonly settingsService = inject(SettingsService);

  protected hideDialog = false;

  protected handleBtnClick(val: boolean) {
    if (val) {
      this.handleSave();
    } else {
      this.handleDismiss();
    }
  }

  protected handleSave() {
    this.settingsService.hideEmptyCommentAgreementDialog$
      .pipe(
        take(1),
        switchMap((v) =>
          iif(
            () => v !== this.hideDialog,
            this.settingsService.setEmptyCommentAgreement(this.hideDialog),
            EMPTY
          )
        ),
        finalize(() => this.dialogRef.close(true))
      )
      .subscribe({
        complete: () => {
          this.dialogRef.close(true);
        },
      });
  }

  protected handleDismiss() {
    this.dialogRef.close(false);
  }
}
