import { Portal } from '@angular/cdk/portal';
import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class PortalBridgeService {
  private readonly portalSubject$ = new BehaviorSubject<Portal<unknown> | null>(
    null
  );

  get portal$() {
    return this.portalSubject$.asObservable();
  }

  attach(portal: Portal<unknown>) {
    this.portalSubject$.next(portal);
  }

  detach() {
    this.portalSubject$.next(null);
  }
}
