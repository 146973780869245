import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component } from '@angular/core';
import { RouterModule } from '@angular/router';
import { SearchComponent } from '@/shared/ui/search.component';
import { BaseHeader } from './base-header';

@Component({
  selector: 'sw-header',
  standalone: true,
  imports: [CommonModule, SearchComponent, RouterModule],
  changeDetection: ChangeDetectionStrategy.OnPush,
  template: `<header
    class="z-50 -ml-[15px] flex h-[100px] w-[calc(100%+30px)] flex-row items-center space-x-[30px] bg-bg/70 px-[15px] backdrop-blur-md"
  >
    <!-- <sw-search class="flex-1" (termChange)="handleTermChange($event)" /> -->
  </header>`,
})
export class HeaderComponent extends BaseHeader {}
