import { Injectable, inject } from '@angular/core';
import { UserService } from './user.service';
import { map } from 'rxjs';

enum SettingsEnum {
  HideEmptyCommentAgreement = 'hideEmptyCommentAgreement',
}

@Injectable({ providedIn: 'root' })
export class SettingsService {
  private readonly userService = inject(UserService);

  get hideEmptyCommentAgreementDialog$() {
    return this.userService.user$.pipe(
      map((u) => !!u?.settings?.[SettingsEnum.HideEmptyCommentAgreement])
    );
  }

  setEmptyCommentAgreement(v = true) {
    return this.userService.updateSettings({
      [SettingsEnum.HideEmptyCommentAgreement]: v,
    });
  }
}
