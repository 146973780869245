import { Injectable } from '@angular/core';
import { ProjectEditor } from './project-editor';
import {
  ProjectDTO,
  CreateProjectDTO,
  Project,
  UpdateProjectDTO,
} from '@/core/models';
import { Observable } from 'rxjs';

@Injectable()
export class ProjectEditorService extends ProjectEditor {
  override create(payload: ProjectDTO<CreateProjectDTO>): Observable<Project> {
    return this.projectService.create(payload);
  }
  override update(
    id: string,
    payload: ProjectDTO<UpdateProjectDTO>
  ): Observable<Project> {
    return this.projectService.update(id, payload);
  }

  override delete(id: string): Observable<unknown> {
    return this.projectService.delete(id);
  }
}
