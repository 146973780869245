import { NgClass } from '@angular/common';
import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

@Component({
  selector: 'a[sw-nav-round], sw-nav-round',
  standalone: true,
  imports: [NgClass],
  changeDetection: ChangeDetectionStrategy.OnPush,
  template: `
    <div
      class="flex h-10 w-10 items-center justify-center rounded-full transition-colors duration-200"
      [ngClass]="{
        'bg-transparent hover:bg-black/5 focus-visible:bg-black/5': appearance !== 'grey',
        'bg-black/5 hover:bg-black/10 focus-visible:bg-black/10': appearance === 'grey',
    }"
    >
      <ng-content></ng-content>
    </div>
  `,
  styles: [
    `
      :host {
        display: block;
      }
    `,
  ],
})
export class NavRoundComponent {
  @Input() appearance: 'transparent' | 'grey' = 'grey';
}
