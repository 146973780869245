import { CommentExtended, PostType, Project } from '@/core/models';
import { CommentsService } from '@/core/services/comments.service';

import { AsyncPipe, NgFor, NgIf, ViewportScroller } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  DestroyRef,
  OnInit,
  inject,
} from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { ActivatedRoute, Router, RouterModule } from '@angular/router';
import {
  Observable,
  combineLatest,
  distinctUntilChanged,
  map,
  of,
  shareReplay,
  switchMap,
  tap,
} from 'rxjs';

import { ProjectService } from '@/core';
import { isMissed, isPresent } from '@/core/helpers';
import { deepEqual } from '@/core/helpers/deep-equal';
import { provideOnboarding } from '@/core/services/onboarding.service';
import {
  ThreadProjectEditorService,
  provideProjectEditor,
} from '@/core/services/project-editor';
import { TopicService } from '@/core/services/topics.service';
import { VerifyProjectService } from '@/features/verify-project-button';
import {
  AppGuideDirective,
  BreakpointDesktopDirective,
  ForDirective,
  LetDirective,
  ThreadGuideService,
} from '@/shared/directives';
import { TrackSeenDirective } from '@/shared/directives/track';
import { RegisterTrackDirective } from '@/shared/directives/track/register-track-seen.directive';
import { TrackSeenCommentsFacade } from '@/shared/directives/track/track-seen-comments-facade.service';
import { provideTrackSeen } from '@/shared/directives/track/track-seen.token';
import {
  BackButtonComponent,
  CommentEditorComponent,
  DelimiterComponent,
  ProjectCardExtendedComponent,
} from '@/shared/ui';
import { ProjectDetailsComponent } from '@/widgets/project-details';
import { DialogService } from 'primeng/dynamicdialog';
import { CreateCommentDirective } from './comment-context/create-comment.directive';
import { ProjectTopicListComponent } from './project-topic-list.component';
import { CommentStackComponent, ExtendedCommentComponent } from './ui';
import { ThreadFilterComponent } from './ui/thread-filter.component';

type ThreadPredicate = (a: CommentExtended, b: CommentExtended) => number;

const isDeletedPredicate = (a: boolean, b: boolean) => {
  return a ? 1 : b ? -1 : 0;
};

const predicateByAgreement: ThreadPredicate = (
  a: CommentExtended,
  b: CommentExtended
) => {
  if (a.absoluteAgreement === b.absoluteAgreement) {
    return 0;
  }

  if (a.isDeleted || a.absoluteAgreement === null) {
    return 1;
  }

  if (b.isDeleted || b.absoluteAgreement === null) {
    return -1;
  }

  return b.absoluteAgreement - a.absoluteAgreement;
};

const predicateByCreatedAt: ThreadPredicate = (
  a: CommentExtended,
  b: CommentExtended
) => {
  const isDeleted = isDeletedPredicate(a.isDeleted, b.isDeleted);

  if (isDeleted !== 0) {
    return isDeleted;
  }
  return new Date(b.createdAt).getDate() - new Date(a.createdAt).getDate();
};

const predicateByComments: ThreadPredicate = (
  a: CommentExtended,
  b: CommentExtended
) => {
  const isDeleted = isDeletedPredicate(a.isDeleted, b.isDeleted);

  if (isDeleted !== 0) {
    return isDeleted;
  }

  return b.answers - a.answers;
};

const predicateMap: Record<string, ThreadPredicate> = {
  '': predicateByAgreement,
  new: predicateByCreatedAt,
  hot: predicateByComments,
};

@Component({
  selector: 'sw-project',
  standalone: true,
  imports: [
    BackButtonComponent,
    BreakpointDesktopDirective,
    CommentEditorComponent,
    CommentStackComponent,
    NgIf,
    NgFor,
    ForDirective,
    AsyncPipe,
    CreateCommentDirective,
    DelimiterComponent,
    ProjectTopicListComponent,
    LetDirective,
    ProjectCardExtendedComponent,
    ExtendedCommentComponent,
    RegisterTrackDirective,
    RouterModule,
    ThreadFilterComponent,
    TrackSeenDirective,
    ProjectDetailsComponent,
  ],
  providers: [
    /* CommentsService, */
    DialogService,
    ProjectService,
    VerifyProjectService,
    provideProjectEditor(ThreadProjectEditorService),
    provideTrackSeen(TrackSeenCommentsFacade),
    provideOnboarding(ThreadGuideService),
  ],
  hostDirectives: [
    {
      directive: AppGuideDirective,
    },
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
  templateUrl: './project.page.html',
})
export class ProjectPage implements OnInit {
  private readonly commentsService = inject(CommentsService);
  private readonly activatedRoute = inject(ActivatedRoute);
  private readonly router = inject(Router);

  private readonly topicService = inject(TopicService);

  private readonly scroller = inject(ViewportScroller);

  protected readonly filter$ = this.activatedRoute.queryParamMap.pipe(
    distinctUntilChanged(),
    map((qMap) => {
      const t = qMap.get('type');
      const type = t ? (['positive', 'negative'].includes(t) ? t : null) : null;

      const s = qMap.get('sort');
      const sort = s ? (['hot', 'new'].includes(s) ? s : null) : null;
      return { sort, type };
    }),
    shareReplay(1)
  );
  protected readonly filterType$ = this.filter$.pipe(
    distinctUntilChanged(),
    map(({ type }) => type)
  );

  // protected readonly filterType$ = this.activatedRoute.queryParams.pipe(
  //   distinctUntilChanged(),
  //   map((p) => p['type'] as string | undefined)
  // );

  protected readonly projectId$ = this.activatedRoute.params.pipe(
    distinctUntilChanged(),
    map((p) => p['id'] as string)
  );

  private readonly commentId$: Observable<string | undefined> =
    this.activatedRoute.params.pipe(
      distinctUntilChanged(),
      map((p) => p['commentId'])
    );

  private readonly selectedTopicId$: Observable<string | undefined> =
    this.activatedRoute.queryParams.pipe(map((params) => params?.['topic']));

  protected commentTopicIds$ = combineLatest({
    commentId: this.commentId$,
    selectedTopicId: this.selectedTopicId$,
  });

  protected readonly selectedTopic$ = combineLatest([
    this.projectId$,
    this.selectedTopicId$,
  ]).pipe(
    switchMap(([projectId, topicId]) => {
      if (isMissed(projectId) || isMissed(topicId)) {
        return of(null);
      }

      return this.topicService
        .getTopics(projectId)
        .pipe(switchMap(() => this.topicService.getTopic$(topicId)));
    })
  );

  protected readonly commentType$ = this.commentId$.pipe(
    map((v) => (isPresent(v) ? 'comment' : 'project') as PostType)
  );

  private readonly destroyRef = inject(DestroyRef);

  protected readonly thread$ = this.filter$.pipe(
    distinctUntilChanged(),
    switchMap((filter) => {
      return this.commentsService.thread$.pipe(
        map((thread) => {
          if (thread === null) {
            return null;
          }

          const t = {
            comments: (filter.type
              ? thread.comments.filter((c) => c.ratingType === filter.type)
              : thread.comments
            )
              .map((c) => new CommentExtended(c))
              .sort(predicateMap[filter.sort || '']),
            parents: thread.parents.map((p) => new CommentExtended(p)),
            project: new Project(thread.project),
          };
          return t;
        })
      );
    }),
    shareReplay(1)
  );

  protected readonly canComment$ = this.thread$.pipe(
    map((thread) => {
      if (isMissed(thread)) {
        return false;
      }

      const lastParent = thread.parents.at(-1);
      if (isMissed(lastParent)) {
        return true;
      }

      return !lastParent.isDeleted;
    })
  );

  ngOnInit(): void {
    combineLatest([this.projectId$, this.commentId$, this.selectedTopicId$])
      .pipe(
        takeUntilDestroyed(this.destroyRef),
        distinctUntilChanged((a, b) => deepEqual(a, b)),
        switchMap(([projectId, commentId, topicId]) => {
          return this.commentsService.fetchThread({
            projectId,
            parentId: commentId,
            topicId,
          });
        }),
        tap((v) => {
          const lastParent = v?.parents.at(-1);
          this.scroller.setOffset([0, 120]);
          if (lastParent) {
            setTimeout(() => {
              this.scroller.scrollToAnchor(lastParent.id);
            });
          } else {
            setTimeout(() => {
              this.scroller.scrollToPosition([0, 0]);
            });
          }
        })
      )
      .subscribe({
        error: () => this.router.navigateByUrl('notfound'),
      });
  }

  protected resetFilter() {
    this.router.navigate([], {
      queryParams: { type: null },
      queryParamsHandling: 'merge',
    });
  }

  trackByComments(_index: number, comment: CommentExtended): string {
    return `${comment.id}${comment.isDeleted ? '-deleted' : ''}`;
  }
}
