import {
  Injectable,
  PLATFORM_ID,
  TransferState,
  inject,
  makeStateKey,
} from '@angular/core';
import { HttpClient, HttpContext } from '@angular/common/http';
import {
  MAP_REQ_HTTP_TOKEN,
  MAP_RES_HTTP_TOKEN,
  SKIP_BASE_URL_HTTP_TOKEN,
} from '@/core/interceptors';
import { of, tap } from 'rxjs';
import { isPlatformServer } from '@angular/common';
import { APP_ORIGIN_URL } from '@/core';

@Injectable({ providedIn: 'root' })
export class MDService {
  private readonly httpClient = inject(HttpClient);
  private readonly platformId = inject(PLATFORM_ID);
  private readonly transferState = inject(TransferState);

  private readonly originUrl = inject(APP_ORIGIN_URL);

  getData(url: string) {
    return this.load(url);
  }

  private load(url: string) {
    const getFileKey = makeStateKey<string | null>(`md-file-${url}`);

    const context = new HttpContext()
      .set(MAP_REQ_HTTP_TOKEN, false)
      .set(MAP_RES_HTTP_TOKEN, false)
      .set(SKIP_BASE_URL_HTTP_TOKEN, true);

    if (this.transferState.hasKey(getFileKey)) {
      const file = this.transferState.get(getFileKey, null);

      if (!file) {
        this.transferState.remove(getFileKey);
      }
      return of(file);
    }

    return this.httpClient
      .get(`${this.originUrl}${url}`, {
        context,
        responseType: 'text',
      })
      .pipe(
        tap((text) => {
          if (isPlatformServer(this.platformId)) {
            this.transferState.set(getFileKey, text);
          }
        })
      );
  }
}
