import { HttpClient } from '@angular/common/http';
import { inject } from '@angular/core';
import { BehaviorSubject, debounceTime } from 'rxjs';

export abstract class BaseAPIService {
  protected readonly _loading$ = new BehaviorSubject<boolean>(false);

  get loading$() {
    return this._loading$.asObservable().pipe(debounceTime(200));
  }

  protected readonly httpClient = inject(HttpClient);
}
