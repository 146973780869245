import {
  ButtonComponent,
  DynamicValidationMessage,
  InputNumberComponent,
} from '@/shared/ui';
import { AsyncPipe } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  Input,
  inject,
} from '@angular/core';
import {
  FormControl,
  FormGroup,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import { LikeExchangeService } from './like-exchange.service';

@Component({
  selector: 'sw-like-info-form',
  standalone: true,
  imports: [
    AsyncPipe,
    ReactiveFormsModule,
    InputNumberComponent,
    DynamicValidationMessage,
    ButtonComponent,
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
  template: `
    <form
      [formGroup]="form"
      (submit)="handleSubmit()"
      class="flex flex-row flex-wrap items-center justify-center gap-5"
    >
      <div class="shrink-0 flex-grow basis-[292px]">
        <sw-input-number
          ngDefaultControl
          formControlName="count"
          [label]="placeholder"
          placeholder="0"
          [showErrorMsg]="false"
          [min]="maxValue === 0 ? 0 : 1"
          [max]="maxValue"
        />
      </div>
      <div class="flex w-full shrink  basis-[200px]">
        <button
          swButton
          [loading]="loading$ | async"
          [disabled]="countControl.disabled"
          class="button button-primary h-[60px] w-full items-center justify-center font-bold"
        >
          {{ buttonText }}
        </button>
      </div>
    </form>
  `,
})
export class LikeInfoFormComponent {
  @Input() maxValue = 99;

  @Input() placeholder = 'Сколько обменять';
  @Input() buttonText = 'Обменять';

  @Input() set disabled(disabled: boolean) {
    if (disabled) {
      this.form.disable();
    } else {
      this.form.enable();
    }
  }

  private _category!: string;

  @Input({ required: true }) set category(category: string) {
    this._category = category;
    this.countControl.reset();
  }

  get category() {
    return this._category;
  }

  private readonly exchangeService = inject(LikeExchangeService);
  protected readonly loading$ = this.exchangeService.loading$;

  protected readonly countControl = new FormControl<number | null>(null, {
    validators: [
      Validators.required,
      Validators.min(1),
      Validators.max(this.maxValue),
    ],
  });

  protected readonly form = new FormGroup({
    count: this.countControl,
  });

  handleSubmit() {
    const { count } = this.form.getRawValue();
    if (this.form.invalid || count === null) {
      return;
    }

    this.exchangeService.exchange(this.category, count).subscribe({
      next: () => this.form.reset(),
    });
  }
}
