import { InjectionToken, inject } from '@angular/core';
import { WINDOW } from './window.token';
import { filter, fromEvent, map, pairwise } from 'rxjs';
import { isPresent } from '../helpers';

export const SCROLL_EVENT = new InjectionToken('Document Scroll Event', {
  factory: () => {
    const window = inject(WINDOW);
    return fromEvent(window, 'scroll');
  },
});

export const SCROLL_DIRECTION = new InjectionToken('Scroll direction', {
  factory: () => {
    const window = inject(WINDOW);
    const scroll$ = inject(SCROLL_EVENT);

    return scroll$.pipe(
      map(() => window.scrollY || window.document.documentElement.scrollTop),
      pairwise(),
      map(([prev, curr]) => {
        if (curr < prev - 3) {
          return 'top';
        } else if (curr > prev + 3) {
          return 'bottom';
        }
        return null;
      }),
      filter(isPresent)
    );
  },
});
