import { clamp } from '@core/helpers';
import {
  Product as FileProduct,
  FilePurchaseSchema,
  FormProduct,
  FormPurchaseSchema,
} from '@core/types/api-types';
import { Camelize } from '@core/types/snake-2-camel';
import { memoize } from '../decorators';

export class FilePurchase implements Camelize<FilePurchaseSchema> {
  constructor(public id: string, public goodId: string, public file: string) {}
}

export class FormPurchase implements Camelize<FormPurchaseSchema> {
  constructor(
    public id: string,
    public goodId: string,
    public jsonForm: object
  ) {}
}

export type Purchase = FilePurchase | FormPurchase;

export class Product {
  constructor(
    public product: Camelize<FormProduct | FileProduct>,
    public purchases: Purchase[] = []
  ) {}

  get productType(): 'form' | 'file' {
    return 'jsonForm' in this.product ? 'form' : 'file';
  }

  get discount() {
    return clamp(this.product.discount || 0, 0, this.product.price);
  }

  get priceWithDiscount() {
    return this.product.price - this.discount;
  }

  get isPurchased(): boolean {
    const lastPurchase = this.purchases.at(-1);

    if (!lastPurchase) {
      return false;
    }

    if (lastPurchase instanceof FormPurchase) {
      return !this.completedPurchase(
        // (this.product as Camelize<FormProduct>).jsonForm as FormSchema,
        lastPurchase.jsonForm
      );
    } else {
      return this.product.forever && Boolean(lastPurchase.file);
    }
  }

  @memoize
  private completedPurchase(json: object) {
    if (!json) {
      return true;
    }

    return Object.keys(json).length !== 0;
  }
  // private completedPurchase(schema: FormSchema, json: object) {
  //   if (!schema || !json) {
  //     return true;
  //   }

  //   const schemaKeys = Object.keys(schema);
  //   for (const sKey of schemaKeys) {
  //     const elem = schema[sKey];
  //     const valuesElem = (json as never)[sKey];

  //     if (!valuesElem) {
  //       return false;
  //     }

  //     if (!isFormSchemaElement(elem)) {
  //       if (
  //         typeof valuesElem === 'string' ||
  //         !this.completedPurchase(elem, valuesElem)
  //       ) {
  //         return false;
  //       }
  //     }
  //   }

  //   return true;
  // }
}
