import { Injectable, inject, isDevMode } from '@angular/core';
import { TRACK_SEEN_API_SERVICE } from './track-seen.token';
import { BehaviorSubject, concatMap, debounceTime, filter } from 'rxjs';
import { isMissed, isPresent } from '@/core/helpers';

@Injectable()
export class TrackSeenService {
  private readonly seen$ = new BehaviorSubject<string[]>([]);

  private parentId?: string | null;

  private readonly api = inject(TRACK_SEEN_API_SERVICE, { optional: true });

  constructor() {
    /**
     * wait for actions and save action bulk
     * after all refresh actions
     */

    if (isPresent(this.api) && !isDevMode()) {
      this.seen$
        .asObservable()
        .pipe(
          filter((v) => v.length > 0),
          debounceTime(400),
          concatMap((v) =>
            // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
            this.api!.setSeen(
              v,
              isMissed(this.parentId) ? undefined : this.parentId
            )
          )
        )
        .subscribe(() => {
          this.seen$.next([]);
        });
    }
  }

  setParentId(id: string | null) {
    this.parentId = id;
  }

  push(id: string) {
    this.seen$.next([id, ...this.seen$.value]);
  }
}
