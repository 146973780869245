import { UserService } from '@/core';
import { DialogRef } from '@angular/cdk/dialog';
import { CommonModule } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  inject,
  ViewEncapsulation,
} from '@angular/core';

import { InfoFAQComponent } from '@/features/info/info-faq.component';
import { InfoHeaderComponent } from '@/features/info/info-header.component';
import { InfoLayoutComponent } from '@/features/info/info-layout.component';
import { MarketComponent } from '@/features/market';
import { GemInfoAddFundsComponent } from './gem-info-add-funds.component';
import { GemInfoDonateComponent } from './gem-info-donate.component';

@Component({
  selector: 'sw-gem-info',
  standalone: true,
  imports: [
    CommonModule,
    InfoLayoutComponent,
    InfoHeaderComponent,
    GemInfoAddFundsComponent,
    GemInfoDonateComponent,
    InfoFAQComponent,
    MarketComponent,
  ],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
  templateUrl: './gem-info.component.html',
})
export class GemInfoComponent {
  protected readonly dialogRef = inject(DialogRef);
  protected readonly gems$ = inject(UserService).userGems$;

  protected readonly faq = [
    {
      title: ' Как я могу получить гемы?',
      content: `
        Гемы можно получить двумя способами:

        1. Написав значимые комментарии к постам, у которых есть прикрепленные гемы
        1. Обменяв полученную карму в категории на гемы в категории
        `,
    },
    {
      title: 'Зачем мне использовать гемы?',
      content: `

      Гемы имеют несколько полезных функций. Вы можете добавлять их к постам для вознаграждения авторов, которые создали самые значимые комментарии. Также, вы можете использовать их для стимулирования обсуждения в конкретных категориях и даже обменивать их на ценные ресурсы, такие как датасеты, вычислительные мощности и предобученные модели.

      `,
    },
  ];
}
