import {
  ChangeDetectionStrategy,
  Component,
  inject,
  Input,
} from '@angular/core';
import { NgControl } from '@angular/forms';
import { MaskitoModule } from '@maskito/angular';
import { MaskitoOptions } from '@maskito/core';
import { maskitoNumberOptionsGenerator } from '@maskito/kit';

@Component({
  selector: 'sw-stepper',
  standalone: true,
  imports: [MaskitoModule],
  changeDetection: ChangeDetectionStrategy.OnPush,
  template: `
    <button
      class="button-base text-center text-2xl disabled:opacity-30"
      (click)="dec()"
      [disabled]="disabled || value === min"
    >
      -
    </button>
    <input
      [value]="ngControl?.value"
      [disabled]="disabled"
      [maskito]="maskitoOptions"
      [placeholder]="placeholder"
      inputmode="numeric"
      class="mx-1 w-full bg-transparent text-center text-lg font-bold leading-[22px] outline-none"
    />
    <button
      class="button-base text-center text-2xl disabled:opacity-30"
      (click)="inc()"
      [disabled]="disabled || value === max"
    >
      +
    </button>
  `,

  styles: [
    `
      :host {
        @apply flex flex-row rounded-md bg-[#EEEEEE] px-[15px] py-[5px];
      }
    `,
  ],
})
export class StepperComponent {
  @Input() min = 0;
  @Input() max = 100;

  @Input() step = 1;

  @Input() placeholder?: string = '';
  protected readonly ngControl = inject(NgControl, { optional: true });

  get value() {
    return this.ngControl ? +this.ngControl.value : null;
  }

  get maskitoOptions(): MaskitoOptions {
    return maskitoNumberOptionsGenerator({
      min: this.min,
      max: this.max,
      precision: 0,
    });
  }

  get disabled() {
    return this.ngControl?.disabled || false;
  }

  protected dec() {
    if (this.ngControl) {
      this.ngControl.control?.patchValue(
        Math.max(this.min, +this.ngControl.value - this.step).toString()
      );
    }
  }

  protected inc() {
    if (this.ngControl) {
      this.ngControl.control?.patchValue(
        Math.min(this.max, +this.ngControl.value + this.step).toString()
      );
    }
  }
}
