import { memoize } from '@/core/decorators';
import { LikeStatistics, User } from '@/core/models';
import { LetDirective } from '@/shared/directives';
import { InitialsPipe, MapperPipe } from '@/shared/pipes';

import { NgClass, NgIf } from '@angular/common';
import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { AvatarModule } from 'primeng/avatar';

@Component({
  selector: 'sw-like-info-progress',
  standalone: true,
  imports: [
    NgIf,
    NgClass,
    MapperPipe,
    LetDirective,
    MapperPipe,
    InitialsPipe,
    AvatarModule,
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
  template: `
    <div
      class="relative  py-[50px] pl-[20px] pr-[34px] md:pl-[68px] md:pr-[82px]"
      *swLet="getSegmentWithPercent(progress, segments) as v"
    >
      <div class="relative flex w-full flex-row">
        <div
          class="absolute left-0 top-0 z-10 h-2 bg-accent transition-all duration-300"
          [style.width.%]="calcWidth(v)"
        ></div>

        <div
          class="absolute top-0 z-30 -translate-x-1/2 -translate-y-1/2
          transition-all duration-300
          "
          [style.left.%]="calcWidth(v)"
        >
          <p-avatar
            [label]="!avatarUrl ? (name | swInitials : 2) : ''"
            [image]="avatarUrl || undefined"
            shape="circle"
            class="block h-10 w-10 shrink-0"
            [style]="{ width: '100%', height: '100%' }"
          />
        </div>

        <div
          class="segment rounded-l-md bg-[#d3d3d3]"
          [ngClass]="{
          segment_current: v[1] === 0,
          'bg-accent': v[1] > 0,

        }"
        >
          <span class="segment-text segment-text-value left-0 -translate-x-1/2"
            >0</span
          >
          <span class="segment-text segment-text-label left-0 -translate-x-1/2"
            >Старт</span
          >
        </div>
        <div
          class="segment bg-[#d3d3d3]"
          [ngClass]="{
          segment_current: v[1] === 1,
          'bg-accent': v[1] > 1,

        }"
        >
          <span
            class="segment-text segment-text-value left-0 -translate-x-1/2"
            >{{ segments[1] }}</span
          >
          <span class="segment-text  segment-text-label left-0 -translate-x-1/2"
            >Среднее знач.<br />в категории</span
          >
        </div>
        <div
          class="segment rounded-r-md bg-[#d3d3d3]"
          [ngClass]="{
          segment_current: v[1] === 2,
          'bg-accent': v[1] > 2,

        }"
        >
          <span
            class="segment-text segment-text-value left-0 -translate-x-1/2"
            >{{ segments[2] }}</span
          >
          <span class="segment-text segment-text-label left-0 -translate-x-1/2"
            >топ -10</span
          >

          <span
            class="segment-text segment-text-value right-0 translate-x-1/2"
            >{{ segments[3] }}</span
          >
          <span class="segment-text segment-text-label right-0 translate-x-1/2">
            <span class="block font-bold" *ngIf="stats.max">{{
              stats.max | swMapper : nameMapper
            }}</span>
            <span class="block font-normal">
              {{ stats.max === null ? 'лидер' : 'Эксперт' }}
            </span>
          </span>
        </div>
      </div>
    </div>
  `,

  styles: [
    `
      .segment {
        @apply relative  h-2 flex-1 transition-colors duration-300 before:absolute
        before:-left-[2px] before:-top-2 before:z-20 before:block
        before:h-6 before:w-1 before:rounded-md

        before:bg-inherit before:transition-colors
        before:duration-300 before:content-['']

        after:absolute after:-right-[2px] after:-top-2 after:z-20
        after:hidden after:h-6 after:w-1
        after:rounded-md after:bg-inherit
        after:content-[''] last:after:block;

        &_current {
          @apply before:bg-accent;
        }

        &-text {
          @apply absolute text-center;
          &-value {
            @apply -top-[50px] text-sm font-medium leading-[18px]  text-black/50;
          }

          &-label {
            @apply top-[24px] text-[10px] font-medium leading-[18px]  md:text-sm;
          }
        }
      }
    `,
  ],
})
export class LikeInfoProgressComponent {
  @Input()
  avatarUrl?: string;

  @Input()
  name = 'Алексей Федоров';

  @Input()
  stats!: LikeStatistics;

  get progress() {
    return this.stats.userTotal ?? 0;
  }

  get segments(): [number, number, number, number] {
    return [
      0,
      this.stats.mean,
      this.stats.top?.count ?? 0,
      this.stats?.max?.count ?? 0,
    ];
  }

  protected readonly nameMapper = (u: User) => {
    return `${u.name} ${u.surname[0]}.`;
  };

  @memoize
  protected calcWidth([value, segment]: [number, number]) {
    return Math.min((segment * 100) / 3 + (value * 1) / 3, 100);
  }

  @memoize
  protected getSegmentWithPercent(
    value: number,
    segments: [number, number, number, number]
  ): [number, number] {
    if (value === 0) {
      return [0, 0];
    } else if (value >= (segments.at(-1) ?? +Infinity)) {
      return [100, 3];
    }

    const maxIndex = segments.findIndex((s) => s > value);
    const minIndex = maxIndex - 1;

    const max = segments[maxIndex] - segments[minIndex];
    const percent = ((value - segments[minIndex]) / max) * 100;

    return [percent, minIndex];
  }
}
