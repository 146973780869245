import {
  isPresent,
  isResponseError,
  validateAllFormFields,
} from '@/core/helpers';
import { Project } from '@/core/models';
import { CategoriesService } from '@/core/services/categories.service';
import { createEmailArrayValidator } from '@/core/validators';
import { TextAreaComponent } from '@/shared/ui/text-area.component';
import { CommonModule } from '@angular/common';
import { HttpErrorResponse } from '@angular/common/http';
import {
  ChangeDetectionStrategy,
  Component,
  OnInit,
  ViewEncapsulation,
  inject,
} from '@angular/core';
import {
  FormControl,
  FormGroup,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import { ChipsModule } from 'primeng/chips';
import { DropdownModule } from 'primeng/dropdown';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { InputSwitchModule } from 'primeng/inputswitch';

import { ProjectEditor } from '@/core/services/project-editor';
import {
  ProjectTopicEditorService,
  ProjectTopicEditorWrapperComponent,
  TopicEditorFormService,
  provideTopicEditorService,
} from '@/features/project-topic';
import { LetDirective } from '@/shared/directives';
import { ButtonComponent } from '@/shared/ui/button.component';
import { DocumentsInputComponent } from '@/shared/ui/documents-input.component';
import { DynamicValidationMessage } from '@/shared/ui/error/dynamic-validation-message.directive';
import { ImageInputComponent } from '@/shared/ui/image-input.component';
import { SVGComponent } from '@/shared/ui/svg.component';
import { NgFormsManager } from '@ngneat/forms-manager';
import { FieldWrapperComponent } from './ui';

@Component({
  selector: 'sw-project-editor',
  standalone: true,
  imports: [
    CommonModule,
    TextAreaComponent,
    ChipsModule,
    InputSwitchModule,
    DocumentsInputComponent,
    ImageInputComponent,
    ReactiveFormsModule,
    DropdownModule,
    LetDirective,
    ButtonComponent,
    ProjectTopicEditorWrapperComponent,
    SVGComponent,
    DynamicValidationMessage,
    FieldWrapperComponent,
  ],
  providers: [provideTopicEditorService(ProjectTopicEditorService)],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
  template: `
    <form [formGroup]="form" (ngSubmit)="handleSubmit()" class="space-y-5">
      <div>
        <sw-image-input
          #imageInput
          [imagePreview]="data?.photo || ''"
          (fileChanged)="handleThumbnailChanged($event)"
          [hasError]="thumbnailHasError"
        />
      </div>

      <!-- <div>
        <input
          type="text"
          class="form-field"
          placeholder="Введите название проекта"
          formControlName="name"
        />
      </div> -->

      <div>
        <sw-pe-field-wrapper>
          <span title>О чем ваш проект</span>
          <sw-textarea
            ngDefaultControl
            formControlName="name"
            styleClass="min-h-[120px]"
            placeholder="Например: Предлагаю субсидировать покупку беспилотных комбайнов для агропредприятий в Республике Татарстан"
          />
        </sw-pe-field-wrapper>
      </div>

      <div>
        <p-dropdown
          formControlName="categories"
          [options]="(categories$ | async) || []"
          optionLabel="name"
          optionValue="name"
          placeholder="Выберите сферу деятельности"
          class="p-fluid block"
        ></p-dropdown>
      </div>
      <!--
      <div>
        <p-chips
          formControlName="tags"
          placeholder="Введите хештег проекта через запятую"
          [allowDuplicate]="false"
          [addOnTab]="true"
          [addOnBlur]="true"
          separator=","
          class="p-fluid block"
        >
          <ng-template let-item pTemplate="item">#{{ item }}</ng-template>
          <ng-template pTemplate="removetokenicon">
            <sw-svg icon="cross" width="12px" height="12px" />
          </ng-template>
        </p-chips>
      </div> -->

      <div>
        <sw-pe-field-wrapper>
          <span title>Прикрепите документ</span>
          <sw-documents-input
            placeholder="Это может быть презентация или любые файлы о вашем проекте"
            [fileList]="data?.documents || []"
            (fileChanged)="handleDocumentsChanged($event)"
            (savedFilesChanged)="handleSavedFilesChanged($event)"
          />
        </sw-pe-field-wrapper>
      </div>

      <ng-container *ngIf="!isEdit" formArrayName="topics">
        <div
          *ngFor="let control of topicsControlArray.controls; let index = index"
        >
          <sw-project-topic-editor-wrapper
            *swLet="index === topicsControlArray.length - 1 as isLast"
            [skipScroll]="skipScrollToTopicPreview"
            [formGroup]="control"
            [isLast]="isLast"
            [canDelete]="(isLast && topicsControlArray.length > 1) || !isLast"
            (save)="handleSaveTopic()"
            (delete)="handleDeleteTopic(index)"
          />
        </div>
      </ng-container>

      <div class="flex flex-row items-center justify-center space-x-[15px]">
        <span>Сделать проект приватным</span>
        <p-inputSwitch class="flex" [formControl]="private" />
      </div>
      <div *ngIf="private.value">
        <p-chips
          *swLet="emailsControl.errors?.['emailarray']?.errorEmails  as errorEmails"
          formControlName="emails"
          placeholder="Введите почту для отправки через запятую"
          class="p-fluid block"
          [addOnTab]="true"
          [addOnBlur]="true"
          [allowDuplicate]="false"
          separator=","
        >
          <ng-template let-item pTemplate="item">
            <span [class.chips-token-error]="errorEmails?.includes(item)">
              {{ item }}
            </span>
          </ng-template>
          <ng-template pTemplate="removetokenicon">
            <sw-svg icon="cross" width="12px" height="12px" />
          </ng-template>
        </p-chips>
      </div>

      <button
        swButton
        class="button button-primary w-full py-5 text-base font-bold leading-5"
        type="submit"
        [loading]="loading$ | async"
      >
        {{ submitButtonText }}
      </button>
    </form>
  `,
})
export class ProjectEditorComponent implements OnInit {
  private readonly formManager = inject(NgFormsManager);

  private readonly dialogRef = inject(DynamicDialogRef);
  private readonly config = inject(DynamicDialogConfig);

  private readonly projectService = inject(ProjectEditor);

  private readonly editorTopicService = inject(TopicEditorFormService);

  protected readonly loading$ = this.projectService.loading$;

  protected skipScrollToTopicPreview = true;

  get data() {
    if (this.config.data && 'project' in this.config.data) {
      return this.config.data.project as Project;
    }

    return null;
  }

  get isEdit() {
    return isPresent(this.data);
  }

  protected submitted = false;

  get submitButtonText() {
    return this.data == null ? 'Создать' : 'Сохранить';
  }

  get thumbnailHasError() {
    return this.submitted && !this.selectedThumbnail && !this.data?.photo;
  }

  protected readonly topicsControlArray =
    this.editorTopicService.createFormArray([{}]);

  protected readonly private = new FormControl<boolean>(
    this.data?.isPrivate ?? false,
    {
      nonNullable: true,
    }
  );
  protected readonly emailsControl = new FormControl<string[] | null>(
    this.data?.emails || [],
    [createEmailArrayValidator()]
  );

  protected selectedThumbnail: File | null = null;
  protected selectedDocuments: File[] = [];
  protected savedFiles: string[] = this.data?.documents || [];

  protected readonly form = new FormGroup({
    name: new FormControl<string>(this.data?.name || '', {
      nonNullable: true,
      validators: [
        Validators.required,
        Validators.minLength(2),
        Validators.maxLength(120),
      ],
    }),
    // about: new FormControl<string>(this.data?.about || '', {
    //   nonNullable: true,
    //   validators: [
    //     Validators.minLength(2),
    //     Validators.maxLength(4096),
    //   ],
    // }),
    categories: new FormControl<string>(
      this.data?.categories?.[0]?.name || '',
      {
        nonNullable: true,
        validators: [Validators.required],
      }
    ),
    tags: new FormControl<string[]>(this.data?.tags || [], {
      nonNullable: true,
      // validators: [Validators.required],
    }),
    ...(!this.isEdit && { topics: this.topicsControlArray }),
    emails: this.emailsControl,
  });

  protected readonly categories$ = inject(CategoriesService).fetchList();

  ngOnInit(): void {
    if (this.data) {
      return;
    }

    this.formManager.upsert('project-editor', this.form, {
      persistState: true,
      arrControlFactory: {
        topics: (value) => this.editorTopicService.createTopicGroup(value),
      },
    });
  }

  protected handleThumbnailChanged(file: File | null) {
    this.selectedThumbnail = file;
  }

  protected handleDocumentsChanged(files: File[]) {
    this.selectedDocuments = files;
  }

  protected handleSavedFilesChanged(files: string[]) {
    this.savedFiles = files;
  }

  protected handleSaveTopic() {
    this.topicsControlArray.push(this.editorTopicService.createTopicGroup({}));
    this.skipScrollToTopicPreview = false;
  }

  protected handleDeleteTopic(topicId: number) {
    this.topicsControlArray.removeAt(topicId);
  }

  protected handleSubmit() {
    this.submitted = true;
    validateAllFormFields(this.form);
    if (this.form.valid && (this.selectedThumbnail || this.data?.photo)) {
      const { categories, emails, name, topics, ...raw } =
        this.form.getRawValue();

      const filteredName = name.replace(/(\s)+/gm, ' ');

      const payloadData = {
        ...raw,
        name: filteredName,
        // about: replaceNewLines(about),
        categories: [categories],
        isPrivate: this.private.value,
        emails: emails || [],
      };

      (!this.data
        ? this.projectService.create({
            files: this.selectedDocuments,
            photo: this.selectedThumbnail || undefined,
            data: {
              ...payloadData,
              topics: (topics || []).map(({ comment, agreement, name }) => ({
                name: name || null,
                agreement: agreement ?? null,
                comment,
              })),
            },
          })
        : this.projectService.update(this.data.id, {
            files: this.selectedDocuments,
            photo: this.selectedThumbnail || undefined,
            data: { ...payloadData, savedFiles: this.savedFiles },
          })
      ).subscribe({
        next: (project) => {
          if (!this.data) {
            this.formManager.clear('project-editor');
          }
          this.dialogRef.close(project.id);
        },
        error: (e: HttpErrorResponse) => {
          if (e.status === 422 && isResponseError(e.error)) {
            e.error.detail.forEach((e) => {
              const [field] = e.loc;
              this.form.get(field)?.setErrors({
                commonError: {
                  msg: e.msg,
                },
              });
            });
          }
        },
      });
    }
  }
}
