import { InjectionToken, inject } from '@angular/core';
import { UserService } from '../services';

export const UserMenu = new InjectionToken<
  { label: string; icon: string; routerLink?: string[]; command?: () => void }[]
>('User menu list', {
  factory: () => {
    const userService = inject(UserService);
    /**
     * TODO: remove unused menu elements
     */
    return [
      {
        label: 'Настройки',
        icon: 'icon sw-settings-icon',
        routerLink: ['/settings'],
      },
      // {
      //   label: 'Синхронизация контактов',
      //   icon: 'icon sw-human-icon',
      //   routerLink: ['/settings'],
      // },
      // {
      //   label: 'Авторизация по QR-коду',
      //   icon: 'icon sw-qr-code-icon',
      //   routerLink: ['/settings'],
      // },
      // {
      //   label: 'Черный список',
      //   icon: 'icon sw-cross-square-icon',
      //   routerLink: ['/settings'],
      // },
      // {
      //   label: 'Оформление',
      //   icon: 'icon sw-brush-icon',

      //   routerLink: ['/settings'],
      // },
      // {
      //   label: 'О приложении',
      //   icon: 'icon sw-app-icon',
      //   routerLink: ['/settings'],
      // },
      {
        label: 'Выйти из аккаунта',
        icon: 'icon sw-logout-icon',
        command: userService.logout.bind(userService),
      },
    ];
  },
});
