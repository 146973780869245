import { isPlatformServer } from '@angular/common';
import {
  inject,
  Injectable,
  makeStateKey,
  PLATFORM_ID,
  TransferState,
} from '@angular/core';
import { JWTPair } from '@core/types/jwt-pair';
import { CookieService } from 'ngx-cookie-service';
import { SsrCookieService } from 'ngx-cookie-service-ssr';

const ACCESS_TOKEN = 'sw_access_token';
const REFRESH_TOKEN = 'sw_refresh_token';

@Injectable({ providedIn: 'root' })
export class TokenService {
  private readonly ssrCookieService = inject(SsrCookieService);
  private readonly cookieService = inject(CookieService);
  private readonly platform = inject(PLATFORM_ID);

  private readonly isServer = isPlatformServer(this.platform);

  private readonly transferState = inject(TransferState);

  private service = this.isServer ? this.ssrCookieService : this.cookieService;

  private readonly pairKey = makeStateKey<{
    accessToken: string;
    refreshToken: string;
  }>('atpK');

  getAccessToken() {
    this.updateTokens();
    return this.service.get(ACCESS_TOKEN);
  }

  getRefreshToken() {
    this.updateTokens();
    return this.service.get(REFRESH_TOKEN);
  }

  setTokenPair({ refreshToken, accessToken }: JWTPair) {
    const COOKIE_EXPIRED_AT = new Date(Date.now() + 90 * 24 * 3600000);

    this.service.set(
      ACCESS_TOKEN,
      accessToken,
      COOKIE_EXPIRED_AT,
      '/',
      undefined,
      true,
      'Strict'
    );
    this.service.set(
      REFRESH_TOKEN,
      refreshToken,
      COOKIE_EXPIRED_AT,
      '/',
      undefined,
      true,
      'Strict'
    );

    if (this.isServer) {
      this.transferState.set(this.pairKey, { accessToken, refreshToken });
    }
  }

  removeSessionToken() {
    this.service.delete(ACCESS_TOKEN);
    this.service.delete(REFRESH_TOKEN);
  }

  private updateTokens() {
    if (this.transferState.hasKey(this.pairKey)) {
      const COOKIE_EXPIRED_AT = new Date(Date.now() + 90 * 24 * 3600000);
      const pair = this.transferState.get(this.pairKey, null);
      this.transferState.remove(this.pairKey);

      if (pair === null) {
        return;
      }

      this.cookieService.set(
        ACCESS_TOKEN,
        pair.accessToken,
        COOKIE_EXPIRED_AT,
        '/',
        undefined,
        true,
        'Strict'
      );
      this.cookieService.set(
        REFRESH_TOKEN,
        pair.refreshToken,
        COOKIE_EXPIRED_AT,
        '/',
        undefined,
        true,
        'Strict'
      );
    }
  }
}
