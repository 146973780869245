import { UserService } from '@/core';
import { InitialsPipe } from '@/shared/pipes';
import { SearchComponent } from '@/shared/ui/search.component';
import { SVGComponent } from '@/shared/ui/svg.component';
import { CommonModule, NgIf } from '@angular/common';
import { ChangeDetectionStrategy, Component, inject } from '@angular/core';
import { toSignal } from '@angular/core/rxjs-interop';
import { RouterModule } from '@angular/router';
import { AvatarModule } from 'primeng/avatar';
import { DialogService } from 'primeng/dynamicdialog';
import { BaseHeader } from './base-header';
import { MobileMenuDialogComponent } from './mobile-menu-dialog.component';

@Component({
  selector: 'sw-header-mobile',
  standalone: true,
  imports: [
    NgIf,
    CommonModule,
    SearchComponent,
    RouterModule,
    AvatarModule,
    InitialsPipe,
    SVGComponent,
  ],
  providers: [DialogService],
  changeDetection: ChangeDetectionStrategy.OnPush,
  template: `
    <header
      class="z-50  flex h-[60px]  flex-row items-center bg-grey pl-[33px] pr-[18px] shadow-header backdrop-blur-md"
    >
      <div class="shrink-0">
        <a
          routerLink="/"
          class="transition-opacity duration-200 hover:opacity-50 focus-visible:opacity-50"
        >
          <div class="flex h-10 w-10 items-center justify-center">
            <sw-svg
              icon="logo"
              width="33px"
              height="21px"
              class="text-base text-accent"
            />
          </div>
        </a>
      </div>

      <ng-container *ngIf="user$() as user">
        <!-- <sw-search
          class="flex-1"
          iconPosition="right"
          iconSize="lg"
          (termChange)="handleTermChange($event)"
          styleClass="bg-inherit"
        /> -->

        <div class="ml-auto">
          <p-avatar
            [label]="!user.photo ? (user.fullName | swInitials : 2) : ''"
            [image]="user.photo || undefined"
            shape="circle"
            class="block h-10 w-10 cursor-pointer"
            [style]="{ width: '100%', height: '100%' }"
            (click)="openMenu()"
          />
        </div>
      </ng-container>
    </header>
  `,
})
export class HeaderMobileComponent extends BaseHeader {
  protected readonly user$ = toSignal(inject(UserService).user$);

  protected readonly dialogService = inject(DialogService);

  protected openMenu() {
    this.dialogService.open(MobileMenuDialogComponent, {
      showHeader: false,
      width: '100%',
      dismissableMask: true,
    });
  }
}
