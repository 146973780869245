<sw-info-layout
  (closeDialog)="dialogRef.close()"
  headerBgImg="heart"
  *swLet="((userLikes$ | async) || {})[categoryControl.value] ?? 0 as likes"
>
  <sw-info-header title="Карма в категории" layout-header>
    <p class="text-center text-lg text-white" description>
      За значимые комментарии вы получаете карму. Но карма начисляется только в
      той категории, в которой находится пост, к которому вы написали значимый
      комментарий. Чем больше лайков у поста, к которому вы написали
      комментарий, и чем выше оценка значимости у вашего комментария, тем выше
      вознаграждение в виде кармы.
    </p>

    <div class="mx-auto flex flex-col items-center space-y-3" content>
      <div class="w-64 shrink-0 rounded-md bg-white">
        <p-dropdown
          [formControl]="categoryControl"
          [options]="(categories$ | async) || []"
          optionLabel="name"
          optionValue="slug"
          appendTo="body"
          placeholder="Выберите категорию"
          class="p-fluid block"
          emptyMessage=" "
        />
      </div>
      <div class="flex flex-row items-center space-x-5">
        <span class="text-[18px] leading-[22px] text-white/50"
          >Всего кармы</span
        >
        <span class="text-[32px] font-bold text-white">{{
          likes | swShortNumber
        }}</span>
      </div>
    </div>
  </sw-info-header>

  <div class="space-y-[50px]">
    <section>
      <sw-like-info-experience-tracker [category]="categoryControl.value" />
    </section>

    <section>
      <sw-like-info-gem-exchange [category]="categoryControl.value" />
    </section>

    <!-- <section>
      <sw-like-info-tops />
    </section> -->

    <!-- <section>
      <sw-like-info-feature-list />
    </section> -->
    <section>
      <sw-info-faq [content]="faq" />
    </section>
  </div>
</sw-info-layout>
