import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

import { NavPrevDirective } from '@/shared/directives';
import { SVGComponent } from './svg.component';

@Component({
  selector: 'sw-back-button',
  standalone: true,
  imports: [NavPrevDirective, SVGComponent],
  changeDetection: ChangeDetectionStrategy.OnPush,
  template: `
    <button
      swNavPrev
      aria-label="Вернуться на предыдущую страницу"
      [class]="styleClass"
    >
      <sw-svg icon="arrow-left" width="40px" height="40px" />
    </button>
  `,
})
export class BackButtonComponent {
  @Input()
  styleClass?: string;
}
