import { MenuIconComponent } from '@/shared/ui/menu-icon.component';
import { NavRoundComponent } from '@/shared/ui/nav-round.component';
import { SVGComponent } from '@/shared/ui/svg.component';
import { ChangeDetectionStrategy, Component } from '@angular/core';
import { RouterLink, RouterLinkActive } from '@angular/router';
import { BaseMenu } from './base-menu.component';

@Component({
  selector: 'sw-sidebar-left',
  standalone: true,
  imports: [
    RouterLink,
    RouterLinkActive,
    SVGComponent,
    NavRoundComponent,
    MenuIconComponent,
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
  template: `
    <a
      routerLink="/"
      class="mb-[31px] block transition-opacity duration-200 hover:opacity-50 focus-visible:opacity-50"
    >
      <div class="flex h-10 w-10 items-center justify-center ">
        <sw-svg
          icon="logo"
          width="33px"
          height="21px"
          class="text-base text-accent"
        />
      </div>
    </a>

    <ul class="space-y-[10px]">
      @for (item of menu; track $index) {

      <li>
        <a [routerLink]="[item.path]" routerLinkActive="text-accent">
          <sw-nav-round appearance="grey">
            <sw-menu-icon [icon]="item.icon" />
          </sw-nav-round>
        </a>
      </li>
      }
    </ul>

    <ng-template #houseIcon>
      <sw-svg icon="house" width="1.25em" height="1.1875em" />
    </ng-template>

    <ng-template #personIcon>
      <sw-svg icon="person" width="1.125em" height="1.0625em" />
    </ng-template>

    <ng-template #bookmarkIcon>
      <sw-svg icon="bookmark" width="1em" height="1.125em" />
    </ng-template>
  `,
  styles: [
    `
      :host {
        @apply block pt-[30px];
      }
    `,
  ],
})
export class MenuComponent extends BaseMenu {}
