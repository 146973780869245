import { Category } from '@/core/types/category.type';
import { BreakpointDesktopDirective, SaveDirective } from '@/shared/directives';
import { animate, style, transition, trigger } from '@angular/animations';
import { AsyncPipe, NgIf } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  inject,
  signal,
} from '@angular/core';
import { RouterLink } from '@angular/router';

import { FileBoxComponent } from '@/shared/ui/file-box.component';
import { HeaderCardComponent } from '@/shared/ui/header-card.component';
import { SVGComponent } from '@/shared/ui/svg.component';
import { TagsListComponent } from '@/shared/ui/tags-list.component';
import { TextComponent } from '@/shared/ui/text.component';
import { UserComponent } from '@/shared/ui/user.component';
import { ProjectEditorComponent } from '@features/project-editor/project-editor.component';
import { BaseProjectCard } from './base-project-card.component';
import { ProjectReactionsComponent } from './project-reactions.component';

import { ProjectEditor } from '@/core/services/project-editor';
import { NavPrevService } from '@/shared/directives/nav-prev/nav-prev.service';
import { PermissionActionDirective } from '@/shared/directives/permission/permission-action.directive';
import { DialogService } from 'primeng/dynamicdialog';
import { filter, finalize, switchMap, take } from 'rxjs';
import { ConfirmDialogComponent } from '../confirm-dialog.component';
import { LoaderComponent } from '../loader.component';
import { ProjectCardCategoriesComponent } from './project-card-categories.component';

@Component({
  selector: 'sw-project-card-extended',
  standalone: true,
  imports: [
    RouterLink,
    AsyncPipe,
    NgIf,
    FileBoxComponent,
    HeaderCardComponent,
    ProjectReactionsComponent,
    ProjectCardCategoriesComponent,
    SVGComponent,
    TagsListComponent,
    UserComponent,
    SaveDirective,
    BreakpointDesktopDirective,
    PermissionActionDirective,
    LoaderComponent,
    TextComponent,
  ],
  providers: [DialogService],
  changeDetection: ChangeDetectionStrategy.OnPush,
  animations: [
    trigger('showInfo', [
      transition(':enter', [
        style({ opacity: 0, height: 0 }),
        animate('200ms', style({ height: '*', opacity: 1 })),
      ]),
      transition(':leave', [
        style({ opacity: 1, height: '*' }),
        animate('200ms', style({ opacity: 0, height: 0 })),
      ]),
    ]),
  ],
  template: `
    <div class="relative z-0">
      <ng-template swBreakpointDesktop>
        <sw-project-reactions
          [gems]="project.gems"
          [projectId]="project.id"
          [likes]="project.likes"
          [isLiked]="project.isLiked"
          class="absolute -left-[70px] top-0 h-full"
        />
      </ng-template>
      <div class="island-base relative z-0 overflow-hidden ">
        <sw-loader [overlay]="true" [loading]="loading()">
          <sw-header-card
            [name]="project.owner.fullName"
            [avatarUrl]="project.owner.photo || undefined"
            [description]="project.owner.rank || undefined"
            [showStar]="project.owner.showStar"
            [menuItems]="menuItems$ | async"
            [createdAt]="project.createdAt"
          >
            <ng-container actions>
              <div class="flex items-center">
                <svg
                  *ngIf="project.isPrivate"
                  width="19"
                  height="20"
                  viewBox="0 0 19 20"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <g opacity="0.5">
                    <path
                      fill-rule="evenodd"
                      clip-rule="evenodd"
                      d="M5.65519 6.125C6.03452 3.64566 7.66869 2.625 9.07373 2.625C10.4788 2.625 12.1129 3.64566 12.4923 6.125H5.65519ZM3.63933 6.125C4.05997 2.61959 6.44444 0.625 9.07373 0.625C11.703 0.625 14.0875 2.61959 14.5081 6.125H16.0737C17.1783 6.125 18.0737 7.02043 18.0737 8.125V17.625C18.0737 18.7296 17.1783 19.625 16.0737 19.625H2.07373C0.969161 19.625 0.0737305 18.7296 0.0737305 17.625V8.125C0.0737305 7.02043 0.969161 6.125 2.07373 6.125H3.63933ZM2.07373 17.625L2.07373 8.125H16.0737V17.625H2.07373Z"
                      fill="#1A1A1A"
                    />
                  </g>
                </svg>
              </div>
            </ng-container>
          </sw-header-card>
          <div
            class="relative  space-y-5 border-t border-black/10 px-5 pb-5 pt-[15px]"
          >
            <h2 class="title">
              {{ project.name }}
            </h2>

            <!-- <sw-tags-list [tags]="tags" class="block" /> -->

            <div class="relative  h-[260px] w-full overflow-hidden rounded-md">
              <img
                [src]="project.photo"
                draggable="false"
                alt
                class="h-full w-full object-cover object-center"
              />

              @if(project.categories.length) {
              <sw-project-card-categories [list]="project.categories" />
              }
            </div>

            <div
              class="space-y-5 overflow-hidden"
              *ngIf="showInfo"
              [@showInfo]="showInfo"
            >
              <div *ngIf="project.about">
                <span class="text-lg font-bold leading-[22px]">О проекте</span>
                <div class="mt-[5px] text-sm font-medium leading-[22px]">
                  <sw-text [text]="project.about" />
                </div>
              </div>
              <div *ngIf="project.documents && project.documents.length">
                <span class="text-lg font-bold leading-[22px]">Документы</span>

                <div class="mt-[10px] flex flex-row flex-wrap gap-[10px]">
                  <button
                    *ngFor="
                      let d of project.documents;
                      trackBy: trackByDocuments
                    "
                    swPermissionAction
                    [swSave]="d"
                    class="max-w-[calc(100vw-2.5rem)] md:max-w-[450px]"
                  >
                    <sw-file-box [fileName]="d" class="cursor-pointer" />
                  </button>
                </div>
              </div>
            </div>

            <button
              *ngIf="showInfoButton"
              (click)="showInfo = !showInfo"
              class="button-base flex w-full flex-row items-center justify-center gap-[10px] rounded-md
            bg-grey p-[10px] text-sm font-medium leading-[17px] text-accent
          "
            >
              <span>{{ showInfoBtnLabel }}</span>
              <span [class.rotate-180]="showInfo" class="transition-transform">
                <sw-svg icon="chevron-down" width="13px" height="8px" />
              </span>
            </button>
          </div>
        </sw-loader>
      </div>
    </div>
  `,
})
export class ProjectCardExtendedComponent extends BaseProjectCard {
  private readonly projectService = inject(ProjectEditor);
  private readonly dialogService = inject(DialogService);
  private readonly navPrevService = inject(NavPrevService);

  protected loading = signal(false);

  get showInfoButton() {
    return this.project.about || this.project.documents.length;
  }

  protected showInfo = true;

  get showInfoBtnLabel() {
    return this.showInfo ? 'Скрыть' : 'О проекте';
  }

  protected override handleOnDelete(): void {
    this.dialogService
      .open(ConfirmDialogComponent, {
        header: 'Удаление проекта',
        data: {
          msg: 'Вы действительно хотите удалить проект?',
          yes: 'Удалить',
        },
      })
      .onClose.pipe(
        take(1),
        filter(Boolean),
        switchMap(() => {
          this.loading.set(true);
          return this.projectService.delete(this.project.id);
        }),
        finalize(() => this.loading.set(false))
      )
      .subscribe({
        next: () => {
          this.navPrevService.back();
        },
      });
  }

  protected override handleOnEdit(): void {
    this.dialogService.open(ProjectEditorComponent, {
      header: 'Редактирование проекта',
      width: 'min(100%, 556px)',
      dismissableMask: true,
      data: {
        project: this.project,
      },
    });
  }

  trackByCategories(_index: number, category: Category): string {
    return category.slug;
  }

  trackByDocuments(index: number, doc: string): string {
    return doc + index;
  }
}
