import {
  HttpContextToken,
  HttpErrorResponse,
  HttpInterceptorFn,
} from '@angular/common/http';
import { inject } from '@angular/core';
import { UserService } from '@core/services';
import { throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';

export const SKIP_ERROR_ALERT_HTTP_TOKEN = new HttpContextToken<boolean>(
  () => false
);

export const ErrorCatchingInterceptor: HttpInterceptorFn = (request, next) => {
  const userService = inject(UserService);

  return request.context.get(SKIP_ERROR_ALERT_HTTP_TOKEN)
    ? next(request)
    : next(request).pipe(
        catchError((error: HttpErrorResponse) => {
          if (error.status === 401) {
            userService.logout();
          }
          return throwError(() => error);
        })
      );
};
