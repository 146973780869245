import { RatingTypeLabels } from '@/core/tokens';
import { RatingType } from '@/core/types';
import { CommonModule } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  inject,
  Input,
} from '@angular/core';

import { LetDirective } from '@shared/directives';
import { ShortNumberPipe } from '@shared/pipes';
import { HorizontalBarComponent } from './horizontal-bar.component';

@Component({
  selector: 'sw-rating',
  standalone: true,
  imports: [
    CommonModule,
    LetDirective,
    HorizontalBarComponent,
    ShortNumberPipe,
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
  template: `
    <div
      class="bg-gradient-to-b from-white p-5 pt-[15px]"
      [ngClass]="{
        'to-green/20': ratingType === 'positive',
        'to-red/20': ratingType === 'negative',

      }"
    >
      <div class="flex items-center justify-between">
        <p class="text-sm font-medium leading-[22px]">
          Рейтинг проекта:
          <span
            [ngClass]="{
              'text-green': ratingType === 'positive',
              'text-red': ratingType === 'negative',
              'text-black/50': ratingType === 'neutral'
            }"
            >{{ labelMap[ratingType] }}</span
          >
        </p>

        <span
          class="shrink-0 text-lg font-bold xs:text-[24px] xs:leading-[22px]"
        >
          {{ rating === null ? '?' : rating }} / 5
        </span>
      </div>

      <sw-horizontal-bar [value]="getRatingValues()" class="mt-[15px] block" />
    </div>
  `,
})
export class RatingComponent {
  @Input()
  rating!: number | null;

  @Input({ required: true })
  ratingType!: RatingType;

  protected readonly labelMap: Record<RatingType, string> =
    inject(RatingTypeLabels);

  getRatingValues(): [number, number] {
    if (this.rating === null || this.rating === 0) {
      return [0, 0];
    }

    const percent = Math.ceil(((this.rating + 5) / 10) * 100);

    return [100 - percent, percent];
  }
}
