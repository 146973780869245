import { isPlatformBrowser } from '@angular/common';
import {
  InjectionToken,
  PLATFORM_ID,
  Provider,
  inject,
  isDevMode,
} from '@angular/core';
import { APP_ORIGIN_URL } from './app-origin-url.token';

export const API_URL = new InjectionToken<string>('The URL of API', {
  factory: () => {
    const origin = inject(APP_ORIGIN_URL);
    return isPlatformBrowser(inject(PLATFORM_ID))
      ? '/api'
      : isDevMode()
      ? 'http://localhost:4200/api'
      : `${origin}/api`;
  },
});

export function provideApiURL(apiUrl: string): Provider {
  return {
    provide: API_URL,
    useValue: apiUrl,
  };
}
