import { RatingType } from '@core/types';

import { isMissed, isPresent } from '@core/helpers';
import { CommentStatistics } from './comment-statistics.model';
import { TUser, User } from './user.model';

export type PostType = 'project' | 'comment';

export type CommentMedia = {
  cover: string;
  image: string;
};

interface TComment {
  id: string;
  comment: string;
  sentiment: -1 | 0 | 1;
  answers: number;
  author: TUser;
  agreement: number | null;
  projectId: string;
  parentId: string;
  topicId?: string | null;
  isDeleted: boolean;
  createdAt: string;
}

export function isCommentMedia(obj: unknown): obj is CommentMedia {
  return (
    isPresent((obj as CommentMedia)?.cover) &&
    isPresent((obj as CommentMedia)?.image)
  );
}

export interface TCommentExtended extends TComment {
  files?: unknown[];

  // media: Media[];
  // attachments?: Doc[];
  comments?: Comment[];
  myAgreement: number | null;
  statistics?: CommentStatistics;
}

export class Comment implements TComment {
  public readonly id!: string;
  public readonly answers!: number;
  public readonly agreement!: number | null;
  public readonly comment!: string;
  public readonly author!: User;
  public readonly createdAt!: string;
  public readonly projectId!: string;
  public readonly topicId?: string | null;
  public readonly parentId!: string;
  public readonly isDeleted!: boolean;
  public readonly sentiment!: 0 | 1 | -1;

  constructor({ author, ...c }: TComment) {
    Object.assign(this, c);
    this.author = new User(author);
  }

  get ratingType(): RatingType {
    return isMissed(this.sentiment) || this.sentiment === 0
      ? 'neutral'
      : this.sentiment > 0
      ? 'positive'
      : 'negative';
  }

  get absoluteAgreement(): number | null {
    return isPresent(this.agreement) ? Math.abs(this.agreement) : null;
  }
}

//TODO Refactoring
export class CommentExtended
  extends Comment
  implements Omit<TCommentExtended, 'files'>
{
  public readonly media: CommentMedia[];
  // public readonly files: string[];
  // public readonly attachments: string[];
  public readonly comments?: Comment[];
  public readonly myAgreement: number | null;

  private _statistics?: CommentStatistics;

  get statistics() {
    return this._statistics;
  }

  constructor({
    comments,
    statistics,
    ...c
  }: TCommentExtended & { files?: unknown[]; media?: unknown[] }) {
    super(c);
    if (comments) {
      this.comments = comments.map((comment) => new Comment(comment));
    }

    // this.files = c.files;
    // this.media = c.files.filter((f) => isImageExtension(extractFileName(f)));
    // this.attachments = c.files.filter(
    //   (f) => !isImageExtension(extractFileName(f))
    // );

    this.media = [...(c.files || [])].filter(isCommentMedia);

    this.myAgreement = c.myAgreement;

    if (statistics) {
      this.setStatistics(statistics);
    }
  }

  setStatistics(statistics: CommentStatistics) {
    this._statistics = statistics;
  }

  getCommentShort() {
    return new Comment(this);
  }
}

export interface CreateCommentDTO {
  comment: string;
  agreement: number;
}

export interface UpdateCommentDTO extends CreateCommentDTO {
  id: string;
  savedFiles: string[];
}

export interface CommentDTO<T> {
  files?: File[];
  data: T;
}

type CommentParent = { projectId: string; topicId?: string } & (
  | {
      type: 'project';
    }
  | { type: 'comment'; parentId: string }
);

export type CreateCommentPayload = {
  parent: CommentParent;
  payload: CommentDTO<CreateCommentDTO>;
};

export type UpdateCommentPayload = {
  commentId: string;
  payload: CommentDTO<UpdateCommentDTO>;
};
