import { UserService } from '@/core';
import { Product } from '@/core/models';
import { MarketService } from '@/core/services/market.service';

import { ForDirective, LetDirective } from '@/shared/directives';
import { AsyncPipe, NgFor, NgIf } from '@angular/common';
import { ChangeDetectionStrategy, Component, inject } from '@angular/core';
import { DialogService } from 'primeng/dynamicdialog';
import { SkeletonModule } from 'primeng/skeleton';
import { MarketItemComponent } from './ui/market-item.component';

@Component({
  selector: 'sw-market',
  standalone: true,
  imports: [
    MarketItemComponent,
    NgFor,
    NgIf,
    ForDirective,
    LetDirective,
    AsyncPipe,
    SkeletonModule,
  ],
  providers: [MarketService, DialogService],
  changeDetection: ChangeDetectionStrategy.OnPush,
  template: `
    <div class="space-y-[30px]">
      <h2 class="info-title">Обмен гемов</h2>

      <div class="space-y-2" *swLet="(gems$ | async) ?? 0 as gems">
        <div
          *ngFor="let item of list$ | async; empty: emptyMsg; trackBy: trackBy"
        >
          <sw-market-item
            [goodId]="item.product.id"
            [name]="item.product.name"
            [price]="item.priceWithDiscount"
            [isPurchased]="item.isPurchased"
            [productType]="item.productType"
            [purchases]="item.purchases"
            [canPurchase]="item.priceWithDiscount < gems"
          />
        </div>

        <ng-container *ngIf="loading$ | async">
          <div *ngFor="let item of [1, 2, 3]">
            <p-skeleton width="100%" borderRadius="10px" height="58px" />
          </div>
        </ng-container>

        <ng-template #emptyMsg>
          <p class="text-center text-sm leading-[18px] text-black/50">
            В данном разделе пока пусто. <br />Мы работаем над обновлениями и
            скоро здесь появятся новые товары.
          </p>
        </ng-template>
      </div>
    </div>
  `,
})
export class MarketComponent {
  protected readonly gems$ = inject(UserService).userGems$;
  private readonly marketService = inject(MarketService);

  protected readonly list$ = this.marketService.fetchList();
  protected readonly loading$ = this.marketService.loading$;

  trackBy(_index: number, item: Product): string {
    return item.product.id;
  }
}
