import { CategoriesService, UserService } from '@/core';
import { Directive, inject } from '@angular/core';
import { Observable, tap } from 'rxjs';

import { GemContext } from './gem-context';
import { GEM_CONTEXT } from './gem-context.token';

@Directive({
  selector: '[swGemsToCategory]',
  standalone: true,
  providers: [
    {
      provide: GEM_CONTEXT,
      useExisting: SendGemsToCategoryDirective,
    },
  ],
})
export class SendGemsToCategoryDirective extends GemContext {
  private readonly userService = inject(UserService);
  private readonly categoriesService = inject(CategoriesService);

  override sendGems(count: number): Observable<unknown> {
    return this.gemService.addGemsToCategory(this.recipient, count).pipe(
      tap({
        next: ({ slug }) => {
          this.categoriesService.addGemsToCategory({ slug, count });
          this.userService.writeOffGems(count);
        },
      })
    );
  }
}
