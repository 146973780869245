import { DOCUMENT } from '@angular/common';
import { Directive, ElementRef, Inject, Output } from '@angular/core';
import { Observable, fromEvent, filter, take, merge, switchMap } from 'rxjs';

@Directive({ selector: '[swClickOutside]', standalone: true })
export class ClickOutsideDirective {
  @Output()
  swClickOutside: Observable<unknown>;

  constructor(
    @Inject(ElementRef) { nativeElement }: ElementRef<HTMLElement>,
    @Inject(DOCUMENT) documentRef: Document
  ) {
    const esc$: Observable<unknown> = fromEvent<KeyboardEvent>(
      documentRef,
      'keydown'
    ).pipe(filter(({ key }) => key === 'Escape'));

    const clickOutside$ = fromEvent<MouseEvent>(documentRef, 'mousedown').pipe(
      filter(
        ({ target }) =>
          target instanceof Element && !nativeElement.contains(target)
      ),
      switchMap(() =>
        fromEvent<MouseEvent>(documentRef, 'mouseup').pipe(
          take(1),
          filter(
            ({ target }) =>
              target instanceof Element && !nativeElement.contains(target)
          )
        )
      )
    );

    this.swClickOutside = merge(clickOutside$, esc$);
  }
}
