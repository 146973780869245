import { InjectionToken, isDevMode } from '@angular/core';
import { environment } from 'src/environments/environment';

export const APP_ORIGIN_URL = new InjectionToken<string>(
  'The URL of Application',
  {
    factory: () =>
      isDevMode() ? 'http://localhost:4200' : environment.APP_URL,
  }
);
