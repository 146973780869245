import { NgIf } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  Input,
  ViewEncapsulation,
} from '@angular/core';
import { MenuItem } from 'primeng/api';
import { MenuModule } from 'primeng/menu';

import { UserComponent } from './user.component';

@Component({
  selector: 'sw-header-card',
  standalone: true,
  imports: [NgIf, UserComponent, MenuModule],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
  template: `
    <div
      class="flex flex-row items-center justify-between px-5 pb-[14px] pt-[18px]"
    >
      <div>
        <sw-user
          [name]="name"
          [avatarUrl]="avatarUrl"
          [description]="description"
          [showStar]="showStar"
          [timeAgo]="createdAt"
        />
      </div>

      <div class="project-header-actions flex flex-row space-x-[15px]">
        <ng-content select="[actions]" />
        <button
          *ngIf="menuItems"
          (click)="menu?.toggle($event)"
          class="button-rounded h-[30px] w-[30px] bg-black/5"
        >
          <svg
            width="3"
            height="14"
            viewBox="0 0 3 14"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <g opacity="0.5">
              <circle cx="1.5" cy="2" r="1.5" fill="#1A1A1A" />
              <circle cx="1.5" cy="7" r="1.5" fill="#1A1A1A" />
              <circle cx="1.5" cy="12" r="1.5" fill="#1A1A1A" />
            </g>
          </svg>
        </button>
      </div>
    </div>

    <p-menu
      #menu
      [model]="menuItems || []"
      appendTo="body"
      [popup]="true"
      styleClass="header-card-menu"
      [autoZIndex]="false"
    ></p-menu>
  `,

  styles: [
    `
      .header-card-menu.p-menu {
        @apply z-[1] w-[172px] border-y-[10px]  border-solid border-transparent bg-transparent shadow-none min-[734px]:-ml-[142px];

        .p-menu-list {
          @apply w-full overflow-hidden rounded-md bg-white py-[5px] shadow-[0px_2px_8px_rgba(0,0,0,0.15)];
        }
        .p-menuitem-link {
          @apply justify-end px-5 py-[5px];

          .p-menuitem-text {
            @apply text-xs leading-[15px] text-black;
          }
        }
      }
    `,
  ],
})
export class HeaderCardComponent {
  @Input({ required: true }) name!: string;
  @Input() description?: string;
  @Input() avatarUrl?: string;

  @Input() showStar?: boolean;
  @Input() createdAt?: Date | number | string;

  @Input() menuItems: MenuItem[] | null = null;
}
