import { validateAllFormFields } from '@/core/helpers';
import { Directive, EventEmitter, Input, Output } from '@angular/core';
import { FormGroup, FormControl } from '@angular/forms';

@Directive()
export abstract class TopicEditor {
  @Input()
  creating = true;

  @Input()
  canDelete = false;

  @Input({ required: true })
  formGroup!: FormGroup<{
    comment: FormControl<string>;
    name: FormControl<string>;
    agreement: FormControl<number | null>;
  }>;

  @Output()
  save = new EventEmitter<void>();

  @Output()
  delete = new EventEmitter<void>();

  protected handleAddMore() {
    validateAllFormFields(this.formGroup);
    if (this.formGroup.invalid) {
      return;
    }

    this.save.emit();
  }

  abstract get buttonLabel(): string;
}
