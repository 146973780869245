import { ShortNumberPipe } from '@/shared/pipes';
import { SVGComponent } from '@/shared/ui/svg.component';
import { DecimalPipe, NgClass, NgIf } from '@angular/common';
import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

@Component({
  selector: 'sw-project-topic-block',
  standalone: true,
  imports: [SVGComponent, NgClass, NgIf, ShortNumberPipe, DecimalPipe],
  changeDetection: ChangeDetectionStrategy.OnPush,
  template: `
    <div
      class="island-base highlight flex min-h-[101px] w-full min-w-[230px]  flex-1 select-none flex-col space-y-[10px]  whitespace-pre-line border-l-[12px] px-4 py-5 md:max-w-none md:whitespace-normal"
      [ngClass]="{
        'border-l-[#C6E8D4]': appearance === 'positive',
        'border-l-[#FCD6D6]': appearance === 'negative',
        'border-l-black/10': appearance === 'neutral',
        'justify-center': hideInfo,
        'justify-between': !hideInfo
      }"
    >
      <span class="font-bold leading-6 md:text-lg ">{{ label }} </span>
      <div class="flex flex-row items-center justify-between" *ngIf="!hideInfo">
        <div
          class="flex w-max flex-row items-center space-x-[6px] rounded-[4px] bg-black/10 px-2 py-1 text-black/70"
        >
          <sw-svg
            icon="warn"
            width="12px"
            height="12px"
            class="shrink-0 text-[12px]"
          />
          <span class="text-[12px] font-bold leading-[17px]"
            >{{ (rating | number : '1.0-2' : 'en') || '?' }} / 5</span
          >
        </div>
        <span class="flex flex-row items-center space-x-[6px]">
          <svg
            width="13px"
            height="12px"
            viewBox="0 0 13 12"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <mask id="path-1-inside-1_156_5454" fill="white">
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M11.1263 7.76072C12.2818 6.9397 12.9999 5.7897 12.9999 4.5167C12.9999 2.02219 10.2424 0 6.84077 0C3.43918 0 0.681641 2.02219 0.681641 4.5167C0.681641 7.0112 3.43918 9.03339 6.84077 9.03339C7.05854 9.03339 7.27368 9.0251 7.48562 9.00893L10.8152 12L11.1263 7.76072Z"
              />
            </mask>
            <path
              d="M11.1263 7.76072L9.96787 6.1304L9.2006 6.67561L9.13171 7.61433L11.1263 7.76072ZM7.48562 9.00893L8.82217 7.52111L8.18607 6.94968L7.33347 7.01473L7.48562 9.00893ZM10.8152 12L9.47867 13.4878L12.5115 16.2123L12.8099 12.1464L10.8152 12ZM10.9999 4.5167C10.9999 4.98214 10.7408 5.58118 9.96787 6.1304L12.2848 9.39104C13.8227 8.29823 14.9999 6.59727 14.9999 4.5167H10.9999ZM6.84077 2C8.16102 2 9.27613 2.3952 10.0132 2.93572C10.7515 3.47711 10.9999 4.05966 10.9999 4.5167H14.9999C14.9999 2.47923 13.8696 0.803434 12.3787 -0.289901C10.8866 -1.3841 8.92211 -2 6.84077 -2V2ZM2.68164 4.5167C2.68164 4.05966 2.93008 3.47711 3.66834 2.93572C4.40541 2.3952 5.52052 2 6.84077 2V-2C4.75943 -2 2.79498 -1.3841 1.30288 -0.289901C-0.18803 0.803434 -1.31836 2.47923 -1.31836 4.5167H2.68164ZM6.84077 7.03339C5.52052 7.03339 4.40541 6.63819 3.66834 6.09767C2.93008 5.55628 2.68164 4.97373 2.68164 4.5167H-1.31836C-1.31836 6.55416 -0.18803 8.22996 1.30288 9.32329C2.79498 10.4175 4.75943 11.0334 6.84077 11.0334V7.03339ZM7.33347 7.01473C7.17219 7.02703 7.00781 7.03339 6.84077 7.03339V11.0334C7.10928 11.0334 7.37516 11.0232 7.63776 11.0031L7.33347 7.01473ZM12.1518 10.5122L8.82217 7.52111L6.14906 10.4968L9.47867 13.4878L12.1518 10.5122ZM9.13171 7.61433L8.82059 11.8536L12.8099 12.1464L13.121 7.9071L9.13171 7.61433Z"
              fill="#1A1A1A"
              fill-opacity="0.7"
              mask="url(#path-1-inside-1_156_5454)"
            />
          </svg>

          <span class="text-[14px] font-bold ">{{
            comments || 0 | swShortNumber
          }}</span>
        </span>
      </div>
    </div>
  `,

  styles: [
    `
      :host {
        @apply flex h-auto h-full md:block;
        &.active {
          .highlight {
            @apply shadow-[0_0_0_2px_#6284FF];
          }
        }
      }
    `,
  ],
})
export class ProjectTopicBlockComponent {
  @Input()
  appearance: 'neutral' | 'positive' | 'negative' = 'neutral';

  @Input({ required: true })
  label!: string;

  @Input({ required: true })
  rating: number | null = null;

  @Input({ required: true })
  comments: number | null = null;

  @Input()
  hideInfo = false;
}
