import { FormSchema } from '@/core/types';
import { NgIf, NgTemplateOutlet } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  TemplateRef,
  inject,
} from '@angular/core';
import { FormGroup, ReactiveFormsModule } from '@angular/forms';
import { FormBuilderService } from './form-builder.service';
import { FormGroupComponent } from './ui/form-group.component';

@Component({
  selector: 'sw-form-builder',
  standalone: true,
  imports: [NgIf, FormGroupComponent, ReactiveFormsModule, NgTemplateOutlet],
  providers: [FormBuilderService],
  changeDetection: ChangeDetectionStrategy.OnPush,
  template: `
    <form
      *ngIf="form"
      [formGroup]="form"
      (ngSubmit)="handleSubmit()"
      class="space-y-6"
    >
      <div>
        <sw-form-group [form]="form" [schema]="schema" />
      </div>

      <div class="w-full" *ngIf="buttons">
        <ng-container [ngTemplateOutlet]="buttons" />
      </div>
    </form>
  `,
})
export class FormBuilderComponent implements OnInit {
  @Input({ required: true }) schema!: FormSchema;
  @Input({ required: true }) buttons!: TemplateRef<unknown>;

  @Output()
  formSubmit = new EventEmitter<FormGroup>();

  private readonly formBuilder = inject(FormBuilderService);

  protected form?: FormGroup;

  ngOnInit(): void {
    this.form = this.formBuilder.build(this.schema);
  }

  handleSubmit() {
    this.formSubmit.emit(this.form);
  }
}
