import { ProjectService } from '@/core';
import { CommentsService } from '@/core/services/comments.service';
import { LikeService } from '@/core/services/likes.service';
import { Directive, inject, Input } from '@angular/core';
import { tap } from 'rxjs';

@Directive({ selector: '[swLikeProject]', standalone: true })
export class LikeProjectDirective {
  @Input({ required: true })
  recipient!: string;

  @Input({ required: true })
  isLiked!: boolean;

  private readonly likeService = inject(LikeService);
  private readonly projectService = inject(ProjectService, { optional: true });
  private readonly commentsService = inject(CommentsService, {
    optional: true,
  });

  loading$ = this.likeService.loading$;

  toggleLike() {
    return this.likeService.toggleLike(this.recipient).pipe(
      tap({
        next: ({ projectId, count: c }) => {
          if (this.projectService) {
            this.projectService.updateProjectLikes(projectId, c, !this.isLiked);
          }

          if (this.commentsService) {
            this.commentsService.updateProjectLikes(
              projectId,
              c,
              !this.isLiked
            );
          }
        },
      })
    );
  }
}
