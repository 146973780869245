import { LetDirective } from '@/shared/directives';
import { BgLinesComponent } from '@/shared/ui';
import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, inject } from '@angular/core';
import { toSignal } from '@angular/core/rxjs-interop';
import { NavigationEnd, Router, RouterModule } from '@angular/router';
import { filter, map, startWith } from 'rxjs';

@Component({
  selector: 'sw-auth-layout',
  standalone: true,
  imports: [RouterModule, CommonModule, LetDirective, BgLinesComponent],
  changeDetection: ChangeDetectionStrategy.OnPush,
  template: `
    <sw-bg-lines />

    <div
      class="fixed left-0 top-1/2 hidden w-full max-w-[45%] -translate-y-1/2 lg:block"
    >
      <img
        src="./assets/img/auth-bg.png"
        alt
        class="object-cover object-center"
      />
    </div>

    <div
      class="bg-auth z-10 m-auto flex h-full min-h-[594px] w-full max-w-[849px] shrink-0 flex-row justify-center lg:justify-between"
    >
      <main
        class="flex w-full flex-col justify-between px-4 lg:ml-auto lg:max-w-[353px] lg:p-0 "
      >
        <a routerLink="/">
          <h1
            class="text-center text-[32px] font-bold leading-[39px] text-accent"
          >
            Единое Окно
          </h1>
        </a>
        <div class="island-base my-4 p-[10px]" *swLet="tabs() as activeTab">
          <div
            class="after:top-5px relative flex flex-1 space-x-[5px] overflow-hidden rounded-md
          bg-[#F3F3F3] p-[5px] text-accent/50 after:absolute after:z-0 after:h-[calc(100%-10px)] after:w-[calc(50%-5px)] after:rounded-[5px] after:bg-white after:transition-all after:duration-200 after:content-['']"
            [ngClass]="{
              'after:left-[5px]': activeTab === 'login',
              'after:left-1/2': activeTab === 'signup',
              'after:-left-1/2': activeTab === null
            }"
          >
            <a
              class="z-10 flex-1 text-center "
              routerLink="/login"
              routerLinkActive="text-accent"
              >Вход</a
            >
            <a
              class="z-10 flex-1 text-center"
              routerLink="/signup"
              routerLinkActive="text-accent"
              >Регистрация</a
            >
          </div>
          <div class="mt-5">
            <router-outlet />
          </div>
        </div>

        <p class="text-center text-sm leading-[17px] text-black/50">
          Единое Окно / Все права защищены / {{ today }}
        </p>
      </main>
    </div>
  `,
  styles: [
    `
      :host {
        @apply relative flex min-h-screen py-[80px];
      }
    `,
  ],
})
export class AuthLayoutComponent {
  private readonly router = inject(Router);

  protected readonly today = new Date().getFullYear();

  protected readonly tabs = toSignal(
    this.router.events.pipe(
      startWith(0),
      filter((event) => event instanceof NavigationEnd),
      map(() => {
        return this.router.url.includes('/login')
          ? 'login'
          : this.router.url.includes('/signup')
          ? 'signup'
          : null;
      })
    )
  );
}
