import { ProjectTopicDialogComponent } from '@/features/project-topic/project-topic-dialog.component';
import { PermissionActionDirective } from '@/shared/directives/permission/permission-action.directive';
import {
  ChangeDetectionStrategy,
  Component,
  Input,
  inject,
} from '@angular/core';
import { DialogService } from 'primeng/dynamicdialog';

@Component({
  selector: 'sw-create-topic-button',
  standalone: true,
  imports: [PermissionActionDirective],
  providers: [DialogService],
  changeDetection: ChangeDetectionStrategy.OnPush,
  template: `
    <button
      class="button button-primary mr-auto box-border w-full items-center justify-center px-5 py-2 md:h-[60px] md:w-full md:py-[10px]"
      type="button"
      swPermissionAction
      (click)="addTopic()"
    >
      <span class=" text-base font-bold leading-[20px] text-white"
        >Добавить тему
      </span>
    </button>
  `,
})
export class CreateTopicButtonComponent {
  @Input({ required: true }) projectId = '';

  private readonly dialogService = inject(DialogService);

  protected addTopic() {
    if (this.projectId === '') {
      return;
    }

    this.dialogService.open(ProjectTopicDialogComponent, {
      header: 'Добавление темы',
      width: 'min(100%, 556px)',
      dismissableMask: true,
      data: {
        projectId: this.projectId,
      },
    });
  }
}
