import { InjectionToken, Provider } from '@angular/core';
import { Observable } from 'rxjs';

export abstract class TrackSeenAPI {
  abstract setSeen(ids: string[]): Observable<unknown>;
  abstract setSeen(ids: string[], parentId: string): Observable<unknown>;
  abstract setSeen(ids: string[], parentId?: string): Observable<unknown>;
}

export const TRACK_SEEN_API_SERVICE = new InjectionToken<TrackSeenAPI>(
  'Track Seen Api Service'
);

export function provideTrackSeen(useClass: new () => TrackSeenAPI): Provider {
  return {
    provide: TRACK_SEEN_API_SERVICE,
    useClass,
  };
}
