import { HttpContext } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { MAP_REQ_HTTP_TOKEN } from '@core/interceptors';
import {
  CommentDTO,
  CommentExtended,
  CreateCommentDTO,
  CreateCommentPayload,
  Project,
  TCommentExtended,
  Thread,
  TUser,
  UpdateCommentPayload,
} from '@core/models';
import { CommentsKey, CommentsListQueries } from '@core/types';
import {
  BehaviorSubject,
  combineLatest,
  map,
  Observable,
  of,
  switchMap,
  take,
  tap,
} from 'rxjs';
import * as snakecaseKeys from 'snakecase-keys';

import { isPresent } from '@core/helpers';
import { withCache } from '@ngneat/cashew';
import {
  CommentStatistics,
  TCommentStatistics,
} from '../models/comment-statistics.model';
import { BaseAPIService } from './base-api.service';
import { ProjectAPIService } from './project.service';
import { UserService } from './user.service';

@Injectable({ providedIn: 'root' })
export class CommentsAPIService extends BaseAPIService {
  private readonly projectAPIService = inject(ProjectAPIService);

  fetchThread({ projectId, parentId, topicId }: CommentsListQueries) {
    const params = {
      ...(isPresent(parentId)
        ? {
            parent_id: parentId,
          }
        : null),
      ...(isPresent(topicId)
        ? {
            topic_id: topicId,
          }
        : null),
    };

    return this.httpClient.get<Thread>(`/discussions/comments/${projectId}`, {
      params,
      context: withCache({
        ttl: 1000,
      }),
    });
  }

  create({ parent, payload }: CreateCommentPayload) {
    this._loading$.next(true);

    const formData = this.createFormData(payload);

    const context = new HttpContext();
    context.set(MAP_REQ_HTTP_TOKEN, false);

    const params = {
      ...(parent.type === 'comment' && { parent_id: parent.parentId }),
      ...(parent.topicId && { topic_id: parent.topicId }),
      // t: Date.now(),
    };

    return this.httpClient
      .post<CommentExtended>(
        `/discussions/comment/${parent.projectId}`,
        formData,
        {
          context,
          params,
        }
      )
      .pipe(
        tap({
          next: () => {
            this.projectAPIService.invalidateProjects();
          },
          finalize: () => this._loading$.next(false),
        })
      );
  }

  update({ commentId, payload }: UpdateCommentPayload) {
    this._loading$.next(true);

    const formData = this.createFormData(payload);

    const context = new HttpContext();
    context.set(MAP_REQ_HTTP_TOKEN, false);

    return this.httpClient
      .put<CommentExtended>(`/discussions/comment/${commentId}`, formData, {
        context,
      })
      .pipe(
        tap({
          next: () => {
            this.projectAPIService.invalidateProjects();
          },
          finalize: () => this._loading$.next(false),
        })
      );
  }

  delete(commentId: string) {
    this._loading$.next(true);

    return this.httpClient
      .delete<unknown>(`/discussions/comment/${commentId}`)
      .pipe(
        tap({
          next: () => {
            this.projectAPIService.invalidateProjects();
          },
          finalize: () => this._loading$.next(false),
        })
      );
  }

  setAgreement(commentId: string, value: number | null) {
    return this.httpClient
      .post<{
        agreement: number;
        author: TUser;
        projectId: string;
        parentId: string;
      }>(`/discussions/agreement/${commentId}`, {
        agreement: value,
      })
      .pipe(
        tap({
          next: () => {
            this.projectAPIService.invalidateProjects();
          },
        })
      );
  }

  fetchStatics({ projectId, parentId }: CommentsKey) {
    const params = isPresent(parentId)
      ? {
          parent_id: parentId,
        }
      : undefined;

    return this.httpClient.get<TCommentStatistics[]>(
      `/discussions/statistics/${projectId}`,
      {
        params,
        context: withCache({
          ttl: 10000,
        }),
      }
    );
  }

  setSeen(projectId: string, commentIds: string[]) {
    this._loading$.next(true);
    return this.httpClient
      .post(`/discussions/views/${projectId}`, { commentIds })
      .pipe(
        tap({
          finalize: () => this._loading$.next(false),
        })
      );
  }

  private createFormData(payload: CommentDTO<CreateCommentDTO>) {
    const fd = new FormData();

    payload.files?.forEach((f) => {
      fd.append('files', f);
    });

    fd.append(
      'data',
      JSON.stringify(
        snakecaseKeys(payload.data as unknown as Record<string, unknown>, {
          deep: true,
        })
      )
    );
    return fd;
  }
}

@Injectable()
export class CommentsService {
  private readonly api = inject(CommentsAPIService);
  private readonly isSuperuser$ = inject(UserService).isSuperuser$;

  readonly loading$ = this.api.loading$;

  private readonly _thread$ = new BehaviorSubject<Thread | null>(null);

  get thread$(): Observable<Thread | null> {
    return this._thread$.asObservable();
  }

  create(payload: CreateCommentPayload) {
    return this.api.create(payload).pipe(
      tap((c) => {
        const t = this._thread$.value;
        const parent = payload.parent;
        const newComment = new CommentExtended({
          ...c,
          answers: 0,
          createdAt: c.createdAt ?? new Date(),
          comments: [],
        });
        if (!t) {
          return;
        }
        const { comments, ...thread } = t;

        const map = (comments: CommentExtended[]) => {
          return comments.map((comment) => {
            if (comment.id === c.parentId) {
              const { answers, ...cmt } = comment;
              return new CommentExtended({
                ...cmt,
                answers: answers + 1,
                comments: [
                  newComment.getCommentShort(),
                  ...(comment.comments || []),
                ].slice(0, 3),
              });
            }
            return comment;
          });
        };

        if (parent.type === 'project') {
          this._thread$.next({
            ...thread,
            comments: [newComment, ...comments],
          });
        } else if (thread.parents.some((p) => p.id === c.parentId)) {
          const isLastParent =
            thread.parents[thread.parents.length - 1]?.id === c.parentId;

          this._thread$.next({
            project: thread.project,
            parents: map(thread.parents),
            comments: isLastParent ? [newComment, ...comments] : comments,
          });
          return;
        } else {
          this._thread$.next({
            ...thread,
            comments: map(comments),
          });
        }
      })
    );
  }

  update(payload: UpdateCommentPayload) {
    return this.api
      .update(payload)
      .pipe(tap((c) => this.updateCommentInThread(payload.commentId, c)));
  }

  delete(commentId: string) {
    return this.api.delete(commentId).pipe(
      tap(() => {
        this.updateCommentInThread(commentId, {
          agreement: null,
          comment: 'Комментарий удален',
          isDeleted: true,
        });
      })
    );
  }

  setAgreement(commentId: string, value: number | null) {
    this.updateCommentInThread(commentId, { myAgreement: value });

    return this.api.setAgreement(commentId, value);
  }

  fetchThread(payload: CommentsListQueries) {
    const thread$ = this.api.fetchThread(payload).pipe(
      map((t) => {
        return {
          project: new Project(t.project),
          parents: t.parents.map((c) => new CommentExtended(c)),
          comments: t.comments.map((c) => new CommentExtended(c)),
        };
      })
    );

    const callStat$ = this.isSuperuser$.pipe(
      take(1),
      switchMap((isSuperuser) => {
        return isSuperuser
          ? this.api.fetchStatics({
              parentId: payload.parentId,
              projectId: payload.projectId,
            })
          : of([]);
      }),
      map((stat) => {
        const statMap: Record<string, TCommentStatistics> = {};
        stat.forEach((s) => {
          statMap[s.commentId] = s;
        });
        return statMap;
      })
    );

    return combineLatest([thread$, callStat$]).pipe(
      map(([thread, stat]) => {
        return {
          ...thread,
          comments: thread.comments.map((c) => {
            const foundStat = stat[c.id];
            return foundStat
              ? new CommentExtended({
                  ...c,
                  statistics: new CommentStatistics(foundStat),
                })
              : new CommentExtended(c);
          }),
        };
      }),
      tap((thread) => {
        this._thread$.next(thread);
      })
    );
  }

  updateProjectGems(projectId: string, gems: number): void {
    const v = this._thread$.value;

    if (!(v && v.project.id === projectId)) {
      return;
    }

    this._thread$.next({
      ...v,
      project: new Project({
        ...v.project,
        gems,
      }),
    });
  }

  updateProjectLikes(projectId: string, likes: number, isLiked: boolean): void {
    const v = this._thread$.value;

    if (!(v && v.project.id === projectId)) {
      return;
    }

    this._thread$.next({
      ...v,
      project: new Project({
        ...v.project,
        likes,
        isLiked,
      }),
    });
  }

  updateProjectInThread(project: Partial<Project>) {
    const t = this._thread$.value;
    if (!t) {
      return;
    }

    this._thread$.next({
      ...t,
      project: new Project({
        ...t.project,
        ...project,
      }),
    });
  }

  updateCommentInThread(commentId: string, value: Partial<TCommentExtended>) {
    const t = this._thread$.value;
    if (!t) {
      return;
    }

    const map = (comments: CommentExtended[]) => {
      return comments.map((tc) =>
        tc.id === commentId ? new CommentExtended({ ...tc, ...value }) : tc
      );
    };

    const thread: Thread =
      t.parents.at(-1)?.id === commentId
        ? {
            ...t,
            parents: map(t.parents),
          }
        : {
            ...t,
            comments: map(t.comments),
          };

    this._thread$.next(thread);
  }

  public reset() {
    this._thread$.next(null);
  }
}
