export interface TCommentStatistics {
  commentId: string;
  views: number;
  likes: number;
  superLikes: number;
  dislikes: number;
}

export class CommentStatistics
  implements Omit<TCommentStatistics, 'commentId'>
{
  public views: number;
  public likes: number;
  public superLikes: number;
  public dislikes: number;

  get likesConversion() {
    if (this.views === 0) {
      return 0;
    }
    return this.likes / this.views;
  }
  get superLikesConversion() {
    if (this.views === 0) {
      return 0;
    }
    return this.superLikes / this.views;
  }
  get dislikesConversion() {
    if (this.views === 0) {
      return 0;
    }
    return this.dislikes / this.views;
  }

  constructor({
    views = 0,
    likes = 0,
    superLikes = 0,
    dislikes = 0,
  }: Partial<Omit<TCommentStatistics, 'commentId'>> = {}) {
    this.views = views;
    this.likes = likes;
    this.superLikes = superLikes;
    this.dislikes = dislikes;
  }
}
