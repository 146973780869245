import { ImageInput } from '@/core';
import { ChangeDetectionStrategy, Component } from '@angular/core';

@Component({
  selector: 'sw-comment-editor-image-input',
  standalone: true,
  imports: [],
  changeDetection: ChangeDetectionStrategy.OnPush,
  template: `
    <button
      (click)="fileInput.click()"
      type="button"
      aria-label="Прикрепить изображение"
      class="button-rounded aspect-square h-[37px] w-[37px] bg-black/10"
    >
      <span
        ><svg
          width="19"
          height="18"
          viewBox="0 0 19 18"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M15.5 2H3.5C2.94772 2 2.5 2.44772 2.5 3V11.0858L3.79289 9.79289L4.5 9.08579L5.20711 9.79289L8 12.5858L16.2929 4.29289C16.3562 4.22955 16.426 4.17648 16.5 4.13369V3C16.5 2.44772 16.0523 2 15.5 2ZM0.5 15V13.5002V13.4998V3C0.5 1.34315 1.84315 0 3.5 0H15.5C17.1569 0 18.5 1.34315 18.5 3V15C18.5 16.6569 17.1569 18 15.5 18H3.5C1.84315 18 0.5 16.6569 0.5 15ZM7.29289 14.7071L4.5 11.9142L2.5 13.9142V15C2.5 15.5523 2.94772 16 3.5 16H15.5C16.0523 16 16.5 15.5523 16.5 15V6.91421L8.70711 14.7071L8 15.4142L7.29289 14.7071ZM8.5 6.5C8.5 6.77614 8.27614 7 8 7C7.72386 7 7.5 6.77614 7.5 6.5C7.5 6.22386 7.72386 6 8 6C8.27614 6 8.5 6.22386 8.5 6.5ZM10.5 6.5C10.5 7.88071 9.38071 9 8 9C6.61929 9 5.5 7.88071 5.5 6.5C5.5 5.11929 6.61929 4 8 4C9.38071 4 10.5 5.11929 10.5 6.5Z"
            fill="#1A1A1A"
          />
        </svg>
      </span>
    </button>
    <input
      #fileInput
      type="file"
      class="absolute -z-10 h-0 w-0 appearance-none overflow-hidden"
      accept="image/jpeg, image/png, image/webp"
      tabindex="-1"
      [multiple]="true"
      (change)="handleChange($event)"
    />
  `,
})
export class CommentEditorImageInputComponent extends ImageInput<File[]> {
  protected override handleChange(event: Event): void {
    const element = event.currentTarget as HTMLInputElement;
    const fileList: FileList | null = element.files;

    if (fileList) {
      this.fileChanged.emit(Array.from(fileList));
    }
    element.value = '';
  }
}
