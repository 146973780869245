import { isMissed } from '@core/helpers';
import { RatingType } from '@core/types';
import { Category } from '@core/types/category.type';
import { Comment } from './comment.model';
import { User } from './user.model';

export interface IProject {
  id: string;
  name: string;
  about: string;
  isPrivate: boolean;
  isVerified: boolean;
  categories: Category[];
  tags?: string[];
  photo: string;
  owner: Pick<User, 'id' | 'name' | 'surname' | 'photo' | 'rank'>;
  documents?: string[];
  createdAt: string;
  rating: number | null;
  gems: number;
  likes: number;
  isLiked: boolean;

  emails?: string[];
  comments?: {
    count: number;
    comments: Comment[];
  } | null;
}

export class Project {
  /**
   * id - id проекта
   */
  public id!: string;
  /**
   * name - название проекта
   */
  public name!: string;
  /**
   * about - о проекте
   */
  public about!: string;

  /**
   * isVerified - флаг подтвержденный проект
   */
  public isVerified!: boolean;

  /**
   * isPrivate - флаг приватности
   */
  public isPrivate!: boolean;
  /**
   * categories - Список отраслей проекта
   */
  public categories!: Category[];
  /**
   * tags - список тегов проекта
   */
  public tags?: string[];
  /**
   * photo - миниатюра проекта
   */
  public photo!: string;
  /**
   * documents - список прикрепленных файлов
   */
  public documents!: string[];
  /**
   * ownerId - id создателя проекта
   */
  public owner!: User;
  /**
   * createdAt - дата создания проекта
   */
  public createdAt!: string;

  /**
   * email адреса пользователей, кому доступен пост
   */
  public emails?: string[];

  /**
   * Несколько комментариев первого уровня
   */
  public comments?: {
    count: number;
    comments: Comment[];
  } | null;

  public rating: number | null = null;

  public gems!: number;

  public likes!: number;

  public isLiked!: boolean;

  constructor(p: IProject) {
    const { owner, comments, ...project } = p;
    Object.assign(this, project);
    this.owner = new User(owner);

    if (comments) {
      this.comments = {
        count: comments.count,
        comments: comments.comments.map((c) => new Comment(c)),
      };
    }
  }

  get ratingType(): RatingType {
    return isMissed(this.rating) || this.rating === 0
      ? 'neutral'
      : this.rating > 0
      ? 'positive'
      : 'negative';
  }
}

export type CreateProjectDTO = Pick<Project, 'name' | 'isPrivate' | 'tags'> &
  Partial<Pick<Project, 'about'>> & {
    /**
     * emails - Список email, которым доступен проект, если он приватный
     */
    emails: string[];
    categories: string[];
    topics: {
      name: string | null;
      comment: string;
      agreement: number | null;
    }[];
  };

export type UpdateProjectDTO = Omit<CreateProjectDTO, 'topics'> & {
  /**
   * Список имен файлов, которые не нужно удалять. Пустой массив будет означать удаление всех файлов
   */
  savedFiles: string[];
};

export type ProjectDTO<T = UpdateProjectDTO | CreateProjectDTO> = {
  /**
   * thumbnail .jpg, .png
   */
  photo?: File;
  /**
   * .pdf files
   */
  files?: File[];
  data: T;
};
