import { Directive, ElementRef, Input, OnInit, inject } from '@angular/core';

@Directive({ selector: '[swScrollOnInit]', standalone: true })
export class ScrollOnInitDirective implements OnInit {
  @Input()
  skipScroll = false;

  @Input()
  scrollPosition?: ScrollLogicalPosition;

  private readonly elementRef: ElementRef<HTMLElement> = inject(ElementRef);

  ngOnInit(): void {
    if (this.skipScroll) {
      return;
    }

    const el = this.elementRef.nativeElement;

    setTimeout(() => {
      el.scrollIntoView({
        behavior: 'smooth',
        block: this.scrollPosition,
      });
    }, 100);
  }
}
