import { MaskitoOptions } from '@maskito/core';

export const PhoneRegex = /^\+7\s\(\d{3}\)\s\d{3}-\d{4}$/;

export const PhoneMask = {
  mask: [
    '+',
    '7',
    ' ',
    '(',
    /\d/,
    /\d/,
    /\d/,
    ')',
    ' ',
    /\d/,
    /\d/,
    /\d/,
    '-',
    /\d/,
    /\d/,
    /\d/,
    /\d/,
  ],
} as MaskitoOptions;
