import { ChangeDetectionStrategy, Component, inject } from '@angular/core';
import { EditorTabBtnsComponent } from './editor-tab-btns.component';
import { EditorTopicCustomComponent } from './editor-topic-custom.component';
import { NgControl, ReactiveFormsModule } from '@angular/forms';

import { NgClass, NgIf } from '@angular/common';
import { EditorTopicDefaultComponent } from './editor-topic-default.component';

@Component({
  selector: 'sw-editor-topic-tag',
  standalone: true,
  imports: [
    NgIf,
    NgClass,
    EditorTabBtnsComponent,
    EditorTopicDefaultComponent,
    EditorTopicCustomComponent,
    ReactiveFormsModule,
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
  template: `
    <div class="space-y-5">
      <div>
        <sw-editor-tab-btns
          [(option)]="tagType"
          (optionChange)="ngControl.reset()"
        />
      </div>
      <div class="">
        <sw-editor-topic-custom *ngIf="tagType; else defaultList" />

        <ng-template #defaultList>
          <sw-editor-topic-default />
        </ng-template>
      </div>
    </div>
  `,
})
export class EditorTopicTagComponent {
  protected readonly ngControl = inject(NgControl);

  /**
   * false - defaults list
   * true - custom tag
   */
  protected tagType = true;
}
