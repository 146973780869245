import { LikeService } from '@/core/services/likes.service';
import { Injectable, Provider, inject } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable()
export abstract class LikeExchangeService {
  protected readonly likeService = inject(LikeService);

  protected readonly loadingSubject$ = new BehaviorSubject<boolean>(false);

  readonly loading$ = this.loadingSubject$.asObservable();

  abstract exchange(categorySlug: string, count: number): Observable<unknown>;
}

export function provideLikeExchange(
  useClass: new () => LikeExchangeService
): Provider {
  return {
    provide: LikeExchangeService,
    useClass,
  };
}
