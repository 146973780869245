import { CommentsTopicsService } from '@/core/services/comments-topics.service';
import { TopicService } from '@/core/services/topics.service';
import { NgClass, NgIf } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  DestroyRef,
  Input,
  OnInit,
  ViewEncapsulation,
  inject,
} from '@angular/core';
import { takeUntilDestroyed, toSignal } from '@angular/core/rxjs-interop';
import { FormControl, ReactiveFormsModule } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { DropdownModule } from 'primeng/dropdown';
import { distinctUntilChanged, map, switchMap } from 'rxjs';

@Component({
  selector: 'sw-comment-topic-selector',
  standalone: true,
  imports: [DropdownModule, ReactiveFormsModule, NgIf, NgClass],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
  providers: [CommentsTopicsService],
  template: `
    <div
      class="flex w-full max-w-[450px] flex-row items-center space-x-5 rounded-md bg-gray-100 p-2"
      *ngIf="options() as list"
    >
      <p-dropdown
        [formControl]="topicControl"
        [options]="list"
        optionLabel="label"
        optionValue="value"
        placeholder="Выберите тему проекта"
        appendTo="body"
        [ngClass]="{
          'w-full': !showClearBtn(),
          'w-[calc(100%-44px)]': showClearBtn()
        }"
        [lazy]="false"
        emptyMessage="У проекта нет тем"
      />
      <button
        *ngIf="showClearBtn()"
        (click)="clearTopic()"
        class="button-base flex aspect-square h-6 w-6 flex-shrink-0 items-center justify-center rounded-full bg-accent text-white"
      >
        <i class="pi pi-times"></i>
      </button>
    </div>
  `,
  styles: [
    `
      sw-comment-topic-selector {
        .p-dropdown {
          @apply w-full rounded-sm border bg-white py-2 pl-3 pr-2;

          .p-dropdown-trigger {
            @apply w-[20px];
          }
          .p-dropdown-label {
            @apply text-[14px];
            &.p-placeholder {
              @apply text-black;
            }
          }
        }
      }
    `,
  ],
})
export class CommentTopicSelectorComponent implements OnInit {
  @Input()
  commentId!: string;

  private prevTopicId?: string | null;

  @Input()
  set topicId(topicId: string | undefined | null) {
    this.prevTopicId = topicId;
    this.topicControl.setValue(topicId || null);
  }

  private readonly destroyRef = inject(DestroyRef);

  private readonly projectId =
    inject(ActivatedRoute).snapshot.paramMap.get('id') || '';

  private readonly topicService = inject(TopicService);
  private readonly commentsTopicsService = inject(CommentsTopicsService);

  protected readonly topics$ = this.topicService.getTopics(this.projectId).pipe(
    map((t) => {
      return t.map((topic) => ({ label: topic.name, value: topic.id }));
    })
  );

  protected readonly options = toSignal(this.topics$);

  protected readonly topicControl = new FormControl<string | null>(null);

  protected readonly showClearBtn = toSignal(
    this.topicControl.valueChanges.pipe(map(Boolean), distinctUntilChanged())
  );

  ngOnInit(): void {
    this.topicControl.valueChanges
      .pipe(
        takeUntilDestroyed(this.destroyRef),
        switchMap((topicId) => {
          return this.commentsTopicsService.linkCommentToTopic({
            commentId: this.commentId,
            prevTopicId: this.prevTopicId,
            topicId,
          });
        })
      )
      .subscribe({
        next: ({ id }) => {
          this.prevTopicId = id;
        },
      });
  }

  protected clearTopic() {
    this.topicControl.setValue(null);
  }
}
