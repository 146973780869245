<sw-info-layout (closeDialog)="dialogRef.close()" headerBgImg="gem">
  <sw-info-header title="Гемы" layout-header>
    <div description class="text-center text-lg text-white">
      <p>
        Гемы - это внутренняя валюта на платформе. Их можно добавлять к постам,
        чтобы вознаграждать авторов самых значимых комментариев и ускорить
        процесс обсуждения проекта. Также гемы можно жертвовать на целые
        категории, чтобы стимулировать процесс обсуждения проектов в этих
        категориях.
      </p>
      <p>
        Полученные гемы можно обменивать на датасеты, вычислительные мощности,
        предобученные модели и многое другое.
      </p>
    </div>

    <div class="mx-auto flex flex-row items-center space-x-5" content>
      <span class="text-[18px] leading-[22px] text-white/50"
        >Всего кристаллов</span
      >
      <span class="text-[32px] font-bold text-white">{{ gems$ | async }}</span>
      <span class="text-[13px] leading-[16px] text-white/50"
        >(1 гем = 1 руб.)</span
      >
    </div>
  </sw-info-header>

  <div class="space-y-[50px] pt-24">
    <!-- <sw-gem-info-add-funds /> -->

    <section class="">
      <sw-market />
    </section>

    <section class="">
      <sw-gem-info-donate />
    </section>

    <section class="">
      <sw-info-faq [content]="faq" />
    </section>
  </div>
</sw-info-layout>
