/* eslint-disable @typescript-eslint/no-explicit-any */
import { InjectionToken } from '@angular/core';

type ErrorMessages = Record<string, (...args: any[]) => string>;
const ERROR_MESSAGES: ErrorMessages = {
  required: () => `Обязательное поле`,
  minlength: ({ requiredLength }) =>
    `Минимальное количество символов ${requiredLength}`,
  maxlength: ({ requiredLength }) =>
    `Максимальное количество символов ${requiredLength}`,
  email: () => `Введите email`,
  emailarray: (context: { errorEmails: string[] }) =>
    `Неверно указан email:  ${context.errorEmails.join(', ')}`,
  isExist: (context: { msg?: string }) => context.msg || 'Уже существует',
  pattern: () => `Поле заполнено неверно`,
  passworddifferent: (context: { msg?: string }) =>
    context.msg || `Пароли не совпадают`,
  passwordsame: (context: { msg?: string }) =>
    context.msg || `Пароли совпадают`,
  commonError: (context: { msg?: string }) =>
    context.msg || 'Поле заполнено неверно',
  strongPassword: () =>
    'Пароль должен содержать как минимум одну цифру, один символ в верхнем регистре и один символ в нижнем регистре.',
};

export const VALIDATION_ERROR_MESSAGES = new InjectionToken<ErrorMessages>(
  'Validation Messaged',
  {
    providedIn: 'root',
    factory: () => ERROR_MESSAGES,
  }
);
