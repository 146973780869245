import { ImagePreviewInput } from '@/core';
import { NgClass } from '@angular/common';
import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

import { DndFilesDirective } from '@/shared/directives/dnd-files.directive';

@Component({
  selector: 'sw-image-input',
  standalone: true,
  imports: [NgClass, DndFilesDirective],
  changeDetection: ChangeDetectionStrategy.OnPush,
  template: `
    <button
      #btn
      class="image-dropdown group relative flex h-[260px] w-full items-center justify-center overflow-hidden rounded-lg  bg-black/5 transition-all duration-150
       "
      [ngClass]="{
        'border border-dashed border-accent': !file && !hasError,
        'input-error border-2': hasError,
        'image-dropdown_active': file
      }"
      swDndFiles
      [swDndFilesDisabled]="!!file"
      [acceptExtension]="imageAccept"
      (fileDropped)="setFile($event[0])"
      (click)="fileInput.click(); btn.blur()"
      type="button"
    >
      @if (!imagePreview) {
      <div class="flex flex-col items-center space-y-[15px]">
        <svg
          width="64"
          height="63"
          viewBox="0 0 64 63"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M53.5 2H10.5C6.08172 2 2.5 5.58172 2.5 10V26.3818C2.5628 26.4181 2.6226 26.4619 2.67815 26.5131L12.8481 35.8975C15.3267 38.1847 19.2028 37.9803 21.4271 35.4452L38.0536 16.4957C41.4597 12.6137 47.5968 12.9282 50.5883 17.1381L61.5 32.4942V10C61.5 5.58172 57.9183 2 53.5 2ZM2.5 53V29.0701L11.4918 37.3674C14.7965 40.4169 19.9647 40.1444 22.9304 36.7643L39.5569 17.8148C42.1115 14.9033 46.7144 15.1392 48.9579 18.2965L61.1848 35.5035C61.271 35.6247 61.3791 35.7209 61.5 35.7906V53C61.5 57.4183 57.9183 61 53.5 61H10.5C6.08172 61 2.5 57.4183 2.5 53ZM10.5 0C4.97715 0 0.5 4.47715 0.5 10V53C0.5 58.5228 4.97715 63 10.5 63H53.5C59.0228 63 63.5 58.5228 63.5 53V10C63.5 4.47715 59.0228 0 53.5 0H10.5ZM20.5 16C20.5 18.2091 18.7091 20 16.5 20C14.2909 20 12.5 18.2091 12.5 16C12.5 13.7909 14.2909 12 16.5 12C18.7091 12 20.5 13.7909 20.5 16ZM22.5 16C22.5 19.3137 19.8137 22 16.5 22C13.1863 22 10.5 19.3137 10.5 16C10.5 12.6863 13.1863 10 16.5 10C19.8137 10 22.5 12.6863 22.5 16Z"
            fill="#194CFF"
          />
        </svg>

        <p class="text-sm leading-[18px] text-black/50">
          Загрузите изображение
        </p>
      </div>
      } @else {
      <img
        [src]="imagePreview"
        draggable="false"
        alt
        class="h-full w-full object-cover object-center"
      />
      }

      <input
        #fileInput
        type="file"
        class="absolute -z-10 h-0 w-0 appearance-none overflow-hidden"
        [accept]="imageAccept"
        tabindex="-1"
        (change)="handleChange($event)"
      />
    </button>
  `,

  styles: [
    `
      .file-over {
        @apply bg-black/10;
      }
    `,
  ],
})
export class ImageInputComponent extends ImagePreviewInput {
  @Input()
  hasError = false;

  protected readonly imageAccept = 'image/jpeg, image/png, image/webp';
}
