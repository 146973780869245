import {
  Directive,
  ElementRef,
  Input,
  OnDestroy,
  OnInit,
  inject,
} from '@angular/core';
import { TrackSeenDirective } from './track-seen.directive';

@Directive({ selector: '[swRegisterTrackSeen]', standalone: true })
export class RegisterTrackDirective implements OnInit, OnDestroy {
  @Input()
  skip = false;

  @Input({ required: true })
  trackSeenId!: string;

  private readonly elementRef = inject(ElementRef<HTMLElement>);
  private readonly trackSeen = inject(TrackSeenDirective, { optional: true });

  get nativeElement(): HTMLElement {
    return this.elementRef.nativeElement;
  }

  ngOnInit(): void {
    if (!this.skip && this.trackSeen) {
      this.trackSeen?.addChildren(this);
    }
  }

  ngOnDestroy(): void {
    if (!this.skip && this.trackSeen) {
      this.trackSeen?.removeChildren(this);
    }
  }
}
