import { registerLocaleData } from '@angular/common';
import {
  provideHttpClient,
  withFetch,
  withInterceptors,
} from '@angular/common/http';
import localeRu from '@angular/common/locales/ru';
import {
  APP_INITIALIZER,
  ApplicationConfig,
  LOCALE_ID,
  importProvidersFrom,
  isDevMode,
} from '@angular/core';
import {
  provideClientHydration,
  withNoHttpTransferCache,
} from '@angular/platform-browser';
import { provideAnimations } from '@angular/platform-browser/animations';
import {
  TitleStrategy,
  provideRouter,
  withEnabledBlockingInitialNavigation,
  withRouterConfig,
} from '@angular/router';
import { provideHttpCache, withHttpCacheInterceptor } from '@ngneat/cashew';
import { MetrikaModule } from 'ng-yandex-metrika';
import { CookieService } from 'ngx-cookie-service';
import { MessageService } from 'primeng/api';
import { EMPTY, catchError } from 'rxjs';
import { environment } from 'src/environments/environment';
import { UserService } from './core';
import {
  baseUrlInterceptor,
  caseMapperInterceptor,
  cookieInterceptor,
  refreshTokenInterceptor,
} from './core/interceptors';
import { AppTitlePrefix } from './core/title-strategy';
import { APP_ROUTES } from './pages/app-router';

registerLocaleData(localeRu, 'ru');

function initializeApp(userService: UserService): () => Promise<boolean> {
  return () => {
    return new Promise((resolve) => {
      userService
        .getMe()
        .pipe(catchError(() => EMPTY))
        .subscribe({
          complete: () => {
            resolve(true);
          },
        });
    });
  };
}

export const appConfig: ApplicationConfig = {
  providers: [
    provideRouter(
      APP_ROUTES,
      withEnabledBlockingInitialNavigation(),
      withRouterConfig({
        onSameUrlNavigation: 'reload',
      })
    ),
    provideHttpClient(
      withFetch(),
      withInterceptors([
        withHttpCacheInterceptor(),
        caseMapperInterceptor,
        baseUrlInterceptor,
        cookieInterceptor,
        refreshTokenInterceptor,
      ])
    ),
    provideHttpCache({
      strategy: 'explicit',
    }),
    provideClientHydration(withNoHttpTransferCache()),
    provideAnimations(),
    importProvidersFrom(
      MetrikaModule.forRoot([
        {
          id: Number(environment.YA_METRICS_ID),
          clickmap: !isDevMode(),
          trackLinks: !isDevMode(),
          accurateTrackBounce: !isDevMode(),
          webvisor: !isDevMode(),
        },
      ])
    ),
    MessageService,
    CookieService,
    { provide: LOCALE_ID, useValue: 'ru-RU' },
    {
      provide: TitleStrategy,
      useClass: AppTitlePrefix,
    },
    {
      provide: APP_INITIALIZER,
      useFactory: initializeApp,
      deps: [UserService],
      multi: true,
    },
  ],
};
