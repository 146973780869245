import {
  Directive,
  ElementRef,
  OnDestroy,
  PLATFORM_ID,
  inject,
} from '@angular/core';
import { PermissionDirective } from './permission.directive';
import { isPlatformBrowser } from '@angular/common';

@Directive({ selector: '[swPermissionAction]', standalone: true })
export class PermissionActionDirective implements OnDestroy {
  protected readonly permission = inject(PermissionDirective, {
    optional: true,
  });

  private readonly elementRef = inject(ElementRef);
  private readonly platformId = inject(PLATFORM_ID);

  constructor() {
    if (isPlatformBrowser(this.platformId)) {
      this.elementRef.nativeElement.addEventListener(
        'click',
        this.onClickHandler,
        true
      );
    }
  }

  private readonly onClickHandler: (event?: MouseEvent) => void = (event) => {
    this.permission?.call(() => {
      event?.preventDefault();
      event?.stopPropagation();
    });
  };

  ngOnDestroy() {
    if (isPlatformBrowser(this.platformId)) {
      document.removeEventListener('click', this.onClickHandler, true);
    }
  }
}
