import { bootstrapApplication } from '@angular/platform-browser';
import { AppComponent } from './app/app.component';
import { appConfig } from './app/app.config';

import { isDevMode } from '@angular/core';
import { BrowserTracing, init } from '@sentry/angular-ivy';
import { environment } from './environments/environment';

if (!isDevMode()) {
  init({
    dsn: environment.SENTRY_DNS,
    environment: environment.SENTRY_ENV,
    integrations: [new BrowserTracing()],
    tracesSampleRate: 1,
  });
}

bootstrapApplication(AppComponent, appConfig).catch((err) =>
  console.error(err)
);
