/* eslint-disable @typescript-eslint/ban-types */
import { isPlatformServer } from '@angular/common';
import { HttpInterceptorFn } from '@angular/common/http';
import { PLATFORM_ID, inject } from '@angular/core';
import { API_URL } from '@core/tokens';
import { CookieService } from 'ngx-cookie-service';
import { SsrCookieService } from 'ngx-cookie-service-ssr';

export const cookieInterceptor: HttpInterceptorFn = (request, next) => {
  const platform = inject(PLATFORM_ID);
  const ssrCookieService = inject(SsrCookieService);
  const cookieService = inject(CookieService);

  const token = isPlatformServer(platform)
    ? ssrCookieService.get('sw_access_token')
    : cookieService.get('sw_access_token');

  const baseURL = inject(API_URL);

  const headers = request.headers;

  const authReq =
    token && request.url.includes(baseURL)
      ? request.clone({
          headers: headers.set('Authorization', `Bearer ${token}`),
        })
      : request;

  return next(authReq);
};
