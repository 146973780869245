import { Directive, EventEmitter, Output } from '@angular/core';

@Directive({ selector: '[swProjectCardMenu]', standalone: true })
export class ProjectMenuDirective {
  // eslint-disable-next-line @angular-eslint/no-output-rename
  @Output()
  editProject = new EventEmitter<string>();

  @Output()
  reportProject = new EventEmitter<void>();

  @Output()
  deleteProject = new EventEmitter<string>();
}
