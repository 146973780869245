import { CategoriesService, UserService } from '@/core';
import { isMissed } from '@/core/helpers';

import { SendGemsToCategoryDirective } from '@/shared/ui/reactions/gem/send-gems-to-category.directive';
import { AsyncPipe } from '@angular/common';
import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  inject,
  ViewChild,
  ViewEncapsulation,
} from '@angular/core';
import {
  FormControl,
  FormGroup,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import { DropdownModule } from 'primeng/dropdown';
import { Observable, tap } from 'rxjs';

import { LetDirective } from '@/shared/directives';
import {
  ButtonComponent,
  DynamicValidationMessage,
  GEM_CONTEXT,
  GemContext,
  InputNumberComponent,
} from '@/shared/ui';
import { GemInfoDonateListComponent } from './gem-info-donate-list.component';

@Component({
  selector: 'sw-gem-info-donate',
  standalone: true,
  imports: [
    AsyncPipe,
    LetDirective,
    InputNumberComponent,
    DropdownModule,
    ButtonComponent,
    GemInfoDonateListComponent,
    ReactiveFormsModule,
    SendGemsToCategoryDirective,
    DynamicValidationMessage,
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
  template: `
    <div
      class="space-y-[30px]"
      swGemsToCategory
      [recipient]="categoryControl.value"
    >
      <h2 class="info-title">Пожертвовать в категорию</h2>

      <form
        [formGroup]="form"
        (submit)="handleSubmit()"
        class="island flex flex-row flex-wrap items-center justify-center gap-5"
      >
        <div class="shrink-0 flex-grow basis-[292px]">
          <p-dropdown
            formControlName="slug"
            [options]="(categories$ | async) || []"
            optionLabel="name"
            optionValue="slug"
            placeholder="Выберите категорию"
            class="p-fluid block"
            [showErrorMsg]="false"
          ></p-dropdown>
        </div>

        <div class="shrink-0 flex-grow basis-[292px]">
          <sw-input-number
            ngDefaultControl
            formControlName="count"
            label="Сколько гемов"
            placeholder="0"
            [min]="1"
            [max]="(gems$ | async) || 0"
            [showErrorMsg]="false"
          />
        </div>

        <div class="flex w-full shrink flex-grow basis-[200px]">
          <button
            swButton
            [loading]="(loading$ | async) || false"
            [disabled]="form.disabled"
            class="button button-primary h-[60px] w-full items-center  justify-center font-bold disabled:pointer-events-none disabled:opacity-60"
            type="submit"
          >
            Пожертвовать
          </button>
        </div>
      </form>

      <div>
        <sw-gem-info-donate-list />
      </div>
    </div>
  `,
  styles: [
    `
      sw-gem-info-donate .p-dropdown {
        @apply rounded-md bg-grey;
      }
    `,
  ],
})
export class GemInfoDonateComponent implements AfterViewInit {
  @ViewChild(GEM_CONTEXT) ctx?: GemContext;

  protected loading$: Observable<boolean> | null = null;

  protected readonly categories$ = inject(CategoriesService).fetchList();

  protected readonly gems$ = inject(UserService).userGems$.pipe(
    tap((gems) => {
      if (isMissed(gems) || gems < 1) {
        this.form.disable();
      } else {
        this.form.enable();
      }
    })
  );

  protected readonly categoryControl = new FormControl<string>('', {
    nonNullable: true,
    validators: [Validators.required],
  });

  protected readonly form = new FormGroup({
    slug: this.categoryControl,
    count: new FormControl<string | number>('', {
      nonNullable: true,
      validators: [Validators.required],
    }),
  });

  protected handleSubmit() {
    if (this.form.invalid || isMissed(this.ctx)) {
      return;
    }

    this.form.disable();
    this.ctx.sendGems(+this.form.getRawValue().count).subscribe({
      next: () => {
        this.form.reset();
        this.form.enable();
      },
    });
  }

  ngAfterViewInit(): void {
    this.loading$ = this.ctx?.loading$ || null;
  }
}
