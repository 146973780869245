import { isMissed } from '@/core/helpers';
import { CommentsService } from '@/core/services/comments.service';
import { LetDirective } from '@/shared/directives';

import { PermissionActionDirective } from '@/shared/directives/permission/permission-action.directive';
import { RatingRageComponent } from '@/shared/ui/rating-range-slider.component';
import { AsyncPipe } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  ViewEncapsulation,
  inject,
} from '@angular/core';
import { FormsModule } from '@angular/forms';
import { OverlayPanel, OverlayPanelModule } from 'primeng/overlaypanel';
import { BaseCommentAgreementBtns } from './base-comment-agreement-btns';
import { AgreementBtnDirective } from './agreement-btn.directive';
import { ButtonComponent, SVGComponent } from '@/shared/ui';

@Component({
  selector: 'sw-comment-agreement-btns-superuser',
  standalone: true,
  imports: [
    SVGComponent,
    OverlayPanelModule,
    FormsModule,
    RatingRageComponent,
    ButtonComponent,
    LetDirective,
    PermissionActionDirective,
    AgreementBtnDirective,
    AsyncPipe,
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
  template: `
    <fieldset class="flex h-full w-full flex-row items-center overflow-hidden">
      <button
        swPermissionAction
        (click)="like($event, rating)"
        class="button flex h-full flex-1 items-center rounded-l-md rounded-r-none bg-black/5 text-[22px]"
        [class.text-accent]="value && value > 0 && value < 5"
        [class.text-gold]="value === 5"
        type="button"
      >
        <sw-svg icon="like" height="22px" width="22px" />
      </button>

      <button
        swPermissionAction
        swAgreementBtn
        [commentId]="commentId"
        [value]="0"
        class="button flex h-full flex-1 items-center rounded-l-none rounded-r-md bg-black/5 text-[22px]"
        [class.text-red]="value === 0"
        type="button"
      >
        <sw-svg icon="dislike" height="22px" width="22px" />
      </button>
    </fieldset>

    <p-overlayPanel
      #rating
      [style]="{ width: '261px' }"
      styleClass="comment-rating"
      appendTo="body"
      [autoZIndex]="false"
      [baseZIndex]="1"
      (onHide)="handleCloseOverlay()"
    >
      <ng-template pTemplate="content">
        <sw-rating-range
          ngDefaultControl
          [(ngModel)]="val"
          class="block"
          color="green"
        />
      </ng-template>
    </p-overlayPanel>
  `,
  styles: [
    `
      .comment-rating.p-overlaypanel {
        @apply -ml-[66px] mt-0 border-y-[15px] border-solid border-transparent bg-transparent shadow-none;
        &::after,
        &::before {
          @apply hidden;
        }
        .p-overlaypanel-content {
          @apply rounded-md bg-white px-2 pb-[5px] pt-[10px] shadow-[0px_2px_8px_rgba(0,0,0,0.15)];
        }
      }
    `,
  ],
})
export class CommentAgreementBtnsSuperuserComponent extends BaseCommentAgreementBtns {
  protected val = 3;
  private readonly commentsService = inject(CommentsService);

  like($event: MouseEvent, panel: OverlayPanel) {
    if (isMissed(this.value) || this.value === 0) {
      this.value = this.val;
      panel.toggle($event);
    } else {
      this.saveAgreement(null);
      this.val = 3;
    }
  }

  protected handleCloseOverlay() {
    this.saveAgreement(this.val);
  }

  private saveAgreement(val: number | null) {
    this.commentsService.setAgreement(this.commentId, val).subscribe();
  }
}
