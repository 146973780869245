import { isMissed, isPresent } from '@/core/helpers';
import { CreateCommentPayload } from '@/core/models';
import { Injectable, inject } from '@angular/core';
import { tap } from 'rxjs';
import { CommentsService } from './comments.service';
import { TopicService } from './topics.service';

@Injectable()
export class CommentsTopicsService {
  private readonly commentsService = inject(CommentsService);
  private readonly topicService = inject(TopicService);

  createComment(payload: CreateCommentPayload) {
    return this.commentsService.create(payload).pipe(
      tap(() => {
        if (isMissed(payload.parent.topicId)) {
          return;
        }

        const topic = this.topicService.getTopic(payload.parent.topicId);

        if (isPresent(topic)) {
          this.topicService.updateTopic(topic.id, {
            comments: topic.comments + 1,
          });
        }
      })
    );
  }

  linkCommentToTopic(payload: {
    commentId: string;
    topicId: string | null;
    prevTopicId?: string | null;
  }) {
    return this.topicService.linkCommentToTopic(payload).pipe(
      tap(() => {
        this.commentsService.updateCommentInThread(payload.commentId, {
          topicId: payload.topicId,
        });
      })
    );
  }
}
