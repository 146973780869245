import { ChangeDetectionStrategy, Component } from '@angular/core';

@Component({
  selector: 'sw-project-stack-delimiter',
  standalone: true,
  imports: [],
  changeDetection: ChangeDetectionStrategy.OnPush,
  template: `
    <div
      class=" mx-10 my-[5px] flex h-[50px] select-none items-center border-l border-black/30 px-[30px]"
    >
      <span class="text-xs leading-[22px] text-black/50">
        <ng-content />
      </span>
    </div>
  `,
})
export class ProjectStackDelimiterComponent {}
