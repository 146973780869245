import { ChangeDetectionStrategy, Component, inject } from '@angular/core';
import { OpinionSuperuserComponent } from './opinion-superuser.component';
import { OpinionUserComponent } from './opinion-user.component';
import { UserService } from '@/core';
import { toSignal } from '@angular/core/rxjs-interop';
import { NgIf } from '@angular/common';

@Component({
  selector: 'sw-opinion-wrapper',
  standalone: true,
  imports: [NgIf, OpinionSuperuserComponent, OpinionUserComponent],
  changeDetection: ChangeDetectionStrategy.OnPush,
  template: `
    <sw-opinion-superuser *ngIf="isSuperUser(); else userOpinion" />
    <ng-template #userOpinion>
      <sw-opinion-user />
    </ng-template>
  `,
})
export class OpinionWrapperComponent {
  protected readonly isSuperUser = toSignal(inject(UserService).isSuperuser$);
}
