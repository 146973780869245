<ng-container *swLet="projectId$ | async as projectId">
  <ng-container *ngIf="thread$ | async as thread">
    <div
      class="mt-[1px]"
      swTrackSeen
      [trackSeenParentId]="projectId"
      *swLet="commentType$ | async as commentType"
    >
      <sw-back-button class="flex" *swBreakpointDesktop />

      <div class="mt-5 space-y-5 sm:mt-[9px]">
        <div>
          <sw-project-details [project]="thread.project" />
        </div>

        <div *swBreakpointDesktop="false">
          <sw-project-topic-list />
        </div>
        <sw-project-card-extended
          class="block"
          *ngIf="thread.parents.length === 0; else comment"
          [project]="thread.project"
        />

        <ng-template #comment>
          <div class="space-y-[5px]">
            <sw-comment-stack class="block" [thread]="thread" #commentStack />
          </div>
        </ng-template>

        <div *ngIf="selectedTopic$ | async as selectedTopic" class="text-sm">
          <span>Выбрана тема: </span>
          <span class="font-bold">{{ selectedTopic?.name }}</span>
        </div>

        <ng-container *ngIf="canComment$ | async">
          <div>
            <sw-delimiter>Создать пост</sw-delimiter>
          </div>
          <div class="island" *ngIf="projectId">
            <sw-comment-editor
              *swLet="commentTopicIds$ | async as commentTopic"
              swCreateComment
              [commentType]="commentType || 'project'"
              [showTopicWarning]="
                !!commentTopic?.selectedTopicId && !commentTopic?.commentId
              "
              [projectId]="projectId"
              [parentId]="commentTopic?.commentId"
              [topicId]="commentTopic?.selectedTopicId"
            />
          </div>
        </ng-container>

        <div class="px-5 pt-[15px] md:px-0">
          <sw-thread-filter />
        </div>

        <div
          *ngFor="
            let c of thread.comments;
            empty: empty;
            trackBy: trackByComments
          "
          swRegisterTrackSeen
          [trackSeenId]="c.id"
          [attr.guide]="'comment-' + c.ratingType"
        >
          <sw-project-extended-comment
            [projectId]="thread.project.id"
            [comment]="c"
          />
        </div>
      </div>
    </div>
  </ng-container>
</ng-container>

<ng-template #empty>
  <p
    class="mt-[150px] text-center text-sm text-black/50"
    *swLet="filterType$ | async as type"
  >
    <ng-container *ngIf="!type; else emptyFilter">
      Оставьте ваш комментарий
    </ng-container>

    <ng-template #emptyFilter>
      <strong
        >{{ type === "positive" ? "Позитивных" : "Отрицательных" }}</strong
      >
      комментариев пока нет. <br />
      <button class="link" (click)="resetFilter()" type="button">
        Сбросить фильтр?
      </button>
    </ng-template>
  </p>
</ng-template>
