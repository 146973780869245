import { AsyncPipe, NgClass, NgIf } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  Input,
  inject,
} from '@angular/core';
import { NgControl } from '@angular/forms';
import { MaskitoModule } from '@maskito/angular';
import { MaskitoOptions } from '@maskito/core';
import { maskitoNumberOptionsGenerator } from '@maskito/kit';

@Component({
  selector: 'sw-input-number',
  standalone: true,
  imports: [NgIf, AsyncPipe, MaskitoModule, NgClass],
  changeDetection: ChangeDetectionStrategy.OnPush,
  template: `
    <label
      class="input-wrapper group relative flex cursor-text flex-row items-center justify-between  rounded-md bg-grey px-5 py-[15px] outline-2 outline-accent focus-within:outline"
      [ngClass]="{
        'pointer-events-none opacity-60': disabled
      }"
    >
      <span
        *ngIf="label"
        class=" absolute top-1/2 flex-shrink flex-grow-0 -translate-y-1/2 overflow-hidden text-ellipsis whitespace-nowrap text-sm font-medium text-black/50 md:visible"
        [style.width]="calcLabelWidth(input.value)"
      >
        {{ label }}
      </span>
      <div class="flex-1">
        <input
          #input
          [value]="value"
          [disabled]="disabled"
          [maskito]="maskitoOptions"
          [placeholder]="placeholder"
          inputmode="numeric"
          class="w-full bg-transparent text-right text-[24px]  leading-[30px] outline-none"
        />
      </div>
    </label>
    {{ (ngControl?.valueChanges | async) && '' }}
  `,

  styles: [
    `
      :host(.ng-dirty.ng-invalid:not(:focus-within)) {
        .input-wrapper {
          box-shadow: 0 0 0 2px red inset;
        }
      }
    `,
  ],
})
export class InputNumberComponent {
  @Input() min = 0;
  @Input() max = 9999999;

  @Input() label?: string = '';

  @Input() placeholder?: string = '';
  protected readonly ngControl = inject(NgControl, { optional: true });

  get value() {
    return this.ngControl?.control ? this.ngControl.control.value : null;
  }

  get maskitoOptions(): MaskitoOptions {
    return maskitoNumberOptionsGenerator({
      min: this.min,
      max: this.max,
      precision: 0,
    });
  }

  get disabled() {
    return this.ngControl?.disabled || false;
  }

  protected calcLabelWidth(v: string) {
    const length = v.replace(/\s/g, '').length;
    const spaces = v.length - length;

    const inputWidth = length * 20 + spaces * 10 + 20;

    return `calc(100% - ${inputWidth}px)`;
  }
}
