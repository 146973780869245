import { Injectable, inject } from '@angular/core';
import { ProjectEditor } from './project-editor';
import {
  ProjectDTO,
  CreateProjectDTO,
  Project,
  UpdateProjectDTO,
} from '@/core/models';
import { Observable, tap } from 'rxjs';
import { CommentsService } from '@/core/services/comments.service';

@Injectable()
export class ThreadProjectEditorService extends ProjectEditor {
  private readonly commentsService = inject(CommentsService);

  override create(payload: ProjectDTO<CreateProjectDTO>): Observable<Project> {
    return this.projectService.create(payload);
  }
  override update(
    id: string,
    payload: ProjectDTO<UpdateProjectDTO>
  ): Observable<Project> {
    return this.projectService.update(id, payload).pipe(
      tap((p) => {
        this.commentsService.updateProjectInThread(p);
      })
    );
  }
  override delete(id: string): Observable<unknown> {
    return this.projectService.delete(id);
  }
}
