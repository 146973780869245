import { UserService } from '@/core';
import { FilePurchase } from '@/core/models';
import { MarketService } from '@/core/services/market.service';
import { FormSchema } from '@/core/types';
import { FormProduct } from '@/core/types/api-types';
import { Camelize } from '@/core/types/snake-2-camel';

import { SaverService } from '@/shared/directives/save';
import { AlertDialogComponent } from '@/shared/ui/alert-dialog.component';
import { Injectable, inject } from '@angular/core';
import { DialogService } from 'primeng/dynamicdialog';
import { BehaviorSubject, EMPTY, defer, switchMap, tap } from 'rxjs';
import { FormBuilderService } from '../form-builder';
import { MarketFormDialogComponent } from './market-form/market-form-dialog.component';

@Injectable()
export class PurchaseService {
  private readonly userService = inject(UserService);
  private readonly marketService = inject(MarketService);
  protected readonly dialogService = inject(DialogService);
  private readonly saveService = inject(SaverService);

  private readonly loadingSubject$ = new BehaviorSubject<boolean>(false);

  get loading$() {
    return this.loadingSubject$.asObservable();
  }

  purchase(goodId: string) {
    this.loadingSubject$.next(true);

    this.marketService
      .purchase(goodId)
      .pipe(
        tap({
          next: ({ product }) => this.userService.writeOffGems(product.price),
          finalize: () => {
            this.loadingSubject$.next(false);
          },
        }),
        switchMap(({ purchase, product }) => {
          if (purchase instanceof FilePurchase) {
            return this.saveService.save(purchase.file);
          } else {
            return this.dialogService
              .open(MarketFormDialogComponent, {
                header: product.name,
                width: 'min(100%, 556px)',
                dismissableMask: true,
                data: {
                  description: product.description,
                  goodId: purchase.goodId,
                  purchaseId: purchase.id,
                  schema: FormBuilderService.mergeValues(
                    (product as Camelize<FormProduct>).jsonForm as FormSchema,
                    purchase.jsonForm
                  ),
                },
              })
              .onClose.pipe(
                switchMap((result) => {
                  return defer(() =>
                    result
                      ? this.dialogService.open(AlertDialogComponent, {
                          showHeader: false,
                          dismissableMask: true,
                          width: 'min(100%, 494px)',
                        }).onClose
                      : EMPTY
                  );
                })
              );
          }
        })
      )
      .subscribe();
  }
}
