import { UserService } from '@/core';
import { DestroyRef, Injectable, inject } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { catchError, of, take } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class PermissionService {
  private readonly isLoggedIn$ = inject(UserService).isLoggedIn$;
  private readonly destroyRef = inject(DestroyRef);

  call({
    onAuthorized,
    onUnauthorized,
  }: {
    onAuthorized?: () => void;
    onUnauthorized?: () => void;
  }) {
    this.isLoggedIn$
      .pipe(
        catchError(() => of(false)),
        takeUntilDestroyed(this.destroyRef),
        take(1)
      )
      .subscribe({
        next: (isAuth) => (isAuth ? onAuthorized?.() : onUnauthorized?.()),
      });
  }
}
