import { MenuIcon } from '@/shared/ui/menu-icon.component';

const MENU: {
  icon: MenuIcon;
  path: string;
  query?: Record<string, string>;
}[] = [
  {
    icon: 'house',
    path: '/feed',
  },
  {
    icon: 'person',
    path: '/profile',
  },
  // {
  //   icon: 'bookmark',
  //   path: '/bookmarks',
  // },
];

type MenuItem = (typeof MENU)[number];

export abstract class BaseMenu {
  protected readonly menu = MENU;

  trackByFn(index: number, item: MenuItem) {
    return `${index}-${item.path}`;
  }
}
