import { GemService } from '@/core';
import { CategoryExtended, CategoryWithGems } from '@/core/types/category.type';
import { LetDirective } from '@/shared/directives';
import { CategoryIconComponent } from '@/shared/ui';
import { CommonModule } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  Input,
  OnInit,
  inject,
} from '@angular/core';
import { MaskitoModule } from '@maskito/angular';
import { maskitoNumberOptionsGenerator } from '@maskito/kit';
import { SkeletonModule } from 'primeng/skeleton';

@Component({
  selector: 'sw-gem-info-donate-list',
  standalone: true,
  imports: [
    CommonModule,
    LetDirective,
    CategoryIconComponent,
    SkeletonModule,
    MaskitoModule,
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
  template: `
    <div
      class="island-base overflow-hidden"
      *ngIf="spentGems$ | async as spentGems; else skeleton"
    >
      <dl>
        <div
          class="flex flex-1 items-center justify-between border-b border-b-black/10 p-5"
          *ngFor="let c of spentGems?.categories; trackBy: trackByFn"
        >
          <dt>
            <div class="flex flex-row items-center space-x-[15px]">
              <sw-category-icon [icon]="c.iconFile" class="flex" />
              <span class="text-[18px] leading-[22px]">{{ c.name }}</span>
            </div>
          </dt>
          <dd>
            <span class="text-2xl leading-[30px]">{{ c.gems }}</span>
          </dd>
        </div>
        <div class="flex flex-1 items-center justify-end space-x-[15px] p-5">
          <dt>
            <span class="text-[18px] leading-[22px] text-black/50">Всего</span>
          </dt>
          <dd>
            <span class="text-2xl font-bold leading-[30px]">{{
              spentGems?.total | maskito : options
            }}</span>
          </dd>
        </div>
      </dl>
    </div>

    <ng-template #skeleton>
      <p-skeleton width="100%" height="425px" borderRadius="16px" />
    </ng-template>
  `,
})
export class GemInfoDonateListComponent implements OnInit {
  protected readonly gemService = inject(GemService);
  @Input() categories: CategoryExtended[] = [];

  protected readonly spentGems$ = this.gemService.spentGems$;

  protected readonly options = maskitoNumberOptionsGenerator({ precision: 0 });

  ngOnInit(): void {
    this.gemService.getUserSpentGems().subscribe();
  }

  trackByFn(_index: number, c: CategoryWithGems): string {
    return c.slug;
  }
}
