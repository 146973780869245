export const environment = {
  SENTRY_DNS: 'https://0a930db762ef997fd39a9d4d75b4513e@sentry.dtc.tatar/2',
  SENTRY_ENV: 'production',
  BACKEND_URL: 'https://openapi.dtc.tatar',
  APP_URL: 'https://eoii.ru',
  YA_METRICS_ID: 94730072,
  GUIDE_PROJECT_ID: 'e780f4e9-29da-4be5-9fab-d0085a142a19',
};

// export const environment = {
//   SENTRY_DNS: 'https://0a930db762ef997fd39a9d4d75b4513e@sentry.dtc.tatar/2',
//   SENTRY_ENV: 'develop',
//   BACKEND_URL: 'https://opendev.dtc.tatar',
//   APP_URL: 'https://windowdev.dtc.tatar',
//   YA_METRICS_ID: 94730072,
//   GUIDE_PROJECT_ID: 'c832297e-aecd-4203-9880-1082b90c0ff4',
// };
