import { HtmlSanitizerPipe } from '@/shared/pipes';
import { AsyncPipe } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  Input,
  Provider,
  inject,
} from '@angular/core';
import { MarkdownModule, MarkdownService } from 'ngx-markdown';
import { ReplaySubject, map, switchMap } from 'rxjs';
import { MDService } from './md-page.service';

@Component({
  selector: 'sw-md-page',
  standalone: true,
  imports: [AsyncPipe, HtmlSanitizerPipe],
  providers: [MarkdownModule.forRoot().providers as Provider[], MDService],
  changeDetection: ChangeDetectionStrategy.OnPush,
  template: `
    <div class="prose m-auto" [innerHTML]="md$ | async | sanitizeHtml"></div>
  `,
})
export class MDPageComponent {
  private readonly mdService = inject(MDService);
  private readonly markdownService = inject(MarkdownService);
  @Input({ required: true })
  set src(src: string) {
    this.mdSubject$.next(src);
  }

  private readonly mdSubject$ = new ReplaySubject<string>();
  protected readonly md$ = this.mdSubject$.asObservable().pipe(
    switchMap((url) => this.mdService.getData(url)),
    map((md) => this.markdownService.parse(md || '').toString())
  );
}
