import { HttpErrorResponse, HttpInterceptorFn } from '@angular/common/http';
import { inject } from '@angular/core';
import { UserService } from '@core/services/user.service';
import { API_URL } from '@core/tokens';
import { catchError, switchMap, throwError } from 'rxjs';

const ignoreUrls = ['/logout', '/login', '/signup'];
export const refreshTokenInterceptor: HttpInterceptorFn = (request, next) => {
  const userService = inject(UserService);
  const baseURL = inject(API_URL);

  return next(request).pipe(
    catchError((error) => {
      if (
        error instanceof HttpErrorResponse &&
        request.url.includes(baseURL) &&
        error.status === 401 &&
        !ignoreUrls.some((url) => request.url.includes(url))
      ) {
        return userService.refreshToken().pipe(
          switchMap((res) => {
            const newRequest = request.clone({
              setHeaders: {
                Authorization: `Bearer ${res.accessToken}`,
              },
            });
            return next(newRequest);
          }),
          catchError((error) => {
            userService.logout();
            return throwError(() => error);
          })
        );
      }

      return throwError(() => error);
    })
  );
};
