import { memoize } from '@/core/decorators';
import {
  MAP_REQ_HTTP_TOKEN,
  MAP_RES_HTTP_TOKEN,
  SKIP_BASE_URL_HTTP_TOKEN,
} from '@/core/interceptors';
import {
  AsyncPipe,
  NgIf,
  NgSwitch,
  NgSwitchCase,
  NgSwitchDefault,
  NgTemplateOutlet,
  isPlatformServer,
} from '@angular/common';
import { HttpClient, HttpContext } from '@angular/common/http';
import {
  ChangeDetectionStrategy,
  Component,
  Input,
  PLATFORM_ID,
  TransferState,
  inject,
  makeStateKey,
} from '@angular/core';
import { withCache } from '@ngneat/cashew';
import { SVGComponent } from '@shared/ui/svg.component';
import { of, tap } from 'rxjs';
import { HtmlSanitizerPipe } from '../pipes';

@Component({
  selector: 'sw-category-icon',
  standalone: true,
  imports: [
    NgTemplateOutlet,
    SVGComponent,
    NgIf,
    AsyncPipe,
    HtmlSanitizerPipe,
    NgSwitch,
    NgSwitchDefault,
    NgSwitchCase,
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
  template: `
    <ng-container *ngIf="icon" [ngSwitch]="icon">
      <sw-svg
        *ngSwitchCase="'fire'"
        icon="fire"
        width="1.125em"
        height="1.3125em"
      />
      <sw-svg
        *ngSwitchCase="'diamond'"
        icon="diamond"
        style="width: 1.1875em; height: 1.125em;"
      />

      <div
        *ngSwitchDefault
        class="flex h-[17px] w-[17px] items-center justify-center overflow-hidden [&>svg]:max-h-[22px] [&>svg]:max-w-[22px] [&>svg]:fill-current  md:[&>svg]:max-h-[18px] md:[&>svg]:max-w-[18px]"
        [innerHTML]="getSvg(icon) | async | sanitizeHtml"
      ></div>
    </ng-container>
  `,
})
export class CategoryIconComponent {
  @Input()
  icon?: string;

  private readonly httpClient = inject(HttpClient);
  private readonly platformId = inject(PLATFORM_ID);
  private readonly transferState = inject(TransferState);

  @memoize
  getSvg(icon: string) {
    return this.load(icon);
  }

  private load(url: string) {
    const getFileKey = makeStateKey<string | null>(`svg-icon-${url}`);
    withCache();
    const context = new HttpContext();
    context
      .set(MAP_REQ_HTTP_TOKEN, false)
      .set(MAP_RES_HTTP_TOKEN, false)
      .set(SKIP_BASE_URL_HTTP_TOKEN, true);

    if (this.transferState.hasKey(getFileKey)) {
      const file = this.transferState.get(getFileKey, null);

      if (!file) {
        this.transferState.remove(getFileKey);
      }
      return of(file);
    }

    return this.httpClient
      .get(url, {
        context,
        responseType: 'text',
      })
      .pipe(
        tap((text) => {
          if (isPlatformServer(this.platformId)) {
            this.transferState.set(getFileKey, text);
          }
        })
      );
  }
}
