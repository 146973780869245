import { SCROLL_DIRECTION, UserService, WINDOW } from '@/core';
import {
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  ViewChild,
  inject,
} from '@angular/core';
import {
  ActivatedRoute,
  Router,
  RouterLink,
  RouterOutlet,
} from '@angular/router';
import { distinctUntilChanged, map } from 'rxjs';

import { OpenInfoTabDirective } from '@/features/info/open-info-tab.directive';
import { UserNotificationsComponent } from '@/features/notification';
import { LetDirective, LinkActiveDirective } from '@/shared/directives';
import { SVGComponent } from '@/shared/ui/svg.component';
import { UserDropdownComponent } from '@/shared/ui/user-dropdown.component';
import { NgStyle } from '@angular/common';
import { toSignal } from '@angular/core/rxjs-interop';

@Component({
  selector: 'sw-sidebar-layout',
  standalone: true,
  imports: [
    NgStyle,
    RouterOutlet,
    RouterLink,
    UserDropdownComponent,
    UserNotificationsComponent,
    SVGComponent,
    OpenInfoTabDirective,
    LinkActiveDirective,
    LetDirective,
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
  template: `
    <div class="h-full min-h-[800px]">
      <div
        class="sticky w-[282px] flex-shrink-0"
        *swLet="user() as u"
        [ngStyle]="position()"
      >
        <div
          class="fixed top-0 z-10 -ml-[30px] w-[322px] bg-bg pb-[31px] pl-[30px] pr-[10px] pt-[30px]"
        >
          <!-- -->
          <div class="relative  flex flex-row justify-center space-x-5">
            @if(u) {
            <div>
              <sw-user-notifications />
            </div>
            <div class="relative flex-1">
              <sw-user-dropdown
                [name]="u.fullName"
                [avatarUrl]="u.photo || undefined"
                [description]="u.rank"
                [showStar]="u.showStar"
              />
            </div>

            } @else {
            <a
              routerLink="/login"
              class="button button-primary w-full cursor-pointer py-3 text-sm font-bold leading-[18px]"
            >
              Войти
            </a>
            }
          </div>
        </div>
        <div class="mb-6 space-y-5 pt-[101px]" #container>
          @if(u) {

          <div class="mt-1 flex flex-row">
            <button
              swInfoTab="karma"
              class="button-base flex flex-1 items-center justify-center space-x-[6px] rounded-l-md bg-[#EEEEEE] pb-[7px] pt-[8px]"
            >
              <span class="flex text-black/50">
                <sw-svg
                  icon="heart"
                  width="18px"
                  height="16px"
                  class="text-[16px]"
                />
              </span>
              <span class="text-sm">Карма</span>
            </button>
            <div class="w-[1px] bg-black/10"></div>
            <button
              swInfoTab="gem"
              class="button-base flex flex-1 items-center justify-center space-x-[6px] rounded-r-md  bg-[#EEEEEE] pb-[7px] pt-[8px]"
            >
              <span class="flex text-black/50">
                <sw-svg
                  icon="gem"
                  width="16px"
                  height="16px"
                  class="text-[16px]"
                />
              </span>
              <span class="text-sm">Гемы</span>
            </button>
          </div>
          }

          <div>
            <ng-content />
          </div>

          <div class="text-[10px] leading-3 text-black/50">
            <p class="mb-2">
              <a
                href="/policy"
                class="underline underline-offset-2 transition-colors duration-200 hover:text-accent focus-visible:text-accent"
                >Политика обработки персональных данных</a
              >
            </p>

            <p>Единое Окно / Все права защищены / {{ today }}</p>
          </div>
        </div>
      </div>
    </div>
  `,
})
export class SidebarLayout {
  @ViewChild('container', { static: false }) container?: ElementRef;

  protected readonly user = toSignal(inject(UserService).user$, {
    initialValue: null,
  });

  protected readonly router = inject(Router);
  protected readonly route = inject(ActivatedRoute);
  private readonly window = inject(WINDOW);

  protected readonly today = new Date().getFullYear();

  protected readonly position = toSignal(
    inject(SCROLL_DIRECTION).pipe(
      distinctUntilChanged(),
      map(() => {
        const elem: HTMLElement | undefined = this.container?.nativeElement;
        if (!elem) {
          return null;
        }

        const innerHeight = this.window.innerHeight;

        const height = elem.getBoundingClientRect().height;

        const topOffset = -(height - innerHeight + 100);

        if (topOffset >= 0) {
          return { top: 0 };
        }

        return {
          top: `${topOffset}px`,
        };
      })
    )
  );
}
