import {
  FormSchema,
  FormSchemaElement,
  isFormSchemaElement,
} from '@/core/types';
import { Injectable } from '@angular/core';
import {
  FormControl,
  FormGroup,
  ValidatorFn,
  Validators,
} from '@angular/forms';

@Injectable()
export class FormBuilderService {
  static mergeValues(
    schema: FormSchema,
    values: object,
    field: Extract<keyof FormSchemaElement, 'default'> = 'default'
  ): FormSchema {
    const s = structuredClone(schema);

    const valuesKeys = Object.keys(values);

    for (const vKey of valuesKeys) {
      const elem = s[vKey];
      if (!elem) {
        continue;
      }

      if (isFormSchemaElement(elem)) {
        elem[field] = (((values as never)[vKey] || elem[field]) ??
          '') as string;
      } else {
        s[vKey] = this.mergeValues(
          elem,
          ((values as never)[vKey] || {}) as object,
          'default'
        );
      }
    }

    return s;
  }

  build(schema: FormSchema): FormGroup {
    const form = new FormGroup({});

    for (const key in schema) {
      const elem = schema[key];

      // if (Array.isArray(elem)) {
      //   const formArray = new FormArray([] as (FormControl | FormGroup)[]);

      //   elem.forEach((item) => {
      //     if (isFormSchemaElement(item)) {
      //       formArray.push(this.createFormControl(item));
      //     } else {
      //       formArray.push(this.build(item));
      //     }
      //   });

      //   form.addControl(key, formArray);
      // } else {

      const control = isFormSchemaElement(elem)
        ? this.createFormControl(elem)
        : this.build(elem);

      form.addControl(key, control);
      // }
    }

    return form;
  }

  private mapValidators(validators?: string[]) {
    const validatorsMap: Record<string, ValidatorFn> = {
      required: Validators.required,
      email: Validators.email,
    };

    return (validators || [])
      .map((v) => validatorsMap[v] || '')
      .filter(Boolean);
  }

  private createFormControl(item: FormSchemaElement) {
    const validators = this.mapValidators(item.validators);
    return new FormControl<string>(item.default || '', {
      nonNullable: true,
      validators,
    });
  }
}
