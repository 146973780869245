import { DynamicValidationMessage } from '@/shared/ui';
import { NgIf, NgSwitch, NgSwitchCase, NgSwitchDefault } from '@angular/common';
import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { FormControl, ReactiveFormsModule } from '@angular/forms';

@Component({
  selector: 'sw-field-wrapper',
  standalone: true,
  imports: [
    NgIf,
    ReactiveFormsModule,
    NgSwitch,
    NgSwitchCase,
    NgSwitchDefault,
    DynamicValidationMessage,
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
  template: `
    <div class="space-y-2" [ngSwitch]="type">
      <div *ngIf="label" class="font-medium text-black">
        <span>{{ label }}</span>
      </div>
      <div class="">
        <input
          *ngSwitchDefault
          [placeholder]="placeholder"
          [type]="type === 'email' ? 'email' : 'text'"
          class="form-field"
          [formControl]="control"
          autocomplete="given-name"
        />
      </div>
    </div>
  `,
})
export class FormFieldWrapperComponent {
  @Input({ required: true })
  control!: FormControl;

  @Input({ required: true })
  type = 'text';

  @Input()
  label?: string;

  @Input()
  placeholder?: string;
}
