import { CommentDTO } from '@/core/models';
import { CommentsService } from '@/core/services/comments.service';
import { inject } from '@angular/core';
import { Observable } from 'rxjs';

export abstract class CommentContext {
  protected readonly commentService = inject(CommentsService);
  public readonly loading$ = this.commentService.loading$;

  abstract send(payload: CommentDTO<unknown>): Observable<unknown>;
}
