export class TrackActivity {
  public readonly createdAt = new Date();

  get key() {
    return `${this.id}-${this.event}-${this.createdAt.toISOString()}`;
  }

  constructor(
    public id: string,
    public event: string,
    public cords: { x: number; y: number }
  ) {}

  toJSON() {
    return {
      id: this.id,
      event: this.event,
      createAt: this.createdAt.toISOString(),
    };
  }
}
