import { NgIf } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output,
} from '@angular/core';
import { FileNamePipe } from '../pipes/filename.pipe';

@Component({
  selector: 'sw-file-box',
  standalone: true,
  imports: [NgIf, FileNamePipe],
  changeDetection: ChangeDetectionStrategy.OnPush,
  template: `
    <div
      class="flex w-max max-w-full flex-row items-center rounded-md bg-grey py-2 pl-5 pr-[15px]"
      [class]="styleClass"
    >
      <span
        ><svg
          width="15"
          height="19"
          viewBox="0 0 15 19"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            opacity="0.3"
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M14.1493 0.80542H13.1493H5.04978H4.63812L4.34577 1.09523L0.295988 5.1098L0 5.40321V5.81998V17.1946V18.1946H1H13.1493H14.1493V17.1946V1.80542V0.80542ZM12.1493 2.80542V16.1946H2V6.23676L5.46144 2.80542H12.1493ZM4.04967 7.88005C4.04967 7.32776 4.49738 6.88005 5.04967 6.88005H9.9094C10.4617 6.88005 10.9094 7.32776 10.9094 7.88005C10.9094 8.43233 10.4617 8.88005 9.9094 8.88005H5.04967C4.49738 8.88005 4.04967 8.43233 4.04967 7.88005ZM5.04967 11.3348C4.49738 11.3348 4.04967 11.7825 4.04967 12.3348C4.04967 12.8871 4.49738 13.3348 5.04967 13.3348H9.9094C10.4617 13.3348 10.9094 12.8871 10.9094 12.3348C10.9094 11.7825 10.4617 11.3348 9.9094 11.3348H5.04967Z"
            fill="#1A1A1A"
          />
        </svg>
      </span>
      <span
        class="ml-[10px] mr-[5px] flex-1 select-none overflow-hidden
          text-ellipsis whitespace-pre text-sm leading-[22px]
        "
        >{{ fileName | filename }}</span
      >

      <button
        *ngIf="showRemove"
        (click)="remove.emit()"
        class="button-rounded h-5 w-5 p-[5px] hover:bg-black/10  focus-visible:bg-black/10"
      >
        <svg
          width="12"
          height="13"
          viewBox="0 0 12 13"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            opacity="0.3"
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M9.98231 11.7471C10.3728 12.1376 11.006 12.1376 11.3965 11.7471C11.787 11.3566 11.787 10.7234 11.3965 10.3329L7.56361 6.49998L11.3965 2.66707C11.787 2.27654 11.787 1.64338 11.3965 1.25285C11.006 0.86233 10.3728 0.86233 9.98231 1.25285L6.14939 5.08577L2.31648 1.25285C1.92596 0.86233 1.29279 0.86233 0.902269 1.25285C0.511744 1.64338 0.511744 2.27654 0.902269 2.66707L4.73518 6.49998L0.902268 10.3329C0.511744 10.7234 0.511744 11.3566 0.902268 11.7471C1.29279 12.1376 1.92596 12.1376 2.31648 11.7471L6.14939 7.91419L9.98231 11.7471Z"
            fill="#1A1A1A"
          />
        </svg>
      </button>
    </div>
  `,
})
export class FileBoxComponent {
  @Input({ required: true }) fileName!: string;
  @Input() showRemove = false;

  @Input() styleClass = '';

  @Output() remove = new EventEmitter<void>();
}
