import { isMissed } from '@/core/helpers';
import { MapperPipe } from '@/shared/pipes';

import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output,
} from '@angular/core';

@Component({
  selector: 'sw-info-layout',
  standalone: true,
  imports: [MapperPipe],
  changeDetection: ChangeDetectionStrategy.OnPush,
  template: `
    <div
      class="header w-full bg-black bg-contain bg-center bg-repeat-x px-5 pb-[102px] pt-[42px] md:px-0"
      [style.background-image]="headerBgImg | swMapper : bgImageMapper"
    >
      <div class="m-auto flex max-w-[864px] flex-col">
        <div class="mb-[24px] flex flex-row justify-end">
          <button
            aria-label="Закрыть"
            class="button-rounded"
            (click)="closeDialog.emit()"
          >
            <svg
              width="20px"
              height="20px"
              viewBox="0 0 20 20"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M17.4649 18.8793C17.8555 19.2698 18.4886 19.2698 18.8791 18.8793C19.2697 18.4888 19.2697 17.8556 18.8791 17.4651L11.4142 10.0002L18.8791 2.53523C19.2697 2.14471 19.2697 1.51154 18.8791 1.12102C18.4886 0.730495 17.8555 0.730495 17.4649 1.12102L10 8.58594L2.53508 1.12102C2.14456 0.730494 1.51139 0.730494 1.12087 1.12102C0.730343 1.51154 0.730343 2.14471 1.12087 2.53523L8.58579 10.0002L1.12087 17.4651C0.730341 17.8556 0.730341 18.4888 1.12087 18.8793C1.51139 19.2698 2.14455 19.2698 2.53508 18.8793L10 11.4144L17.4649 18.8793Z"
                fill="white"
              />
            </svg>
          </button>
        </div>
        <div>
          <ng-content select="[layout-header]" />
        </div>
      </div>
    </div>

    <div class="z-10 mx-auto -mt-[50px] flex w-full max-w-[864px] flex-col">
      <ng-content />
    </div>
  `,
  styles: [
    `
      :host {
        @apply flex min-h-screen w-screen flex-col bg-[#FAFAFA] pb-[165px];
      }

      .header {
        clip-path: ellipse(100% 60% at 50% 40%);
      }
    `,
  ],
})
export class InfoLayoutComponent {
  @Input()
  headerBgImg?: 'gem' | 'heart';

  protected readonly bgImageMapper = (img?: 'gem' | 'heart') => {
    if (isMissed(img)) {
      return '';
    }

    return `url(/assets/img/info-bg-${img}.svg)`;
  };

  @Output() closeDialog = new EventEmitter<void>();
}
