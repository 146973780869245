import { CommonModule } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  inject,
  Input,
} from '@angular/core';
import { FormControl, FormsModule, NgControl } from '@angular/forms';
import { SliderModule } from 'primeng/slider';

@Component({
  selector: 'sw-range-slider',
  standalone: true,
  imports: [CommonModule, SliderModule, FormsModule],
  changeDetection: ChangeDetectionStrategy.OnPush,
  template: `<div
    class="w-full"
    [style.paddingLeft.%]="scalePadding"
    [style.paddingRight.%]="scalePadding"
  >
    <div class="relative m-auto mb-2 w-[98%]">
      <p-slider
        class="w-full"
        [ngStyle]="{
          '--slider-color': color === 'green' ? '#1AA654' : '#CF3535'
        }"
        [ngModel]="control?.value"
        (ngModelChange)="control?.setValue($event)"
        [step]="step"
        [min]="min"
        [max]="max"
      />
    </div>
    <div
      class="flex w-[110%] flex-row"
      [style.width.%]="sliderWidth"
      [style.marginLeft.%]="-scalePadding"
    >
      <button
        *ngFor="let s of steps"
        (click)="control?.setValue(s)"
        type="button"
        class="flex-1 select-none text-center text-xs font-bold leading-[22px] text-black"
      >
        {{ s }}
      </button>
    </div>
  </div>`,
})
export class RangeSliderComponent {
  readonly ngControl = inject(NgControl, { optional: true });

  get control() {
    return this.ngControl?.control as FormControl | null;
  }

  @Input() color?: 'red' | 'green' = 'green';

  private _min = 1;
  @Input() set min(m: number) {
    if (this.max < m) {
      throw Error(`max value ${this.max} less than ${m}`);
    }
  }

  get min() {
    return this._min;
  }

  private _max = 5;
  @Input() set max(m: number) {
    if (this.min > m) {
      throw Error(`min value ${this.min} bigger than ${m}`);
    }

    this._max = m;
  }

  get max() {
    return this._max;
  }

  @Input() step = 1;

  get stepsCount() {
    return this.max - this.min + 1;
  }

  get sliderWidth() {
    return 100 + (this.step / this.stepsCount) * 100;
  }

  get scalePadding() {
    return (this.step / this.stepsCount) * 50;
  }

  protected readonly steps = [...new Array(this.stepsCount)].map(
    (_, i) => i + 1
  );
}
