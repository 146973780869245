import { Injectable } from '@angular/core';
import { BehaviorSubject, debounceTime, filter } from 'rxjs';
import { TrackActivity } from './track-activity';

@Injectable({ providedIn: 'root' })
export class TrackActivityService {
  private readonly actions$ = new BehaviorSubject<TrackActivity[]>([]);

  constructor() {
    /**
     * wait for actions and save action bulk
     * after all refresh actions
     */
    this.actions$
      .asObservable()
      .pipe(
        filter((v) => v.length > 0),
        debounceTime(400)
      )
      .subscribe(() => {
        this.actions$.next([]);
      });
  }

  push(action: TrackActivity) {
    this.actions$.next([action, ...this.actions$.value]);
  }
}
