import { CommentsService } from '@/core/services/comments.service';
import { TopicService } from '@/core/services/topics.service';
import { MainLayout } from '@/widgets/main-layout';
import { SidebarTopicsComponent } from '@/widgets/sidebar';
import { inject } from '@angular/core';
import { Route, Router, UrlSegment } from '@angular/router';
import { catchError, map, of } from 'rxjs';
import { ThreadTitleResolver } from './thread-title.resolver';
// import { isMissed } from '@/core/helpers';

const canMatchThread = (_: Route, segments: UrlSegment[]) => {
  const route = inject(Router);
  const comments = inject(CommentsService);

  const queryParams = route.getCurrentNavigation()?.initialUrl
    .queryParams as Partial<{ topic: string }>;

  const [, projectId, commentId] = segments;

  // if (isMissed(queryParams.topic)) {
  //   return topics.getTopics(projectId.path).pipe(
  //     map(([topic]) => {
  //       if (isMissed(topic)) {
  //         return true;
  //       }

  //       return route.createUrlTree(
  //         ['/', 'thread', projectId.path, commentId?.path].filter(Boolean),
  //         {
  //           queryParams: { topic: topic.id },
  //           queryParamsHandling: 'merge',
  //         }
  //       );
  //     })
  //   );
  // }

  return comments
    .fetchThread({
      projectId: projectId?.path,
      parentId: commentId?.path,
      topicId: queryParams?.topic,
    })
    .pipe(
      map(() => true),
      catchError(() => of(false))
    );
};

const resetThread = () => {
  const comments = inject(CommentsService);
  const topics = inject(TopicService);

  [comments, topics].forEach((s) => s.reset());

  return true;
};

export const THREAD_ROUTES: Route = {
  path: 'thread',
  // path: 'thread',
  loadComponent: () => MainLayout,
  providers: [
    CommentsService,
    // TopicService,
    // {
    //   provide: NavPrevService,
    //   useClass: ThreadNavPrevService,
    // },
  ],
  canMatch: [canMatchThread],
  canDeactivate: [resetThread],

  children: [
    {
      path: ':id',
      loadComponent: () => import('./project.page').then((c) => c.ProjectPage),
      pathMatch: 'full',
      title: ThreadTitleResolver,
      data: {
        showBackBtn: true,
      },
    },
    {
      path: ':id/:commentId',
      loadComponent: () => import('./project.page').then((c) => c.ProjectPage),
      title: ThreadTitleResolver,
      data: {
        showBackBtn: true,
      },
    },
    {
      path: '',
      loadComponent: () => SidebarTopicsComponent,
      outlet: 'right-sidebar',
    },
  ],
};
