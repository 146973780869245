import { CommentStatistics } from '@/core/models/comment-statistics.model';
import { DecimalPipe } from '@angular/common';
import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

@Component({
  selector: 'sw-comment-statistics-superuser',
  standalone: true,
  imports: [DecimalPipe],
  changeDetection: ChangeDetectionStrategy.OnPush,
  template: `
    <div class="">
      <ul class="flex flex-row flex-wrap gap-2 text-xs">
        <li>
          <span class="font-medium">Просмотров</span>:
          {{ (statistics || defaultStats).views }}
        </li>
        <li>
          <span class="font-medium">Лайков</span>:
          {{
            (statistics || defaultStats).likesConversion
              | number : '1.0-2' : 'en'
          }}% ({{ (statistics || defaultStats).likes }})
        </li>
        <li>
          <span class="font-medium">Суперлайков</span>:
          {{
            (statistics || defaultStats).superLikesConversion
              | number : '1.0-2' : 'en'
          }}% ({{ (statistics || defaultStats).superLikes }})
        </li>
        <li>
          <span class="font-medium">Дизлайков</span>:
          {{
            (statistics || defaultStats).dislikesConversion
              | number : '1.0-2' : 'en'
          }}% ({{ (statistics || defaultStats).dislikes }})
        </li>
      </ul>
    </div>
  `,
})
export class CommentStatisticsSuperuserComponent {
  @Input()
  statistics?: CommentStatistics;
  protected readonly defaultStats = new CommentStatistics();
}
