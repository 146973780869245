import { Pipe, PipeTransform } from '@angular/core';
import TimeAgo, { Labels } from 'javascript-time-ago';
import ru from 'javascript-time-ago/locale/ru';

TimeAgo.addDefaultLocale(ru);
// import ru from 'javascript-time-ago/locale/ru';

// TimeAgo.addDefaultLocale(ru);

const timeAgoLabels: Labels = {
  year: '',
  month: '',
  week: '',
  day: '',
  hour: '',
  minute: {
    past: {
      one: '{0}м',
      other: '{0}м',
    },
    future: {
      one: '{0}м',
      other: '{0}м',
    },
  },
  second: {
    past: {
      one: '{0}c',
      other: '{0}c',
    },
    future: {
      one: '{0}c',
      other: '{0}c',
    },
  },
};
TimeAgo.addLabels('ru', 'custom', timeAgoLabels);

@Pipe({
  name: 'swTimeAgo',
  standalone: true,
})
export class TimeAgoPipe implements PipeTransform {
  transform(value: Date | string | number, suffix = ''): string {
    const timeAgo = new TimeAgo('en-US');
    return `${timeAgo
      .format(new Date(value), 'mini')
      .replace(' ', '')} ${suffix}`;
  }
}
