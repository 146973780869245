export const COMMENT_PLURAL: [string, string, string] = [
  'комментарий',
  'комментария',
  'комментариев',
];

export const PROJECT_PLURAL: [string, string, string] = [
  'проект',
  'проекта',
  'проектов',
];

export const GEM_PLURAL: [string, string, string] = ['гем', 'гема', 'гемов'];
