import { extractFileName } from '@/core/helpers/extract-filename';
import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'filename',
  standalone: true,
})
export class FileNamePipe implements PipeTransform {
  transform(value: string): string {
    return extractFileName(value);
  }
}
