import { Directive, inject } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { map } from 'rxjs';

@Directive()
export abstract class BaseHeader {
  protected readonly router = inject(Router);
  protected readonly activatedRoute = inject(ActivatedRoute);

  protected handleTermChange(term: string) {
    this.router.navigate([], {
      queryParams: {
        q: term || null,
      },
      queryParamsHandling: 'merge',
    });
  }

  protected readonly query$ = this.activatedRoute.queryParams.pipe(
    map(({ q }) => q)
  );
}
