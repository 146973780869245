import {
  DestroyRef,
  Directive,
  ElementRef,
  PLATFORM_ID,
  inject,
} from '@angular/core';
import { TrackActivityService } from './track-activity.service';
import { TrackActivity } from './track-activity';
import { distinctUntilChanged, filter, fromEvent, map, merge } from 'rxjs';
import { DOCUMENT, isPlatformServer } from '@angular/common';
import { isPresent } from '@/core/helpers';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';

// eslint-disable-next-line @angular-eslint/directive-selector
@Directive({ selector: 'a, button', standalone: true })
export class TrackActivityDirective {
  private readonly id: string = '';
  private readonly cords: { x: number; y: number } = { x: 0, y: 0 };

  private readonly trackService = inject(TrackActivityService);

  private readonly document = inject(DOCUMENT);

  private readonly destroyRef = inject(DestroyRef);

  private readonly elementRef: ElementRef<
    HTMLAnchorElement | HTMLButtonElement
  > = inject(ElementRef);

  private readonly platformId = inject(PLATFORM_ID);

  constructor() {
    if (isPlatformServer(this.platformId)) {
      return;
    }

    const trackId = this.elementRef.nativeElement.getAttribute('attr-trackid');
    const nodeName = this.elementRef.nativeElement.nodeName;
    const { top, left, height, width } =
      this.elementRef.nativeElement.getBoundingClientRect();

    this.id = `${nodeName}-${trackId + '-'}t${top}l${left})}`;

    this.cords = { x: left + width / 2, y: top + height / 2 };

    const mouseover$ = fromEvent(this.document, 'mousemove').pipe(
      filter((e) => isPresent(e.target)),
      map((e) =>
        this.elementRef.nativeElement.contains(e.target as HTMLElement)
      ),
      distinctUntilChanged(),
      filter((over, index) => !(index === 0 && !over)),
      map((v) => (v ? 'mouseover' : 'mouseout'))
    );

    const click$ = fromEvent(this.elementRef.nativeElement, 'click').pipe(
      map(() => 'click')
    );

    merge(mouseover$, click$)
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe({
        next: (type) => {
          this.trackService.push(new TrackActivity(this.id, type, this.cords));
        },
      });
  }
}
