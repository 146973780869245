import { ProjectTopicListComponent } from '@/pages/project';
import { ChangeDetectionStrategy, Component } from '@angular/core';

@Component({
  selector: 'sw-sidebar-topics',
  standalone: true,
  imports: [ProjectTopicListComponent],
  changeDetection: ChangeDetectionStrategy.OnPush,
  template: ` <div>
    <sw-project-topic-list />
  </div>`,
})
export class SidebarTopicsComponent {}
