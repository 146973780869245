import {
  OverlayDirective,
  ReactionOnboardingDirective,
} from '@/shared/ui/portal';
import { AsyncPipe, NgClass } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  Input,
  ViewChild,
  inject,
} from '@angular/core';
import { SVGComponent } from '@shared/ui/svg.component';

import { RouterModule } from '@angular/router';
import { ReactionButtonComponent } from '../reaction-button.component';
import { LikeProjectDirective } from './like-project.directive';

@Component({
  selector: 'sw-like-reaction',
  standalone: true,
  imports: [
    SVGComponent,
    AsyncPipe,
    NgClass,
    ReactionButtonComponent,
    OverlayDirective,
    ReactionOnboardingDirective,
    RouterModule,
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
  template: `
    <sw-reaction-button
      [value]="projectLikes"
      [loading]="(loading$ | async) || false"
      [swOverlay]="successMsg"
      #overlay="swOverlay"
      (clickBtn)="toggleLike()"
      swReactionOnboarding
      #reactionOnBoarding="swReactionOnboarding"
      (hoverIcon)="reactionOnBoarding.open()"
      reactionHeader="Карма"
      [reactionContent]="aboutMsg"
    >
      <sw-svg
        icon="heart"
        width="19px"
        height="17px"
        class="text-[17px] "
        [ngClass]="{
          'text-black/50': !isLiked,
          'text-accent': isLiked
        }"
      />
    </sw-reaction-button>

    <ng-template #successMsg>
      <p class="w-[146px] p-5 text-center text-xs">
        Супер! Вы внесли свой вклад в проект. 😀
      </p>
    </ng-template>

    <ng-template #aboutMsg>
      <div class="space-y-2 pr-5">
        <p class="text-xs">
          Чем больше лайков у поста, тем выше вознаграждение в виде кармы
          получают пользователи за комментарии и активность под этим постом. Чем
          больше кармы получает пользователь, тем весомее становятся его голос и
          комментарии. Карму также можно менять на Гемы (внутреннюю валюту).
        </p>
        <div class="">
          <a
            class="link"
            routerLink="."
            [queryParams]="{ info: 'karma' }"
            queryParamsHandling="merge"
            >Узнать подробнее о лайках и карме.</a
          >
        </div>
      </div>
    </ng-template>
  `,
})
export class LikeReactionComponent {
  @ViewChild(OverlayDirective) overlay?: OverlayDirective;

  @Input({ required: true })
  projectLikes!: number;

  @Input({ required: true })
  isLiked!: boolean;

  private readonly ctx = inject(LikeProjectDirective, { optional: true });

  protected readonly loading$ = this.ctx?.loading$;

  get recipient() {
    return this.ctx?.recipient;
  }

  toggleLike() {
    this.overlay?.close();

    this.ctx?.toggleLike().subscribe({
      next: () => {
        if (!this.isLiked) {
          this.overlay?.open();
        }
      },
    });
  }
}
