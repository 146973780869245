import { OnboardingService } from '@/core/services/onboarding.service';
import { StorageService } from '@/core/services/storage.service';
import { isPlatformBrowser } from '@angular/common';
import {
  DestroyRef,
  Directive,
  ElementRef,
  OnInit,
  PLATFORM_ID,
  inject,
} from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { first, fromEvent } from 'rxjs';
import { COMPLETE_GUIDE_ELEMENTS } from './complete-guide.token';

const SKIP_FEED_GUIDE = 'SKIP_FEED_GUIDE';

@Directive({ selector: '[swCompleteGuide]', standalone: true })
export class CompleteGuideDirective implements OnInit {
  private readonly storageService = inject(StorageService);

  private readonly triggers = inject(COMPLETE_GUIDE_ELEMENTS);

  private readonly onboarding = inject(OnboardingService);

  private readonly platformId = inject(PLATFORM_ID);
  protected readonly isClient = isPlatformBrowser(this.platformId);

  private readonly elementRef = inject(ElementRef);
  private readonly skip = !!this.storageService.get<boolean>(SKIP_FEED_GUIDE);

  private readonly destroyRef = inject(DestroyRef);

  ngOnInit(): void {
    if (!this.isClient || this.skip) {
      return;
    }

    fromEvent<PointerEvent>(this.elementRef.nativeElement, 'click')
      .pipe(takeUntilDestroyed(this.destroyRef), first())
      .subscribe(this.handleClick.bind(this));
  }

  handleClick(event: PointerEvent) {
    const target = event.target as HTMLElement | null;
    if (target === null) {
      return;
    }

    const checkContains = (v: string) => target.closest(v) !== null;

    const isTriggered = Array.isArray(this.triggers)
      ? this.triggers.some(checkContains)
      : checkContains(this.triggers);

    if (isTriggered) {
      this.onboarding.stop();
    }
  }
}
