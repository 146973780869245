import { AsyncPipe } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output,
} from '@angular/core';
import { FormsModule } from '@angular/forms';
import { LetDirective } from '@shared/directives';
import { CheckboxModule } from 'primeng/checkbox';

@Component({
  selector: 'sw-confirm-dialog-container',
  standalone: true,
  imports: [CheckboxModule, AsyncPipe, FormsModule, LetDirective],
  changeDetection: ChangeDetectionStrategy.OnPush,
  template: `<div class="space-y-5">
    <div class="">
      <ng-content select="[content]" />
    </div>

    <div class="flex flex-row space-x-3">
      <button class="button flex-1 py-2" (click)="btnClick.emit(false)">
        {{ no || 'Отменить' }}
      </button>
      <button
        class="button  button-primary flex-1 py-2"
        (click)="btnClick.emit(true)"
      >
        {{ yes || 'Сохранить' }}
      </button>
    </div>
  </div>`,
})
export class ConfirmDialogContainerComponent {
  @Input() yes?: string;

  @Input() no?: string;

  @Output()
  btnClick = new EventEmitter<boolean>();
}
