import { CommonModule, KeyValue } from '@angular/common';
import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { ValidationErrors } from '@angular/forms';

import { ErrorMessagesPipe } from './error.pipe';

@Component({
  selector: 'sw-error',
  standalone: true,
  imports: [CommonModule, ErrorMessagesPipe],
  changeDetection: ChangeDetectionStrategy.OnPush,
  template: `
    <div *ngIf="errors" class="mt-1 space-y-[2px]">
      <p
        *ngFor="let error of errors | keyvalue; trackBy: trackByFn"
        class="text-xs text-red"
      >
        {{ error | swErrorMessages }}
      </p>
    </div>
  `,
})
export class ErrorComponent {
  @Input({ required: true }) errors: ValidationErrors | undefined | null = null;

  trackByFn(_index: number, item: KeyValue<string, unknown>) {
    return item.key;
  }
}
