import { Sentiment } from '@/core/types';
import {
  ChangeDetectionStrategy,
  Component,
  DestroyRef,
  OnDestroy,
  OnInit,
  ViewEncapsulation,
  inject,
} from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { FormGroup, FormControl, ReactiveFormsModule } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { BindQueryParamsFactory } from '@ngneat/bind-query-params';
import { DropdownModule } from 'primeng/dropdown';
import { distinctUntilChanged } from 'rxjs';

@Component({
  selector: 'sw-thread-filter',
  standalone: true,
  imports: [DropdownModule, ReactiveFormsModule],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
  template: `
    <form class="flex flex-row flex-wrap gap-5" [formGroup]="filters">
      <div class="island-base flex-1">
        <p-dropdown
          formControlName="type"
          [options]="sentimentList"
          optionLabel="label"
          optionValue="value"
          placeholder="Все комментарии"
          class="w-full"
          emptyMessage=" "
        />
      </div>
      <div class="island-base flex-1">
        <p-dropdown
          formControlName="sort"
          [options]="sortList"
          optionLabel="label"
          optionValue="value"
          placeholder="Сначала самые значимые"
          class="w-full"
          emptyMessage=" "
        />
      </div>
    </form>
  `,
  styles: [
    `
      sw-thread-filter {
        .p-dropdown {
          @apply w-full rounded-md bg-white py-3 pl-3 pr-2;

          .p-dropdown-trigger {
            @apply w-[20px];
          }
          .p-dropdown-label {
            @apply text-[14px];
            &.p-placeholder {
              @apply text-black;
            }
          }
        }
      }
    `,
  ],
})
export class ThreadFilterComponent implements OnDestroy, OnInit {
  private readonly bindQueryFactory = inject(BindQueryParamsFactory);
  private readonly activatedRoute = inject(ActivatedRoute);
  private readonly destroyRef = inject(DestroyRef);

  protected readonly sentimentList: { label: string; value: Sentiment | '' }[] =
    [
      {
        label: 'Все комментарии',
        value: '',
      },
      {
        label: 'Только положительные',
        value: 'positive',
      },
      {
        label: 'Только отрицательные',
        value: 'negative',
      },
    ];

  protected readonly sortList: { label: string; value: string }[] = [
    {
      label: 'Сначала самые значимые',
      value: '',
    },
    {
      label: 'Сначала самые новые',
      value: 'new',
    },
    {
      label: 'Сначала обсуждаемые',
      value: 'hot',
    },
  ];

  protected readonly typeControl = new FormControl<string>('', {
    nonNullable: true,
  });

  protected readonly filters = new FormGroup({
    sort: new FormControl<string>('', { nonNullable: true }),
    type: this.typeControl,
  });

  private readonly bindQueryParamsManager = this.bindQueryFactory
    .create<{ sort: string; type: string }>([
      { queryKey: 'sort' },
      { queryKey: 'type' },
    ])
    .connect(this.filters);

  ngOnInit(): void {
    this.activatedRoute.queryParams
      .pipe(distinctUntilChanged(), takeUntilDestroyed(this.destroyRef))
      .subscribe((params) => {
        if (!params['type']) {
          this.typeControl.patchValue('');
        }
        this.bindQueryParamsManager.syncAllDefs();
      });
  }

  ngOnDestroy(): void {
    this.bindQueryParamsManager.destroy();
  }
}
