import { isPlatformBrowser } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { Injectable, OnDestroy, PLATFORM_ID, inject } from '@angular/core';
import { Notification } from '@core/types/notification.type';
import {
  Subject,
  catchError,
  exhaustMap,
  map,
  merge,
  of,
  startWith,
  take,
  tap,
  timer,
} from 'rxjs';

type NotificationResponse = {
  message: string;
  createdAt: string;
  meta: { isRead: boolean } & Record<string, unknown>;
}[];

type NotificationUnreadResponse = {
  unread: number;
};

@Injectable({ providedIn: 'root' })
export class NotificationApiService {
  private readonly httpClient = inject(HttpClient);

  fetchList() {
    return this.httpClient.get<NotificationResponse>(`/notification/list`);
  }

  getUpdates() {
    return this.httpClient
      .get<NotificationUnreadResponse>(`/notification/count`)
      .pipe(
        take(1),
        map(({ unread }) => unread || 0)
      );
  }
}

@Injectable({ providedIn: 'root' })
export class NotificationService implements OnDestroy {
  private readonly platformId = inject(PLATFORM_ID);

  private readonly api = inject(NotificationApiService);
  private readonly reset$ = new Subject<void>();

  ngOnDestroy(): void {
    this.reset$.complete();
  }

  getList() {
    return this.api.fetchList().pipe(
      tap(() => {
        this.reset$.next();
      }),
      map((list) => {
        return list.map(
          (item) =>
            ({
              message: item.message,
              createdAt: item.createdAt,
              isRead: item.meta.isRead,
              meta: item.meta,
            } satisfies Notification)
        );
      }),
      catchError(() => of([]))
    );
  }

  subscribeUpdates() {
    if (!isPlatformBrowser(this.platformId)) {
      return of(0);
    }

    return merge(
      this.reset$.pipe(map(() => 0)),

      timer(0, 15_000).pipe(
        startWith(0),
        exhaustMap(() => this.api.getUpdates().pipe(catchError(() => of(0))))
      )
    );
  }
}
