import { CategoriesService, UserService } from '@/core';

import { DialogRef } from '@angular/cdk/dialog';
import { AsyncPipe, NgIf } from '@angular/common';
import { ChangeDetectionStrategy, Component, inject } from '@angular/core';

import { LetDirective } from '@/shared/directives';
import { ShortNumberPipe } from '@/shared/pipes';
import { FormControl, ReactiveFormsModule } from '@angular/forms';
import { DropdownModule } from 'primeng/dropdown';
import { map } from 'rxjs';
import { InfoFAQComponent } from '../info-faq.component';
import { InfoHeaderComponent } from '../info-header.component';
import { InfoLayoutComponent } from '../info-layout.component';
import { LikeInfoFeatureListComponent } from './like-info-feature-list.component';
import { LikeInfoGemExchangeComponent } from './like-info-gem-exchange.component';
import { LikeInfoExperienceTrackerComponent } from './like-info-progress-tracker.component';

@Component({
  selector: 'sw-like-info',
  standalone: true,
  imports: [
    NgIf,
    AsyncPipe,
    ShortNumberPipe,
    InfoLayoutComponent,
    InfoHeaderComponent,
    InfoFAQComponent,
    LikeInfoFeatureListComponent,
    LikeInfoExperienceTrackerComponent,
    LikeInfoGemExchangeComponent,
    // LikeInfoTopsComponent,
    LetDirective,
    ReactiveFormsModule,
    DropdownModule,
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
  templateUrl: './like-info.component.html',
  styleUrls: [],
})
export class LikeInfoComponent {
  protected readonly dialogRef = inject(DialogRef);
  protected readonly userLikes$ = inject(UserService).userLikes$;
  protected readonly categories$ = inject(CategoriesService)
    .fetchCategoriesWithCounts()
    .pipe(
      map((categories) => {
        return categories.top;
      })
    );

  protected readonly categoryControl = new FormControl<string>('agro', {
    nonNullable: true,
  });

  protected readonly faq = [
    {
      title: 'Как начисляется карма?',
      content:
        'Например, вы написали комментарий к посту в категории “Сельское хозяйство”. Предположим, этот пост набрал 100 лайков. Если значимость вашего комментария окажется равной 5, то вы получите 50 кармы в категории “Сельское хозяйство”. Если значимость окажется равной 4, то вы получите 30 кармы, если 3, то 15 кармы и так далее.',
    },
    {
      title: 'Зачем нужна карма?',
      content:
        'Карму в категории можно перевести в опыт в категории или получить гемы. Чем больше опыта, тем выше вес вашей оценки значимости комментариев. Также опытные пользователи получают возможность оценивать значимость не только с помощью лайков, но и с помощью пятибалльной шкалы.',
    },
  ];
}
