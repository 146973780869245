import { Injectable } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import {
  EditorTopic,
  TopicEditorFormService,
} from './topic-editor-form-service';

@Injectable()
export class ProjectTopicEditorService extends TopicEditorFormService {
  createTopicGroup(topic: Partial<EditorTopic> = {}) {
    const agreementControl = new FormControl<number | null>(
      topic.agreement ?? null
    );

    const commentControl = new FormControl<string>(topic.comment || '', {
      nonNullable: true,
      validators: [
        Validators.required,
        Validators.minLength(2),
        Validators.maxLength(4096),
      ],
    });

    const fg = new FormGroup({
      name: new FormControl<string>(topic.name || '', {
        nonNullable: true,
        validators: [Validators.minLength(2), Validators.maxLength(50)],
      }),
      comment: commentControl,
      agreement: agreementControl,
    });

    return fg;
  }
}
