import { BreakpointObserver, BreakpointState } from '@angular/cdk/layout';
import { isPlatformServer } from '@angular/common';
import {
  ChangeDetectorRef,
  DestroyRef,
  Directive,
  Input,
  OnInit,
  PLATFORM_ID,
  TemplateRef,
  ViewContainerRef,
  inject,
  ɵstringify,
} from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { DeviceDetectorService } from 'ngx-device-detector';
import { distinctUntilChanged } from 'rxjs/operators';
import { REQUEST } from 'src/express.tokens';

@Directive({
  selector: '[swBreakpointDesktop]',
  standalone: true,
})
export class BreakpointDesktopDirective implements OnInit {
  private elseTemplateRef: TemplateRef<unknown> | null = null;

  private inverse = false;

  @Input()
  set swBreakpointDesktop(value: '' | boolean) {
    if (value === '') {
      this.inverse = false;
    } else {
      this.inverse = !value;
    }
  }

  @Input()
  set swBreakpointDesktopElse(templateRef: TemplateRef<unknown> | null) {
    assertTemplate('ngIfElse', templateRef);
    this.elseTemplateRef = templateRef;
  }

  private isRendered = false;
  private isElseRendered = false;

  private readonly templateRef = inject(TemplateRef<unknown>);
  private readonly viewContainerRef = inject(ViewContainerRef);
  private readonly cdr = inject(ChangeDetectorRef);
  private readonly destroyRef = inject(DestroyRef);
  private readonly breakpointObserver = inject(BreakpointObserver);
  private readonly platformId = inject(PLATFORM_ID);

  private readonly request = inject(REQUEST, { optional: true });
  private readonly deviceService = inject(DeviceDetectorService);

  ngOnInit(): void {
    if (isPlatformServer(this.platformId)) {
      /**
       * prerender mobile view when request headers detected as mobile device
       */
      const isMobile = this.deviceService.isMobile(
        this.request?.headers['user-agent']
      );
      this.handleResize({ matches: !isMobile, breakpoints: {} });
      return;
    }

    this.breakpointObserver
      .observe(['(min-width: 768px)'])
      .pipe(
        takeUntilDestroyed(this.destroyRef),
        distinctUntilChanged((p, c) => p.matches === c.matches)
      )
      .subscribe(this.handleResize);
  }

  handleResize = ({ matches }: BreakpointState) => {
    if (this.inverse ? !matches : matches) {
      if (!this.isRendered) {
        this.viewContainerRef.clear();
        this.viewContainerRef.createEmbeddedView(this.templateRef);
        this.isElseRendered = false;
        this.isRendered = true;
      }
    } else {
      if (!this.isElseRendered) {
        this.viewContainerRef.clear();
        this.isRendered = false;

        if (this.elseTemplateRef) {
          this.viewContainerRef.createEmbeddedView(this.elseTemplateRef);
          this.isElseRendered = true;
        }
      }
    }

    this.cdr.markForCheck();
  };
}

function assertTemplate(
  property: string,
  templateRef: TemplateRef<unknown> | null
): void {
  const isTemplateRefOrNull = !!(
    !templateRef || templateRef.createEmbeddedView
  );
  if (!isTemplateRefOrNull) {
    throw new Error(
      `${property} must be a TemplateRef, but received '${ɵstringify(
        templateRef
      )}'.`
    );
  }
}
