import {
  ConnectionPositionPair,
  Overlay,
  OverlayRef,
} from '@angular/cdk/overlay';
import { ComponentPortal } from '@angular/cdk/portal';
import { DOCUMENT } from '@angular/common';
import {
  DestroyRef,
  Directive,
  ElementRef,
  EventEmitter,
  InjectionToken,
  Injector,
  Input,
  OnDestroy,
  Output,
  TemplateRef,
  inject,
} from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import {
  Subject,
  delay,
  distinctUntilChanged,
  fromEvent,
  map,
  of,
  startWith,
  switchMap,
  takeUntil,
  throttleTime,
  timer,
} from 'rxjs';

import { OverlayComponent } from './overlay.component';

export type OverlayData = {
  content: TemplateRef<unknown> | string;
  styleClass?: string;
};

export const OVERLAY_DATA = new InjectionToken<OverlayData>('Overlay data');

@Directive({
  selector: '[swOverlay]',
  standalone: true,
  exportAs: 'swOverlay',
})
export class OverlayDirective implements OnDestroy {
  @Input() styleClass?: string;
  @Input() timeout = 5_000;

  @Input({ required: true }) swOverlay!: string | TemplateRef<unknown>;

  @Output() clickOutside = new EventEmitter<MouseEvent>();

  private readonly isOpen = new Subject<boolean>();

  private readonly document = inject(DOCUMENT);

  private readonly destroy$ = new Subject<void>();

  overlayRef: OverlayRef | null = null;

  constructor(
    private readonly elementRef: ElementRef<HTMLElement>,
    private readonly overlay: Overlay,
    private readonly destroyRef: DestroyRef
  ) {
    this.isOpen
      .asObservable()
      .pipe(
        switchMap((v) => {
          if (v && this.timeout > 0) {
            return timer(this.timeout).pipe(
              map(() => false),
              startWith(v)
            );
          }
          return of(v);
        }),
        takeUntilDestroyed(this.destroyRef),
        distinctUntilChanged(),
        delay(100)
      )
      .subscribe({
        next: (isOpen) => {
          if (isOpen) {
            this.show();
          } else {
            this.destroy();
          }
        },
      });
  }

  open() {
    if (this.overlayRef) {
      this.close();
    }

    this.isOpen.next(true);
  }

  close() {
    this.isOpen.next(false);
    this.destroy$.next();
  }

  ngOnDestroy(): void {
    this.destroy();
    this.destroy$.complete();
  }

  private show = () => {
    this.overlayRef = this.overlay.create({
      positionStrategy: this.overlay
        .position()
        .flexibleConnectedTo(this.elementRef)
        .withPositions([
          new ConnectionPositionPair(
            { originX: 'start', originY: 'top' },
            { overlayX: 'end', overlayY: 'top' },
            10
          ),
          new ConnectionPositionPair(
            { originX: 'center', originY: 'center' },
            { overlayX: 'end', overlayY: 'top' },
            10
          ),
          new ConnectionPositionPair(
            { originX: 'start', originY: 'top' },
            { overlayX: 'end', overlayY: 'center' }
          ),
          new ConnectionPositionPair(
            { originX: 'end', originY: 'top' },
            { overlayX: 'start', overlayY: 'center' }
          ),
        ]),
      scrollStrategy: this.overlay.scrollStrategies.close({
        threshold: 200,
      }),
    });

    fromEvent(this.document, 'click')
      .pipe(takeUntil(this.destroy$), distinctUntilChanged(), throttleTime(100))
      .subscribe((e) => {
        const target = e.target as HTMLElement;

        if (
          !(
            this.elementRef.nativeElement === target ||
            this.elementRef.nativeElement.contains(target) ||
            target.closest('sw-overlay')
          )
        ) {
          this.clickOutside.emit(e as MouseEvent);
        }
      });

    const injector: Injector = Injector.create({
      providers: [
        {
          provide: OVERLAY_DATA,
          useValue: { content: this.swOverlay, styleClass: this.styleClass },
        },
      ],
    });
    const component = new ComponentPortal(OverlayComponent, null, injector);
    this.overlayRef?.attach(component);
  };

  private destroy = () => {
    this.overlayRef?.detach();
    this.overlayRef?.dispose();

    this.destroy$.next();
  };
}
