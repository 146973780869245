import { CategoriesService, GEM_PLURAL, UserService } from '@/core';

import { LetDirective } from '@/shared/directives';
import { PluralPipe, ShortNumberPipe } from '@/shared/pipes';
import { AsyncPipe, NgIf } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  Input,
  inject,
} from '@angular/core';
import {
  BehaviorSubject,
  combineLatest,
  distinctUntilChanged,
  map,
  switchMap,
} from 'rxjs';
import { LikeExchangeGemService } from './like-exchange-gem.service';
import { provideLikeExchange } from './like-exchange.service';
import { LikeInfoFormComponent } from './like-info-form.component';

@Component({
  selector: 'sw-like-info-gem-exchange',
  standalone: true,
  imports: [
    LikeInfoFormComponent,
    AsyncPipe,
    NgIf,
    LetDirective,
    PluralPipe,
    ShortNumberPipe,
  ],
  providers: [provideLikeExchange(LikeExchangeGemService)],
  changeDetection: ChangeDetectionStrategy.OnPush,
  template: `
    <div class="space-y-[30px]">
      <h2 class="info-title">Обменять карму на гемы</h2>

      <div class="island space-y-3" *ngIf="category$ | async as c">
        <p class="text-sm text-gray-400">
          В категории <strong>{{ c.name }}</strong>
          {{ c.gems | swShortNumber }}
          {{ c.gems | swPlural : gemPlural }}
        </p>
        <div class="" *swLet="(maxValue$ | async) || 0 as maxValue">
          <sw-like-info-form
            [maxValue]="maxValue"
            placeholder="Сколько обменять"
            buttonText="Обменять"
            [category]="category"
            [disabled]="maxValue === 0"
          />
        </div>
      </div>
    </div>
  `,
})
export class LikeInfoGemExchangeComponent {
  private readonly categoriesService = inject(CategoriesService);
  private readonly userService = inject(UserService);

  private readonly categorySubject$ = new BehaviorSubject<string>('');

  protected readonly userLikes$ = this.categorySubject$.asObservable().pipe(
    switchMap((category) => {
      return this.userService.userLikes$.pipe(
        map((likes) => likes[category] ?? 0)
      );
    }),
    distinctUntilChanged()
  );

  protected readonly category$ = this.categorySubject$.asObservable().pipe(
    switchMap((slug) => this.categoriesService.getCategoryWithCount(slug)),
    map((category) => ({
      gems: category?.gems || 0,
      name: category?.name || '',
    }))
  );

  protected readonly maxValue$ = combineLatest([
    this.userLikes$,
    this.category$,
  ]).pipe(
    map(([likes, category]) => {
      return Math.max(Math.min(likes, category.gems), 0);
    })
  );

  @Input({ required: true })
  set category(category: string) {
    this.categorySubject$.next(category);
  }

  get category() {
    return this.categorySubject$.value;
  }

  protected readonly gemPlural = GEM_PLURAL;
}
