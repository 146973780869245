import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output,
} from '@angular/core';
import { LikeExchangeExperienceService } from './like-exchange-experience.service';
import { provideLikeExchange } from './like-exchange.service';
import { LikeInfoFormComponent } from './like-info-form.component';

@Component({
  selector: 'sw-like-info-experience-exchange',
  standalone: true,
  imports: [LikeInfoFormComponent],
  providers: [provideLikeExchange(LikeExchangeExperienceService)],
  changeDetection: ChangeDetectionStrategy.OnPush,
  template: `
    <sw-like-info-form
      [maxValue]="maxValue"
      placeholder="Сколько обменять"
      buttonText="Обменять"
      [category]="category"
      [disabled]="maxValue === 0"
    />
  `,
})
export class LikeInfoExperienceExchangeComponent {
  @Input({ required: true }) category!: string;
  @Input() maxValue = 999;

  @Output() valueChanged = new EventEmitter<number>();
}
