import { HttpContextToken, HttpInterceptorFn } from '@angular/common/http';
import { inject } from '@angular/core';
import { API_URL } from '@core/tokens';

export const SKIP_BASE_URL_HTTP_TOKEN = new HttpContextToken<boolean>(
  () => false
);

export const baseUrlInterceptor: HttpInterceptorFn = (request, next) => {
  const baseURL = inject(API_URL);

  return next(
    request.context.get(SKIP_BASE_URL_HTTP_TOKEN)
      ? request
      : request.clone({ url: `${baseURL}${request.url}` })
  );
};
