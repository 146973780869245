import { isPlatformServer } from '@angular/common';
import {
  Injectable,
  PLATFORM_ID,
  TransferState,
  inject,
  makeStateKey,
} from '@angular/core';
import { CreateTopicDTO, TTopic, Topic } from '@core/models';
import { BehaviorSubject, Observable, map, of, tap } from 'rxjs';
import { BaseAPIService } from './base-api.service';

@Injectable({ providedIn: 'root' })
export class TopicAPIServiceService extends BaseAPIService {
  private readonly platformId = inject(PLATFORM_ID);
  private readonly transferState = inject(TransferState);

  fetchTopics(projectId: string) {
    const fetchTopicsKey = makeStateKey<TTopic[]>(`fetchTopics-${projectId}`);

    const mapTopics = (t: TTopic) => new Topic(t);

    if (this.transferState.hasKey(fetchTopicsKey)) {
      const topics = this.transferState.get(fetchTopicsKey, []).map(mapTopics);

      this._loading$.next(false);
      this.transferState.remove(fetchTopicsKey);
      return of(topics);
    }

    return this.httpClient.get<TTopic[]>(`/topics/${projectId}`).pipe(
      tap((topics) => {
        if (isPlatformServer(this.platformId)) {
          this.transferState.set(fetchTopicsKey, topics);
        }
      }),

      map((topics: TTopic[]) => topics.map(mapTopics))
    );
  }

  createTopics(projectId: string, payload: CreateTopicDTO) {
    return this.httpClient
      .post<TTopic[]>(`/topics/${projectId}`, payload.topics)
      .pipe(map((topics) => topics.map((t) => new Topic(t))));
  }

  linkCommentToTopic(payload: { commentId: string; topicId: string | null }) {
    return this.httpClient.post<TTopic>('/topics/', payload);
  }
}

@Injectable({ providedIn: 'root' })
export class TopicService {
  private readonly api = inject(TopicAPIServiceService);

  private readonly _topics$ = new BehaviorSubject<Topic[]>([]);

  get topics$(): Observable<Topic[]> {
    return this._topics$.asObservable();
  }
  createTopics(projectId: string, payload: CreateTopicDTO) {
    return this.api.createTopics(projectId, payload).pipe(
      tap((t) => {
        this._topics$.next([...t, ...this._topics$.value]);
      })
    );
  }

  getTopics(projectId: string) {
    return this.api.fetchTopics(projectId).pipe(
      tap((t) => {
        this._topics$.next(t);
      })
    );
  }

  getTopic$(topicId: string) {
    return this.topics$.pipe(
      map((topics) => {
        return topics.find((t) => t.id === topicId);
      })
    );
  }

  getTopic(topicId: string) {
    return this._topics$.value.find((t) => t.id === topicId);
  }

  updateTopic(topicId: string, topic: Partial<Topic>) {
    this._topics$.next(
      this._topics$.value.map((t) =>
        t.id === topicId ? new Topic({ ...t, ...topic }) : t
      )
    );
  }

  linkCommentToTopic({
    prevTopicId,
    ...payload
  }: {
    commentId: string;
    topicId: string | null;
    prevTopicId?: string | null;
  }) {
    return this.api.linkCommentToTopic(payload).pipe(
      tap((topic) => {
        this._topics$.next(
          this._topics$.value.map((t) => {
            if (t.id === topic.id) {
              return new Topic(topic);
            } else if (t.id === prevTopicId) {
              return new Topic({
                ...t,
                comments: t.comments - 1,
              });
            }
            return t;
          })
        );
      })
    );
  }

  public reset() {
    this._topics$.next([]);
  }
}
