import { Route } from '@angular/router';

import { AuthLayoutComponent } from './auth.layout';

export const AUTH_ROUTES: Route = {
  path: '',
  loadComponent: () => AuthLayoutComponent,
  children: [
    {
      path: 'login',
      loadComponent: () => import('./auth-login.page').then((c) => c.LogInPage),
      title: 'Вход',
    },
    {
      path: 'signup',
      loadComponent: () =>
        import('./auth-signup.page').then((c) => c.SignupPage),
      title: 'Регистрация',
    },
    {
      path: 'recovery',
      loadComponent: () =>
        import('./auth-recover.page').then((c) => c.RecoverPage),
      title: 'Восстановление доступа',
    },
  ],
};
