import { Directive, EventEmitter, Output, inject } from '@angular/core';
import { PermissionService } from './permission.service';

@Directive({ selector: '[swPermission]', standalone: true })
export class PermissionDirective {
  private readonly permissionService = inject(PermissionService);

  @Output()
  calledUnauthorized = new EventEmitter<void>();

  call(onUnauthorized: () => void) {
    this.permissionService.call({
      onUnauthorized: () => {
        onUnauthorized?.();
        this.calledUnauthorized.emit();
      },
    });
  }
}
