import { Category } from '@/core/types/category.type';
import { LetDirective } from '@/shared/directives';
import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { RouterModule } from '@angular/router';
import { CategoryTagComponent } from '../category-tag.component';

@Component({
  selector: 'sw-project-description',
  standalone: true,
  imports: [CommonModule, RouterModule, LetDirective, CategoryTagComponent],
  changeDetection: ChangeDetectionStrategy.OnPush,
  template: `
    <div class="overflow-hidden rounded-md bg-[#EEEEEE]">
      <div class="relative h-[150px] w-full">
        <img
          [src]="info.thumbUrl"
          draggable="false"
          alt
          class="h-full w-full object-cover object-center"
        />
        <div
          class="absolute left-5 top-5 flex flex-row flex-wrap gap-2"
          *ngIf="info.categories.length"
        >
          <sw-category-tag
            *ngFor="let c of info.categories; trackBy: trackByCategories"
            [color]="c.color"
          >
            {{ c.name }}
          </sw-category-tag>
        </div>
      </div>
      <div class="flex flex-col p-5">
        <span class="mb-[5px] text-lg font-bold leading-[22px]">{{
          info.title
        }}</span>
        <p
          class="line-clamp-2 h-full overflow-hidden text-sm font-medium leading-[19px] text-black/50"
        >
          {{ info.description }}
        </p>
        <span
          class="mr-auto text-sm font-medium leading-[19px] text-black/50 underline underline-offset-2 transition-colors duration-200 hover:text-black/30 focus-visible:text-black/30"
        >
          Развернуть
        </span>
      </div>
    </div>
  `,
})
export class ProjectDescriptionComponent {
  @Input({ required: true }) info!: {
    thumbUrl: string;
    title: string;
    description: string;
    categories: Category[];
  };

  trackByCategories(_index: number, category: Category): string {
    return category.slug;
  }
}
