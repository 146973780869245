import { isPlatformServer } from '@angular/common';
import { HttpContext } from '@angular/common/http';
import {
  Injectable,
  PLATFORM_ID,
  TransferState,
  inject,
  makeStateKey,
} from '@angular/core';
import { MAP_REQ_HTTP_TOKEN } from '@core/interceptors';
import {
  CreateProjectDTO,
  Project,
  ProjectDTO,
  UpdateProjectDTO,
} from '@core/models';
import { ProjectListQueries } from '@core/types';
import {
  BehaviorSubject,
  Observable,
  catchError,
  first,
  map,
  of,
  tap,
} from 'rxjs';
import * as snakecaseKeys from 'snakecase-keys';

import { CacheBucket, HttpCacheManager, withCache } from '@ngneat/cashew';
import { BaseAPIService } from './base-api.service';

@Injectable({ providedIn: 'root' })
export class ProjectAPIService extends BaseAPIService {
  protected readonly projectsBucket = new CacheBucket();
  private readonly cacheManager = inject(HttpCacheManager);

  private readonly platformId = inject(PLATFORM_ID);
  private readonly transferState = inject(TransferState);

  fetchByPk(id: string) {
    this._loading$.next(true);

    return this.httpClient
      .get<Project>(`/projects/${id}`, {
        context: withCache({
          bucket: this.projectsBucket,
          ttl: 15000,
        }),
      })
      .pipe(
        tap({
          finalize: () => this._loading$.next(false),
        })
      );
  }

  fetchList(params: ProjectListQueries) {
    const getProjectsKey = makeStateKey<Project[] | null>(
      `getProjects-${JSON.stringify(params)}`
    );

    if (this.transferState.hasKey(getProjectsKey)) {
      const projects = this.transferState.get(getProjectsKey, null);
      this._loading$.next(false);
      this.transferState.remove(getProjectsKey);
      return of(projects || []);
    }

    return this.httpClient
      .get<Project[]>(`/projects/`, {
        params: { ...snakecaseKeys(params) },
        context: withCache({
          bucket: this.projectsBucket,
          ttl: 15000,
        }),
      })
      .pipe(
        tap({
          next: (list) => {
            if (isPlatformServer(this.platformId)) {
              this.transferState.set(getProjectsKey, list);
            }
          },
        }),
        first()
      );
  }

  updateVerified(projectId: string, isVerified: boolean) {
    return this.httpClient.patch<{ isVerified: boolean }>(
      `/projects/verified/${projectId}`,
      {
        isVerified,
      }
    );
  }

  create(payload: ProjectDTO<CreateProjectDTO>) {
    this._loading$.next(true);

    const formData = this.createFormData(payload);

    const context = new HttpContext();
    context.set(MAP_REQ_HTTP_TOKEN, false);

    return this.httpClient
      .post<Project>(`/projects/`, formData, { context })
      .pipe(
        tap({
          next: () => this.invalidateProjects(),
          finalize: () => this._loading$.next(false),
        })
      );
  }

  update(id: string, payload: ProjectDTO<UpdateProjectDTO>) {
    this._loading$.next(true);

    const formData = this.createFormData(payload);

    const context = new HttpContext();
    context.set(MAP_REQ_HTTP_TOKEN, false);
    return this.httpClient
      .put<Project>(`/projects/${id}`, formData, { context })
      .pipe(
        tap({
          next: () => this.invalidateProjects(),
          finalize: () => this._loading$.next(false),
        }),
        map((project) => new Project(project))
      );
  }

  delete(id: string) {
    this._loading$.next(true);

    return this.httpClient.delete(`/projects/${id}`).pipe(
      tap({
        next: () => this.invalidateProjects(),
        finalize: () => this._loading$.next(false),
      })
    );
  }

  private createFormData(payload: ProjectDTO): FormData {
    const formData = new FormData();

    if (payload.photo) {
      formData.append('photo', payload.photo);
    }

    if (payload.files && payload.files.length > 0) {
      payload.files.forEach((f) => formData.append('files', f));
    }

    formData.append(
      'data',
      JSON.stringify(snakecaseKeys(payload.data, { deep: true }))
    );
    return formData;
  }

  invalidateProjects() {
    this.cacheManager.delete(this.projectsBucket);
  }
}

@Injectable()
export class ProjectService {
  private readonly api = inject(ProjectAPIService);

  private readonly _projects$ = new BehaviorSubject<Project[] | null>(null);

  get projects$(): Observable<Project[] | null> {
    return this._projects$.asObservable();
    //.pipe(debounceTime(100));
  }

  readonly loading$ = this.api.loading$;

  fetchList(params: ProjectListQueries = {}): Observable<Project[]> {
    return this.api.fetchList(params).pipe(
      catchError(() => of([])),
      tap((projects) => {
        this._projects$.next([
          ...(this._projects$.value || []),
          ...projects.map((p) => new Project(p)),
        ]);
      })
    );
  }

  fetchByPk(id: string) {
    return this.api.fetchByPk(id);
  }

  create(payload: ProjectDTO<CreateProjectDTO>) {
    return this.api.create(payload).pipe(
      tap((p) => {
        p.rating = 0;
        this._projects$.next([
          new Project(p),
          ...(this._projects$.value || []),
        ]);
      })
    );
  }

  update(id: string, payload: ProjectDTO<UpdateProjectDTO>) {
    return this.api.update(id, payload).pipe(
      tap((p) =>
        this._projects$.next(
          this._projects$.value
            ? this._projects$.value.map((project) =>
                project.id === p.id
                  ? new Project({
                      ...p,
                      comments: p.comments || project.comments,
                      rating: (p.rating ?? project.rating) || null,
                    })
                  : project
              )
            : [p]
        )
      )
    );
  }

  updateProjectGames(projectId: string, gems: number): void {
    const p = this._projects$.value;

    if (!p) {
      return;
    }
    this.api.invalidateProjects();

    this._projects$.next(
      p.map(
        (project) =>
          new Project(project.id === projectId ? { ...project, gems } : project)
      )
    );
  }

  updateProjectLikes(projectId: string, likes: number, isLiked: boolean): void {
    const p = this._projects$.value;

    if (!p) {
      return;
    }
    this.api.invalidateProjects();

    this._projects$.next(
      p.map(
        (project) =>
          new Project(
            project.id === projectId ? { ...project, likes, isLiked } : project
          )
      )
    );
  }

  updateVerified(projectId: string, isVerified: boolean) {
    return this.api.updateVerified(projectId, isVerified);
  }

  delete(projectId: string) {
    const prevValue = this._projects$.value || [];
    this._projects$.next(prevValue.filter(({ id }) => id !== projectId));

    return this.api.delete(projectId).pipe(
      tap({
        error: () => this._projects$.next(prevValue),
      })
    );
  }

  resetProjects() {
    this._projects$.next(null);
  }
}
