import { Directive, HostListener, inject, InjectionToken } from '@angular/core';

import { NavPrevService } from './nav-prev.service';

export const NAVIGATION_PREV_FALLBACK_PATH = new InjectionToken<string>(
  'Navigation prev fallback token',
  {
    factory: () => '/',
  }
);

@Directive({ selector: '[swNavPrev]', standalone: true })
export class NavPrevDirective {
  private readonly navService = inject(NavPrevService);
  private readonly fallbackPath = inject(NAVIGATION_PREV_FALLBACK_PATH);

  @HostListener('click')
  onClick() {
    this.navService.back(this.fallbackPath);
  }
}
