import { Project } from '@/core/models';
import { Comment } from '@/core/models/comment.model';
import { animate, style, transition, trigger } from '@angular/animations';
import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { RouterModule } from '@angular/router';

import { CommentComponent } from '../comment.component';
import { ShowAllCommentsBtnComponent } from '../show-all-comments-btn.component';
import { ShowCommentsBtnComponent } from '../show-comments-btn.component';

@Component({
  selector: 'sw-comments-list',
  standalone: true,
  imports: [
    RouterModule,
    CommonModule,
    CommentComponent,
    ShowCommentsBtnComponent,
    ShowAllCommentsBtnComponent,
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
  animations: [
    trigger('showComments', [
      transition(':enter', [
        style({ opacity: 0, height: 0 }),
        animate('200ms', style({ opacity: 1, height: '*' })),
      ]),
    ]),
  ],
  template: ` <div class="w-full">
      <sw-show-comments-btn
        *ngIf="!open"
        [avatars]="userAvatars"
        [totalComments]="comments.count"
        (click)="open = true"
      />
    </div>

    <div [@showComments]="open" *ngIf="open">
      <a
        *ngFor="let comment of comments?.comments; trackBy: trackByFn"
        class="group mt-1 block outline-none first:mt-0"
        [routerLink]="['/', 'thread', projectId, comment.id]"
        [queryParams]="comment.topicId ? { topic: comment.topicId } : undefined"
        queryParamsHandling="merge"
      >
        <sw-comment
          [comment]="comment"
          class="bg-white transition-colors duration-200 group-hover:bg-black/10 group-focus:bg-black/10"
        />
      </a>

      <div *ngIf="showMoreBtn && comments.count > 3" class="mt-5">
        <a
          [routerLink]="
            commentId
              ? ['/', 'thread', projectId, commentId]
              : ['/', 'thread', projectId]
          "
          class="group block outline-none"
        >
          <sw-show-all-comments-btn
            [avatars]="userAvatars"
            [commentsCount]="comments.count - 3"
            class="block rounded-md bg-black/5 px-[15px]
            py-[9px] transition-colors duration-200 group-hover:bg-black/10 group-focus:bg-black/10"
          />
        </a>
      </div>
    </div>`,
})
export class CommentsListComponent {
  @Input({ required: true })
  projectId!: string;

  @Input() commentId?: string;

  @Input()
  open = false;

  @Input({ required: true })
  comments!: NonNullable<Project['comments']>;

  @Input() showMoreBtn = true;

  get userAvatars(): { name: string; photo?: string | null }[] {
    return this.comments.comments.slice(0, 3).map(({ author }) => ({
      name: author.fullName,
      photo: author.photo,
    }));
  }

  trackByFn(_index: number, comment: Comment): string {
    return comment.id;
  }
}
