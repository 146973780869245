import { UserService } from '@/core';
import { LetDirective } from '@/shared/directives';
import { CommonModule } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  inject,
  Input,
  Output,
  TemplateRef,
} from '@angular/core';
import { FormControl, ReactiveFormsModule, Validators } from '@angular/forms';
import { MaskitoModule } from '@maskito/angular';
import { maskitoNumberOptionsGenerator } from '@maskito/kit';
import { ButtonComponent } from '@shared/ui/button.component';
import { StepperComponent } from '@shared/ui/input-stepper.component';
import { SVGComponent } from '@shared/ui/svg.component';
import { delay, tap } from 'rxjs';

import { GEM_CONTEXT } from './gem-context.token';

@Component({
  selector: 'sw-gem-donate-form',
  standalone: true,
  imports: [
    CommonModule,
    StepperComponent,
    ReactiveFormsModule,
    MaskitoModule,
    LetDirective,
    ButtonComponent,
    SVGComponent,
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
  template: `
    <ng-container *swLet="gems$ | async as gems">
      <div *ngIf="gems !== null && gems !== undefined">
        <ng-container
          *ngTemplateOutlet="
            descriptionTemplate || defaultDescription;
            context: {
              $implicit: gems | maskito : options,
              balance: gems | maskito : options
            }
          "
        />

        <ng-template #defaultDescription let-balance>
          <div class="mb-[10px] space-y-[5px] text-center">
            <span class="block text-sm font-bold leading-[18px]"
              >Добавить гемы</span
            >
            <span class="block text-xs text-black/50"
              >Ваш баланс ({{ balance }})
            </span>
          </div>
        </ng-template>

        <div class="flex flex-row items-center justify-center space-x-[10px]">
          <div class="w-full md:max-w-[172px]">
            <sw-stepper
              ngDefaultControl
              [formControl]="control"
              placeholder="100"
              [disabled]="gems === 0"
              [min]="1"
              [max]="gems < defaultMax ? gems : defaultMax"
            />
          </div>
          <button
            type="submit"
            swButton
            [loading]="loading$ | async"
            [disabled]="control.disabled || control.invalid"
            aria-label=""
            class="button-rounded aspect-square h-[37px] w-[37px] bg-accent disabled:opacity-50"
            (click)="submit()"
          >
            <sw-svg icon="arrow-right" width="20px" height="16px" />
          </button>
        </div>
      </div>
    </ng-container>
  `,
})
export class GemDonateFormComponent {
  private readonly ctx = inject(GEM_CONTEXT);

  @Input()
  defaultMax = 100;

  @Input()
  descriptionTemplate?: TemplateRef<{ balance: number | null }>;

  protected loading$ = this.ctx.loading$;

  protected gems$ = inject(UserService).userGems$.pipe(
    tap({
      next: (gems) => {
        if (gems === 0) {
          this.control.disable();
        }
      },
    })
  );

  @Output() sended = new EventEmitter<void>();

  protected control = new FormControl<string | number>('', {
    nonNullable: true,
    validators: [Validators.required],
  });

  protected options = maskitoNumberOptionsGenerator({ precision: 0 });

  submit() {
    if (this.control.invalid) {
      return;
    }

    this.ctx
      .sendGems(+this.control.value)
      .pipe(delay(50))
      .subscribe({
        next: () => {
          this.sended.emit();
        },
      });
  }
}
