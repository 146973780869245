import { animate, style, transition, trigger } from '@angular/animations';
import { CommonModule } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  inject,
  TemplateRef,
} from '@angular/core';

import { REACTION_ONBOARDING_DATA } from './reaction-onboarding.directive';

@Component({
  selector: 'sw-reaction-onboarding',
  standalone: true,
  imports: [CommonModule],
  changeDetection: ChangeDetectionStrategy.OnPush,
  animations: [
    trigger('show', [
      transition(':enter', [
        style({ opacity: 0 }),
        animate('100ms', style({ opacity: 1 })),
      ]),
      transition(':leave', [
        style({ opacity: 0.8 }),
        animate('100ms', style({ opacity: 0 })),
      ]),
    ]),
  ],

  template: `
    <div @show class="island-base flex h-[237px] w-[236px] flex-col pb-2">
      <div class="flex flex-row justify-center px-5 pb-2 pt-[15px]">
        <span class="text-center text-sm font-bold leading-[18px]">{{
          data.title
        }}</span>
      </div>
      <div
        class="scrollbar flex h-full min-h-0 flex-col overflow-y-auto pl-5 pt-[5px]
        text-xs
      "
      >
        <ng-container [ngTemplateOutlet]="template" />
        <p *ngIf="text">{{ text }}</p>
      </div>
    </div>
  `,
  styles: [
    `
      :host {
        @apply border-x-[20px] border-y-[10px] border-solid border-transparent;
      }
    `,
  ],
})
export class ReactionOnboardingComponent {
  protected readonly data = inject(REACTION_ONBOARDING_DATA);

  get template(): TemplateRef<unknown> | null {
    return typeof this.data.content === 'string' ? null : this.data.content;
  }

  get text(): string | null {
    return typeof this.data.content === 'string' ? this.data.content : null;
  }
}
