import { RatingType } from '@/core/types';
import { NgClass } from '@angular/common';
import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

@Component({
  selector: 'sw-comment-rating',
  standalone: true,
  imports: [NgClass],
  changeDetection: ChangeDetectionStrategy.OnPush,
  template: `<div
    class="flex flex-row items-center space-x-[5px] text-sm font-bold leading-[22px]"
    [attr.guide]="'comment-rating-' + type"
  >
    <span
      class="hidden sm:block"
      [ngClass]="{
        'text-green': type === 'positive',
        'text-red': type === 'negative'
      }"
      >{{ ratingLabelMapper(type) }}</span
    >
    <span
      class="rounded-[5px] px-[5px] py-[1px] text-sm text-white sm:text-base"
      [ngClass]="{
        'bg-green': type === 'positive',
        'bg-red': type === 'negative'
      }"
      >{{ value ?? '?' }}/5</span
    >
  </div>`,
})
export class CommentRatingComponent {
  @Input({ required: true })
  type!: RatingType;

  @Input({ required: true })
  value: number | null = null;

  protected ratingLabelMapper(ratingType: RatingType) {
    return ratingType === 'neutral'
      ? null
      : ratingType === 'positive'
      ? 'Согласен'
      : 'Не согласен';
  }
}
