import { CommonModule } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  DestroyRef,
  EventEmitter,
  OnInit,
  Output,
  inject,
} from '@angular/core';
import { FormsModule, NgControl, ReactiveFormsModule } from '@angular/forms';
import { RatingRageComponent } from '@shared/ui/rating-range-slider.component';
import { isMissed } from '@/core/helpers';
import { trigger, transition, style, animate } from '@angular/animations';
import { startWith } from 'rxjs';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';

type Sentiment = 'positive' | 'negative';

@Component({
  selector: 'sw-opinion-superuser',
  standalone: true,
  imports: [
    CommonModule,
    RatingRageComponent,
    FormsModule,
    ReactiveFormsModule,
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
  animations: [
    trigger('showSlider', [
      transition(':enter', [
        style({ opacity: 0, height: 0 }),
        animate('200ms', style({ height: '66px', opacity: 1 })),
      ]),
      transition(':leave', [
        style({ opacity: 1, height: '*' }),
        animate('200ms', style({ opacity: 0, height: 0 })),
      ]),
    ]),
  ],

  template: `
    <div class="flex flex-col  space-y-[10px] rounded-md bg-black/5 p-[10px]">
      <span class="text-center text-xs leading-[22px] text-black/50"
        >Значимость комментария</span
      >
      <div class="flex flex-1 flex-row items-center">
        <button
          swPermissionAction
          type="button"
          class="button flex-1 rounded-r-none border border-r-0 border-black/10  text-sm font-medium leading-[22px] text-green"
          [ngClass]="{
            'bg-green/20': sentiment === 'positive'
          }"
          (click)="handleBtn('positive')"
        >
          Согласен
        </button>
        <button
          swPermissionAction
          type="button"
          class="button flex-1 rounded-l-none border border-black/10 text-sm font-medium leading-[22px] text-red"
          [ngClass]="{
            'bg-red/20': sentiment === 'negative'
          }"
          (click)="handleBtn('negative')"
        >
          Не согласен
        </button>
      </div>
      <div *ngIf="sentiment" [@showSlider]="!!sentiment">
        <sw-rating-range
          ngDefaultControl
          [ngModel]="value"
          (ngModelChange)="handleChange($event)"
          class="block"
          [color]="ratingColor"
        />
      </div>
    </div>
  `,
})
export class OpinionSuperuserComponent implements OnInit {
  private readonly destroyRef = inject(DestroyRef);

  protected readonly ngControl = inject(NgControl, { optional: true });

  protected sentiment?: Sentiment | null;

  @Output()
  readonly positive = new EventEmitter<void>();

  @Output()
  readonly negative = new EventEmitter<void>();

  get control() {
    return this.ngControl?.control;
  }

  get value() {
    return Math.abs(this.control?.value ?? 3);
  }

  get ratingColor() {
    if (isMissed(this.sentiment)) {
      return undefined;
    }
    return this.sentiment === 'positive' ? 'green' : 'red';
  }

  ngOnInit(): void {
    this.ngControl?.valueChanges
      ?.pipe(
        startWith(this.ngControl.value),
        takeUntilDestroyed(this.destroyRef)
      )
      .subscribe((v) => {
        const s: Sentiment | null = isMissed(v)
          ? null
          : v > 0
          ? 'positive'
          : 'negative';

        if (s !== this.sentiment) {
          this.sentiment = s;
        }
      });
  }

  protected handleBtn(btn: Sentiment) {
    this.sentiment = this.sentiment === btn ? null : btn;

    this.handleChange(this.control?.value);
  }
  protected handleChange(v: number | null) {
    this.control?.patchValue(
      this.sentiment
        ? Math.abs(v || 3) * (this.sentiment === 'negative' ? -1 : 1)
        : null
    );
  }
}
