import { UserMenu } from '@/core';
import { NgFor } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  ViewEncapsulation,
  inject,
} from '@angular/core';
import { RouterLink } from '@angular/router';
import { DynamicDialogRef } from 'primeng/dynamicdialog';

@Component({
  selector: 'sw-mobile-menu-dialog',
  standalone: true,
  imports: [NgFor, RouterLink],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
  template: `<ul class="mobile-menu-list rounded-t-[15px] bg-white">
    <li
      *ngFor="let item of menuList"
      class="last:border-b-none border-b border-b-black/10"
    >
      <a
        class="flex cursor-pointer flex-row items-center space-x-4 p-5 text-sm font-medium leading-none text-black outline-none"
        [routerLink]="item.routerLink"
        (click)="dialogRef.close(); item?.command()"
      >
        <span class="inline-block h-[18px] w-[18px]" [class]="item.icon"></span>
        <span>{{ item.label }}</span>
      </a>
    </li>
  </ul>`,
  styles: [
    `
      .p-dialog:has(sw-mobile-menu-dialog) .p-dialog-content {
        padding: 0 !important;
      }
    `,
  ],
})
export class MobileMenuDialogComponent {
  protected readonly dialogRef = inject(DynamicDialogRef);

  readonly menuList = inject(UserMenu);
}
