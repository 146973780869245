import { ChangeDetectionStrategy, Component, inject } from '@angular/core';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { ConfirmDialogContainerComponent } from './confirm-dialog-container.component';

@Component({
  selector: 'sw-confirm-dialog',
  standalone: true,
  imports: [ConfirmDialogContainerComponent],
  changeDetection: ChangeDetectionStrategy.OnPush,
  template: `<sw-confirm-dialog-container
    (btnClick)="dialogRef.close($event)"
    [yes]="data.yes"
    [no]="data.no"
  >
    <p class="text-lg font-medium" content>
      {{ data.msg }}
    </p>
  </sw-confirm-dialog-container> `,
})
export class ConfirmDialogComponent {
  protected readonly dialogRef = inject(DynamicDialogRef);
  protected readonly config = inject(DynamicDialogConfig);

  get data() {
    return this.config.data;
  }
  protected hideDialog = false;
}
