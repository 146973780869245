import { ChangeDetectionStrategy, Component, inject } from '@angular/core';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';

@Component({
  selector: 'sw-alert-dialog',
  standalone: true,
  imports: [],
  changeDetection: ChangeDetectionStrategy.OnPush,
  template: `
    <div
      class="flex w-full flex-col items-center justify-center space-y-5 text-center"
    >
      <div>
        <svg
          width="84"
          height="85"
          viewBox="0 0 84 85"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <circle cx="42" cy="42.5" r="42" fill="#00C000" />
          <path
            d="M23 41.1061L37.25 55.5L62 30.5"
            stroke="white"
            stroke-width="5"
            stroke-linecap="square"
          />
        </svg>
      </div>
      <p class="text-2xl font-bold text-black">
        Спасибо. Ваша заявка была отправлена!
      </p>
      <p class="text-black/50">Свяжемся в ближайшее время</p>
      <button class="button button-primary w-full py-3" (click)="handleClose()">
        Закрыть
      </button>
    </div>
  `,
})
export class AlertDialogComponent {
  protected readonly dialogRef = inject(DynamicDialogRef);
  protected readonly config = inject(DynamicDialogConfig);

  get data() {
    return this.config.data;
  }

  protected handleClose() {
    this.dialogRef.close();
  }
}
