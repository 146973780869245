import { CommentStatistics } from '@/core/models/comment-statistics.model';
import { NgIf } from '@angular/common';
import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { CommentStatisticsSuperuserComponent } from './comment-statistics-supersuer.component';

@Component({
  selector: 'sw-comment-statistics-wrapper',
  standalone: true,
  imports: [NgIf, CommentStatisticsSuperuserComponent],
  changeDetection: ChangeDetectionStrategy.OnPush,
  template: ` <sw-comment-statistics-superuser [statistics]="statistics" /> `,
})
export class CommentStatisticsWrapperComponent {
  @Input({ required: true })
  statistics?: CommentStatistics;
}
