import { ChangeDetectionStrategy, Component } from '@angular/core';

@Component({
  selector: 'sw-delimiter',
  standalone: true,
  imports: [],
  changeDetection: ChangeDetectionStrategy.OnPush,
  template: `
    <div
      class="relative
      flex w-full select-none items-center justify-center
      "
    >
      <div class="h-[1px] flex-1 bg-black/10"></div>
      <span
        class="max-w-[80%] shrink-0 px-5 text-center text-xs leading-[22px] text-black/50"
      >
        <ng-content />
      </span>
      <div class="h-[1px] flex-1 bg-black/10"></div>
    </div>
  `,
})
export class DelimiterComponent {}
