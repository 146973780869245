type FieldType = 'text' | 'email' | 'textarea' | 'phone';

export type FormSchemaElement = {
  fieldType: FieldType;
  default?: string;
  label?: string;
  placeholder?: string;
  validators?: string[];
};

export type FormSchema = {
  [key: string]: FormSchema | FormSchemaElement;
  // | FormSchema[]
  // | FormSchemaElement[];
};

export function isFormSchemaElement(
  value: unknown
): value is FormSchemaElement {
  return 'fieldType' in (value as object);
}
