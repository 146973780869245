import {
  CommentDTO,
  CreateCommentDTO,
  CreateCommentPayload,
} from '@/core/models';
import { Directive, Input, inject } from '@angular/core';

import { CommentContext } from './comment-context';
import { EDITOR_COMMENT_CONTEXT } from './comment-context.token';
import { isMissed } from '@/core/helpers';
import { CommentsTopicsService } from '@/core/services/comments-topics.service';

@Directive({
  selector: '[swCreateComment]',
  standalone: true,
  providers: [
    {
      provide: EDITOR_COMMENT_CONTEXT,
      useExisting: CreateCommentDirective,
    },
    CommentsTopicsService,
  ],
})
export class CreateCommentDirective extends CommentContext {
  protected readonly commentTopicService = inject(CommentsTopicsService);

  @Input({ required: true })
  commentType!: CreateCommentPayload['parent']['type'];

  @Input({ required: true })
  projectId!: string;

  @Input()
  parentId?: string;

  @Input()
  topicId?: string;

  send(payload: CommentDTO<CreateCommentDTO>) {
    if (this.commentType === 'comment' && isMissed(this.parentId)) {
      throw Error('CreateCommentDirection for comment require parent id');
    }

    return this.commentTopicService.createComment({
      parent: {
        type: this.commentType,
        projectId: this.projectId,
        topicId: this.topicId,
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        parentId: this.parentId!,
      },
      payload,
    });
  }
}
