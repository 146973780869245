import { InjectionToken, Provider } from '@angular/core';

export const COMPLETE_GUIDE_ELEMENTS = new InjectionToken<string | string[]>(
  'element selectors that, when clicked, complete the guide'
);

export function provideCompleteGuideElements(
  useValue: string | string[]
): Provider {
  return {
    provide: COMPLETE_GUIDE_ELEMENTS,
    useValue,
  };
}
