import { NgIf } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  HostBinding,
  Input,
} from '@angular/core';

@Component({
  selector: 'sw-button,button[swButton]',
  standalone: true,
  imports: [NgIf],
  changeDetection: ChangeDetectionStrategy.OnPush,
  template: `
    <span [class.invisible]="loading" class="flex"><ng-content /></span>
    <span
      class="absolute left-1/2 top-1/2 aspect-square h-3/4 -translate-x-1/2 -translate-y-1/2"
      *ngIf="loading"
    >
      <svg
        class="h-full w-full"
        viewBox="0 0 38 38"
        xmlns="http://www.w3.org/2000/svg"
        [attr.stroke]="spinnerColor"
      >
        <g fill="none" fill-rule="evenodd">
          <g transform="translate(1 1)" stroke-width="2">
            <circle stroke-opacity=".5" cx="18" cy="18" r="18" />
            <path d="M36 18c0-9.94-8.06-18-18-18">
              <animateTransform
                attributeName="transform"
                type="rotate"
                from="0 18 18"
                to="360 18 18"
                dur="1s"
                repeatCount="indefinite"
              />
            </path>
          </g>
        </g>
      </svg>
    </span>
  `,
  styles: [
    `
      :host {
        @apply relative;
      }
    `,
  ],
})
export class ButtonComponent {
  @Input()
  loading: boolean | null = false;

  @Input()
  disabled = false;

  @Input()
  spinnerColor = '#fff';

  @HostBinding('attr.disabled')
  get isDisabled() {
    return this.loading || this.disabled ? true : null;
  }
}
