import { inject, Injectable } from '@angular/core';
import {
  BehaviorSubject,
  EMPTY,
  finalize,
  first,
  map,
  of,
  switchMap,
  tap,
} from 'rxjs';

import { FilePurchase, FormPurchase, Product, Purchase } from '@core/models';
import {
  FilePurchaseSchema,
  FormPurchaseSchema,
  ProductOut,
} from '@core/types/api-types';
import { Camelize } from '@core/types/snake-2-camel';
import { CacheBucket } from '@ngneat/cashew';
import { BaseAPIService } from './base-api.service';

type Products = Camelize<ProductOut>[];

@Injectable({ providedIn: 'root' })
export class MarketAPIService extends BaseAPIService {
  protected readonly MarketBucket = new CacheBucket();

  fetchList() {
    this._loading$.next(true);
    return this.httpClient
      .get<Products>('/market/')
      .pipe(finalize(() => this._loading$.next(false)));
  }

  purchase(goodId: string) {
    return this.httpClient.post<Camelize<Purchase>>(
      `/market/purchase/${goodId}`,
      {}
    );
  }

  saveForm(purchaseId: string, payload: object) {
    return this.httpClient
      .post<Camelize<FormPurchase>>(
        `/market/upload_form/${purchaseId}`,
        payload
      )
      .pipe(map((p) => new FormPurchase(p.id, p.goodId, p.jsonForm)));
  }
}

@Injectable()
export class MarketService {
  private readonly api = inject(MarketAPIService);

  private readonly productsSubject$ = new BehaviorSubject<Product[]>([]);

  get products$() {
    return this.productsSubject$.asObservable();
  }

  get loading$() {
    return this.api.loading$;
  }

  fetchList() {
    return this.api.fetchList().pipe(
      tap((list) =>
        this.productsSubject$.next(
          list.map((p) => {
            const purchase = (
              (p.purchasedItems || []) as unknown as Camelize<
                FormPurchaseSchema | FilePurchaseSchema
              >[]
            ).map((p) => {
              if ('jsonForm' in p) {
                return new FormPurchase(p.id, p.goodId, p.jsonForm);
              }

              return new FilePurchase(p.id, p.goodId, p.file);
            });

            return new Product(p.product, purchase);
          })
        )
      ),
      switchMap(() => this.products$)
    );
  }

  purchase(goodId: string) {
    return this.products$.pipe(
      first(),
      map((list) => list.find((p) => p.product.id === goodId)),
      switchMap((product) => {
        if (!product) {
          return EMPTY;
        }

        const purchaseRequest = this.api.purchase(goodId).pipe(
          map((p) =>
            'jsonForm' in p
              ? new FormPurchase(p.id, p.goodId, {})
              : new FilePurchase(p.id, p.goodId, p.file)
          ),
          tap((p) => {
            const list = this.productsSubject$.value;
            this.productsSubject$.next(
              list.map((product) =>
                product.product.id === goodId
                  ? new Product(product.product, [...product.purchases, p])
                  : product
              )
            );
          }),
          map((p) => ({
            product: product.product,
            purchase: p,
          }))
        );

        const lastPurchase = product.purchases.at(-1);

        return lastPurchase && product.isPurchased
          ? of({ product: product.product, purchase: lastPurchase })
          : purchaseRequest;
      })
    );
  }

  saveForm(goodId: string, purchaseId: string, payload: object) {
    return this.api.saveForm(purchaseId, payload).pipe(
      tap((purchase) => {
        const list = this.productsSubject$.value;
        this.productsSubject$.next(
          list.map((product) =>
            product.product.id === goodId
              ? new Product(
                  product.product,
                  product.purchases.map((p) =>
                    p.id === purchase.id ? purchase : p
                  )
                )
              : product
          )
        );
      })
    );
  }
}
