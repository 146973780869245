import { Pipe, PipeTransform, inject } from '@angular/core';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';

@Pipe({
  name: 'sanitizeHtml',
  standalone: true,
})
export class HtmlSanitizerPipe implements PipeTransform {
  private readonly sanitizer = inject(DomSanitizer);

  transform(value: string | null): SafeHtml | null {
    if (value === null) {
      return null;
    }
    return this.sanitizer.bypassSecurityTrustHtml(value);
  }
}
