import { UserService } from '@/core';
import { isPresent } from '@/core/helpers';
import { DOCUMENT, isPlatformServer } from '@angular/common';
import {
  DestroyRef,
  Directive,
  Input,
  PLATFORM_ID,
  Renderer2,
  inject,
} from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { EMPTY, fromEvent, switchMap, throttleTime } from 'rxjs';
import { RegisterTrackDirective } from './register-track-seen.directive';
import { TrackSeenService } from './track-seen.service';

@Directive({
  selector: '[swTrackSeen]',
  providers: [TrackSeenService],
  standalone: true,
})
export class TrackSeenDirective {
  private children: RegisterTrackDirective[] = [];
  private readonly document = inject(DOCUMENT);
  private readonly destroyRef = inject(DestroyRef);

  private readonly renderer = inject(Renderer2);

  private readonly trackSeenService = inject(TrackSeenService);

  private readonly user$ = inject(UserService).user$;

  private readonly platformId = inject(PLATFORM_ID);

  @Input()
  set trackSeenParentId(id: string | null) {
    this.trackSeenService.setParentId(id);
  }

  constructor() {
    if (isPlatformServer(this.platformId)) {
      return;
    }

    this.user$
      .pipe(
        takeUntilDestroyed(this.destroyRef),
        switchMap((u) =>
          isPresent(u)
            ? fromEvent(this.document, 'scroll').pipe(throttleTime(30))
            : EMPTY
        )
      )
      .subscribe(() => {
        this.checkSeen();
      });
  }

  addChildren(item: RegisterTrackDirective) {
    if (isPlatformServer(this.platformId)) {
      return;
    }

    this.children.push(item);

    this.checkSeen();
  }

  removeChildren(item: RegisterTrackDirective) {
    this.children = this.children.filter((c) => c !== item);
  }

  private isElementInViewPort(element: HTMLElement) {
    const rect = element.getBoundingClientRect();

    const isVisible =
      rect.top >= 0 &&
      rect.bottom * 0.8 <=
        (window.innerHeight || document.documentElement.clientHeight);

    return isVisible;
  }

  checkSeen() {
    for (const ch of this.children) {
      if (
        this.isElementInViewPort(ch.nativeElement) &&
        !ch.nativeElement.getAttribute('seen')
      ) {
        this.renderer.setAttribute(ch.nativeElement, 'seen', 'true');
        this.trackSeenService.push(ch.trackSeenId);
      }
    }
  }
}
