import { NgClass, NgIf } from '@angular/common';
import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

export type NotificationStatus = 'info' | 'neutral' | 'error';

@Component({
  selector: 'sw-notification',
  standalone: true,
  imports: [NgIf, NgClass],
  changeDetection: ChangeDetectionStrategy.OnPush,
  template: `
    <div
      class="flex flex-row space-x-4 rounded-md p-4 text-[14px]"
      [ngClass]="{
        'bg-accent/50': status === 'info',
        'bg-red/80': status === 'error',
        'text-white': status && status !== 'neutral',
        'bg-white': status === undefined || status === 'neutral',
      }"
    >
      <div *ngIf="icon">
        <i class="pi" [class]="icon" style="font-size: 1.5rem"></i>
      </div>
      <p class="text-inherit ">
        <ng-content />
      </p>
    </div>
  `,
})
export class NotificationComponent {
  @Input()
  status?: NotificationStatus;

  @Input()
  //prime-ng icon
  icon?: string;
}
