import { Pipe, PipeTransform } from '@angular/core';
import { twMerge } from 'tailwind-merge';

@Pipe({
  name: 'twMerge',
  standalone: true,
})
export class TailwindMergePipe implements PipeTransform {
  transform(classes: string): string {
    return twMerge(classes);
  }
}
