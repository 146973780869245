import { sameSign } from '@/core/helpers';
import { Directive, inject } from '@angular/core';
import { NgControl } from '@angular/forms';

@Directive()
export abstract class BaseOpinion {
  protected readonly ngControl = inject(NgControl, { optional: true });

  get control() {
    return this.ngControl?.control;
  }

  get value() {
    return this.control?.value as number | null;
  }

  /**
   * @param {Boolean} btn - positive is true, negative - false
   */
  protected handleBtn(btn: boolean) {
    if (this.value && sameSign(this.value, btn ? 1 : -1)) {
      this.control?.patchValue(null);
    } else {
      this.control?.patchValue(
        (this.value || 5) * (this.value === null ? (btn ? 1 : -1) : -1)
      );
    }
  }
}
