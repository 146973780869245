import { CommentDTO, UpdateCommentDTO } from '@/core/models';
import { Directive, Input } from '@angular/core';

import { CommentContext } from './comment-context';
import { EDITOR_COMMENT_CONTEXT } from './comment-context.token';

@Directive({
  selector: '[swUpdateComment]',
  standalone: true,
  providers: [
    {
      provide: EDITOR_COMMENT_CONTEXT,
      useExisting: UpdateCommentDirective,
    },
  ],
})
export class UpdateCommentDirective extends CommentContext {
  @Input({ required: true })
  commentId!: string;

  send(payload: CommentDTO<UpdateCommentDTO>) {
    return this.commentService.update({
      commentId: this.commentId,
      payload,
    });
  }
}
