import { UserService } from '@/core';
import { ButtonComponent } from '@/shared/ui';
import { NgClass } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  Input,
  inject,
  signal,
} from '@angular/core';
import { toSignal } from '@angular/core/rxjs-interop';
import { VerifyProjectService } from '@features/verify-project-button/model';
import { finalize } from 'rxjs';

@Component({
  selector: 'sw-verify-project-button',
  standalone: true,
  imports: [ButtonComponent, NgClass],
  changeDetection: ChangeDetectionStrategy.OnPush,
  template: `
    @if(isAdmin()) {

    <div class="flex w-full justify-end">
      <button
        swButton
        class="button px-4 py-2"
        [loading]="loading()"
        [ngClass]="{
          'button-primary': !isVerified,
          'button-destructive': isVerified,
        }"
        (click)="updateProjectVerified()"
      >
        {{ label }}
      </button>
    </div>

    }
  `,
})
export class VerifyProjectButtonComponent {
  @Input({ required: true }) projectId!: string;
  @Input() isVerified = false;

  protected readonly loading = signal(false);

  protected readonly isAdmin = toSignal(inject(UserService).isSuperuser$, {
    initialValue: false,
  });

  private readonly verifyProject = inject(VerifyProjectService);

  get label() {
    return this.isVerified ? 'Отменить верификацию' : 'Верифицировать';
  }

  updateProjectVerified() {
    this.loading.set(true);
    this.verifyProject
      .updateVerify(this.projectId, !this.isVerified)
      .pipe(finalize(() => this.loading.set(false)))
      .subscribe();
  }
}
