import { Dialog, DialogModule } from '@angular/cdk/dialog';
import {
  ChangeDetectionStrategy,
  Component,
  OnInit,
  inject,
} from '@angular/core';
import { Router } from '@angular/router';

import { DisableScrollService } from '@/core/services/disable-scroll.service';
import { LikeInfoComponent } from './like-info.component';

@Component({
  selector: 'sw-like-info-entry',
  standalone: true,
  imports: [DialogModule],
  providers: [DisableScrollService],
  changeDetection: ChangeDetectionStrategy.OnPush,
  template: ``,
})
export class LikeInfoEntryComponent implements OnInit {
  private readonly dialog = inject(Dialog);
  private readonly router = inject(Router);
  private readonly disableScrollService = inject(DisableScrollService);

  ngOnInit(): void {
    this.openDialog();
  }

  openDialog(): void {
    this.disableScrollService.disableWindowScroll();
    const dialogRef = this.dialog.open(LikeInfoComponent, {
      autoFocus: false,
    });

    dialogRef.closed.subscribe({
      next: () => {
        this.router.navigate([], {
          queryParams: {
            info: null,
          },
          queryParamsHandling: 'merge',
          replaceUrl: true,
        });
      },
      complete: () => {
        this.disableScrollService.enableWindowScroll();
      },
    });
  }
}
