import { AsyncPipe, NgFor, NgIf } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  Input,
  ViewEncapsulation,
} from '@angular/core';
import { InitialsPipe } from '@shared/pipes';
import { AvatarModule } from 'primeng/avatar';
import { AvatarGroupModule } from 'primeng/avatargroup';

@Component({
  selector: 'sw-show-comments-btn',
  standalone: true,
  imports: [
    NgFor,
    AsyncPipe,
    NgIf,
    AvatarModule,
    InitialsPipe,
    AvatarGroupModule,
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
  template: `
    <button class="button button-outline w-full border-black/10">
      <div
        class=" flex w-full flex-row items-center justify-center space-x-5 border-black/10 px-3 py-[6px] text-[12px] text-black/50 hover:bg-black/5 active:bg-black/10 min-[400px]:px-5"
      >
        <span>
          <svg
            width="13px"
            height="8px"
            viewBox="0 0 13 8"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              opacity="0.5"
              d="M12 1.5L6.5 6.5L1 1.5"
              stroke="#1A1A1A"
              stroke-width="2"
              stroke-linecap="round"
            />
          </svg>
        </span>
        <span
          class="text-right text-xs font-medium leading-[17px] min-[400px]:text-sm"
          >Развернуть комментарии</span
        >
        <div class="flex flex-row">
          <p-avatarGroup>
            <ng-container *ngFor="let avatar of avatars">
              <p-avatar
                *ngIf="avatar.photo; else withoutImage"
                [image]="avatar.photo"
                shape="circle"
                class="max-[340px]:hidden max-[340px]:first:block"
              />

              <ng-template #withoutImage>
                <p-avatar
                  [label]="avatar.name | swInitials : 2"
                  shape="circle"
                  class="max-[340px]:hidden max-[340px]:first:block"
                />
              </ng-template>
            </ng-container>
          </p-avatarGroup>

          <span class="ml-[10px] text-sm text-black">{{ totalComments }}</span>
        </div>
      </div>
    </button>
  `,
  styles: [
    `
      sw-show-comments-btn .p-avatar-group p-avatar + p-avatar {
        margin-left: -10px !important;
      }

      sw-show-comments-btn .p-avatar-group .p-avatar {
        @apply border;
      }

      sw-show-comments-btn p-avatar {
        @apply h-5 w-5;
      }
      sw-show-comments-btn .p-avatar {
        @apply flex h-5 w-5 text-black;
      }
      sw-show-comments-btn .p-avatar-text {
        @apply text-[12px];
      }
    `,
  ],
  encapsulation: ViewEncapsulation.None,
})
export class ShowCommentsBtnComponent {
  @Input({ required: true }) totalComments!: number;
  @Input({ required: true }) avatars!: {
    name: string;
    photo?: string | null;
  }[];
}
