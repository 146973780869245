import { GemService } from '@/core';
import { Directive, inject, Input } from '@angular/core';
import { Observable } from 'rxjs';

@Directive()
export abstract class GemContext {
  protected readonly gemService = inject(GemService);
  public readonly loading$ = this.gemService.loading$;

  @Input({ required: true })
  recipient!: string;

  abstract sendGems(count: number): Observable<unknown>;
}
