import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output,
} from '@angular/core';

import { ShortNumberPipe } from '@shared/pipes';
import { ButtonComponent } from '@shared/ui/button.component';
import { SVGComponent } from '@shared/ui/svg.component';

@Component({
  selector: 'sw-reaction-button',
  standalone: true,
  imports: [SVGComponent, ShortNumberPipe, ButtonComponent],
  changeDetection: ChangeDetectionStrategy.OnPush,
  template: `
    <div
      class="flex items-center justify-center space-x-[10px] md:w-10 md:flex-col md:space-x-0 md:space-y-[10px]"
    >
      <button
        swButton
        [loading]="loading"
        (click)="clickBtn.emit()"
        type="button"
        class="button-rounded aspect-square h-10 w-10 bg-black/5 text-black"
      >
        <sw-svg icon="plus" width="13px" height="14px" />
      </button>

      <span class="text-sm font-bold leading-[22px]">{{
        value | swShortNumber
      }}</span>

      <div
        class="flex cursor-help items-center justify-center"
        (mouseover)="hoverIcon.emit()"
        (focus)="hoverIcon.emit()"
      >
        <ng-content slot="icon" />
      </div>
    </div>
  `,
})
export class ReactionButtonComponent {
  @Input() loading = false;
  @Input()
  value!: number;

  @Output()
  clickBtn = new EventEmitter<void>();

  @Output()
  hoverIcon = new EventEmitter<void>();

  @Output()
  leaveIcon = new EventEmitter<void>();
}
