import { Location } from '@angular/common';
import { Injectable, inject } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';

export abstract class NavPrev {
  protected readonly router = inject(Router);
  abstract back(fallbackPath: string): void;
}

@Injectable({ providedIn: 'root' })
export class NavPrevService extends NavPrev {
  private history: string[] = [];

  constructor(private location: Location) {
    super();
    this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        this.history.push(event.urlAfterRedirects);
      }
    });
  }

  override back(fallbackPath = '/'): void {
    this.history.pop();
    if (this.history.length > 0) {
      this.location.back();
    } else {
      this.router.navigateByUrl(fallbackPath);
    }
  }
}
