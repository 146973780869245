/**
 * Compares two values deeply.
 *
 * @param {unknown} a - The first value to compare.
 * @param {unknown} b - The second value to compare.
 * @return {boolean} Whether the two values are deeply equal.
 */
export const deepEqual = (a: unknown, b: unknown) => {
  return a === b || JSON.stringify(a) === JSON.stringify(b);
};
