import { Injectable, Provider } from '@angular/core';
import { FormArray, FormControl, FormGroup } from '@angular/forms';

export type EditorTopic = {
  comment: string;
  name: string;
  agreement?: number | null;
};

@Injectable()
export abstract class TopicEditorFormService {
  createFormArray(topics: Partial<EditorTopic>[]) {
    return new FormArray(topics.map(this.createTopicGroup.bind(this)));
  }

  abstract createTopicGroup(topic: Partial<EditorTopic>): FormGroup<{
    name: FormControl<string>;
    comment: FormControl<string>;
    agreement: FormControl<number | null>;
  }>;
}

export function provideTopicEditorService(
  useClass: new () => TopicEditorFormService
): Provider {
  return {
    provide: TopicEditorFormService,
    useClass,
  };
}
