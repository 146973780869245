import { PermissionActionDirective } from '@/shared/directives/permission/permission-action.directive';
import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import {
  GemReactionComponent,
  LikeProjectDirective,
  LikeReactionComponent,
  SendGemsToProjectDirective,
} from '@shared/ui/reactions';

@Component({
  selector: 'sw-project-reactions',
  standalone: true,
  imports: [
    LikeReactionComponent,
    GemReactionComponent,
    LikeProjectDirective,
    SendGemsToProjectDirective,
    PermissionActionDirective,
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
  template: `
    <div
      class="flex flex-row items-center justify-around md:flex-col md:space-y-[22px]"
    >
      <sw-like-reaction
        class="block"
        [attr.guide]="showGuideAttr ? 'like-reactions' : ''"
        swLikeProject
        swPermissionAction
        [recipient]="projectId"
        [isLiked]="isLiked"
        [projectLikes]="likes"
      />
      <sw-gem-reaction
        class="block"
        [attr.guide]="showGuideAttr ? 'gem-reactions' : ''"
        swGemsToProject
        swPermissionAction
        [recipient]="projectId"
        [projectGems]="gems"
      />
    </div>
  `,
})
export class ProjectReactionsComponent {
  @Input({ required: true }) projectId!: string;

  @Input({ required: true }) likes!: number;
  @Input() isLiked = false;

  @Input({ required: true }) gems!: number;

  @Input()
  showGuideAttr = false;
}
