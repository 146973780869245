import { OnboardingService } from '@/core/services/onboarding.service';
import { AfterViewInit, Directive, inject } from '@angular/core';

@Directive({ selector: '[swAppGuide]', standalone: true })
export class AppGuideDirective implements AfterViewInit {
  private readonly onboarding = inject(OnboardingService);

  ngAfterViewInit(): void {
    this.onboarding.start();
  }
}
