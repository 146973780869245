import {
  ChangeDetectionStrategy,
  Component,
  Input,
  inject,
} from '@angular/core';
import { RangeSliderComponent } from './range-slider.component';
import { FormsModule } from '@angular/forms';
import { NgIf } from '@angular/common';
import { MapperPipe } from '../pipes';
import { RATING_LABELS_TOKEN } from '@/core';

@Component({
  selector: 'sw-rating-range',
  standalone: true,
  imports: [NgIf, RangeSliderComponent, FormsModule, MapperPipe],
  changeDetection: ChangeDetectionStrategy.OnPush,
  template: `
    <div class="flex flex-row items-center">
      <div
        class="flex min-h-[66px] basis-[75px] flex-col space-y-[10px]"
        *ngIf="slide.ngControl?.value as slideValue"
      >
        <img
          class="m-auto h-8 w-[34px] object-contain object-center"
          [src]="slideValue | swMapper : iconMapper"
          alt
        />
        <span
          class="text-center text-[10px] font-bold leading-[12px] text-black/50"
          >{{ slideValue | swMapper : ratingLabelsMapper }}</span
        >
      </div>

      <div class="flex-1">
        <sw-range-slider [color]="color" #slide />
      </div>
    </div>
  `,
})
export class RatingRageComponent {
  @Input() color?: 'red' | 'green' = 'green';

  private readonly ratingLabels = inject(RATING_LABELS_TOKEN);

  protected readonly ratingLabelsMapper = (v: number) =>
    this.ratingLabels[v - 1];

  protected readonly iconMapper = (v: number) =>
    `assets/img/icons/slider-${v}.png`;
}
